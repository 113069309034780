import { NotificationEvents } from 'modules/messaging';
import React from 'react';
import SettingsToggleOnly from '../toggles/settingsToggleOnly'
import BlackoutToggleEvent from '../toggles/blackoutToggleEvent';
import { GlobalApplicationState } from "globalApplicationState";
import { ConnectedProps, connect } from "react-redux";
import { lcidMappings } from 'modules/resources/models';
import TextBubble from 'modules/common/components/textBubble';
import {actions} from '../..';
import SingleTextAreaInput from '../singleTextAreaInput';

interface ComponentProps { }
interface ComponentState {
    confirmationActive: boolean,
    profileActive: boolean,
    verifySMSActive: boolean,
    confirmationContent: lcidMappings,
    profileContent: lcidMappings,
    verifySMSContent: lcidMappings,
 }
 interface TextBubble {
    id: string;
    name: string;
}

class MessagingGeneralControls extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props)
        this.state = {
            confirmationActive: false,
            profileActive: false,
            verifySMSActive: false,
            confirmationContent: { },
            profileContent: { },
            verifySMSContent: { },
        }
    }

    public render() {
        const confirmationActive = this.props.notificationSettings.settings[NotificationEvents.AccessGrantedEmail].toggle;
        const profileActive = this.props.notificationSettings.settings[NotificationEvents.UserProfileUpdatedEmail].toggle;
        const verifyActive = this.props.notificationSettings.settings[NotificationEvents.VerifySMSPhone].toggle;
        const maxTextLength = 400;

        const suggestionsForNonSMS = [
            {id: "{{tenantName}}", name: "{{tenantName}}"},
            {id: "{{tenantApp}}", name: "{{tenantApp}}"},
        ] as TextBubble[]

        const smsOptions = [
            {id: "Reply YES to consent", name: "Reply YES to consent"},
            {id: "Reply NO to unsubscribe", name: "Reply NO to unsubscribe"},
            {id: "{{newLine}}", name: "{{newLine}}"},
            {id: "{{userPreferredName}}", name: "{{userPreferredName}}"},
            {id: "{{userFirstName}}", name: "{{userFirstName}}"},
            {id: "{{userLastName}}", name: "{{userLastName}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},
            {id: "{{tenantName}}", name: "{{tenantName}}"},
        ] as TextBubble[]

        return (
            <React.Fragment>
                <div className="settings-section">
                    <SettingsToggleOnly 
                        title={"Send an email confirmation to external users who are granted access"}
                        description={"External and Social users that have been invited will receive a confirmation once they are granted access to the platform."}
                        emailEvent={NotificationEvents.AccessGrantedEmail}
                        textVisible={confirmationActive}
                    />

                    {confirmationActive &&
                        <div>
                            <div className="label-description-block">
                            <SingleTextAreaInput
                                returnTranslatedContent={this.getTranslatedContent}
                                maxTextLength={maxTextLength}
                                placeholder={this.props.emailSubjects[this.eventUppercase(NotificationEvents.AccessGrantedEmail)].toString()}
                                prompt={"Subject"}
                                toLoad={this.props.notificationSettings.settings[NotificationEvents.AccessGrantedEmail].translatedSubject}
                                event={NotificationEvents.AccessGrantedEmail}
                                platform={'email'}
                            />                       
                            </div>
                            <div className="word-bubble-formatting">
                                <p className="suggestions-section-text">Use suggestions to personalize: </p>
                                <TextBubble list={suggestionsForNonSMS}/>
                            </div>
                        </div>
                    }
                </div>

                <div className="settings-section">
                    <SettingsToggleOnly 
                        title={"Email Sparrow Manager of any User Profile Updates"}
                        description={"Sparrow Managers will receive an email informing them of employees who have updated/modified their profile details."}
                        emailEvent={NotificationEvents.UserProfileUpdatedEmail}
                        textVisible={profileActive}
                    />

                    {profileActive &&
                    <div>
                        <BlackoutToggleEvent descriptor="Apply blackout period" event={NotificationEvents.UserProfileUpdatedEmail}/>
                        <div className="input-row">
                            <div className="label-description-block">
                            <SingleTextAreaInput
                                returnTranslatedContent={this.getTranslatedContent}
                                maxTextLength={maxTextLength}
                                disabled={!profileActive}
                                placeholder={this.props.emailSubjects[this.eventUppercase(NotificationEvents.UserProfileUpdatedEmail)].toString()}
                                prompt={"Subject"}
                                toLoad={this.props.notificationSettings.settings[NotificationEvents.UserProfileUpdatedEmail].translatedSubject}
                                event={NotificationEvents.UserProfileUpdatedEmail}
                                platform={'email'}
                            />                       
                            </div>
                            <div className="word-bubble-formatting">
                                <p className="suggestions-section-text">Use suggestions to personalize: </p>
                                <TextBubble list={suggestionsForNonSMS} />
                            </div>
                        </div>
                    </div>
                    }
                </div>

                <div className="settings-section">
                    <SettingsToggleOnly 
                        title={"Verify SMS Phone Numbers"}
                        description={"Enabling this will automatically send users an SMS asking to re-verify and subscribe any time a user changes their phone number."}
                        emailEvent={NotificationEvents.VerifySMSPhone}
                        textVisible={verifyActive}
                    />

                    {verifyActive &&
                    <div>
                        <BlackoutToggleEvent descriptor="Apply blackout period" event={NotificationEvents.VerifySMSPhone}/>
                        <div className="label-description-block">
                            <SingleTextAreaInput
                                returnTranslatedContent={this.getTranslatedContent}
                                maxTextLength={maxTextLength}
                                disabled={!verifyActive}
                                placeholder={this.props.smsBodies[this.eventUppercase(NotificationEvents.VerifySMSPhone)].toString()}
                                prompt={"Text Message"}
                                toLoad={this.props.notificationSettings.settings[NotificationEvents.VerifySMSPhone].translatedBody}
                                event={NotificationEvents.VerifySMSPhone}
                                platform={'sms'}
                            />                 
                        </div>
                        <div className="word-bubble-formatting">
                            <p className="suggestions-section-text">Use suggestions to personalize: </p>
                            <TextBubble list={smsOptions} />
                        </div>
                    </div>
                    }
                </div>
                
            </React.Fragment>
        )
    }

    private getTranslatedContent = (translatedContent: any, platform: string, event: NotificationEvents) => {
        switch(platform) {
            case 'email':
                this.props.setEmailSubject(event, translatedContent);
                break;

            case 'sms':
                this.props.setSMSBody(event, translatedContent);
                break;

            default:
                console.log("unknown platform, error.")
                break;
        }
    }

    private eventUppercase = (eventToFix: string) => {
        if(eventToFix !== null && eventToFix.length > 2)
            return eventToFix[0].toUpperCase() + eventToFix.slice(1);
        else
            return "Invalid string to fix."
    }
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        emailSubjects: state.resources.emailSubjectsResource,
        smsBodies: state.resources.smsBodiesResource,
        pristine: state.settings.pristineNotificationSettingsCopy
    }),
    {
        setEmailSubject: actions.SetEmailSubject,
        setSMSBody: actions.SetSMSBody,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(MessagingGeneralControls));
