
import { UserRoleStrings } from "modules/authorization/models";
import { CurrentUserSettings } from "modules/settings/models";
import { UserRole } from "modules/users";


export const getRole = (userSettings: CurrentUserSettings) => {
    if (userSettings.roles.indexOf(UserRoleStrings.OWNER) >= 0)
        return "Owner";
    else if (userSettings.roles.indexOf(UserRoleStrings.AUTHOR) >= 0)
        return "Author";
    else if (userSettings.roles.indexOf(UserRoleStrings.CONTRIBUTOR) >= 0)
        return "Contributor";
    return "User";
}

/**
 * Get an array of roles that a role should have according to our hierarchy
 */
export const getHierarchyRoles = (role: UserRole): string[] => {
    let hierarchyRoles: string[] = [UserRoleStrings.INTERNAL];

    if (role === UserRole.CONTRIBUTOR || role === UserRole.AUTHOR || role === UserRole.OWNER)
        hierarchyRoles.push(UserRoleStrings.CONTRIBUTOR);

    if (role === UserRole.AUTHOR || role === UserRole.OWNER)
        hierarchyRoles.push(UserRoleStrings.AUTHOR);

    if (role === UserRole.OWNER)
        hierarchyRoles.push(UserRoleStrings.OWNER);

    return hierarchyRoles;
}
