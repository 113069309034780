import * as React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button } from "@mui/material";

import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import confirm from "utils/notyPopups";
import { EventListItem } from "modules/events/models";
import TabContentToolbar from "pages/common/tabContentToolbar";
import { actions } from "modules/events";
import HoverText from "modules/documents/components/action-buttons/hoverText";

interface IEventActionToolbarProps {
    pageId: string;
    selectedItems: EventListItem[];
    actions: EventActions;
    isContributor: boolean;
}

interface EventActions {
    onClearAll: () => void;
    onPreview: () => void;
    onEdit: () => void;
    onClone: () => void;
}

export const EventActionToolbar: React.FunctionComponent<PropsWithRedux> = (props) => {
    const { pageId, selectedItems, actions } = props;

    const getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void, isDisabled: boolean = false) => {
        return (
            <Button aria-label={title.toLowerCase()}
                color="primary"
                startIcon={icon}
                onClick={() => onClick()}
                disabled={isDisabled}
            >
                {title}
            </Button>
        );
    }

    const onManageAttendance = () =>
        props.redirectTo(`/${props.tenantId}/admin/events/attendance/${selectedItems[0].id}`);

    const onDelete = async () => {
        const confirmationMessage: string = (selectedItems.length > 1)
            ? `You're about to permanently delete these ${selectedItems.length} events.`
            : `You're about to permanently delete '${selectedItems[0].title}.`;

        if (await confirm.show({
            title: `Delete Event`,
            text: (
                <div>
                    <div>{`${confirmationMessage} You cannot undo this action.`}</div>
                    <br />
                    <div>Are you sure?</div>
                </div>
            ),
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
        })) {
            if (selectedItems.length === 1) {
                props.deleteEvents([selectedItems[0]]).then(() => {
                    actions.onClearAll();
                });
            }
            else {
                props.deleteEvents(selectedItems).then(() => {
                    actions.onClearAll();
                });
            }
        }
    }

    const getDeleteIcon = () :JSX.Element => {
        const anyPublishedOrScheduled = selectedItems
            .map(si => si.status?.toLowerCase())
            .some(status => status === "published"  || status === "scheduled");
        const isDisabled = props.isContributor && anyPublishedOrScheduled;

        return isDisabled
            ? <HoverText
                underline={false}
                label={getToolbarIcon("Delete", <DeleteIcon />, onDelete, isDisabled)}
                style={{width: "fit-content"}}
            >
                {"Contact your administrator(s) to delete published or scheduled posts"}
            </HoverText>
            : getToolbarIcon("Delete", <DeleteIcon />, onDelete, isDisabled);
    }

    if (!selectedItems.length)
        return <React.Fragment></React.Fragment>;

    return (
        <TabContentToolbar>
            {getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, actions.onClearAll)}
            <Box flexDirection={"row"} display={"flex"}>
                {selectedItems.length === 1 &&
                    <React.Fragment>
                        {(pageId === "published" || pageId === "all") && selectedItems[0].eventType !== "informational" && selectedItems[0].publishedTime !== null &&
                            getToolbarIcon("Manage Participants", <SettingsIcon />, onManageAttendance)
                        }
                        {getToolbarIcon("Edit", <EditIcon />, actions.onEdit)}
                        {getToolbarIcon("Preview", <VisibilityIcon />, () => actions.onPreview())}
                        {getToolbarIcon("Duplicate", <FileCopyIcon />, () => actions.onClone())}
                    </React.Fragment>
                }
                {getDeleteIcon()}
            </Box>
        </TabContentToolbar>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IEventActionToolbarProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        deleteEvents: actions.deleteEvents,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventActionToolbar);