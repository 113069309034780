import React from "react";
import { Typography } from "@mui/material";

import { AuthoringAppBarAction, AuthoringAppBarActions, IAuthoringAppBarAction } from "modules/common/components/authoring/authoringAppBarActions";
import { useContentGuidelines } from "modules/common/hooks/data/useContentGuidelines";
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { DRAFT_CHANGED_BTN_TEXT, DRAFT_MOVE_BTN_TEXT, DRAFT_SAVE_BTN_TEXT } from "../../../../common/components/authoring/constants";
import { getPublishBtnName } from "../../../../common/components/authoring/utils";

import "../../../../common/components/authoring/styles/authoringAppBar.sass";

interface IPostAuthoringActionsProps {
    appBarState: PostAppBarState;
    draftState: PostDraftActionState;
    actions: PostAppBarActions;
}

interface PostAppBarState {
    isSubmission: boolean;
    isContributor: boolean;
    isDraftIdPresent: boolean | string;
}

interface PostDraftActionState {
    isValidDraft: boolean;
    isSettingsDrawerOpen: boolean;
}

interface PostAppBarActions {
    discardPost: () => void;
    previewPost: () => void;
    backToManagePosts: () => void;
    toggleSettingsDrawer: (open: boolean | undefined) => void;
    publishPost: () => Promise<void>;
    savePost: (draftSaveOnly?: boolean, preview?: boolean, redirectTo?: string, isSubmission?: boolean) => Promise<boolean>;
    rejectSubmission: () => void;
    openGuidelines: () => void;
}

const PostAuthoringActions: React.FunctionComponent<PropsWithRedux> = (props) => {
    const {
        appBarState: state,
        draftState,
        actions,
        changedSinceSaved,
    } = props;
    const isSubmissionMode = (state.isContributor && !state.isDraftIdPresent) || state.isSubmission;
    const isReviewMode = state.isSubmission && !state.isContributor;

    const { isEmpty: isGuidelineEmpty } = useContentGuidelines();

    const getSaveLabel = () => {
        if (!state.isSubmission) return DRAFT_SAVE_BTN_TEXT;
        return state.isContributor ? DRAFT_MOVE_BTN_TEXT : DRAFT_CHANGED_BTN_TEXT;
    }

    const onSavePost = async () => {
        isReviewMode
            ? await actions.savePost(true, false, "", true)
            : await actions.savePost(true, false);
    }

    const allActions: Record<AuthoringAppBarAction, IAuthoringAppBarAction | undefined> = {
        [AuthoringAppBarAction.Discard]: {
            onClick: actions.discardPost,
            disabled: !state.isDraftIdPresent
        },
        [AuthoringAppBarAction.Preview]: {
            onClick: actions.previewPost,
            disabled: false
        },
        [AuthoringAppBarAction.Back]: {
            onClick: actions.backToManagePosts,
            disabled: false
        },
        [AuthoringAppBarAction.ToggleSettingsPanel]: {
            onClick: () => actions.toggleSettingsDrawer(undefined),
            disabled: !draftState.isSettingsDrawerOpen,
            label: "Post settings"
        },
        [AuthoringAppBarAction.Publish]: {
            onClick: async () => await actions.publishPost(),
            disabled: !draftState.isValidDraft,
            label: getPublishBtnName(state.isContributor, state.isSubmission, state.isDraftIdPresent)
        },
        [AuthoringAppBarAction.Save]: {
            onClick: onSavePost,
            disabled: !changedSinceSaved,
            label: getSaveLabel()
        },
        [AuthoringAppBarAction.Reject]: {
            onClick: actions.rejectSubmission,
            disabled: false,
        },
        [AuthoringAppBarAction.OpenGuidelines]: {
            onClick: actions.openGuidelines,
            disabled: isGuidelineEmpty
        }
    };

    const getActions = () => {
        if (!isReviewMode) {
            allActions[AuthoringAppBarAction.Reject] = undefined;
        }

        if (isGuidelineEmpty) allActions[AuthoringAppBarAction.OpenGuidelines] = undefined;

        return allActions;
    };

    return (
        <React.Fragment>
            {
                isSubmissionMode &&
                <div className="needs-review-div">
                    <span className="needs-review-dot" />
                    <Typography fontSize={"15px"}>
                        {state.isContributor ? 'Needs Review' : 'Submission'}
                    </Typography>
                </div>
            }
            <AuthoringAppBarActions actions={getActions()} />
        </React.Fragment>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IPostAuthoringActionsProps) => ({
        ...ownProps,
        changedSinceSaved: state.posts.editor.changedSinceSaved
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PostAuthoringActions);
