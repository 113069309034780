export enum SortStyle {
    modifiedDesc,
    modifiedAsc,
    titleDesc,
    titleAsc,
    publishDesc,
    publishAsc,
    expiryDesc,
    expiryAsc,
    typeDesc,
    typeAsc,
    authorDesc,
    authorAsc,
    stateDesc,
    stateAsc,
    submittedDesc,
    submittedAsc,
    startDesc,
    startAsc,
    disableDesc,
    disableAsc,
    languageDesc,
    languageAsc,
    viewsDesc,
    viewsAsc,
};

export enum ContentState {
    Draft,
    Submission,
    Scheduled,
    Published
};

export const availableContentStates: ContentState[] = [
    ContentState.Draft,
    ContentState.Published,
    ContentState.Scheduled,
    ContentState.Submission
];

export const contentStateLabels: Record<ContentState, string> = ({
    [ContentState.Draft]: "Draft",
    [ContentState.Submission]: "Needs Review",
    [ContentState.Scheduled]: "Scheduled",
    [ContentState.Published]: "Published"
});