import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            position: "relative",
            "& a": {
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "none"
                }
            }
        },
        expandableText: {
            opacity: 0,
            overflow: "hidden",
            width: 0,
            "&.expanded": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                opacity: 1,
                transition: "opacity linear 0.1s 0.2s",
                width: "100%"
            },
            "& svg": {
                fill: "#888888",
                marginTop: -3
            }
        },
        listItem: {
            borderLeftColor: "transparent",
            borderLeftStyle: "solid",
            borderLeftWidth: 5,
            height: 45,
            "&:hover": {
                backgroundColor: "inherit",
                "& $listItemText": {
                    fontWeight: 500
                }
            },
            "&.selected": {
                borderLeftColor: theme.palette.secondary.main,
                "& $listItemIcon svg": {
                    fill: theme.palette.secondary.main
                },
                "& $listItemText": {
                    fontWeight: 500
                }
            }
        },
        listItemIcon: {
            minWidth: 36,
            "& svg": {
                fill: "#333333"
            }
        },
        listItemText: {
            color: "#333333",
            fontSize: 14,
            whiteSpace: "nowrap"
        }
    })
);