import React from "react";
import Dialog from "@mui/material/Dialog";

import ContentGuidelinePreviewBody from "./contentGuidelinePreviewBody";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { actions } from "modules/profile";
import Cookies, { COMMON_COOKIE_KEYS } from "utils/cookie";

import { GuidelineHint, HintType } from "modules/common/components/authoring/dialogs/guidelineHint";
import "./contentGuidelines.sass";

interface IContentGuidelinePreviewProps {
    showPreview: boolean;
    onClose: () => void;
    isAuthoring?: boolean;
    hintType?: HintType;
}

const AUTO_HIDE_GUIDELINES_MESSAGE = "Don't automatically show this again";

const ContentGuidelinePreview: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [autoHide, setAutoHide] = React.useState(props.autoHideContentGuidelines ?? false);
    const [openGuidelineHint, setOpenGuidelineHint] = React.useState(false);

    const updateUserSettings = async () => {
        if (props.autoHideContentGuidelines === autoHide) return;

        await props.saveUserSettings({ hideContentGuidelines: autoHide });
    };

    const handleDialogClose = async () => {
        await updateUserSettings();
        props.onClose();

        if (props.hintType && Cookies.isFirstSet(`${props.hintType.toString()}-${COMMON_COOKIE_KEYS.GUIDELINE_PREVIEWED}`))
            setOpenGuidelineHint(true);
    };

    const contentGuidelineHint = React.useMemo(() => {
        if (!props.hintType) return <React.Fragment></React.Fragment>;

        return <GuidelineHint
            open={openGuidelineHint}
            onClose={() => setOpenGuidelineHint(false)}
            hintType={props.hintType}>
        </GuidelineHint>;
    }, [openGuidelineHint, props.hintType]);

    const autoHideOption = React.useMemo((): JSX.Element =>
        <FormControlLabel
            control={
                <Checkbox size="small"
                    checked={autoHide}
                    onChange={(_, checked: boolean) => setAutoHide(checked)}
                />
            }
            label={<Typography variant="caption">{AUTO_HIDE_GUIDELINES_MESSAGE}</Typography>}
        />, [autoHide]);

    return (
        <>
            <Dialog
                open={props.showPreview}
                className="content-guidelines-preview"
                onClose={handleDialogClose}
            >
                <ContentGuidelinePreviewBody
                    onClose={handleDialogClose}
                    isAuthoring={props.isAuthoring} />

                {
                    props.isAuthoring &&
                    <DialogActions className="authoring-actions">
                        {autoHideOption}
                    </DialogActions>
                }
            </Dialog>
            {contentGuidelineHint}
        </>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IContentGuidelinePreviewProps) => ({
        ...ownProps,
        autoHideContentGuidelines: state.settings.currentUser.hideContentGuidelines,
    }),
    {
        saveUserSettings: actions.saveUserSettings,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ContentGuidelinePreview);