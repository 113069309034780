import { GlobalApplicationState } from "globalApplicationState";
import { UserRoleStrings } from "modules/authorization/models";
import RequireRole from "modules/common/components/requireRole";
import PageForbidden from "pages/pageForbidden/pageForbidden";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { Route, RouteComponentProps, withRouter } from "react-router";

const AuthRoute: React.FunctionComponent<PropsWithRedux> = ({
    path,
    roleName,
    component,
    exact = false,
    children,
}) => {
    return <RequireRole roleName={roleName} unauthorizedElement={<PageForbidden />}>
        <Route path={path} component={component} exact={exact}>
            {children}
        </Route>
    </RequireRole>;
};

interface ComponentProps {
    path: string;
    exact?: boolean;
    roleName: UserRoleStrings;
    component?: React.ComponentType<any>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps ) => ({
        ...ownProps,
    }),
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(AuthRoute));
