import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { Tag } from "./models";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Disclaimer from "../disclaimer";
import { tagsApi } from "api/instances";

interface TagList {
  id: string;
  name: string;
  tags: Tag[];
}

class AuthoringWarnings extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      showTagsList: false,
      tagsList: this.getTagsList(),
      onlyRestricted: false
    };
  }

  public render() {
    const hasMixedRestrictions: boolean = this.hasMixedRestrictions();
    const hasMentionWarning: boolean = this.needsMentionWarning();

    return (
      <React.Fragment>
        <div className="section-card-heading">Topics</div>
        {hasMixedRestrictions &&
          <Disclaimer
            icon={<InfoOutlinedIcon />}
            text={
              <React.Fragment>
                <span className="mixed-restrictions-warning">
                  Selecting both a restricted and a non-restricted topic could make the content available to unintended users.
                </span>
              </React.Fragment>
            }
          />
        }
        {hasMentionWarning &&
          <Disclaimer
            icon={<InfoOutlinedIcon />}
            text={
              <React.Fragment>
                <span className="mixed-restrictions-warning">
                  @Mentioning someone in a restricted post will not send a notification to the user if they do not have access to the restricted topic.
                </span>
              </React.Fragment>
            }
          />
        }
      </React.Fragment>
    );
  }

  private getTagsList = (): TagList[] => {
    let tagsList: TagList[] = [];

    tagsApi.getUserTags(true).then(tagGroups => {
      tagGroups.map(tagGroup => {
        const tags: Tag[] = tagGroup.tags.filter((tag) => !tag.disabled);
        if (!!tags.length) {
          tagsList.push({
            id: tagGroup.id,
            name: tagGroup.name,
            tags: tags
          });
        }
        return tagGroup;
      });
    }).catch((_) => {
      tagsList = [];
    });

    return tagsList;
  }

    /*
   * Indicates when the selected topics are a mixture of both restricted and unrestricted topics.
   * returns: boolean, true if there's a mixture.
   */
  private hasMixedRestrictions = (): boolean => {
    if (!this.props.tags || !this.props.tags.length) return false;

    const selectedTopicIds = this.props.tags.map((topic: Tag) => topic.id);

    const hasRestricted = this.state.tagsList.flatMap((topicGroup: TagList) => topicGroup.tags)
        .some((topic: Tag) => selectedTopicIds.includes(topic.id) && topic.restricted);

    const hasNonRestricted = this.state.tagsList.flatMap((topicGroup: TagList) => topicGroup.tags)
    .some((topic: Tag) => selectedTopicIds.includes(topic.id) && !topic.restricted);

    return hasRestricted && hasNonRestricted;
  }

  /*
   * Indicates when a warning due to mentions should be shown.
   * returns: boolean, true if there's a mention in the text and there's ONLY restricted tags.
   */
  private needsMentionWarning = (): boolean => {
    if (!this.props.tags || !this.props.tags.length) return false;
    
    const selectedTopicIds = this.props.tags.map((topic: Tag) => topic.id);

    const isOnlyRestricted = this.state.tagsList
        .flatMap((topicGroup: TagList) => topicGroup.tags)
        .every( (topic: Tag) => !selectedTopicIds.includes(topic.id) || topic.restricted )

    return isOnlyRestricted && this.props.hasMention;
  }
}

interface ComponentProps {
  tags: Tag[];
  onChange: (tags: Tag[]) => void;
  hasMention: boolean;
}

interface ComponentState {
  showTagsList: boolean;
  tagsList: TagList[];
  onlyRestricted: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tagSettings: state.settings.tagSettings
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(AuthoringWarnings);