import { CONTRIBUTOR_PUBLISH_TEXT, CONTRIBUTOR_REPUBLISH_TEXT, DFLT_PUBLISH_TEXT, SUBMISSION_PUBLISH_TEXT } from "modules/common/components/authoring/constants";

/**
 * Computes the name of the label for the publish button. This varies depending on
 * the state of the draft and the permissions of the user viewing the draft. 
 * @param isContributor boolean, if the current user is contributor
 * @param isSubmission boolean, if the current draft was marked as a submission
 * @param isDraftIdPresent boolean, if the current draft has an id
 * @returns string, the label for the publish button in the post creation page
 */
export const getPublishBtnName = (
    isContributor: boolean,
    isSubmission: boolean,
    isDraftIdPresent: boolean | string
    ): string => {
    if (isContributor){
        return (isDraftIdPresent && isSubmission) ? CONTRIBUTOR_REPUBLISH_TEXT : CONTRIBUTOR_PUBLISH_TEXT
    } 
    else {
        return isSubmission ? SUBMISSION_PUBLISH_TEXT : DFLT_PUBLISH_TEXT;
    }
};
