import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { push } from "react-router-redux";
import { EventListItem } from "modules/events/models";
import { UserRoleWrapper } from "routes/_layout/common/components/wrappers/UserRoleWrapper";
import { UserRoleStrings } from "modules/authorization/models";
import { getHighestRole } from "utils/userRoleUtils";

//TODO: Combine moreOptions components into a single one, rather than having multiple. 

const MoreOptions: React.FunctionComponent<PropsWithRedux> = props => {
  let event = props.event;

  return (
    <MoreOptionsIconButton>
      <List disablePadding>
          <MoreOptionsItem
            text="Edit"
            disabled={event.publishedTime !== null && event.eventType.toLowerCase() === "compliance"}
            onClick={() => props.onHandleEdit(event)}
          />
          <MoreOptionsItem
            text="Preview"
            onClick={() => {
                props.onPreview(event);
            }}
          />

          <UserRoleWrapper userRole={UserRoleStrings.AUTHOR}>
            <Divider light />
            {
                event.status.toLowerCase() === "published"  && 
                <MoreOptionsItem
                    text="Manage Participants"
                    disabled={event.eventType.toLowerCase() === "informational"}
                    onClick={() => {
                        props.redirectTo("/" + props.tenant + "/admin/events/attendance/" + event.id);
                    }}
                />
            }
          </UserRoleWrapper>

          <Divider light />

          <MoreOptionsItem
            text="Duplicate"
            onClick={() => {
                props.onClone(event);
            }}
          />

          <UserRoleWrapper userRole={UserRoleStrings.AUTHOR}>
            {
                event.status.toLowerCase() === "published" && <MoreOptionsItem
                    text="Unpublish"
                    onClick={() => {
                        props.onUnpublish(event);
                    }}
                    />
            }
          </UserRoleWrapper>

        <MoreOptionsItem
            text="Delete"
            textStyle={{color: "#B72026"}}
            onClick={() => {
                props.onDelete(event);
            }}
            disabled={getHighestRole(props.currentUser) === UserRoleStrings.CONTRIBUTOR && (event.status.toLowerCase() === "published" || event.status.toLowerCase() === "scheduled")}
        />
      </List>
    </MoreOptionsIconButton>
  );
}

interface ComponentProps {
  event: EventListItem;
  onHandleEdit: (eventToEdit?: EventListItem) => void;
  onPreview: (eventToPreview?: EventListItem) => void;
  onClone: (eventToClone?: EventListItem) => void;
  onDelete: (eventToDelete?: EventListItem) => void;
  onUnpublish: (eventToUnpublish?: EventListItem) => void;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id,
    currentUser: state.settings.currentUser
  }),
  {
    redirectTo: push,
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreOptions);