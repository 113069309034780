import * as React from "react";

import { Tag as ITag } from "../../../models";

import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";


class Tags extends React.Component<ComponentProps, ComponentState> {
  public render() {
    if (!this.props.tags || !this.props.tags.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <div className="info-field">
        <div className="info-icon">
          <LocalOfferOutlinedIcon fontSize="small" />
        </div>
        <div>{this.props.tags.map((tag) => tag.name).join(", ")}</div>
      </div>
    );
  }
}

interface ComponentProps {
  tags: ITag[] | undefined;
}

interface ComponentState {}

export default Tags;