import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import * as actions from 'modules/posts/actionCreator';
import { GlobalApplicationState } from 'globalApplicationState';

import './successfulActionDialog.sass';

class SuccessfulActionDialog extends React.Component<PropsWithRedux, {}> {
    private wrapper: HTMLElement;

    private defaultImage = () =>
        <div className="success-checkmark-pop-wrapper"
            onClick={this.onClickCheckmark}
            ref={(e) => this.wrapper = e!}>
            <div className="success-checkmark-pop-bg">
                <div className="success-checkmark-pop-checkmark"></div>
            </div>
        </div>;

    public render() {
        return (
            <Dialog
                open={true} // logic for open/close is handled outside of this component, TODO: probably move the isOpen into this component.
                className="successful-action-container"
                PaperProps={{sx: {width: "460px"}}}
                onClose={this.props.closeModal ?? this.onClickOK}
            >
                <DialogContent className="dialog">
                    {this.props.customImage ?? this.defaultImage()}

                    <h3 className="title">{this.props.title}</h3>

                    <div className="modal-info">
                        {this.props.displayMessage}
                    </div>

                    {this.props.children ??
                        <div className="button-wrapper">
                            <button className="button primary" onClick={this.props.closeModal ?? this.onClickOK}>OK</button>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }

    private onClickCheckmark = (_e: any) => {
        this.wrapper.className = "";
        setTimeout(() => {
            this.wrapper.className = "success-checkmark-pop-wrapper";
        }, 50);
    };

    private onClickOK = () => {
        if (this.props.afterPublishSuccess) {
            this.props.afterPublishSuccess();
        }
        this.props.hideSuccessfulActionDialog();
    };
}

interface ComponentProps {
    displayMessage: JSX.Element;
    title: string;
    afterPublishSuccess?: () => void;
    closeModal?: () => any;
    customImage?: JSX.Element;
    children?: React.ReactNode;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps
    }),
    {
        hideSuccessfulActionDialog: actions.hidePublishSuccessfulDialog
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(SuccessfulActionDialog);