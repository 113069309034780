import React from "react";

import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import Typography from "@mui/material/Typography";

import { AuthoringAppBarAction, AuthoringAppBarActions, IAuthoringAppBarAction } from "modules/common/components/authoring/authoringAppBarActions";
import { useContentGuidelines } from "modules/common/hooks/data/useContentGuidelines";
import { UserRoleStrings } from "modules/authorization/models";
import { getHighestRole } from "utils/userRoleUtils";
import { DRAFT_CHANGED_BTN_TEXT, DRAFT_MOVE_BTN_TEXT, DRAFT_SAVE_BTN_TEXT } from "modules/common/components/authoring/constants";

import "../../../../common/components/authoring/styles/authoringAppBar.sass";
import { getPublishBtnName } from "modules/common/components/authoring/utils";
interface IEventAuthoringActionsProps {
    appBarState: EventAppBarState;
    actions: EventAppBarActions;
}

interface EventAppBarState {
    isValidDraft: boolean;
    hasDraft: boolean;
    isSubmission: boolean;
    isPublished: boolean;
}

interface EventAppBarActions {
    discardEvent: () => void;
    publishEvent: () => void;
    saveEvent: () => void;
    openGuidelines: () => void;
    rejectSubmission: () => void;
}

const EventAuthoringActions: React.FunctionComponent<PropsWithRedux> = (props) => {
    const {
        appBarState: state,
        actions,
    } = props;

    const { isEmpty: isGuidelineEmpty } = useContentGuidelines();
    const isContributor = getHighestRole(props.currentUser) === UserRoleStrings.CONTRIBUTOR;
    const isReview = props.appBarState.isSubmission && props.currentUser.roles.includes(UserRoleStrings.AUTHOR);

    const getSaveLabel = () => {
        if (!state.isSubmission) return DRAFT_SAVE_BTN_TEXT;
        return isContributor ? DRAFT_MOVE_BTN_TEXT : DRAFT_CHANGED_BTN_TEXT;
    };

    const allActions: Record<AuthoringAppBarAction, IAuthoringAppBarAction | undefined> = {
        [AuthoringAppBarAction.Discard]: {
            onClick: actions.discardEvent,
            disabled: !state.hasDraft
        },
        [AuthoringAppBarAction.Publish]: {
            onClick: async () => actions.publishEvent(),
            disabled: !state.isValidDraft,
            label: getPublishBtnName(isContributor, state.isSubmission, state.isValidDraft)
        },
        [AuthoringAppBarAction.Save]: {
            onClick: async () => actions.saveEvent(),
            disabled: !state.hasDraft && !props.changedSinceSaved,
            label: getSaveLabel()
        },
        [AuthoringAppBarAction.OpenGuidelines]: {
            onClick: actions.openGuidelines,
            disabled: isGuidelineEmpty
        },
        [AuthoringAppBarAction.Reject]: {
            onClick: actions.rejectSubmission,
            disabled: !isReview
        },
        [AuthoringAppBarAction.Back]: undefined,
        [AuthoringAppBarAction.Preview]: undefined,
        [AuthoringAppBarAction.ToggleSettingsPanel]: undefined,
    };

    const eventActions = React.useMemo(
        (): Record<AuthoringAppBarAction, IAuthoringAppBarAction | undefined> => {
            if (!isReview) {
                allActions[AuthoringAppBarAction.Reject] = undefined;
            }

            if (isGuidelineEmpty) allActions[AuthoringAppBarAction.OpenGuidelines] = undefined;
            return allActions;
        }, [allActions, isGuidelineEmpty, isReview]
    );

    return <React.Fragment>
        {
            props.appBarState.isSubmission && !props.appBarState.isPublished &&
            <div className="needs-review-div">
                <span className="needs-review-dot" />
                <Typography fontSize={"15px"}>
                    {isContributor ? 'Needs Review' : 'Submission'}
                </Typography>
            </div>
        }
        <AuthoringAppBarActions actions={eventActions} />
    </React.Fragment>
        
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IEventAuthoringActionsProps) => ({
        ...ownProps,
        changedSinceSaved: state.events.changedSinceSaved,
        currentUser: state.settings.currentUser,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventAuthoringActions);
