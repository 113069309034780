import React from "react";

import { FormControl, MenuItem, Select } from "@mui/material";
import { UserRole } from "modules/users";

interface IRoleSelectorProps {
    selectedRole: UserRole;
    onSelectRole: (newRole: UserRole) => void;
    formControlStyle?: React.CSSProperties;
    selectStyle?: React.CSSProperties;
}

export const RoleSelector: React.FunctionComponent<IRoleSelectorProps> = ({
    selectedRole,
    formControlStyle,
    selectStyle,
    onSelectRole,
}) => {
    return (
        <FormControl size="small" fullWidth style={formControlStyle}>
            <Select
                value={selectedRole}
                variant={"outlined"}
                style={selectStyle}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                }}
            >
                <MenuItem value={UserRole.USER} onClick={() => onSelectRole(UserRole.USER)}>User</MenuItem>
                <MenuItem value={UserRole.CONTRIBUTOR} onClick={() => onSelectRole(UserRole.CONTRIBUTOR)}>Contributor</MenuItem>
                <MenuItem value={UserRole.AUTHOR} onClick={() => onSelectRole(UserRole.AUTHOR)}>Author</MenuItem>
                <MenuItem value={UserRole.OWNER} onClick={() => onSelectRole(UserRole.OWNER)}>Owner</MenuItem>
            </Select>
        </FormControl>
    );
}
