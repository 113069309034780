import { useEffect, useRef, useState } from "react";

import { IReadabilitySettings, IReadabilitySettingsOptionModel } from "modules/settings";
import useIsMounted from "../useIsMounted";
import { cognitiveServicesApi } from "api/instances";
import useIsFirstRender from "../useIsFirstRender";

export type UseReadabilitySettingsReturnType = {
    options?: IReadabilitySettingsOptionModel;
    settings?: IReadabilitySettings;
    onChange: (newSettings: IReadabilitySettings) => void;
    resetToDefault: () => void;
    defaults: IReadabilitySettings | undefined;
    isPristine: boolean;
}

/**
 * Hook to use readability settings and options
 * returns {options, settings, onChange, resetToDefault, defaults, isPristine};
 */
export const useReadabilitySettings = (): UseReadabilitySettingsReturnType => {
    const isMounted = useIsMounted();
    const isFirstRender = useIsFirstRender();

    const [options, setOptions] = useState<IReadabilitySettingsOptionModel | undefined>();
    const [selections, setSelections] = useState<IReadabilitySettings | undefined>();
    const [isPristine, setIsPristine] = useState<boolean>(true);

    const defaults = useRef<IReadabilitySettings | undefined>();

    useEffect(() => {
        const fetch = async () => {
            let newOpts: IReadabilitySettingsOptionModel | undefined;
            let newSelections: IReadabilitySettings | undefined;

            try {
                const OPTS_IDX = 0;
                const SETTINGS_IDX = 1;

                let results = await Promise.all([cognitiveServicesApi.getReadabilityOptions(), cognitiveServicesApi.getReadabilitySettings()]);

                newOpts = results[OPTS_IDX];
                newSelections = results[SETTINGS_IDX];

                // store defaults in case of reset
                if (isFirstRender)
                    defaults.current = newSelections;

            }
            finally {
                if (isMounted()) {
                    setIsPristine(true);
                    setSelections(newSelections);
                    setOptions(newOpts);
                }
            }
        }

        if (isFirstRender)
            fetch();
    }, [isMounted, isFirstRender]);

    const resetToDefault = (): void => {
        setSelections(defaults.current);
        setIsPristine(true);
    }

    const onChange = (newSettings: IReadabilitySettings) => {
        setSelections(newSettings);
        setIsPristine(false);
    }

    return { options, settings: selections, onChange, resetToDefault, defaults: defaults.current, isPristine }
}