import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { POST_TYPES, PostListItem } from "modules/posts/models";
import { push } from "react-router-redux";

const MoreOptions: React.FunctionComponent<PropsWithRedux> = ({
    isContributor = false,
    ...props
}) => {
    let post = props.post;

    return (
        <MoreOptionsIconButton>
            <List disablePadding>
                <MoreOptionsItem
                    text="Edit"
                    onClick={props.onEditPost}
                />
                <MoreOptionsItem
                    text="Preview"
                    onClick={() => {
                        props.onPreview(post);
                    }}
                />
                <Divider light />
                {
                    !isContributor &&
                    <MoreOptionsItem
                        text="Insights"
                        disabled={post.status.toLowerCase() === "draft" || post.status.toLowerCase() === "scheduled"}
                        onClick={() => {
                            props.onInsight(post);
                        }}
                    />
                }
                <MoreOptionsItem
                    text="Duplicate"
                    onClick={() => {
                        props.onClone(post);
                    }}
                />
                {
                    !isContributor && post.postType !== POST_TYPES.COMPLIANCE &&
                    <>
                        <Divider light />
                        <MoreOptionsItem
                            text="Boost"
                            disabled={post.status.toLowerCase() === "draft" || post.status.toLowerCase() === "scheduled"}
                            onClick={() => {
                                props.onBoost(post);
                            }}
                        />
                        <Divider light />
                    </>
                }
                <Divider light />
                {
                    !isContributor && post.status.toLowerCase() !== "draft" &&
                    <MoreOptionsItem
                        text={"Unpublish"}
                        disabled={post.status.toLowerCase() === "draft"}
                        onClick={() => {
                            props.onUnpublish(post);
                        }}
                    />
                }
                <MoreOptionsItem
                    text="Delete"
                    textStyle={{ color: "#B72026" }}
                    onClick={() => {
                        props.onDelete(post);
                    }}
                    disabled={isContributor && post.status.toLowerCase() !== "draft"}
                />
            </List>
        </MoreOptionsIconButton>
    );
}

interface ComponentProps {
    post: PostListItem;
    onPreview: (postToPreview?: PostListItem) => void;
    onPublish: (postToPublish: PostListItem) => void;
    onClone: (postToClone?: PostListItem) => void;
    onBoost: (postToBoost?: PostListItem) => void;
    onUnpublish: (postToUnpublish?: PostListItem) => void;
    onDelete: (postToDelete?: PostListItem) => void;
    isContributor?: boolean;
    onEditPost: () => void;
    onInsight: (postToPreview?: PostListItem) => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant.id
    }),
    {
        redirectTo: push,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MoreOptions);
