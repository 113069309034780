import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as actions from "../../actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import moment from "moment";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";

import confirm from "utils/notyPopups";

import { Event, NotificationRange, Reminder, ValidationChecks } from "../../models";
import EventPreview from "../event-views/eventPreview";
import EventEditor from "./eventEditor";
import Validation from "./validation";

import { PickerLocalization } from "modules/common/components/pickerLocalization";

import "modules/common/components/authoring/authoring.sass";
import { ImageScale } from "modules/posts/models";
import { adminFilesApi, submissionsApi } from "api/instances";
import { ICustomCssModelv1 } from "api/files";
import ContentGuidelinePreview from "modules/settings/components/content/guidelines/contentGuidelinePreview";
import EventAuthoringActionsAppBar from "./components/eventAuthoringActionsAppBar";
import { HintType } from "modules/common/components/authoring/dialogs/guidelineHint";
import { getHighestRole } from "utils/userRoleUtils";
import { UserRoleStrings } from "modules/authorization/models";
import RejectSubmissionDialog from "modules/common/components/authoring/dialogs/rejectSubmissionDialog";
import DraftHistoryButton from "modules/common/components/authoring/draftLog/draftHistoryButton";

const EventCreation: React.FunctionComponent<PropsWithRedux> = (props) => {
    const getNewEvent = React.useMemo((): Partial<Event> => {
        const author: string = props.currentUser
            ? (props.currentUser.preferredName
                ? `${props.currentUser.preferredName} ${props.currentUser.lastName}`
                : `${props.currentUser.firstName} ${props.currentUser.lastName}`)
            : "";
        const authorEmail: string = props.currentUser ? props.currentUser.email : "";
        const bannerColor: string = (props.tenantSettings && props.tenantSettings.themeSettings && props.tenantSettings.themeSettings.color) || "#2196f3";
        const lcid: string = props.tenantSettings ? props.tenantSettings.defaultLCID : "en-us";
        const eventStartTime: string = moment(new Date()).startOf("hour").add(1, "hour").add(1, "day").toISOString();
        const eventEndTime: string = moment(eventStartTime).add(30, "minutes").toISOString();

        const newEvent: Partial<Event> = {
            id: "",
            attachedContent: [],
            author: author,
            authorEmail: authorEmail,
            bannerColor: bannerColor,
            capacity: {
                InPerson: -1,
                NotAttending: -1,
                Online: -1,
                Waitlist: -1
            },
            commentingEnabled: false,
            eventTimes: {
                [eventStartTime]: eventEndTime
            },
            eventType: "informational",
            fileAttachments: [],
            locationLink: "",
            locationLinkLabel: "",
            notifications: {
                emailOnPublish: props.notificationSettings.defaultEventSettings?.emailOnPublish as NotificationRange || "none",
                mobileOnPublish: props.notificationSettings.defaultEventSettings?.mobileOnPublish as NotificationRange || "subscribers",
                reminders: props.notificationSettings.defaultEventSettings?.reminders as Reminder[] || [],
                smsOnPublish: props.notificationSettings.defaultEventSettings?.smsOnPublish as NotificationRange || "none",
                teamsOnPublish: props.notificationSettings.defaultEventSettings?.teamsOnPublish as NotificationRange || "subscribers"
            },
            respondingEnabled: false,
            tags: [],
            translatedContent: {
                [lcid]: {
                    body: "",
                    description: "",
                    title: ""
                }
            }
        };
        return newEvent;

    }, [props.tenantSettings, props.notificationSettings, props.currentUser]);

    const validation = new Validation();
    const [event, setEvent] = useState<Partial<Event> |  undefined>(undefined);

    const [hasError, setHasError] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [validationChecks, setValidationChecks] = useState<ValidationChecks>(validation.getValidationChecks(getNewEvent));
    const [isValid, setIsValid] = useState<boolean>(validation.isValid(validationChecks));
    const [showNotificationSettings, setShowNotificationSettings] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [showReminderSettings, setShowReminderSettings] = useState<boolean>(false);
    const [showRejectSubmissionDialog, setShowRejectSubmissionDialog] = useState<boolean>(false);
    const [openContentGuidelines, setOpenContentGuidelines] = useState<boolean>(false);
    const [isFirstOpenGuidelines, setIsFirstOpenGuidelines] = useState<boolean>(true);
    const [customCss, setCustomCss] = useState<ICustomCssModelv1 | undefined>(undefined);

    const isContributor = getHighestRole(props.currentUser) === UserRoleStrings.CONTRIBUTOR;
    const draftId = props.match.params.draftId;

    const {
        getDraft,
        clearChangedSinceSaved,
        updateUrlComplete,
        getTagSettings,
        publishEvent,
        submitEvent,
        saveEvent,
        redirectTo,
        updateUrl
    } = props;

    useEffect(() => {
        const currValidation = new Validation();

        const onGetDraft = async (draftId: string) => {
            try {
                const draftEvent = await getDraft(draftId, ImageScale.Unprocessed);
                if (!!draftEvent) {
                    setEvent(draftEvent);
                    const checks = currValidation.getValidationChecks(draftEvent);
                    setValidationChecks(checks);
                    setIsValid(currValidation.isValid(checks));
                    setHasError(false);
                }
                else {
                    setHasError(true);
                }
            }
            catch {
                setHasError(true);
            }
            finally {
                setIsFetching(false);
            }
        };

        if (!!draftId) {
            clearChangedSinceSaved();

            if (draftId === "new") {
                setEvent({ ...getNewEvent });

                const checks = currValidation.getValidationChecks(getNewEvent);
                setValidationChecks(checks);

                setHasError(false);
                setIsFetching(false);
                setIsValid(currValidation.isValid(checks));
            } else {
                onGetDraft(draftId);
            }
        }
    }, [draftId, getNewEvent, getDraft, clearChangedSinceSaved]);

    useEffect(() => {
        if (!!draftId) {
            if (props.isUpdatingUrl) {
                setEvent({ ...event, id: draftId });
                updateUrlComplete();
            }
        }
    }, [props.isUpdatingUrl, event, draftId, updateUrlComplete]);

    useEffect(() => {
        moment.locale("en");
    }, [])

    useEffect(() => {
        if (props.tagSettings.shouldFetch || !props.tagSettings.tagGroups.length)
            getTagSettings();
    }, [props.tagSettings.shouldFetch, props.tagSettings.tagGroups.length, getTagSettings]);

    useEffect(() => {
        const fetchAndSetCustomCss = async () => {
            const res = await adminFilesApi.getCustomTinyMceCss();
            setCustomCss(res);
        };

        try {
            if (!props.tenantSettings.showFeatures.tinyMceCustomCssEnabled) return;

            setIsFetching(true);
            fetchAndSetCustomCss();
        }
        finally {
            setIsFetching(false);
        }
    }, [props.tenantSettings.showFeatures.tinyMceCustomCssEnabled]);


    useEffect(() => {

        if (isFirstOpenGuidelines && props.guidelines && !props.isGuidelineEmpty && !props.autoHideContentGuidelines && !props.isSaving)
        {
            setOpenContentGuidelines(true);
            setIsFirstOpenGuidelines(false);
        }
                
    }, [props.guidelines, props.isSaving, props.isGuidelineEmpty, props.autoHideContentGuidelines, isFirstOpenGuidelines]);

    const onHidePreview = () => setShowPreview(false);

    const redirectToManageEvents = useCallback(() => {
        redirectTo(`/${props.match.params.tenant}/admin/events`);
    }, [props.match.params.tenant, redirectTo]);

    const onPublishEvent = useCallback((sendNotification: boolean) => {
        const triggerPublish = async () => {
            const succeeded = isContributor
                ? await submitEvent(draftId)
                : await publishEvent(draftId, sendNotification);

            if (succeeded) redirectToManageEvents();
        }
        
        onHidePreview();
        triggerPublish();
    }, [isContributor, redirectToManageEvents, publishEvent, draftId, submitEvent]);

    const eventPreview = React.useMemo(() => !!event && <EventPreview
        show={showPreview}
        event={event}
        onEditDetails={onHidePreview}
        onPublish={onPublishEvent}
        onClose={onHidePreview}
        isContributor={isContributor}
        inEditor
        />, [showPreview, event, onPublishEvent, isContributor]);

    const contentGuidelinePreview = React.useMemo(() => 
    {
        return <ContentGuidelinePreview
            showPreview={openContentGuidelines}
            onClose={() => setOpenContentGuidelines(false)}
            isAuthoring
            hintType={HintType.Event}
        />
    }
    , [openContentGuidelines]);

    const backToManageEvents = async () => {
        if (!props.changedSinceSaved) return redirectToManageEvents();

        if (await confirm.show({
            title: "Back to Manage Events",
            yesColor: "#3b78ab",
            noColor: "#888888",
            text: (
                <React.Fragment>
                    <div className="emphasis">Your changes will not be saved.</div>
                    <br />
                    <div>Do you want to continue?</div>
                </React.Fragment>
            )
        })) {
            props.clearChangedSinceSaved();
            redirectToManageEvents();
        }
    };

    const discardEvent = useCallback(() => {
        clearChangedSinceSaved();
        redirectToManageEvents();
    }, [clearChangedSinceSaved, redirectToManageEvents]);

    const onSave = useCallback(async (redirect: boolean = true) => {
        if (!event?.id) updateUrl();

        // a contributor moving a submission back to drafts
        const toSave = event?.isSubmission && isContributor ?  {...event, isSubmission: false} : event

        const response = toSave && await saveEvent(toSave)
        if (response?.id && redirect)
            redirectTo(`/${props.match.params.tenant}/admin/events/edit/${response.id}`);

        return response?.id;
    }, [props.match.params.tenant, redirectTo, saveEvent, event, updateUrl, isContributor])

    const previewEvent = useCallback(async () => {
        const draftId = await onSave();
        if (draftId) setShowPreview(true);
    }, [onSave]);

    const commands = React.useMemo(() => <EventAuthoringActionsAppBar
        actions={{
            discardEvent: discardEvent,
            publishEvent: previewEvent,
            saveEvent: onSave,
            openGuidelines: () => setOpenContentGuidelines(true),
            rejectSubmission: () => setShowRejectSubmissionDialog(true)
        }}
        appBarState={{
            hasDraft: Boolean(event?.id),
            isValidDraft: isValid,
            isSubmission: event?.isSubmission ?? false,
            isPublished: event?.publishTime ? new Date(event.publishTime) < new Date() : false,
        }}
    />, [isValid, event, discardEvent, previewEvent, onSave]);

    const onChangeEvent = (value: Partial<Event>) => {
        const changedEvent = { ...event, ...value };
        setEvent(changedEvent);

        const checks = validation.getValidationChecks(changedEvent);
        setValidationChecks(checks);
        setIsValid(validation.isValid(checks));

        props.hasChangedSinceSaved();
    };

    const onRejectSubmission = async (comments?: string) => {
        try {
            await submissionsApi.unsubmitEvent(draftId, comments);
        }
        finally {
            props.clearChangedSinceSaved();
            redirectToManageEvents();
        }
    }

    const getRejectionDialog = (): JSX.Element =>
        <RejectSubmissionDialog
            unsavedChanges={props.changedSinceSaved}
            open={showRejectSubmissionDialog}
            setOpen={setShowRejectSubmissionDialog} 
            saveProgress={async () => !!(await onSave(false)) }
            rejectSubmission={onRejectSubmission}
        />

    return (
        <BasePage fullWidth>
            <Breadcrumb
                items={[
                    { title: "Events", onClick: backToManageEvents },
                    { title: draftId === "new" ? "Create new event" : "Edit event" }
                ]}
                backItem={{ title: "Back to Manage Events", onClick: backToManageEvents }}
                middleComponent={<DraftHistoryButton otherPopoutsClosed={!openContentGuidelines}/>}
                rightComponent={commands}
                isFlex
            />
            <MainContent>
                <div className="authoring-page">
                    {isFetching || !event
                        ? <Loading />
                        : hasError
                            ? <div className="no-event-data">This event could not be loaded.</div>
                            : <PickerLocalization>
                                <EventEditor
                                    customCss={customCss}
                                    event={event}
                                    onChangeEvent={onChangeEvent}
                                    isValid={isValid}
                                    showNotificationSettings={showNotificationSettings}
                                    showReminderSettings={showReminderSettings}
                                    onShowNotificationSettings={setShowNotificationSettings}
                                    onShowReminderSettings={setShowReminderSettings}
                                    validationChecks={validationChecks}
                                />
                            </PickerLocalization>
                    }
                    {eventPreview}
                    {contentGuidelinePreview}
                    {getRejectionDialog()}
                    <ErrorSnackbar
                        errorMessage={props.errorMessage}
                        clearErrorMessage={props.clearErrorMessage}
                    />
                    <LoadingOverlay absolute={true} show={props.isSaving} />
                </div>
            </MainContent>
        </BasePage>
    );
};

interface RouteParams {
    tenant: string;
    draftId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        changedSinceSaved: state.events.changedSinceSaved,
        errorMessage: state.events.errorMessage,
        isSaving: state.events.isSaving,
        isUpdatingUrl: state.events.isUpdatingUrl,
        currentUser: state.settings.currentUser,
        notificationSettings: state.settings.notificationSettings,
        tagSettings: state.settings.tagSettings,
        tenantSettings: state.settings.tenantSettings,
        guidelines: state.guidelines.guidelines,
        isGuidelineEmpty: state.guidelines.isEmpty,
        autoHideContentGuidelines: state.settings.currentUser.hideContentGuidelines
    }),
    {
        clearChangedSinceSaved: actions.clearChangedSinceSaved,
        hasChangedSinceSaved: actions.hasChangedSinceSaved,
        clearErrorMessage: actions.clearErrorMessage,
        getDraft: actions.getDraft,
        getTagSettings: settingsActions.getTagSettings,
        publishEvent: actions.publishEvent,
        submitEvent: actions.submitEvent,
        saveEvent: actions.saveEvent,
        updateUrl: actions.updateUrl,
        updateUrlComplete: actions.updateUrlComplete,
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventCreation);
