import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

import { cognitiveServicesApi, settingsApi } from 'api/instances';
import ContentGuidelines from './guidelines/contentGuidelines';
import { useReadabilitySettings } from 'modules/common/hooks/data/useReadabilitySettings';
import { useContentGuidelines } from 'modules/common/hooks/data/useContentGuidelines';
import { ReadabilitySettingsForm } from './readability/readabilitySettingsForm';
import { actions, IContentGuidelines, IReadabilitySettings } from 'modules/settings';
import Loading from 'modules/common/components/loading';
import '../styles/settingsTabWithSidebar.sass';
import './contentSettings.sass';

enum ContentSettingsTabs {
    Guidelines,
    Readability,
}

export const ContentSettingsForm: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [activeTab, setActiveTab] = React.useState<ContentSettingsTabs>(ContentSettingsTabs.Guidelines);

    const {
        options: readabilityOptions,
        settings: readabilitySettings,
        onChange: onChangeReadabilitySettings,
        defaults: defaultReadabilitySettings,
        resetToDefault: resetReadabilitySettings,
        isPristine: isReadabilityPristine,
    } = useReadabilitySettings();

    const {
        guidelines: contentGuidelines,
        onChange: onChangeContentGuidelines,
        resetToDefault: resetContentGuidelines,
        isEmpty: isGuidelineEmpty,
        isPristine: isGuidelinePristine,
    } = useContentGuidelines();

    const { completeResetSettings, completeSaveSettings } = props;

    // saving actions
    React.useEffect(() => {
        const saveContentSettings = async () => {
            try {
                if (readabilitySettings && props.isSmartContentEnabled && !isReadabilityPristine) await cognitiveServicesApi.saveReadabilitySettings(readabilitySettings);
                if (contentGuidelines && !isGuidelinePristine) await settingsApi.SaveContentSettings(contentGuidelines);
            }
            catch {
                completeSaveSettings();
            }
        };

        if (props.saving) saveContentSettings();
    }, [props.saving, props.isSmartContentEnabled, isGuidelinePristine, isReadabilityPristine, contentGuidelines, readabilitySettings, completeSaveSettings]);

    // resetting actions
    React.useEffect(() => {
        const resetContentSettings = async () => {
            try {
                if (readabilitySettings && !isReadabilityPristine && props.isSmartContentEnabled) resetReadabilitySettings();
                if (contentGuidelines && !isGuidelinePristine) resetContentGuidelines();
            }
            finally {
                completeResetSettings();
            }
        };

        if (props.resetting) resetContentSettings();
    }, [props.resetting, props.isSmartContentEnabled, isGuidelinePristine, isReadabilityPristine, contentGuidelines, readabilitySettings, completeResetSettings, resetReadabilitySettings, resetContentGuidelines]);

    const getContentGuidelinesTab = (): JSX.Element => {
        const onChange = (settings: IContentGuidelines) => {
            props.changePristine(false);
            onChangeContentGuidelines(settings);
        };

        return <ContentGuidelines
            isGuidelineEmpty={isGuidelineEmpty}
            onChange={onChange} />;
    };

    const getReadabilitySettingsTab = (): JSX.Element => {
        const onChange = (settings: IReadabilitySettings) => {
            props.changePristine(false);
            onChangeReadabilitySettings(settings);
        };

        return <ReadabilitySettingsForm
            defaultReadabilitySettings={defaultReadabilitySettings}
            readabilityOptions={readabilityOptions}
            readabilitySettings={readabilitySettings}
            onChangeReadabilitySettings={onChange}
        />;
    };

    const tabMapping = [
        { id: ContentSettingsTabs.Guidelines, title: "Content Guidelines", content: getContentGuidelinesTab() },
        { id: ContentSettingsTabs.Readability, title: "Readability Settings", content: getReadabilitySettingsTab() }
    ];

    const tabs = () => tabMapping.map((tab) => {
        const active = tab.id === activeTab ? '--active' : '';
        return (
            <div key={tab.id} className={`sidebar-tab${active}`} onClick={() => setActiveTab(tab.id)}>
                {tab.title}
            </div>
        );
    });

    const getTabContent = (): JSX.Element | undefined =>
        tabMapping.find((tab) => tab.id === activeTab)?.content;

    if (props.fetching) return <Loading />;

    return (
        <React.Fragment>
            {
                props.isSmartContentEnabled
                    ? <div className="messaging-center-container">
                        <div className="messaging-center-sidebar">
                            {tabs()}
                        </div>
                        <div className="messaging-center-content">
                            {getTabContent()}
                        </div>
                    </div>
                    : getContentGuidelinesTab()
            }
        </React.Fragment>
    );
};

const connector = connect(
    (state: GlobalApplicationState) => ({
        fetching: state.settings.fetching,
        saving: state.settings.saving,
        resetting: state.settings.resetting,
        isSmartContentEnabled: state.settings.tenantSettings.showFeatures.postSmartContentEnabledForTenant,
    }),
    {
        changePristine: actions.changePristineState,
        completeSaveSettings: actions.completeSaveSettings,
        completeResetSettings: actions.completeResetSettings
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(ContentSettingsForm));
