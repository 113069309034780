import * as React from "react";

import { ConnectedProps, connect } from "react-redux";
import { push } from "react-router-redux";
import { Box, Paper, Stack } from "@mui/material";

import { useContentGuidelines } from "modules/common/hooks/data/useContentGuidelines";
import PageForbidden from "pages/pageForbidden/pageForbidden";
import ContentGuidelinePreviewBody from "./contentGuidelinePreviewBody";
import Loading from "modules/common/components/loading";
import { BackItem } from "pages/common/breadcrumb";
import AuthoringAppBar from "modules/common/components/authoring/authoringAppBar";
import BasePage from "pages/common/basePage";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { GlobalApplicationState } from "globalApplicationState";
import { setShouldDisplayNav } from "modules/adminLayout/actionCreator";
import "./contentGuidelines.sass";

const ContentGuidelinesPage: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {
    const { guidelines, isEmpty: isGuidelineEmpty } = useContentGuidelines();
    const { setShouldDisplayNav } = props;

    React.useLayoutEffect(() => {
        setShouldDisplayNav(false);
    }, [setShouldDisplayNav]);

    if (!guidelines) return <Loading />;

    if (isGuidelineEmpty) return <PageForbidden />;

    return (
        <BasePage fullWidth>
            <Stack spacing={2}>
                <div style={{ minHeight: "50px" }}>
                    <AuthoringAppBar
                        backAction={
                            <BackItem textStyle={{ fontWeight: 500 }} title="Back to Admin Portal"
                                onClick={() => props.redirectTo(`/${props.match.params.tenant}/admin`)}
                            />
                        }
                        actions={<React.Fragment />}
                    />
                </div>

                <div className="content-guideline-page">
                    <Paper className="background">
                        <Box>
                            <ContentGuidelinePreviewBody isAuthoring={false} />
                        </Box>
                    </Paper>
                </div>
            </Stack>
        </BasePage>
    );
};

interface RouteParams {
    tenant: string;
}

interface ComponentProps {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        shouldDisplayNav: state.adminLayout.shouldDisplayNav,
    }),
    {
        redirectTo: push,
        setShouldDisplayNav
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ContentGuidelinesPage));