import * as React from "react"

import DownloadReports from "./action-buttons/downloadReports";
import NewPost from "./action-buttons/newPost";
import PostListing from "./post-listing/postListing";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";

class PostManagementPage extends React.Component<ComponentProps, {}> {
  public render() {
    return (
      <BasePage fullWidth>
        <Header
          title={"Manage Posts"}
          rightComponent={
            <React.Fragment>
              {
                !this.props.isContributor &&
                <DownloadReports />
              }
              <NewPost />
            </React.Fragment>
          }
        />
        <MainContent>
          <div className="authoring-page">
            <PostListing/>
          </div>
        </MainContent>
      </BasePage>
    )
  }
}

interface ComponentProps {
  isContributor: boolean;
}

export default PostManagementPage;