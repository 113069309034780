import React from "react";

import { Drawer, DrawerProps, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IAuthoringDrawerProps extends DrawerProps {
    onBookmarkClick: () => void;
}

const AuthoringDrawer: React.FunctionComponent<IAuthoringDrawerProps> = ({
    children,
    onBookmarkClick,
    ...drawerProps
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    return (
        <Drawer
            anchor="right"
            variant="persistent"
            {...drawerProps}
        >
            <>
                {isSmallAndSmaller &&
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={onBookmarkClick} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>}
                {children}
            </>
        </Drawer>
    );
};

export default AuthoringDrawer;