import { IMenuItem } from "./components/MenuItem";

export class MenuUtils {
    private tenant: string;
    private locationPathName: string;

    constructor(tenant: string, locationPathName: string) {
        this.tenant = tenant;
        this.locationPathName = locationPathName;
    }

    private matchesCurrentUrl = (myUrl: string): boolean => {
        const urlWithTenant = myUrl.replace("~/", `/${this.tenant}/`);
        return this.locationPathName.indexOf(urlWithTenant) >= 0;
    };

    private isChildSelected = (childrenItems?: IMenuItem[]): boolean => {
        if (!childrenItems || childrenItems.length === 0) return false;
        return childrenItems
            .some((childItem: IMenuItem) => this.matchesCurrentUrl(childItem.url || ""));
    };

    private subMenuMatchesCurrentUrl = (subMenuItems?: IMenuItem[]): boolean => {
        if (!subMenuItems || subMenuItems.length === 0) return false;

        return subMenuItems.some((subItem: IMenuItem) =>
            this.matchesCurrentUrl(subItem.url || "") || this.isChildSelected(subItem.children)
        );
    };

    public isLinkItemSelected = (menuItem: IMenuItem): boolean => {
        const menuItemUrl = menuItem.url ?? "";

        return menuItemUrl.startsWith("~/")
            ? this.matchesCurrentUrl(menuItemUrl)
            : this.subMenuMatchesCurrentUrl(menuItem.children);
    };
}