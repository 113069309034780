import * as React from "react";
import { DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import FileAttachmentDisplay from "../fileAttachmentDisplay";
import { parseAnchors } from "utils/htmlUtils";
import { AttachedFile } from "modules/gallery";
import { ospreyApi } from "api/instances";

import { GlobalApplicationState } from "globalApplicationState";
import { useSelector } from "react-redux";
import Loading from "modules/common/components/loading";

import "./contentGuidelines.sass";

interface IContentGuidelinePreviewBodyProps {
    onClose?: () => void;
    isAuthoring?: boolean;
}

const ContentGuidelinePreviewBody: React.FunctionComponent<IContentGuidelinePreviewBodyProps> = (props) => {
    const { guidelines } = useSelector((state: GlobalApplicationState) => state.guidelines);
    const { id: tenantId } = useSelector((state: GlobalApplicationState) => state.tenant);
    
    const guidelineAttachments = guidelines?.attachments;
    const [attachedFiles, setAttachedFiles] = React.useState<AttachedFile[]>(guidelines?.attachments ?? []);
    
    React.useEffect(() => {
        const fetchNewUrls = async (filesWithoutUrls: AttachedFile[]) => {
            const updatedFiles = await ospreyApi.getFilesWithUrls(filesWithoutUrls);
            const filesWithUrls = guidelineAttachments?.filter(file => file.fileUrl) ?? [];

            setAttachedFiles([...filesWithUrls, ...updatedFiles]);
        };

        if (guidelineAttachments) {
            const filesMissingUrl = guidelineAttachments.filter(file => !file.fileUrl);

            if (filesMissingUrl.length > 0) {
                fetchNewUrls(filesMissingUrl);
            }
            else {
                const validFiles = guidelineAttachments.filter(file => file.fileUrl);
                setAttachedFiles(validFiles)
            }
        };
    }, [guidelineAttachments]);

    const openInNew = () => {
        window.open(`${tenantId}/admin/guidelines`, '_blank');
    };

    if (!guidelines) return <Loading />;
    
    return (
        <div className="content-guidelines-preview-body">
            <DialogTitle className="header">
                <div className="wrapped-text" style={{fontWeight: "bold"}}> {guidelines.header}</div>

                <div>
                    {
                        props.isAuthoring &&
                        <IconButton onClick={openInNew} size="large">
                            <OpenInNewIcon />
                        </IconButton>
                    }
                    {
                        props.onClose &&
                        <IconButton onClick={props.onClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    }
                </div>
            </DialogTitle>

            <DialogContent>
                <Divider />

                <div className="wrapped-text" style={{paddingTop: "24px"}}>
                    <div dangerouslySetInnerHTML={{ __html: parseAnchors(guidelines.description) }}></div>
                </div>

                { guidelines.description && attachedFiles.length > 0 && <Divider/> }

                {
                    attachedFiles.length > 0 && <FileAttachmentDisplay attachments={attachedFiles} />
                }
            </DialogContent>
        </div>
    );
};

export default ContentGuidelinePreviewBody;