import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from "@mui/material/styles";
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText } from "@mui/material";

import { IMenuItem } from "../MenuItem";
import TenantLink from "modules/common/components/tenantLink";
import { useMenuStyles } from "../../styles/menuItemStyles";

const useAccordionStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordion: {
            backgroundColor: theme.palette.background.default,
            "&::before": {
                display: "none"
            },
            "&$accordionExpanded": {
                margin: 0
            },
            "& $accordion": {
                backgroundColor: "inherit",
                "& $accordionDetails": {
                    borderTop: "1px solid rgba(0, 0, 0, 0.08)",
                    marginLeft: 20
                },
                "& $accordionSummary": {
                    "&:hover": {
                        backgroundColor: theme.palette.background.default
                    }
                }
            }
        },
        accordionDetails: {
            backgroundColor: "#e8e8e8",
            display: "block",
            padding: 0
        },
        accordionExpanded: {
            "& $accordionSummary": {
                minHeight: 45
            },
            "& $accordionSummaryContent": {
                margin: 0
            },
            "& > div > $accordionExpandIcon": {
                transform: "rotate(90deg)"
            }
        },
        accordionExpandIcon: {
            color: "#666666"
        },
        accordionSummary: {
            boxSizing: "border-box",
            display: "inline-flex",
            height: 45,
            margin: 0,
            minHeight: 45,
            minWidth: "100%",
            padding: "0 8px 0 0",
            "&:hover $listItemText": {
                fontWeight: 500
            },
            "&.collapsed": {
                display: "block",
                "& $accordionExpandIcon": {
                    display: "none"
                },
            },
            "&.selected $accordionExpandIcon": {
                color: theme.palette.secondary.main
            }
        },
        accordionSummaryContent: {
            display: "block",
            margin: 0
        },
        subList: {
            padding: 0,
            "& $listItem": {
                "&:hover": {
                    backgroundColor: theme.palette.background.default
                }
            }
        }
    })
);

interface ComponentProps {
    expand?: boolean;
    expandedMenuItem: string;
    hideIcons?: boolean;
    menuItem: IMenuItem;
    selected?: boolean;
    setExpand: (expand: boolean) => void;
    setExpandedMenuItem: (menuItem: string) => void;
  }

export const AccordionWrapper: React.FunctionComponent<ComponentProps & { setExpandedParentMenuItem?: (childMenuItem: string) => void; }> = props => {
    const [expandedMenuItem, setExpandedMenuItem] = React.useState("");

    const accordionClasses = useAccordionStyles();
    const menuClasses = useMenuStyles();    

    const { menuItem } = props;

    if (!!menuItem.children && !!menuItem.children.length) {
        const subList: JSX.Element = (
            <List className={accordionClasses.subList}>
                <div className={menuClasses.menuItem}>
                    {menuItem.children.map((subMenuItem, i) => {
                        if (subMenuItem.hide)
                            return <React.Fragment key={i}></React.Fragment>;

                        return (
                            <AccordionWrapper
                                key={i}
                                {...props}
                                expandedMenuItem={expandedMenuItem}
                                menuItem={subMenuItem}
                                setExpandedMenuItem={setExpandedMenuItem}
                                setExpandedParentMenuItem={props.setExpandedMenuItem}
                            >
                                <TenantLink to={subMenuItem.url || ""} pushToBrowserHistory={subMenuItem.pushToBrowserHistory}>
                                    <ListItem
                                        button
                                        disableRipple
                                        onClick={() => {
                                            if (!!props.setExpandedParentMenuItem)
                                                props.setExpandedParentMenuItem("");
                                            else
                                                props.setExpandedMenuItem("");
                                        }}
                                        className={menuClasses.listItem}
                                    >
                                        <div className={`${menuClasses.expandableText} ${props.expand ? "expanded" : ""}`}>
                                            <ListItemText primary={subMenuItem.label} classes={{ primary: menuClasses.listItemText }} />
                                        </div>
                                    </ListItem>
                                </TenantLink>
                            </AccordionWrapper>
                        );
                    })}
                </div>
            </List>
        );

        return (
            <Accordion
                square
                elevation={0}
                expanded={props.expand && props.expandedMenuItem === menuItem.label}
                onChange={(event: any, expanded: boolean) => {
                    if (expanded && !menuItem.url)
                        props.setExpandedMenuItem(menuItem.label);
                    else
                        props.setExpandedMenuItem("");

                    if (!props.expand)
                        props.setExpand(true);
                }}
                classes={{ expanded: accordionClasses.accordionExpanded }}
                className={accordionClasses.accordion}
            >
                <AccordionSummary
                    expandIcon={<ChevronRightIcon />}
                    disableRipple
                    onClick={(event: any) => {
                        if (!!menuItem.url) {
                            event.stopPropagation();

                            if (props.expandedMenuItem !== menuItem.label)
                                props.setExpandedMenuItem(menuItem.label);
                            else
                                props.setExpandedMenuItem("");
                        }
                    }}
                    classes={{
                        content: accordionClasses.accordionSummaryContent,
                        expandIconWrapper: accordionClasses.accordionExpandIcon
                    }}
                    className={`${accordionClasses.accordionSummary} ${props.expand ? "" : "collapsed"} ${props.selected ? "selected" : ""}`}
                >
                    {props.children}
                </AccordionSummary>
                <AccordionDetails className={accordionClasses.accordionDetails}>
                    {subList}
                </AccordionDetails>
            </Accordion>
        );
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};