import * as React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { actions } from '../..';
import 'styles/settings.sass'

interface ComponentProps {
    descriptor: string;
    event: string;
    secondEvent?: string;
}
 
const BlackoutToggleEvent: React.FC<PropsWithRedux> = ({descriptor, event, secondEvent, eventsWithBlackout, toggleBlackoutPeriod}) => {
        const activated = eventsWithBlackout.includes(event);
        if(secondEvent === undefined)
            secondEvent = "";
        return ( 
            <div>
                <input 
                    className="blackout-checkbox-single"
                    type="checkbox"
                    title="blackoutToggle"
                    onChange={() => toggleBlackoutPeriod(event, secondEvent!, "", "", !activated)}
                    checked={activated}></input>
                <span className="blackout-checkbox-text" onClick={() => toggleBlackoutPeriod(event, secondEvent!, "", "", !activated)}>{descriptor}</span>
            </div>
            );
}
 
const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        eventsWithBlackout: state.settings.applyBlackoutPeriodsTo,
    }),
    {
        toggleBlackoutPeriod: actions.ApplyBlackoutPeriod
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(BlackoutToggleEvent));