import * as React from "react";
import { GlobalApplicationState } from "../../globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import CookieBanner from "./common/components/CookieBanner";

class ExternalLayout extends React.Component<PropsWithRedux, any> {
    public render() {
        return (
            <div id="app-base" className={"external-app"}>
                <div className="primary-content-wrapper-external" >
                    <div className="wrapper" style={{backgroundColor: "#2f4050"}}>
                        <div className="logo-wrapper">
                            <img src="/images/sparrow_banner.png" alt="Sparrow" width="400px" />
                        </div>
                        {this.props.children}
                    </div>
                </div>
                <CookieBanner />
            </div>
        );
    }
}

interface ComponentProps {
    strongBackground?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps
    })
);
type PropsWithRedux = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(ExternalLayout));
