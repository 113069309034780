import React, { useEffect } from "react";

import FilledInput from "../forms/inputs/filledInput";
import Bodyv2 from "./bodyv2";
import { DEFAULT_TRANSLATABLE_CONTENT, TranslatableContent } from "./models";
import { ICustomCssModelv1 } from "api/files";
import { PromptTypeEnum } from "modules/smartContent/models";

interface ITranslatableContentProps {
    translation: TranslatableContent;
    highlightedText?: string;
    activeLcid?: string;
    fetchingAnalyses?: boolean;
    customCss?: ICustomCssModelv1;
    smartContentSuggestion: string;
    showSCDropdownButton?: boolean;
    onBodyFocus?: () => void;
    onFullscreen?: () => void;
    onChangeBody: (newBody: string) => void;
    onChangeTitle: (newTitle: string) => void;
    onChangeSummary: (newSummary: string) => void;
    onShowSmartContentInsert: (highlightedText: string, promptType: PromptTypeEnum) => void;
    clearSmartContentSuggestion?: () => void;
    generateAndOpenSmartContent?: (promptTypeForGeneration: PromptTypeEnum, promptTypeForInsert: PromptTypeEnum, body?: string) => void;
}

interface TextPostContent {
    oldTitle: string,
    oldSummary: string
}

const defaultContentText : TextPostContent = {
    oldTitle:"",
    oldSummary: ""
}

/**
 * Form for translatable content
 * - this is a authoring v2 component
 */
const TranslatableContentEditor: React.FunctionComponent<ITranslatableContentProps> = ({
    translation = DEFAULT_TRANSLATABLE_CONTENT,
    highlightedText = "",
    activeLcid,
    fetchingAnalyses = false,
    customCss,
    smartContentSuggestion,
    onBodyFocus,
    onFullscreen,
    onChangeBody,
    onChangeSummary,
    onChangeTitle,
    onShowSmartContentInsert,
    clearSmartContentSuggestion,
    generateAndOpenSmartContent
}) => {
    // destructure values
    const { body, title, description } = translation;

    const [contentText, setContentText] = React.useState<TextPostContent>(defaultContentText);
    const [titleHasAnyValue, setTitleHasAnyValue] = React.useState<boolean>(title.length <= 0);
    const [summaryHasAnyValue, setSummaryHasAnyValue] = React.useState<boolean>(description.length <= 0);

    //Makes sure that these values are always updated in case of a undo.
    useEffect(() => {
        setTitleHasAnyValue(title.length <= 0);
        setSummaryHasAnyValue(description.length <= 0);
    }, [title, description])

    /**
     * Grab title change and pass to parent change handler
     */
    const onBlurTitle = (event: React.FocusEvent<HTMLInputElement>) => {
        let newTitle = event.target.value as string;
        if (contentText.oldTitle !== newTitle) {
            onChangeTitle(newTitle);
        }
    }

    /**
     * Grab summary change and pass to parent change handler
     */
    const onBlurSummary = (event: React.FocusEvent<HTMLInputElement>) => {
        const newSummary = event.target.value as string;
        if (contentText.oldSummary !== newSummary) {
            onChangeSummary(newSummary)
        }
    }

    /**
     * Tracks the original value written in title textfield
     */
    const onFocusTitle = (event: React.FocusEvent<HTMLInputElement>) => {
        let oldTitle = event.target.value;
        setContentText({...contentText, oldTitle})
    }

    /**
     * Tracks the original value written in summary textfield
     */
    const onFocusSummary = (event: React.FocusEvent<HTMLInputElement>) => {
        let oldSummary = event.target.value;
        setContentText({...contentText, oldSummary})
    }

    return (
        <>
            <FilledInput
                refreshable
                label={"Title"}
                onUndo={(newValue: string | undefined) => onChangeTitle(newValue || "")}
                required
                value={title}
                characterCount
                maxCount={100}
                inputProps={{
                    placeholder: "Enter a title",
                    onFocus: onFocusTitle,
                    onBlur: onBlurTitle,
                }}
                setMagicButtonDisabled={(disabled: boolean) => setTitleHasAnyValue(disabled)}
                smartContentButtonDisabled={titleHasAnyValue}
                promptType={PromptTypeEnum.Title}
                onSmartContentGenerate={generateAndOpenSmartContent}
            />
            <FilledInput
                refreshable
                onUndo={(newValue: string | undefined) => onChangeSummary(newValue || "")}
                label={"Summary (optional)"}
                value={description}
                characterCount
                maxCount={300}
                inputProps={{
                    placeholder: "Give an overview of your post to help people quickly see what it's about.",
                    style: {
                        fontSize: 16
                    },
                    onFocus: onFocusSummary,
                    onBlur: onBlurSummary
                }}
                onSmartContentGenerate={generateAndOpenSmartContent}
                setMagicButtonDisabled={(disabled: boolean) => setSummaryHasAnyValue(disabled)}
                smartContentButtonDisabled={summaryHasAnyValue}
                promptType={PromptTypeEnum.Summarize}
            />
            <Bodyv2
                fetchingAnalyses={fetchingAnalyses}
                activeLcid={activeLcid}
                value={highlightedText || body}
                editorOptions={{ min_height: 670 }}
                onChange={onChangeBody}
                onFocus={onBodyFocus}
                onFullscreen={onFullscreen}
                customCss={customCss}
                showSCDropdownButton={true}
                onShowSmartContentInsert={onShowSmartContentInsert}
                smartContentSuggestion={smartContentSuggestion}
                clearSmartContentSuggestion={clearSmartContentSuggestion}
                enableSmartContentSummarizeButton={summaryHasAnyValue}
                enableSmartContentTitleButton={titleHasAnyValue}
                generateAndOpenSmartContent={generateAndOpenSmartContent}
            />
        </>
    )
}

export default TranslatableContentEditor;
