import React from "react";

import { IGPTChoice, PromptTypeEnum } from "./models";
import { SmartContentSuggestion } from "./smartContentSuggestion";
import { ISmartContentListPageProps, SmartContentListPage } from "./smartContentListPage";

interface ISmartContentTitles extends ISmartContentListPageProps {
    titles: IGPTChoice[];
    onApplyTitle: (newTitle: string) => void;
}

const SmartContentTitles: React.FunctionComponent<ISmartContentTitles> = ({
    titles,
    onApplyTitle,
    ...props
}) => {
    // before passing to propped function, scroll to bottom of list so spinner shows
    const onGenerateMoreHandler = async (promptType: PromptTypeEnum): Promise<void> => {
        const element = document.getElementById("smart-content-titles-list");
        if (element)
            element.scroll({ top: element.scrollHeight, behavior: "smooth" });

        await props.onGenerateMore(promptType);
    }

    return (
        <SmartContentListPage
            {...props}
            contentId="smart-content-titles-list"
            onGenerateMore={onGenerateMoreHandler}
            enableGenerateMoreListButton
            title={"Suggested Titles"}
            disclaimerText="Applying a suggestion will clear and replace any existing text on the title of your post."
        >
            {titles.map(({ index, message: { content } }: IGPTChoice) =>
                <SmartContentSuggestion
                    copyToClipboard
                    key={`${index}-title`}
                    onConfirm={() => onApplyTitle(content)}
                    suggestion={{ suggestion: content, suggestionId: index.toString() }}
                    confirmLabel="APPLY"
                    confirmPosition="inline"
                />)}
        </SmartContentListPage>
    );
}

export { SmartContentTitles };
