import * as network from "utils/network";
import * as Actions from "./actions";
import * as localeActions from "../localization/actions";

import {
    ClientSettings,
    ClientSettingsTagGroup,
    TenantSettings,
    TenantSettingsTagGroup,
    TenantSettingsTag,
    ReactionTab,
    TenantAttribute,
    ThemeSettings,
    CurrentUserSettings,
    ExternalPostSource,
    TranslatableLanguage,
} from "./models";

import uuid from "uuid";
import { AppThunkResult } from "typedActions";
import { messagingApi, settingsApi } from "api/instances";
import { errorAlert } from "utils/notyPopups";
import { TenantNotificationSettings } from "modules/messaging";
import { lcidMappings } from "modules/resources";
import { isBannerText } from "utils/bannerText";

export const getClientSettings = (): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.ClientSettings;

    dispatch(Actions.CreateFetchClientConfigInitAction({}));

    return network
        .CreateApiRequestInit(
            uri,
            {
                type: network.RequestMethod.GET,
                params: {}
            },
            getState
        )
        .then((request) => {
            return fetch(request.uri, request.init).then((response) => {
                if (response.status === 200) {
                    return response.json().then((clientSettings: ClientSettings) => {
                        if (clientSettings) {
                            dispatch(localeActions.UpdateLangPref(clientSettings.defaultLCID));
                            dispatch(
                                Actions.CreateFetchClientConfigCompleteAction({
                                    clientSettings: { ...clientSettings },
                                    succeeded: true
                                })
                            );
                        }
                    });
                } else {
                    dispatch(Actions.CreateFetchClientConfigCompleteAction({ clientSettings: null, succeeded: false }));
                }
            });
        });
};

export const getTenantSettings = (): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.TenantSettings;

    dispatch(Actions.CreateFetchTenantConfigInitAction({}));
    return network
        .CreateApiRequestInit(
            uri,
            {
                type: network.RequestMethod.GET,
                params: {},
            },
            getState
        )
        .then((request) => {
            return fetch(request.uri, request.init).then((response) => {
                if (response.status === 200) {
                    return response.json().then((tenantSettings: TenantSettings) => {
                        if (tenantSettings) {
                            dispatch(localeActions.UpdateLangPref(tenantSettings.defaultLCID));
                            dispatch(
                                Actions.CreateFetchTenantConfigCompleteAction({
                                    tenantSettings: { ...tenantSettings },
                                    succeeded: true,
                                })
                            );
                        }
                    });
                } else {
                    dispatch(Actions.CreateFetchTenantConfigCompleteAction({ tenantSettings: null, succeeded: false }));
                }
            });
        });
};

export const getNotificationSettings = (): AppThunkResult => (dispatch, getState) => {

    const uri = getState().config.FetchNotificationSettings;

    dispatch(Actions.CreateFetchNotificationSettingsInitAction({}))
    return network
        .CreateApiRequestInit(
            uri,
            {
                type: network.RequestMethod.GET,
            },
            getState
        )
        .then((request) => {
            return fetch(request.uri, request.init).then((response) => {
                if (response.status === 200) {
                    return response.json().then((notificationSettings: TenantNotificationSettings) => {
                        if (notificationSettings) {
                            dispatch(Actions.CreateFetchNotificationSettingsCompleteAction({notificationSettings, succeeded: true}))
                        }
                    })
                }
            })
        })
}

export const getTagSettings = (): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.FetchTagSettings;

    dispatch(Actions.FetchTagSettingsInitAction({}));

    return network
        .CreateApiRequestInit(
            uri,
            {
                type: network.RequestMethod.GET,
            },
            getState
        )
        .then((request) => {
            return fetch(request.uri, request.init).then((response) => {
                if (response.status === 200) {
                    return response.json().then((tagSettings: ClientSettingsTagGroup[]) => {
                        dispatch(Actions.FetchTagSettingsCompleteAction({ tagSettings, succeeded: true }));
                    });
                }
            });
        })
}

export const getTenantAttributes = () => (dispatch, getState): Promise<TenantAttribute[]> => {
    return settingsApi.GetTenantAttributes()
      .then(tenantAttributes => {
        dispatch(Actions.FetchTenantAttributesComplete({ succeeded: true, tenantAttributes }))
        return tenantAttributes;
      })
      .catch(err => {
        dispatch(Actions.FetchTenantAttributesComplete({ succeeded: false, tenantAttributes: [], err }));
        return [];
      });
  }

export const getUserTagSettings = (): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.FetchUserTagSettings;

    dispatch(Actions.FetchUserTagSettingsInitAction({}));

    return network
        .CreateApiRequestInit(
            uri,
            {
                type: network.RequestMethod.GET,
            },
            getState
        )
        .then((request) => {
            return fetch(request.uri, request.init).then((response) => {
                if (response.status === 200) {
                    return response.json().then((tagSettings: ClientSettingsTagGroup[]) => {
                        dispatch(Actions.FetchUserTagSettingsCompleteAction({ tagSettings, succeeded: true }));
                    });
                }
            });
        })
}

export const getUserSettings = (): AppThunkResult => (dispatch, getState) => {
    const uri = getState().config.FetchUserSettings;

    dispatch(Actions.CreateFetchUserSettingsInitAction({}));
    return network
        .CreateApiRequestInit(
            uri,
            {
                type: network.RequestMethod.GET,
                queryParams: {},
                params: {},
            },
            getState
        )
        .then((request) => {
            return fetch(request.uri, request.init).then((response) => {
                if (response.status === 200) {
                    return response.json().then((currentUser: CurrentUserSettings) => {
                        dispatch(Actions.CreateFetchUserSettingsCompleteAction({ user: currentUser, succeeded: true }));
                    });
                }
            });
        });
};

export const updateUserSettings = (userSettings: Partial<CurrentUserSettings>) => (dispatch, getState): void => {
    dispatch(Actions.UpdateUserSettings({ userSettings }));
};


export const changePristineState = (newState: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateChangePristineStateAction({newState}));
}

export const setShowInvitedExternalUsers = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetShowInvitedExternalUsersEnabled({enabled}));
}

export const setPostNotificationsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetPostNotificationsEnabled({enabled}));
}

export const setSmsNotificationsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetSmsNotificationsEnabled({enabled}));
}

export const setEmailNotificationsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetEmailNotificationsEnabled({enabled}));
}

export const setDisplayName = (displayName: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateTenantDisplayNameChangedAction({ displayName }));
};

export const setCommentsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateCommentsEnabledChangedAction({ enabled }));
};

export const setManageTopicsAudiencesInAdmin = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateManageTopicsAudiencesInAdminChangedAction({ enabled }));
};

export const setExternalPostPurgeEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateExternalPostPurgeChangedAction({ enabled }));
}

export const setEveryoneAudienceEnforcementEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateEveryoneAudienceEnforcementChangedAction({ enabled }));
}

export const setMobileSyncEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetMobileSyncEnabledAction({enabled}));
}

export const setSyncAllAdUsersEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetSyncAllAdUsersEnabledAction({enabled}));
}

export const setMobileSyncNumberSelected = (numberType: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetMobileSyncNumberSelectedAction({numberType}));
}

export const setSyncLevel = (syncLevel: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetSyncLevelAction({syncLevel}));
}

export const setIsCommentingEnabledForNewPostsByDefault = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateIsCommentingEnabledForNewPostsByDefaultChangedAction({ enabled }));
};

export const setGifSelectionEnabledForComments = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateGifSelectionEnabledForCommentsAction({ enabled }));
}

export const setCommentModerationEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateCommentModerationEnabledChangedAction({ enabled }));
};

export const setCommentMaxLength = (maxLength: number): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateCommentsMaxLengthChangedAction({ maxLength }));
};

export const setExternalAuthEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetExternalAuthEnabledAction({ enabled }));
};

export const setReactionsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateToggleReactionsAction({ enabled }));
}

export const setIsReactingEnabledForNewPostsByDefault = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateIsReactingEnabledForNewPostsByDefaultChangedAction({ enabled }));
};

export const setReactionEnabled = (id: string, enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateReactionEnabledChangedAction({ id, enabled }));
};

export const setReactionName = (id: string, name: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateReactionNameChangedAction({ id, name }));
};

export const moveReaction = (id: string, up: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateReactionReorderAction({ id, up }));
};

export const setLanguageSettings = (chosenLanguages: string[], defaultLanugage?: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetLanguageSettings({ chosenLanguages, defaultLanguage: defaultLanugage }));
    let lcid = chosenLanguages[chosenLanguages.length - 1];
    return dispatch(Actions.CreateSetActiveLanguageAction({ lcid }));
};

export const setActiveLanguage = (lcid: string): AppThunkResult => (dispatch, _) => {
    return dispatch(Actions.CreateSetActiveLanguageAction({ lcid }));
};

export const setTimeZone = (timeZone: string) : AppThunkResult => (dispatch, _) => {
    return dispatch(Actions.CreateSetTimeZoneAction({timeZone}));
}

export const completeResetSettings = (): AppThunkResult => async (dispatch, _) => {
    return dispatch(Actions.CreateResetSettingsCompleteAction({succeeded: true}));
}

export const completeSaveSettings = (): AppThunkResult => async (dispatch, _) => {
    return dispatch(Actions.CreateSaveSettingsCompleteAction({succeeded: true}));
}

export const saveTenantSettings = (): AppThunkResult => async (dispatch, getState) => {
    dispatch(Actions.CreateSaveTenantSettingsInitAction({}));
    // validation
    const tagGroups = getState().settings.tenantSettings.tagGroups;
    if (tagGroups.some((g) => !g.name || g.name.trim().length === 0)) {
        dispatch(Actions.SetErrorMessage({ msg: "A topic group name is required!" }));
        return; // fail early if validation errors
    } else {
        dispatch(Actions.SetErrorMessage({ msg: null }));
    }

    const allTags = tagGroups.reduce((a: TenantSettingsTag[], g) => a.concat(g.tags), []);
    if (allTags.some((t) => !t.name || t.name.trim().length === 0)) {
        dispatch(Actions.SetErrorMessage({ msg: "A topic name is required!" }));
        return; // fail early if validation errors
    } else if (allTags.some((t) => t.restricted && t.restrictedTo.length === 0)) {
        dispatch(Actions.SetErrorMessage({ msg: "Restricted topics must have at least one audience chosen!" }));
        return; // fail early if validation errors
    } else {
        dispatch(Actions.SetErrorMessage({ msg: null }));
    }

    const tc = getState().settings.tenantSettings.translatedContent;
    for (let lcid in tc) {
        if (Object.values(tc[lcid]).some ((value) => typeof value !== "boolean" &&  (value === null || value.trim().length === 0))) {
            dispatch(Actions.SetErrorMessage({ msg: "All chosen languages must include translated text. (See 'Language' tab)"}));
            return;
        } else {
            dispatch(Actions.SetErrorMessage({ msg: null }));
        }

        //Text validiation in case one of the mappings are too long then 100 or 50.
        let textTooLong = false;

        Object.keys(tc[lcid]).forEach((key) => {
            let currentMaxtextLength = isBannerText(key) ? 100 : 50;

            if(tc[lcid][key] && tc[lcid][key].length > currentMaxtextLength) {
                textTooLong = true;
            }
        })

        if(textTooLong) {
            dispatch(Actions.SetErrorMessage({ msg: "Translated text for one or more banners exceeds the maximum character length. "}));
            return;
        } else {
            dispatch(Actions.SetErrorMessage({ msg: null }));
        }
    }

    const state = getState();

    for(var key in state.settings.notificationSettings.settings) {
        var translatedSubject = state.settings.notificationSettings.settings[key].translatedSubject;
        var translatedBody = state.settings.notificationSettings.settings[key].translatedBody;
        var translatedContent = state.settings.notificationSettings.settings[key].translatedContent;

        const eventToCheck = key[0].toUpperCase() + key.slice(1);

        if(translatedSubject[state.settings.tenantSettings.defaultLCID] === "" && Object.keys(translatedSubject).length > 1)
            state.settings.notificationSettings.settings[key].translatedSubject[state.settings.tenantSettings.defaultLCID] = state.resources.emailSubjectsResource[eventToCheck] ?? "INVALID EVENT SUBJECT";

        if(translatedBody[state.settings.tenantSettings.defaultLCID] === "" && Object.keys(translatedBody).length > 1)
            state.settings.notificationSettings.settings[key].translatedBody[state.settings.tenantSettings.defaultLCID] = state.resources.smsBodiesResource[eventToCheck] ?? "INVALID EVENT BODY";

        if(translatedContent[state.settings.tenantSettings.defaultLCID]?.m_Item1 === "" && Object.keys(translatedContent).length > 1) {
            state.settings.notificationSettings.settings[key].translatedContent[state.settings.tenantSettings.defaultLCID].m_Item1 = state.resources.pushContentsResource[eventToCheck]?.m_Item1 ?? "INVALID EVENT TITLE";
            state.settings.notificationSettings.settings[key].translatedContent[state.settings.tenantSettings.defaultLCID].m_Item1 = state.resources.pushContentsResource[eventToCheck]?.m_Item2 ?? "INVALID EVENT BODY";
        }
    }

    dispatch(Actions.CreateSaveTenantSettingsInitAction({}));

    messagingApi.UpdateNotificationSettings(state.settings.notificationSettings)
    .then(() => {
        if (state.settings.applyBlackoutPeriodsTo)  {
            messagingApi.ApplyBlackoutPeriods(state.settings.applyBlackoutPeriodsTo);
        }
    });
    return (
        settingsApi.UpdateTenantSettings(state.settings.tenantSettings))
        .then((response) => {
            dispatch(Actions.CreateSaveTenantSettingsCompleteAction({ tenantSettings: response as TenantSettings, notificationSettings: state.settings.notificationSettings, succeeded: true}));
        })
        .catch((error) => {
            dispatch(Actions.CreateSaveTenantSettingsCompleteAction({ tenantSettings: null, notificationSettings: null, succeeded: false}));
            errorAlert(parseError(error.response.data.Message), 5000);
        })
};

export const GetLanguageTranslation = (outputLCID: string, defaultLCID: string): AppThunkResult => async (dispatch, getState) => {
    const state = getState();
    let translateableLanguageObj = state.settings.tenantSettings.translatedContent[defaultLCID] as TranslatableLanguage
    translateableLanguageObj.outputLCID = outputLCID;

    return (
        settingsApi.GetLanguageTranslation(translateableLanguageObj))
        .then((response) => {
            dispatch(Actions.CreateGetLanguageTranslationCompleteAction({ translatedContent: response as TranslatableLanguage, succeeded: true}));
            return response as TranslatableLanguage;
        })
        .catch((error) => {
            dispatch(Actions.CreateGetLanguageTranslationCompleteAction({ translatedContent: null, succeeded: false}));
            errorAlert(parseError(error.response.data.Message), 5000);
        })
};

export const setActiveReactionTab = (activeReactionTab: ReactionTab): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetActiveReactionTabAction({ activeReactionTab }));
};

export const setResetLocalUserPassword = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetResetLocalUserPasswordAction({ enabled }))
}

export const setActiveTagGroup = (id: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetActiveTagGroupAction({ id }));
};

export const addTagGroup = (): AppThunkResult => (dispatch, getState) => {
    const tagGroup = {
        id: uuid(),
        name: "",
        tags: new Array<TenantSettingsTag>(),
        disabled: false,
        isNew: true,
    } as TenantSettingsTagGroup;
    dispatch(Actions.CreateAddTagGroupAction({ tagGroup }));
};

export const updateTagGroup = (id: string, name: string, disabled: boolean, deleted: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateUpdateTagGroupAction({ id, name, disabled, deleted }));
};

export const addTag = (tagGroupId: string): AppThunkResult => (dispatch, getState) => {
    const tag = {
        id: uuid(),
        name: "",
        mandatory: false,
        disabled: false,
        deleted: false,
        default: false,
        restricted: false,
        isNew: true,
        restrictedTo: [],
        defaultFor: [],
        mandatoryFor: [],
    } as TenantSettingsTag;
    dispatch(Actions.CreateAddTagAction({ tagGroupId, tag }));
};

export const updateTag = (
    id: string,
    name: string,
    mandatory: boolean,
    defaultValue: boolean,
    disabled: boolean,
    deleted: boolean,
    restricted: boolean,
    restrictedTo: string[],
    mandatoryFor: string[],
    defaultFor: string[]
): AppThunkResult => (dispatch, getState) => {
    dispatch(
        Actions.CreateUpdateTagAction({
            id,
            name,
            mandatory,
            defaultValue,
            disabled,
            deleted,
            restricted,
            restrictedTo,
            mandatoryFor,
            defaultFor,
        })
    );

    return Promise.resolve(null);
};

export const parseError = (error: string) => {
    switch(error) {
        case "AuthorNameFieldEmpty":
            return "Author name must not be empty";

        case "NameFieldEmpty":
            return "Name must not be empty";

        case "MinimumTagRequired":
            return "Must select at least one topic";

        case "UrlFieldEmpty":
            return "Url must not be empty";

        case "UrlFieldInvalid":
            return "Invalid RSS url entered";

        case "ExternalPostSourceIsNull":
        case "ExternalPostNotFound":
            return "External post not found"

        default:
            return error;
    }
};

export const moveTag = (tagId: string, tagGroupId: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateMoveTagAction({ tagId, tagGroupId }));
};

export const resetTenantSettingsToPristine = (): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateResetTenantSettingsToPristineAction({}));
};

export const setThemeSettings = (enabled: boolean, themeSettings?: ThemeSettings): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateThemeSettingsChangedAction({ enabled, themeSettings }));
};

export const setBannerColorOption = (option: number | string, color: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateBannerColorOptionsChangedAction({ option, color }));
};

export const setSpecialtyColorOption = (option: number | string, color: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSpecialtyColorOptionsChangedAction({ option, color }));
};

export const setMobileColorOption = (option: number | string, color: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateMobileColorOptionsChangedAction({ option, color }));
};

export const setTranslatedText = (key: string, text: string, lcid: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetTranslatedText({ key, text, lcid }));
};

export const SetEmailSubject = (event: string, content: lcidMappings): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetEmailSubject({ event, content }));
}

export const SetSMSBody = (event: string, content: lcidMappings): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetSMSBody({ event, content }));
}

export const SetMobileContent = (event: string, content: lcidMappings): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetMobileContent({ event, content }))
}

export const SetEmailName = (name: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetEmailName({ name }));
}

export const SetEmailSender = (name: string, domain: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetEmailSender({ name, domain }));
}

export const SetToggleShareUrl = (shareToggleEvent: string, enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateToggleShareUrlAction({ shareToggleEvent, enabled }));
};

export const SetShareUrl = (shareEvent: string, url: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetShareUrlAction({ shareEvent, url }));
}

export const SetShareName = (shareEvent: string, name: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetShareNameAction({ shareEvent, name }));
}

export const setFlaggingNotificationWait = (waitTime: number): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetFlaggingNotificationWait({ value: waitTime }));
};

export const setExternalPostPurgeInDays = (days: number): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetExternalPostPurgeInDays({ days }));
};

export const setMandatoryExpiryTime = (days: number): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetMandatoryExpiryTime({ days }));
};

export const toggleMandatoryExpiryTime = (): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.ToggleMandatoryExpiryTime({}));
};

export const setSupportEmail = (email: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetSupportEmail({ email }));
};

export const setSurveysEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetSurveysEnabled({ enabled }));
};

export const setChatEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetChatEnabled({ enabled }));
};

export const setDocumentsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetDocumentsEnabled({ enabled }));
};

export const setMobilePublishingEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetMobilePublishingEnabled({ enabled }));
};

export const setMobileEventsEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetMobileEventsEnabled({ enabled }));
};

export const setPortalPagesEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetPortalPagesEnabled({ enabled }));
};

export const setRestrictedBannerEnabled = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetRestrictedBannerEnabled({ enabled }));
};

export const removeNewTag = (groupId: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.RemoveNewTagAction({ groupId }));
};

export const addExternalPostSource = (source: ExternalPostSource): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.AddExternalPostSourceInitAction({ source }));
    return (settingsApi.AddTenantSettingsExternalPostSource(source))
        .then((response) => {
            if (response.IsCompleted && response.Result) {
                dispatch(Actions.AddExternalPostSourceCompleteAction({ source: response.Result as ExternalPostSource, succeeded: true }));
                return true;
            } else {
                dispatch(Actions.AddExternalPostSourceCompleteAction({ source, succeeded: false }));
                return false;
            }
        })
        .catch((error) => {
            errorAlert(parseError(error.response.data.Message), 5000);
            dispatch(Actions.AddExternalPostSourceCompleteAction({ source, succeeded: false }));
            return false;
        });
};

export const updateExternalPostSource = (source: ExternalPostSource): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.UpdateExternalPostSourceInitAction({ source }));
    return (settingsApi.UpdateTenantSettingsExternalPostSource(source))
        .then((response) => {
            if (response.IsCompleted && response.Result) {
                dispatch(
                    Actions.UpdateExternalPostSourceCompleteAction({ source: response.Result as ExternalPostSource, succeeded: true })
                );
                return true;
            } else {
                dispatch(Actions.UpdateExternalPostSourceCompleteAction({ source, succeeded: false }));
                return false;
            }
        })
        .catch((error) => {
            errorAlert(parseError(error.response.data.Message), 5000);
            dispatch(Actions.UpdateExternalPostSourceCompleteAction({ source, succeeded: false }));
            return false;
        });
};

export const setExternalPostSourceEnabled = (sourceId: string, enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetExternalPostSourceEnabledAction({ sourceId, enabled }));
};

export const deleteExternalPostSource = (sourceId: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.DeleteExternalPostSourceInitAction({}));
    return Promise.resolve(settingsApi.DeleteTenantSettingsExternalPostSource(sourceId))
        .then((response) => {
            dispatch(Actions.DeleteExternalPostSourceCompleteAction({ sourceId, succeeded: true }));
        })
        .catch(() => {
            dispatch(Actions.DeleteExternalPostSourceCompleteAction({ sourceId, succeeded: false }));
        });
};

export const toggleEvent = (teamsEvent:string, mobileEvent:string, emailEvent: string, smsEvent: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateToggleNotificationEventAction({teamsEvent, mobileEvent, emailEvent, smsEvent}))
}

export const ApplyBlackoutPeriod = (teamsEvent:string, mobileEvent:string, emailEvent: string, smsEvent: string, checked: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateApplyBlackoutPeriodAction({teamsEvent, mobileEvent, emailEvent, smsEvent, checked}))
}

export const SetOnPublish = (location: string, platform: string, value: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetOnPublishAction({location, platform, value}))
}

export const SetAllOnPublish = (location: string, value: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetAllOnPublishAction({location, value}))
}

export const EnableAllChannels = (teamsEvent:string, mobileEvent:string, emailEvent: string, smsEvent: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateEnableAllChannelsAction({teamsEvent, mobileEvent, emailEvent, smsEvent}))
}

export const EnableSelectedChannel = (notificationEvent: string, checked: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateEnableSelectedChannelsAction({notificationEvent, checked}))
}

export const ApplyBlackoutPeriodForSMS = (notificationEvent: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateApplyBlackoutPeriodForSMSAction({notificationEvent}))
}

export const SendEmailNotification = (checked: boolean, notificationEvent: string,): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSendEmailNotificationAction({checked, notificationEvent}))
}

export const SendSMSNotification = (checked: boolean, notificationEvent: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSendSMSNotificationAction({checked, notificationEvent}))
}

export const SetDefaultBlackoutForEmail = (hours: number, days: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetDefaultBlackoutForEmailAction({hours, days}))
}

export const SetDefaultBlackoutForSMS = (hours: number, days: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetDefaultBlackoutForSMSAction({hours, days}))
}

export const SetDefaultBlackoutForTeams = (hours: number, days: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetDefaultBlackoutForTeamsAction({hours, days}))
}

export const SetDefaultBlackoutForMobile = (hours: number, days: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetDefaultBlackoutForMobileAction({hours, days}))
}

export const SetLinkPreference = (linkPreference: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetLinkPreference({linkPreference}))
}

export const SetDefaultReminderForPosts = (hours: number, days: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetDefaultReminderForPostsAction({hours, days}))
}

export const SetDefaultReminderForEvents = (hours: number, days: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetDefaultReminderForEventsAction({hours, days}))
}

export const SetRemindersAction = (reminders: any, location: string): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateSetRemindersAction({reminders, location}))
}

export const setCanContributorsToggleComments = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateCanContributorsToggleCommentsAction({ enabled }));
};

export const setCanContributorsToggleReactions = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.CreateCanContributorsToggleReactionsAction({ enabled }));
};

export const SetPostSmartContentEnabledByTenant = (enabled: boolean): AppThunkResult => (dispatch, getState) => {
    dispatch(Actions.SetPostSmartContentEnabledByTenant({ enabled }));
};
