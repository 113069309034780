import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Button, Divider, Drawer, Link, MenuItem, Snackbar, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PermissionDetails, UserDetails, UserRole } from "modules/users/models";
import confirm from "utils/notyPopups";
import Disclaimer from "modules/common/components/disclaimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TenantSettingsTag } from "modules/settings";
import { actions } from "modules/users";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import { MultiSelectTopic } from "modules/settings";
import { USER_ROLE_DESCRIPTIONS } from "modules/authorization/models";
import TopicPermissionSection from "./topicPermissionSection";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { RoleSelector } from "modules/common/components/forms/inputs/roleSelector";

import "./dialogStyling.sass";

const useStyles = makeStyles({
    paper: {
        width: 850
    }
});

const PermissionsDrawer: React.FunctionComponent<PropsWithRedux> = props => {
    //Wrap tag function in callback so we can access it in useEffect.
    const resolveCurrentUserTags = useCallback(() => {
        let allTags: TenantSettingsTag[] = [];
        props.topics.forEach(topicGroup => allTags = allTags.concat(topicGroup.tags)); //Create list of all tags.
        if (props.userData.permissionDetails && props.userData.permissionDetails.topics) {
            allTags = allTags.filter(t => props.userData.permissionDetails.topics.includes(t.id)); //Filter to just what's selected.
        }
        else {
            allTags = [];
        }
        return allTags;
    }, [props.topics, props.userData.permissionDetails]);

    const userRole = props.userData.permissionDetails.role;
    const currentTags = resolveCurrentUserTags();
    let freshClone: UserDetails = JSON.parse(JSON.stringify(props.userData));
    freshClone.permissionDetails.topics = currentTags.map(t => t.id);

    const [selectedRole, setSelectedRole] = React.useState<UserRole>(userRole);
    const [selectedTags, setSelectedTags] = React.useState<MultiSelectTopic[]>(currentTags); //Load from current user data.
    const [userClone, setUserClone] = React.useState<UserDetails>(freshClone); //Object used to keep track of local edits.
    const [isSaving, setIsSaving] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isSubmissionManager, setIsSubmissionManager] = React.useState(props.userData.securityDetails.isSubmissionManager);


    //Reset dropdown when drawer is closed.
    React.useEffect(() => {
        if (!props.isOpen) {
            const userRole = props.userData.permissionDetails.role;
            setSelectedRole(userRole);
            setSelectedTags(resolveCurrentUserTags()); //Reset this to user data.
        }
    }, [props.isOpen, props.userData.permissionDetails.role, resolveCurrentUserTags]);

    // reset submission manager field when role selection changes
    React.useEffect(() => {
        if (selectedRole !== UserRole.AUTHOR && selectedRole !== UserRole.OWNER)
            setIsSubmissionManager(false);
    }, [selectedRole]);

    const classes = useStyles();

    const changesMade = () => {
        let currentUserTopics = props.userData.permissionDetails.topics ?? [];
        let selectedTagIds = selectedTags.map(t => t.id);

        //Check for role match.
        if (userClone.permissionDetails.role !== props.userData.permissionDetails.role) {
            return true;
        }

        //Check for topics mismatch
        else if (selectedTags.length !== currentUserTopics.length) {
            return true;
        }

        //If we make it here they're the same length, so we don't need to worry about checking both
        else if (!currentUserTopics.every(topic => selectedTagIds.includes(topic))) {
            return true;
        } else if (props.userData.securityDetails.isSubmissionManager !== isSubmissionManager) {
            return true;
        }

        return false;
    };

    const closeDrawer = async () => {
        if (changesMade()) {
            if (await confirm.show({
                title: "Back to User Profile",
                text: "You have unsaved permission changes.<br><br>Do you want to continue?",
                yesText: "Keep Editing",
                noText: "Exit Permissions",
            })) {
                //Don't actually do anything except close the dialogue (which happens automatically).
                //Save only happens if they click the button.
            }
            else {
                props.closeDrawer();
            }
        }
        else {
            props.closeDrawer();
        }
    };

    const closeDrawerAndDisableUser = async () => {
        if (changesMade()) {
            if (await confirm.show({
                title: "Back to User Profile",
                text: "You have unsaved permission changes.<br><br>Do you want to continue?",
                yesText: "Keep Editing",
                noText: "Exit Permissions",
            })) {
                //Don't actually do anything except close the dialogue (which happens automatically).
                //Save only happens if they click the button.
            }
            else {
                props.closeDrawer();
                props.disableUser();
            }
        }
        else {
            props.closeDrawer();
            props.disableUser();
        }
    };

    //Update clone of user to have new role.
    const setNewRole = (toSet: UserRole) => {
        let toUpdate = userClone;
        toUpdate.permissionDetails.role = toSet;

        setSelectedRole(toSet);
        setUserClone(toUpdate);
    };

    const getSubmissionManagerSection = (role: string) => {
        return <div>
            <div className="header">Content Management</div>
            <div className="submission-manager">
                <div>
                    <div>Assign as a Submission Manager</div>
                    <div>
                        <Switch color="primary" checked={isSubmissionManager} onChange={(ev, checked) => setIsSubmissionManager(checked)} />
                    </div>
                </div>
                <Typography variant="caption">Notify this {role} when Content is submitted by a Contributor.</Typography>
            </div>
        </div>;
    };

    const savePermissions = () => {
        let userToSave: PermissionDetails = {
            role: selectedRole,
            topics: selectedTags.map(t => t.id),
            isSubmissionManager: isSubmissionManager
        };

        setIsSaving(true);

        props.updatePermissions(userToSave, props.userData.id).then((result) => {
            setIsSaving(false);
            if (!result) {
                setErrorMessage("Changes could not be saved. Please try again.");
                return;
            }
            else {
                setUserClone(JSON.parse(JSON.stringify(props.userData))); //Update everything to saved new permissions.

                props.onSave(props.userData);
            }
        }).catch(err => {
            setErrorMessage("Changes could not be saved. Please try again.");
        });
    };

    const isMissingInput = (): boolean =>
        selectedRole === UserRole.CONTRIBUTOR && selectedTags.length === 0;

    const saveButton = () => <Button
        style={{ marginLeft: "auto", height: "35px", width: "75px" }}
        color={"primary"}
        variant={"contained"}
        disabled={!changesMade() || isSaving || isMissingInput()}
        onClick={savePermissions}
    >
        Save
    </Button>;

    return (
        <Drawer open={props.isOpen} anchor="right" onClose={closeDrawer} classes={{ paper: classes.paper }}>
            <div style={{ padding: 20 }}>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <ArrowBack className="close-modal-icon" style={{ position: "relative", top: "4px" }} onClick={closeDrawer} />
                    <h4 style={{ paddingLeft: "14px" }}>Permissions</h4>
                    {
                        isMissingInput() ?
                            <HoverText
                                style={{ marginLeft: "auto" }}
                                label={saveButton()}
                            >
                                Complete required fields
                            </HoverText>
                            : saveButton()
                    }
                </div>

                <Divider style={{ width: "870px", position: "relative", right: "5%", marginTop: "12px" }} />

                <div style={{ display: "flex", flexDirection: "row", marginTop: "30px", marginBottom: "10px" }}>
                    <span style={{ paddingRight: "35px" }}>Role</span>
                    <RoleSelector
                        selectedRole={selectedRole}
                        onSelectRole={setNewRole}
                        selectStyle={{ position: "relative", top: -9 }}
                    />
                    <MenuItem value={UserRole.CONTRIBUTOR} onClick={() => setNewRole(UserRole.CONTRIBUTOR)}>Contributor</MenuItem>
                </div>

                {selectedRole === UserRole.USER &&
                    <React.Fragment>
                        <Disclaimer
                            icon={<InfoOutlinedIcon />}
                            text={USER_ROLE_DESCRIPTIONS.INTERNAL}>
                        </Disclaimer>

                        <div>
                            <div className="header">Content Engagement</div>
                            <span>
                                All activated users in your Sparrow directory are able to sign in to the portal, and view and interact with any published content they have access to.
                                &nbsp;<Link onClick={closeDrawerAndDisableUser}>Deactivate the user</Link> to revoke their access.
                            </span>
                        </div>
                    </React.Fragment>
                }

                {selectedRole === UserRole.AUTHOR &&
                    <React.Fragment>
                        <Disclaimer
                            icon={<InfoOutlinedIcon />}
                            text={USER_ROLE_DESCRIPTIONS.AUTHOR}>
                        </Disclaimer>

                        <div>
                            <div className="header">Content Engagement</div>
                            <span>
                                All activated users in your Sparrow directory are able to sign in to the portal, and view and interact with any published content they have access to.
                                &nbsp;<Link onClick={closeDrawerAndDisableUser}>Deactivate the user</Link> to revoke their access.
                            </span>
                        </div>

                        <Divider style={{ marginTop: "12px" }} />

                        <TopicPermissionSection
                            role={UserRole.AUTHOR}
                            topics={props.topics}
                            selectedTopics={selectedTags}
                            setSelectedTopics={setSelectedTags} />
                        <Divider />
                        {getSubmissionManagerSection('Author')}
                        <Snackbar />
                    </React.Fragment>
                }
                {selectedRole === UserRole.OWNER &&
                    <React.Fragment>
                        <Disclaimer
                            icon={<InfoOutlinedIcon />}
                            text={USER_ROLE_DESCRIPTIONS.OWNER}>
                        </Disclaimer>
                        {getSubmissionManagerSection('Owner')}
                    </React.Fragment>
                }
                {
                    selectedRole === UserRole.CONTRIBUTOR &&
                    <React.Fragment>
                        <Disclaimer
                            icon={<InfoOutlinedIcon />}
                            text={USER_ROLE_DESCRIPTIONS.CONTRIBUTOR}>
                        </Disclaimer>

                        <div>
                            <div className="header">Content Engagement</div>
                            <span>
                                All activated users in your Sparrow directory are able to sign in to the portal, and view and interact with any published content they have access to.
                                &nbsp;<Link onClick={closeDrawerAndDisableUser}>Deactivate the user</Link> to revoke their access.
                            </span>
                        </div>
                        <Divider style={{ marginTop: "12px" }} />
                        <TopicPermissionSection
                            role={UserRole.CONTRIBUTOR}
                            topics={props.topics}
                            selectedTopics={selectedTags}
                            setSelectedTopics={setSelectedTags}
                            defaultChipLabel="Select at least 1 topic"
                        />
                        <Snackbar />
                    </React.Fragment>
                }
            </div>
            <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")} />
        </Drawer>
    );
};

interface ComponentProps {
    isOpen: boolean;
    userData: UserDetails;
    closeDrawer: () => void;
    disableUser: () => void;
    onSave: (updatedUser: UserDetails) => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        topics: state.settings.tenantSettings.tagGroups
    }), {
    updatePermissions: actions.updatePermissions
}
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PermissionsDrawer);
