import React from "react";

import RequireRole from "modules/common/components/requireRole";
import { UserRoleStrings } from "modules/authorization/models";

interface IUserRoleWrapperProps {
    userRole?: UserRoleStrings;
    strictRole?: boolean;
}

export const UserRoleWrapper: React.FunctionComponent<IUserRoleWrapperProps> = props => {
    if (!props.userRole) return <>{props.children}</>;

    return (
        <RequireRole roleName={props.userRole} strictRole={props.strictRole ?? false}>
            {props.children}
        </RequireRole>
    );
};