import React from 'react';
import SettingsToggleWithBlackout from '../toggles/settingsToggleWithBlackout';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { NotificationEvents } from 'modules/messaging';
import SettingsToggleOrAnd from '../toggles/settingsToggleOrAnd';
import RegularNotificationPopout from '../regularNotificationPopout';
import MobileNotificationPopout from '../mobileNotificationPopout';
import ReminderNotificationPopout from '../reminderNotificationPopout';
import EmailNotificationPopout from '../emailNotificationPopout';
import SettingsToggleWithBlackoutNoSms from '../settingsToggleWithBlackoutNoSms';
import MobileAndEmailNotificationPopout from '../mobileAndEmailNotificationPopout';

interface ComponentProps { }
interface ComponentState {
    postNotificationsEditingVisible: boolean;
    postCommentEditingVisible: boolean;
    postReminderEditingVisible: boolean;
    postMentionsEditingVisible: boolean;
    postCommentMentionsEditingVisible: boolean;
    commentFlaggedEditingVisible: boolean;
}

class MessagingPostControls extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            postNotificationsEditingVisible: false,
            postCommentEditingVisible: false,
            postReminderEditingVisible: false,
            postMentionsEditingVisible: false,
            postCommentMentionsEditingVisible: false,
            commentFlaggedEditingVisible: false
        }
    }

    public render() {
        const postPublishedEmailBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
        ];

        const postPublishedMobileBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
            {id: "{{postDescription}}", name: "{{postDescription}}"},
        ];

        const postPublishedSMSBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
            {id: "{{postDescription}}", name: "{{postDescription}}"},
            {id: "{{newLine}}", name: "{{newLine}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},
            {id: "{{tenantName}}", name: "{{tenantName}}"},
        ];

        const commentCreatedMobileBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
            {id: "{{commentAuthor}}", name: "{{commentAuthor}}"},
            {id: "{{commentBody}}", name: "{{commentBody}}"},
        ];

        const postMentionedMobileBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
            {id: "{{postAuthor}}", name: "{{postAuthor}}"}
        ];

        const postReminderSMSBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
            {id: "{{postLink}}", name: "{{postLink}}"},
            {id: "{{newLine}}", name: "{{newLine}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},
            {id: "{{tenantName}}", name: "{{tenantName}}"},
        ];

        const commentFlaggedEmailBubbles = [
            {id: "{{postTitle}}", name: "{{postTitle}}"},
            {id: "{{tenantName}}", name: "{{tenantName}}"},
        ];

        return (
            <React.Fragment>
                <div className="settings-section">
                    <SettingsToggleWithBlackout
                        title={"Enable notifications for post publishing"}
                        description={"Authors are able to manage publishing notifications for each post. To change its default settings or contents, "}
                        emailEvent={NotificationEvents.PostPublishedEmail}
                        smsEvent={NotificationEvents.PostPublishedSMS}
                        teamsEvent={NotificationEvents.PostPublishedTeams}
                        mobileEvent={NotificationEvents.PostPublishedMobile}
                        id={"postNotificationsEditingVisible"}
                        handleOnClick={() => this.toggleWindow("postNotificationsEditingVisible")}

                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleWithBlackout
                        title={"Enable reminders if posts are unread"}
                        description={"Authors are able to manage publishing notifications for each post. To change its default settings or contents, "}
                        emailEvent={NotificationEvents.PostReminderEmail}
                        smsEvent={NotificationEvents.PostReminderSMS}
                        teamsEvent={NotificationEvents.PostReminderTeams}
                        mobileEvent={NotificationEvents.PostReminderMobile}
                        id={"reminderBlackout"}
                        handleOnClick={() => this.toggleWindow("postReminderEditingVisible")}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleOrAnd
                        title={"Notify Submission Managers when a post is submitted"}
                        description={"Managers will be notified through email or MS Teams when a post is submitted for review and/or publishing."}
                        eventOne={NotificationEvents.PostSubmittedTeams}
                        eventTwo={NotificationEvents.PostSubmittedEmail}
                        andPrompt={"Enable MS Teams and Email notifications"}
                        eventOnePrompt={"Enable MS Teams only"}
                        eventTwoPrompt={"Enable Email only"}
                        id={"postSubmittedNotifications"}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleOrAnd
                        title={"Notify users when mentioned in a post"}
                        description={"Send a notification to a user who has been mentioned in a post. To change its contents, "}
                        eventOne={NotificationEvents.PostMentionedTeams}
                        eventTwo={NotificationEvents.PostMentionedMobile}
                        andPrompt={"Enable Teams Chatbot and Mobile App Notifications"}
                        eventOnePrompt={"Enable Teams only"}
                        eventTwoPrompt={"Enable Mobile only"}
                        id={"mentionPostNotifications"}
                        handleOnClick={() => this.toggleWindow("postMentionsEditingVisible")}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleOrAnd
                        title={"Enable notifications when a comment is created"}
                        description={"Authors are able to manage publishing notifications for each post."}
                        eventOne={NotificationEvents.PostCommentCreatedTeams}
                        eventTwo={NotificationEvents.PostCommentCreatedMobile}
                        andPrompt={"Enable Teams Chatbot and Mobile App Notifications"}
                        eventOnePrompt={"Enable Teams only"}
                        eventTwoPrompt={"Enable Mobile only"}
                        id={"commentNotifications"}
                        handleOnClick={() => this.toggleWindow("postCommentEditingVisible")}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleWithBlackoutNoSms
                        title={"Notify users when mentioned in a comment"}
                        description={"Send a notification to a user who has been mentioned in a comment. To change its contents, "}
                        emailEvent={NotificationEvents.UserMentionedEmail}
                        teamsEvent={NotificationEvents.UserMentionedTeams}
                        mobileEvent={NotificationEvents.UserMentionedMobile}
                        id={"mentionNotifications"}
                        handleOnClick={() => this.toggleWindow("postCommentMentionsEditingVisible")}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleOrAnd
                        title={"Notify moderators when a comment has been flagged"}
                        description={"Send a notification to moderators when a comment has been flagged for review. To change its contents, "}
                        eventOne={NotificationEvents.CommentFlaggedEmail}
                        eventTwo={NotificationEvents.CommentFlaggedTeams}
                        andPrompt={"Enable Email and Teams Chatbot Notifications"}
                        eventOnePrompt={"Enable Email only"}
                        eventTwoPrompt={"Enable Teams only"}
                        id={"commentFlaggedNotifications"}
                        handleOnClick={() => this.toggleWindow("commentFlaggedEditingVisible")}
                    />
                </div>

                {this.state.postNotificationsEditingVisible &&
                    <RegularNotificationPopout
                        updateToggle={() => this.toggleWindow("postNotificationsEditingVisible")}
                        event={"postPublished"}
                        eventPrompt={"Post Published"}
                        emailBubbles={postPublishedEmailBubbles}
                        smsBubbles={postPublishedSMSBubbles}
                        mobileBubbles={postPublishedMobileBubbles}
                        publishName={"defaultPostSettings"}
                        teamsEvent={NotificationEvents.PostPublishedTeams}
                        mobileEvent={NotificationEvents.PostPublishedMobile}
                        emailEvent={NotificationEvents.PostPublishedEmail}
                        smsEvent={NotificationEvents.PostPublishedSMS}
                    />
                }

                {this.state.postCommentEditingVisible &&
                    <MobileNotificationPopout
                        updateToggle={() => this.toggleWindow("postCommentEditingVisible")}
                        event={"postCommentCreated"}
                        eventPrompt={"Comment Created"}
                        bubbles={commentCreatedMobileBubbles}
                    />
                }

                {this.state.postReminderEditingVisible &&
                    <ReminderNotificationPopout
                        updateToggle={() => this.toggleWindow("postReminderEditingVisible")}
                        event={"postReminder"}
                        eventPrompt={"Post Reminder"}
                        emailBubbles={postPublishedEmailBubbles}
                        smsBubbles={postReminderSMSBubbles}
                        mobileBubbles={postPublishedMobileBubbles}
                        reminderName={"defaultPost"}
                    />
                }

                {this.state.postMentionsEditingVisible &&
                    <MobileNotificationPopout
                        updateToggle={() => this.toggleWindow("postMentionsEditingVisible")}
                        event={"postMentioned"}
                        eventPrompt={"Post Mentioned"}
                        bubbles={postMentionedMobileBubbles}
                    />
                }

                {this.state.postCommentMentionsEditingVisible &&
                    <MobileAndEmailNotificationPopout
                        updateToggle={() => this.toggleWindow("postCommentMentionsEditingVisible")}
                        event={"userMentioned"}
                        eventPrompt={"User Mentioned"}
                        bubbles={commentCreatedMobileBubbles}
                        emailBubbles={commentCreatedMobileBubbles}
                    />
                }

                {this.state.commentFlaggedEditingVisible && 
                    <EmailNotificationPopout 
                        updateToggle={() => this.toggleWindow("commentFlaggedEditingVisible")}
                        event={"commentFlagged"}
                        eventPrompt={"Comment Flagged"}
                        bubbles={commentFlaggedEmailBubbles}
                    />
                }
                
            </React.Fragment>
        )
    };

    private toggleWindow = (windowName) => {
        let flippedVal = !(this.state[windowName])
        this.setState({...this.state, [windowName]: flippedVal})
    };
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
    }),
    {

    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(MessagingPostControls));
