import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import React, { useEffect, useState } from "react";

const STRD_REJECTION_TEXT = "You’re about to reject this submission and return it to drafts. "
    + "This will also notify the original author. "
    + "Leave any comments or feedback below.";

const SAVE_CHANGES_TEXT = "You also made changes to this submission. "
    + "Would you like to save your changes before proceeding?";

const DEFAULT_COMMENT = "Enter comments or feedback (Optional)";

const NUM_TEXT_ROWS = 8;

const saveChangesOptions = [
    "Yes, save my changes before rejecting and returning it",
    "No, discard my changes"
];

const RejectSubmissionDialog: React.FunctionComponent<IRejectSubmissionDialogProps> = (props) => {
    const [saveOption, setSaveOption] = useState<number | undefined>(undefined);
    const [comments, setComments] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.unsavedChanges) setSaveOption(0); // default
    }, [props.unsavedChanges])

    const onClose = () => props.setOpen(false);

    const isSaving = () => saveOption === 0;

    const selectOption = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = parseInt(ev.target.value);
        setSaveOption(newValue);
    };

    const changeComments = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setComments(ev.target.value)

    const onReject = async () => {
        onClose();

        if (isSaving()) await props.saveProgress();
        
        await props.rejectSubmission(comments);
    };

    const getSaveChangesSection = () => {
        return (
            <React.Fragment>
                {SAVE_CHANGES_TEXT}

                <br />
                <br />

                <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    value={saveOption}
                    onChange={selectOption}
                >
                    {saveChangesOptions.map((option, n) => (
                        <MenuItem key={n} value={n}> {option} </MenuItem>
                    ))}
                </TextField>
            </React.Fragment>
        );
    };

    return (
        <ConfirmDialog
            title="Reject Submission"
            confirmLabel="Reject"
            denyLabel="Cancel"
            open={props.open}
            onClose={onClose}
            onDeny={onClose}
            onConfirm={onReject}
        >
            {STRD_REJECTION_TEXT}

            <br />
            <br />

            <TextField
                fullWidth
                multiline
                rows={NUM_TEXT_ROWS}
                value={comments}
                placeholder={DEFAULT_COMMENT}
                onChange={changeComments}
            />

            <br />
            <br />

            {props.unsavedChanges && getSaveChangesSection()}
        </ConfirmDialog>
    );
};

interface IRejectSubmissionDialogProps {
    open: boolean;
    unsavedChanges: boolean;
    setOpen: (open: boolean) => void;
    rejectSubmission: (comments : string | undefined) => Promise<void>;
    saveProgress: () => Promise<boolean>;
}

export default RejectSubmissionDialog;