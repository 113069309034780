import React from 'react';
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState"
import { MessagingTabs } from "../models";
import MessagingCenterEmailsTab from './messagingCenterEmailsTab';
import { actions } from "modules/messaging";

import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";

import '../messaging.sass';
import MessagingCenterSMSTab from './messagingCenterSMSTab';
import EmailBlastModal from './emailBlastModal';
import { audiencesApi, messagingApi } from 'api/instances';
import { Audience } from 'modules/audiences/models';
import TextBubble from 'modules/common/components/textBubble';
import SmsBlastModal from './smsBlastModal';
import SuccessfulActionDialog from 'modules/common/components/successfulActionDialog';
import { push } from 'connected-react-router';
import MessagingCenterTeamsTab from './messagingCenterTeamsTab';
import MessagingCenterMobileTab from './messagingCenterMobileTab';

interface ComponentProps { }

interface ComponentState { 
    showEmailModal: boolean;
    showSmsModal: boolean;
    audiences: Audience[];
    selectedAudiences: string[];
    showAudiencePicker: boolean;
    shouldShowSuccessfulBlastDialog: boolean;
    userSMSStats: UserSMSStats;
    userEmailStats: UserEmailStats;
    userTeamsStats: UserTeamsStats;
    userMobileStats: UserMobileStats;
}

interface TextBubble {
    id: string;
    name: string;
}

interface UserSMSStats {
    totalMobileNumbers: number,
    optedIn: number,
    pendingOptIns: number,
    optedOut: number,
    invalidNumbers: number
}

interface UserEmailStats {
    totalEmails: number,
    workAccountsOptedIn: number,
    externalAccountsOptedIn: number,
    optedOut: number,
    invalidEmails: number
}

interface UserTeamsStats {
    totalUsers: number,
    registeredUsers: number
}

interface UserMobileStats {
    activeDevices: number,
    usersWithApp: number,
    usersWithNoDevice: number
}
class MessagingCenterScreen extends React.Component<PropsWithRedux, ComponentState> {

    componentDidMount() {
        audiencesApi.getAllAudiences()
        .then((audiences) => {
            this.setState({
                ...this.state,
                audiences: audiences
            })
        })

        messagingApi.GetSMSStats()
        .then((stats) => {
            this.setState({
                ...this.state,
                userSMSStats: stats
            })
        })

        messagingApi.GetEmailStats()
        .then((stats) => {
            this.setState({
                ...this.state,
                userEmailStats: stats
            })
        })

        messagingApi.GetTeamsStats()
        .then((stats) => {
            this.setState({
                ...this.state,
                userTeamsStats: stats
            })
        })

        messagingApi.GetMobileStats()
        .then((stats) => {
            this.setState({
                ...this.state,
                userMobileStats: stats
            })
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            showEmailModal: false,
            showSmsModal: false,
            audiences: [],
            selectedAudiences: [],
            showAudiencePicker: false,
            shouldShowSuccessfulBlastDialog: false,
            userSMSStats: {
                totalMobileNumbers: 0,
                optedIn: 0,
                optedOut: 0,
                invalidNumbers: 0,
                pendingOptIns: 0
            },
            userEmailStats: {
                totalEmails: 0,
                workAccountsOptedIn: 0,
                externalAccountsOptedIn: 0,
                optedOut: 0,
                invalidEmails: 0
            },
            userTeamsStats: {
                totalUsers: 0,
                registeredUsers: 0
            },
            userMobileStats: {
                activeDevices: 0,
                usersWithApp: 0,
                usersWithNoDevice: 0
            }
        }
    }

    public render() {

        const emailsTabActive = this.props.activeTab === MessagingTabs.Email ? " active" : "";
        const smsTabActive = this.props.activeTab === MessagingTabs.SMS ? " active" : "";
        const teamsTabActive = this.props.activeTab === MessagingTabs.Teams ? " active" : "";
        const mobileTabActive = this.props.activeTab === MessagingTabs.Mobile ? " active" : "";

        const audienceBubbles : TextBubble[] = []
        this.state.selectedAudiences.forEach(element => {
            audienceBubbles.push({id: element, name: this.state.audiences.find((a) => a.id === element)?.displayName} as TextBubble)
        });
        return (
            <BasePage fullWidth>
                <Header
                    title="Direct Messaging"
                />
                <MainContent>
                    <div className="messaging-center-page">
                        <div className="tab-clearfix">
                            <ul style={{float: "left"}} className="tab-btns">
                                <li>
                                    <span className={`tab-btn ${emailsTabActive}`}
                                        onClick={() => { this.handleTabChange(MessagingTabs.Email) } }>
                                        Emails
                                    </span>
                                </li>
                                <li> 
                                    <span className={`tab-btn ${smsTabActive}`}
                                        onClick={() => { this.handleTabChange(MessagingTabs.SMS) } }>
                                        SMS
                                    </span>
                                </li> 
                                <li>
                                    <span className={`tab-btn ${teamsTabActive}`}
                                        onClick={() => {this.handleTabChange(MessagingTabs.Teams)}}>
                                        Teams
                                    </span>    
                                </li>  
                                <li>
                                    <span className={`tab-btn ${mobileTabActive}`}
                                        onClick={() => {this.handleTabChange(MessagingTabs.Mobile)}}>
                                        Mobile
                                    </span>
                                </li>
                            </ul>
                            <div style={{float: "right", margin: 20}} className="send-new-btn">
                                <button className="dropdown-button">SEND NEW</button>
                                <div className="dropdown-btn-content">
                                    <div className="send-new-dropdown" onClick={() => this.setState({...this.state, showEmailModal: true, showSmsModal: false, selectedAudiences: []})}>Email Blast</div>
                                    <div className="send-new-dropdown" onClick={() => this.setState({...this.state, showSmsModal: true, showEmailModal: false, selectedAudiences: []})}>Text Blast (SMS)</div>
                                </div>
                            </div>
                        </div>

                        {
                            this.state.shouldShowSuccessfulBlastDialog ? 
                            <SuccessfulActionDialog 
                                title={"Blast sent"}
                                displayMessage={ <p> Your blast has been sent to your selected audiences </p> }
                                afterPublishSuccess={this.onBlastClose}
                            /> 
                            : null
                        }

                        {this.state.showEmailModal && 
                            <EmailBlastModal 
                                closeModal={this.closeEmailBlast}
                                onSelectAudienceClick={() => this.setState({ ...this.state, showAudiencePicker: true})}
                                textBubbles={audienceBubbles}
                                selectedAudiences={this.state.selectedAudiences}
                                onBlastSuccess={this.onBlastSuccess}
                                applyAudienceChanges={this.applyAudienceChanges}
                                previousAudiences={this.state.selectedAudiences}
                                userAudiences={this.state.audiences}
                            />
                        }

                        {this.state.showSmsModal && 
                            <SmsBlastModal 
                                closeModal={this.closeSMSBlast}
                                onSelectAudienceClick={() => this.setState({ ...this.state, showAudiencePicker: true})}
                                textBubbles={audienceBubbles}
                                selectedAudiences={this.state.selectedAudiences}
                                onBlastSuccess={this.onBlastSuccess}
                                applyAudienceChanges={this.applyAudienceChanges}
                                previousAudiences={this.state.selectedAudiences}
                                userAudiences={this.state.audiences}
                            />
                        }      

                        {emailsTabActive &&
                            <div className={`tab non-list-view ${emailsTabActive}`}>
                                <MessagingCenterEmailsTab 
                                    navigateToSettings={this.navigateToSettings}
                                    userEmailStats={this.state.userEmailStats}
                                />
                            </div>
                        }
                        {smsTabActive &&
                            <div className={`tab non-list-view ${smsTabActive}`}>
                                <MessagingCenterSMSTab 
                                    navigateToSettings={this.navigateToSettings}
                                    userSMSStats={this.state.userSMSStats}
                                />
                            </div>
                        }
                        {teamsTabActive &&
                            <div className={`tab non-list-view ${teamsTabActive}`}>
                                <MessagingCenterTeamsTab
                                    navigateToSettings={this.navigateToSettings}
                                    userTeamsStats={this.state.userTeamsStats}
                                />
                            </div>
                        }
                        {mobileTabActive &&
                            <div className={`tab non-list-view ${mobileTabActive}`}>
                                <MessagingCenterMobileTab
                                    navigateToSettings={this.navigateToSettings}
                                    userMobileStats={this.state.userMobileStats}
                                />
                            </div>
                        }
                    </div>
                </MainContent>
            </BasePage>
        );
    }

    private handleTabChange = (tab: MessagingTabs) => {
        if (!this.state.showSmsModal && !this.state.showEmailModal) {
            this.props.setActiveTab(tab)
        }
    }
    private navigateToSettings = () => {
        this.props.redirectTo('/' + this.props.tenantId + '/admin/settings#messaging')
    }
    private onBlastClose = () => {
        this.setState({
            ...this.state,
            shouldShowSuccessfulBlastDialog: false
        })
    }
    private onBlastSuccess = () => {
        this.setState({
            ...this.state,
            showEmailModal: false,
            showSmsModal: false,
            shouldShowSuccessfulBlastDialog: true
        })
    }

    private applyAudienceChanges = (audiences: string[]) => {
        this.setState({
            ...this.state,
            showAudiencePicker: false,
            selectedAudiences: audiences
        })
    }

    private closeEmailBlast = () => {
        this.setState({
            ...this.state,
            showEmailModal: false,
            selectedAudiences: [],
            showAudiencePicker: false,
        })
    }

    private closeSMSBlast = () => {
        this.setState({
            ...this.state,
            showSmsModal: false,
            selectedAudiences: [],
            showAudiencePicker: false,
        })
    }
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        activeTab: state.messaging.activeTab,
        tenantId: state.tenant.id,
    }),
    {
        setActiveTab: actions.setActiveTab,
        redirectTo: push,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(MessagingCenterScreen)
