import { useEffect, useRef } from "react";

import { IContentGuidelines } from "modules/settings";
import useIsFirstRender from "../useIsFirstRender";
import { useDispatch, useSelector } from "react-redux";
import { fetchContentGuidelines, guidelinesSlice } from "modules/settings/components/content/guidelines/reducer";
import { GlobalApplicationState } from "globalApplicationState";
import { ThunkDispatch } from "@reduxjs/toolkit";

export type UseContentGuidelinesType = {
    guidelines?: IContentGuidelines;
    original?: IContentGuidelines;
    onChange: (newGuidelines: IContentGuidelines) => void;
    resetToDefault: () => void;
    isEmpty: boolean;
    isPristine: boolean;
};

/**
 * Hook to use content guidelines
 * returns {guidelines, onChange, resetToDefault, original, isEmpty, isPristine};
 */
export const useContentGuidelines = (): UseContentGuidelinesType => {
    const isFirstRender = useIsFirstRender();

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {
        guidelines,
        isEmpty,
        isPristine
    } = useSelector((state: GlobalApplicationState) => state.guidelines);

    const original = useRef<IContentGuidelines | undefined>();

    useEffect(() => {
        const fetchGuidelines = async () => {
            const result = await dispatch(fetchContentGuidelines()).unwrap();
            original.current = result;
        }

        if (isFirstRender) fetchGuidelines();

    }, [isFirstRender, dispatch]);

    const resetToDefault = (): void => {
        const guidelineState = {
            guidelines: original.current,
            isPristine: true
        }
        dispatch({ type: guidelinesSlice.actions.SET_GUIDELINES, payload: guidelineState })
    };

    const onChange = (newGuidelines: IContentGuidelines) => {
        const guidelineState = {
            guidelines: newGuidelines,
            isPristine: false
        }
        dispatch({ type: guidelinesSlice.actions.SET_GUIDELINES, payload: guidelineState })
    };

    return { guidelines, onChange, resetToDefault, original: original.current, isEmpty, isPristine };
};
