import React, { ReactElement } from "react";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";

import { Chip } from "@mui/material";
import { IdNamePair } from "../models";
import ExtraChipsMessage from "./forms/messages/extraChipsMessage";
import uuid from "uuid";

interface IChipListProps {
    items: IChipListItem<string, string>[];
    truncateAtIndex?: number; // exclusive index
    closable?: boolean;
    onClose?: (item: IdNamePair<string, string>) => void;
    showEmptyChip?: boolean;
    defaultLabel?: HTMLElement | string;
    emptyStyle?: React.CSSProperties;
    chipStyle?: React.CSSProperties;
}

export interface IChipListItem<TKey, TName> extends IdNamePair<TKey, TName> {
    icon?: JSX.Element;
    disabled?: boolean;
    labelStyle?: React.CSSProperties;
}

const defaultChipStyle: React.CSSProperties = {
    marginRight: "8px",
    marginBottom: "4px",
    backgroundColor: "#e0e0e0", //Background color prevents background change on hover.
    fontSize: 16
}

const ChipList: React.FunctionComponent<IChipListProps> = ({
    items,
    chipStyle,
    truncateAtIndex,
    closable = false,
    onClose,
    showEmptyChip = false,
    emptyStyle,
    defaultLabel = "No Topics Selected"
}) => {
    const id = uuid();

    const getExtraNames = (): string => {
        if (!truncateAtIndex) return "";
        let result = "";

        if (items.length > truncateAtIndex) {
            let remainingTagNames = items.slice(truncateAtIndex).map(tag => tag.name);
            result = remainingTagNames.join(", ");
        }

        return result;
    }

    const getDefaultChip = (item: IChipListItem<string, string>): ReactElement => {
        return <Chip
            key={item.id}
            label={
                <React.Fragment>
                    <span style={item.labelStyle}>{item.name}</span>
                    {item.icon && <LockIcon style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} />}
                </React.Fragment>
            }
            style={chipStyle || defaultChipStyle}
            onDelete={setupClose ? () => onClose!(item) : undefined}
            deleteIcon={setupClose ? <CloseIcon /> : undefined}
            disabled={item.disabled}
        />;
    }

    const setupClose = closable && onClose !== undefined;

    return (
        <>
            {
                showEmptyChip && items.length === 0 &&
                <Chip
                    key={`${id}-empty`}
                    label={defaultLabel}
                    style={{
                        ...defaultChipStyle,
                        fontStyle: "italic",
                        color: "#808080",
                        ...emptyStyle,
                    }}
                />
            }
            {
                items.slice(0, truncateAtIndex).map((item) => (getDefaultChip(item)))
            }
            {
                truncateAtIndex && items.length > truncateAtIndex &&
                <ExtraChipsMessage chipText={`+${items.length - truncateAtIndex}`} hoverText={getExtraNames()} />
            }
        </>
    );
}

export default ChipList;
