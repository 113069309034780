import React from "react";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";

import ChipList from "modules/common/components/chipList";
import TopicMultiselect from "modules/common/components/forms/inputs/topicMultiselect";
import { MultiSelectTopic, TenantSettingsTagGroup } from "modules/settings";
import { UserRole } from "modules/users/models";

import "./permissionDrawer.sass";

interface ComponentProps {
    topics: TenantSettingsTagGroup[];
    selectedTopics: MultiSelectTopic[];
    setSelectedTopics: (topics: MultiSelectTopic[]) => void;
    role: UserRole;
    defaultChipLabel?: string;
}

const SECTION_HEADER = {
    [UserRole.CONTRIBUTOR]: "Content Submission",
    [UserRole.AUTHOR]: "Content Creation"
};

const SECTION_DESCRIPTION = {
    [UserRole.CONTRIBUTOR]: <p>Allow to submit content in these topics<span className="red">*</span></p>,
    [UserRole.AUTHOR]: <p>Assign specific topics when authoring</p>
};

const SECTION_CLAUSE = {
    [UserRole.CONTRIBUTOR]: "Contributor cannot submit content to any topics.",
    [UserRole.AUTHOR]: "Author can publish content using any topic."
};

const TopicPermissionSection: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
    const onChangeTagsChipList = (currentTag: MultiSelectTopic) => {
        const hasSelectedTag: boolean = !!props.selectedTopics.find((selectedTag) => selectedTag.id === currentTag.id);
        let newTopics = [...props.selectedTopics];

        if (hasSelectedTag)
            newTopics = props.selectedTopics.filter(tag => tag.id !== currentTag.id);
        else
            newTopics.push(currentTag);

        props.setSelectedTopics(newTopics);
    };

    return (
        <div className="topic-permission-drawer">
            <div className="header">{SECTION_HEADER[props.role]}</div>
            <div className="interactive-description">
                <div className="description">
                    {SECTION_DESCRIPTION[props.role]}
                    <span className="subtitle"> {`If none are selected, ${SECTION_CLAUSE[props.role]}`}</span>
                </div>
                <TopicMultiselect
                    defaultOptions={props.topics}
                    selectedOptions={props.selectedTopics}
                    onChange={(topics) => { props.setSelectedTopics(topics); }}
                    startIcon={<AddIcon />}
                />
            </div>
            <div>
                <ChipList
                    closable
                    items={props.selectedTopics.map((opt: MultiSelectTopic) => ({
                        id: opt.id,
                        name: opt.name,
                        icon: opt.restricted ? <LockIcon style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} /> : undefined
                    }))}
                    onClose={onChangeTagsChipList}
                    truncateAtIndex={5}
                    defaultLabel={props.defaultChipLabel}
                    showEmptyChip
                />
            </div>
        </div>
    );
};

export default TopicPermissionSection;