import * as React from "react";
import TenantLink from "modules/common/components/tenantLink";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../actionCreator";
import * as userActions from "modules/users/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { DetailedTagStats } from "../models";
import { TagAwarenessCalculator, IAwarenessScore } from "./tagAwarenessCalculator";
import AwarenessVisualization from "./awarenessVisualization";
import Loading from "modules/common/components/loading";
import DateFormatter from "modules/common/dateFormatter";
import HighlightedNumber from "./highlightedNumber";
import { injectIntl, FormattedMessage, FormattedNumber, IntlShape } from "react-intl";
import { UserRoleStrings } from "modules/authorization/models";
import LoadingSpinner from "modules/common/components/loadingSpinner";
import { TenantSettingsTag } from "modules/settings";
import confirm from "utils/notyPopups";
import { browserHistory } from "configureStore";
import { tagsApi, audiencesApi, insightsApi } from "api/instances";
import { withRouter, RouteComponentProps } from "react-router";
import TagSubscriptionManagerModal from "./tagSubscriptionManagerModal";
import ToggleSwitch from "modules/common/components/toggleSwitch";
import MultiSelect from "modules/common/components/forms/inputs/multiselect";
import { Audience } from "modules/audiences/models";
import "./tagDetail.sass";
import DownloadButton from "modules/common/components/buttons/downloadButton";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import MainContent from "pages/common/mainContent";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";


export class TagDetail extends React.Component<PropsWithRedux, ComponentState> {
    private _awarenessPoints: IAwarenessScore[];
    private _isMounted;

    constructor(props) {
        super(props);
        this.state = {
            currentAction: "",
            showSubscriptionManagementModal: false,
            audiences: [],
            editting: false,
            isTagReadsAndPostsFetched: false,
            errorMessage: "",
            fetchedTagStats: {
                posts: [],
                readStats: null,
                id: "",
                groupName: "",
                name: "",
                isMandatory: false,
                subscriberCount: 0,
                postCount: 0,
                viewCount: 0,
            }
        };
    }

    public componentDidMount() {
        this._isMounted = true;
        (window as any).scrollTo(0, 0);

        audiencesApi.getAllAudiences().then((response) => {
            if (this._isMounted) {
                this.setState({
                    audiences: response,
                });
            }
        });

        this.props.fetchTagReadsAndPostReads(this.props.match.params.tagId).then(() => {
            if (this._isMounted) {
                this.setState({
                    ...this.state,
                    isTagReadsAndPostsFetched: true
                })
            }
        });

        insightsApi.GetSpecificTagStats(this.props.match.params.tagId).then((response) => {
            if (this._isMounted) {
                if (response) {
                    this.setState({
                        ...this.state,
                        fetchedTagStats: {
                            ...this.state.fetchedTagStats,
                            subscriberCount: response.subscriberCount,
                            postCount: response.postCount,
                            viewCount: response.viewCount
                        }
                    })
                }
            }
        })

        if (this.props.users === null || this.props.users.length === 0) {
            this.props.fetchUsers();
        }

        if (
            this.props.tagStats &&
            this.props.tagStats.id === this.props.match.params.tagId &&
            this.props.tagStats.readStats &&
            this.props.users
        ) {
            this._awarenessPoints = TagAwarenessCalculator.calculate(this.props.tagStats.readStats, this.props.users);
        }
    }

    public componentWillUpdate(nextProps, nextState) {
        if (nextProps.tagStats && nextProps.tagStats.readStats && nextProps.users) {
            this._awarenessPoints = TagAwarenessCalculator.calculate(nextProps.tagStats.readStats, nextProps.users);
        }
    }

    public componentWillUnmount() {
        this._isMounted = false;
        this.setState({
            ...this.state,
            isTagReadsAndPostsFetched: false
        })
    }

    private defaultStyles: React.CSSProperties = {
        backgroundColor: "#2f4050",
        color: "#ffffff",
        border: "1px solid #ccc",
        outline: "none",
        padding: "8px",
        cursor: "pointer",
        display: "inline-block",
        fontSize: "14px",
        borderRadius: "3px",
        float: "right",
        marginRight: "15px",
    };

    public render() {
        if ((!this.props.tagStats || this.props.tagStats.id !== this.props.match.params.tagId) && this.props.fetching) {
            return <Loading />;
        }

        let topAuthors = this.getTopAuthors(this.props.tagStats);
        let awarenessScore =
            this._awarenessPoints && this._awarenessPoints.length
                ? Math.round(this._awarenessPoints[this._awarenessPoints.length - 1].y)
                : "?";
        let views = this.props.tagStats?.viewCount || 0;

        var tagGroup;
        var tag;

        if (!!this.props.tagStats) {
            tagGroup = !this.props.fetching
                ? this.props.tenantSettings.tagGroups.find((t) => t.id === this.props.match.params.tagGroupId)!
                : null;
            tag = !this.props.fetching ? tagGroup?.tags.find((t) => t.id === this.props.match.params.tagId)! : null;
        } else {
            tagGroup = !this.props.fetching
                ? this.props.tenantSettings.tagGroups.find((t) => t.name === this.props.tagStats?.groupName)!
                : null;
            tag = !this.props.fetching ? tagGroup?.tags.find((t) => t.id === this.props.tagStats.id)! : null;
        }

        return (
            <BasePage fullWidth>
                <Breadcrumb
                    items={[
                        { title: "Manage Topics", link: "~/admin/topics" },
                        { title: tag?.name },
                    ]}
                />
                <MainContent>
                    <div className="channel-detail">
                        <header>
                            <span style={{ display: "flex", alignItems: "center", minHeight: "70px" }}>
                                {this.state.editting ? (
                                    <input
                                        className="name-edit form-control"
                                        autoComplete="off"
                                        value={tag ? tag.name : ""}
                                        onChange={(e) => this.handleTagNameChange(e, tag!)}
                                    />
                                ) : (
                                    <h2
                                        style={{
                                            color: tag?.disabled && !this.props.saving ? "#595959" : "",
                                        }}
                                    >
                                        {tag?.name}
                                    </h2>
                                )}
                                {tag?.disabled && !this.props.saving ? <span className="disabled">(disabled)</span> : null}
                                {this.state.editting ? (
                                    <div className="editing-btns">
                                        <button
                                            style={{ backgroundColor: "#2196f3" }}
                                            disabled={this.props.saving || this.props.fetching || tag?.name === ""}
                                            onClick={this.handleSave}
                                        >
                                            {this.props.saving ? (
                                                <LoadingSpinner size={"14px"} />
                                            ) : (
                                                <FormattedMessage id="common.save" defaultMessage="Save" />
                                            )}
                                        </button>
                                        <button
                                            style={{ backgroundColor: "#ffffff", color: "#000000" }}
                                            onClick={() => this.setState({ ...this.state, editting: false })}
                                        >
                                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className="edit-btn"
                                        disabled={this.props.saving || this.props.fetching}
                                        title="Edit topic name"
                                        onClick={() => this.setState({ ...this.state, editting: true })}
                                    >
                                        <i className="material-icons">edit</i>
                                    </button>
                                )}
                            </span>
                        </header>
                        {this.props.canEditTag  ? (
                            <div style={{ display: "flex", flexDirection: "row-reverse", paddingTop: "50px", paddingBottom: "10px" }}>


                                {!tag?.mandatory || this.props.tenantSettings.showFeatures.everyoneAudienceEnabled ? (
                                    <button onClick={this.toggleSubscriptionModal} disabled={this.props.saving || this.props.fetching || tag?.disabled}>
                                        <FormattedMessage
                                            id="common.manageUserSubscriptions"
                                            defaultMessage="Manage User Subscriptions"
                                        />
                                    </button>
                                ) : null}

                                <DownloadButton
                                    onClick={() => this.downloadTopicUserList()}
                                    text={" Download User Data"}
                                    downloadName={`${tag?.name}-user-data`}
                                    styles={this.defaultStyles}
                                />
                                <button disabled={this.props.saving || this.props.fetching} onClick={() => this.toggleTagDisabled(tag)}>
                                    {this.props.saving && this.state.currentAction === "toggling" ? (
                                        <LoadingSpinner size={"18px"} />
                                    ) : tag?.disabled ? (
                                        <FormattedMessage id="common.enable" defaultMessage="Enable" />
                                    ) : (
                                        <FormattedMessage id="common.disable" defaultMessage="Disable" />
                                    )}
                                </button>
                                <button
                                    onClick={() => this.toggleTagDeleted(tag)}
                                    disabled={this.props.saving || this.props.fetching}
                                    style={{ backgroundColor: "#d9534f", padding: "0px 20px" }}
                                >
                                    {this.props.saving && this.state.currentAction === "deleting" ? (
                                        <LoadingSpinner size={"18px"} />
                                    ) : (
                                        <span>
                                            <FormattedMessage id="common.delete" defaultMessage="Delete" />
                                        </span>
                                    )}
                                </button>
                            </div>
                        ) : null}
                        <div className="hl-stats-wrapper">
                            <div className="hl-stats">
                                <HighlightedNumber
                                    loading={!this._awarenessPoints}
                                    value={this.state.isTagReadsAndPostsFetched ? awarenessScore : 0}
                                    text={this.props.intl.formatMessage({ id: "insights.awareness", defaultMessage: "Awareness" })}
                                    badIfBelow={40}
                                    goodIfAbove={70}
                                />

                                <HighlightedNumber
                                    loading={!this.props.users}
                                    value={this.state.fetchedTagStats.subscriberCount}
                                    text={this.props.intl.formatMessage({ id: "insights.subscribers", defaultMessage: "Subscribers" })}
                                    badIfBelow={(this.props.users || []).length / 5}
                                    goodIfAbove={(this.props.users || []).length * 0.75}
                                />

                                <HighlightedNumber
                                    loading={!this.props.tagStats?.posts}
                                    value={this.state.fetchedTagStats.postCount}
                                    text={this.props.intl.formatMessage({ id: "posts.posts", defaultMessage: "Posts" })}
                                    badIfBelow={1}
                                    goodIfAbove={100}
                                />

                                <HighlightedNumber
                                    loading={!this.props.users}
                                    value={this.state.fetchedTagStats.viewCount}
                                    decimalFormat={views > 1000 && `${views}`.length % 3 !== 0}
                                    text={this.props.intl.formatMessage({ id: "insights.views", defaultMessage: "Views" })}
                                    badIfBelow={(this.props.users || []).length / 5}
                                    goodIfAbove={(this.props.users || []).length * 0.75}
                                />

                                <HighlightedNumber
                                    loading={!this.props.tagStats?.posts}
                                    value={this.state.isTagReadsAndPostsFetched ? topAuthors.length : 0}
                                    text={this.props.intl.formatMessage({ id: "posts.authors", defaultMessage: "Authors" })}
                                    badIfBelow={2}
                                    goodIfAbove={5}
                                />
                                <div className="clearfix"></div>
                                <div style={{ marginLeft: 40, paddingTop: 10 }}>* Updated every hour on the thirty minute mark</div>

                            </div>
                        </div>


                        <div className="topic-details">
                            <div className="row-wrapper">
                                <div className="detail-wrapper">
                                    <i
                                        title={this.props.intl.formatMessage({ id: "common.mandatoryTopic" })}
                                        className="material-icons"
                                        style={{ fontSize: "22px", marginRight: "5px", marginTop: "-4px" }}
                                    >
                                        info
                                    </i>
                                    <label>Mandatory</label>
                                    <ToggleSwitch
                                        value={tag?.mandatory || false}
                                        onClick={() => {
                                            this.toggleTagMandatory(tag!);
                                        }}
                                    />
                                    <p>for</p>
                                </div>
                                {this.renderSelection("mandatory", tag!)}
                            </div>
                            <div className="row-wrapper">
                                <div className="detail-wrapper">
                                    <i
                                        title={this.props.intl.formatMessage({ id: "common.defaultTopic" })}
                                        className="material-icons"
                                        style={{ fontSize: "22px", marginRight: "5px", marginTop: "-4px" }}
                                    >
                                        info
                                    </i>
                                    <label>Default</label>
                                    <ToggleSwitch
                                        value={tag?.default || false}
                                        onClick={() => {
                                            this.toggleTagDefault(tag!);
                                        }}
                                    />
                                    <p>for</p>
                                </div>
                                {this.renderSelection("default", tag!)}
                            </div>
                            {this.props.showAudiences ? (
                                <div className="row-wrapper">
                                    <div className="detail-wrapper">
                                        <i
                                            title={this.props.intl.formatMessage({ id: "common.restrictedTopic" })}
                                            className="material-icons"
                                            style={{ fontSize: "22px", marginRight: "5px", marginTop: "-4px" }}
                                        >
                                            info
                                        </i>
                                        <label>Restricted</label>
                                        <ToggleSwitch
                                            value={tag?.restricted || false}
                                            onClick={() => {
                                                this.toggleTagRestricted(tag!);
                                            }}
                                        />
                                        <p>to &nbsp;</p>
                                    </div>
                                    {this.renderSelection("restricted", tag!)}
                                </div>
                            ) : null}
                            <div className="error-mssg">{this.props.errorMsg}</div>
                            <div style={{ textAlign: "right" }}>
                                <button
                                    className="btn btn-primary"
                                    onClick={this.props.saveTenantSettings}
                                    disabled={this.props.saving || this.props.fetching || this.props.pristine}
                                >
                                    {this.props.saving ? <LoadingSpinner size={"14px"} color="#fff" /> : "Save"}
                                </button>
                                <button
                                    className="btn reset-btn"
                                    onClick={this.props.resetTenantSettingsToPristine}
                                    disabled={this.props.saving || this.props.fetching || this.props.pristine}
                                >
                                    {this.props.fetching ? <LoadingSpinner size={"14px"} color="#fff" /> : "Reset"}
                                </button>
                            </div>
                        </div>

                        <div className="tag-stats">
                            <AwarenessVisualization
                                title={this.props.intl.formatMessage({
                                    id: "insights.awarenessOverTime",
                                    defaultMessage: "Awareness Over Time",
                                })}
                                awarenessScore={this._awarenessPoints}
                                locale={this.props.locale}
                                xLabel={this.props.intl.formatMessage(
                                    {
                                        id: "insights.topicAwarenessXLabel",
                                        defaultMessage: `days since first post was created with the {tagName} topic`,
                                    },
                                    { tagName: this.props.tagStats?.name }
                                )}
                            />
                            <div className="clearfix"></div>
                            <div className="alert alert-info">
                                <FormattedMessage
                                    id="insights.awarenessDescription"
                                    defaultMessage="Awareness of this topic increases as users read posts marked with the {tagName} topic. Post read contribution depends on how much of the post was read by the user and decays over time. In order to maintain awareness of this topic in your organization, posts should be created at regular intervals to keep users engaged."
                                    values={{ tagName: this.props.tagStats?.name }}
                                />
                            </div>
                        </div>
                        <div className="tag-detail-items-wrapper">
                            <div className="tag-detail-items">
                                {(this.props.tagStats?.posts || []).length > 0 ? (
                                    <div className="tag-detail-item">
                                        <h3>
                                            <FormattedMessage
                                                id="insights.postsWithTopic"
                                                defaultMessage="Posts with topic {tagName}"
                                                values={{ tagName: this.props.tagStats?.name }}
                                            />
                                        </h3>
                                        <table className="detail-table fixed">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id="common.title" defaultMessage="Title" />
                                                    </th>
                                                    <th className="fixed-100">
                                                        <FormattedMessage id="common.created" defaultMessage="Created" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!!this.props.tagStats?.posts &&
                                                    this.props.tagStats?.posts.map((post) => {
                                                        return (
                                                            <tr key={post.id}>
                                                                <td>
                                                                    <TenantLink
                                                                        className="post-title-link"
                                                                        to={`~/admin/posts/${post.id}`}
                                                                    >
                                                                        {post.title}
                                                                    </TenantLink>
                                                                    <div className="author">{post.author}</div>
                                                                </td>
                                                                <td>{DateFormatter.formatDate(post.createdTime)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : null}
                                {topAuthors.length > 0 && (
                                    <div className="tag-detail-item">
                                        <h3>
                                            <FormattedMessage id="posts.authors" defaultMessage="Author" />
                                        </h3>
                                        <table className="detail-table fixed">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id="common.name" defaultMessage="Name" />
                                                    </th>
                                                    <th className="fixed-50">
                                                        <FormattedMessage id="posts.posts" defaultMessage="Posts" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topAuthors.map((author) => {
                                                    return (
                                                        <tr key={author.name}>
                                                            <td>{author.name}</td>
                                                            <td>
                                                                <FormattedNumber value={author.count} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <TagSubscriptionManagerModal
                        show={this.state.showSubscriptionManagementModal && !this.props.saving && !this.props.fetching}
                        closeModal={this.toggleSubscriptionModal}
                        userGroups={this.props.userGroups}
                        ADGroupSyncEnabled={this.props.ADGroupSyncEnabled}
                        restricted={tag?.restricted}
                        id={this.props.match.params.tagId}
                        canEditTag={this.props.canEditTag}
                    />
                    <ErrorSnackbar 
                        errorMessage={ this.state.errorMessage || "" }
                        clearErrorMessage={ () => this.setState({ ...this.state, errorMessage: ""}) }
                    />
                </MainContent>
            </BasePage>
        );
    };

    private toggleTagDisabled = async (tag: TenantSettingsTag | null) => {
        if (tag) {
            this.setState({ ...this.state, currentAction: "toggling" });
            this.props.updateTag(
                tag.id,
                tag.name,
                tag.mandatory,
                tag.default,
                !tag.disabled,
                tag.deleted,
                tag.restricted,
                tag.restrictedTo,
                tag.mandatoryFor,
                tag.defaultFor
            );
            this.props.saveTenantSettings();
        }
    };

    private toggleTagDeleted = (tag: TenantSettingsTag | null) => {
        if (tag) {
            this.setState({ ...this.state, currentAction: "deleting" });
            tagsApi
                .GetTagUsageStatus(tag.id)
                .then((tagUsed) => {
                    if (!tagUsed) {
                        //if tag is not used in any posts or events, can delete
                        this.handleTagDeletion(tag);
                    } else {
                        this.setState({errorMessage: "This topic cannot be deleted because it is used by existing posts or events."});
                    }
                })
                .catch((err) => this.setState({errorMessage: "There was an error processing this topic."}));
        }
    };

    private handleTagDeletion = async (tag: TenantSettingsTag) => {
        if (await confirm.show({ text: `Are you sure you want to delete the topic ${tag.name}? Deleted topics will be permanently removed.`, title: "Topic deletion" })) {
            this.props.updateTag(
                tag.id,
                tag.name,
                tag.mandatory,
                tag.default,
                tag.disabled,
                !tag.deleted,
                tag.restricted,
                tag.restrictedTo,
                tag.mandatoryFor,
                tag.defaultFor
            );
            this.props.saveTenantSettings();
            browserHistory.goBack();
        }
        return;
    };

    private getTopAuthors = (tagStats: DetailedTagStats) => {
        let topAuthors = {};
        if (!!tagStats && !!tagStats.posts) {
            for (let i = 0; i < tagStats.posts.length; i++) {
                let post = tagStats.posts[i];
                let authorName = post.author || this.props.intl.formatMessage({ id: "posts.noauthor", defaultMessage: "No Author" });
                let key = authorName.toUpperCase();
                let entry = topAuthors[key];
                if (!entry) {
                    entry = {
                        name: authorName,
                        count: 0,
                    };
                }
                entry.count++;
                topAuthors[key] = entry;
            }
        }
        let topAuthorsArr = Object.keys(topAuthors).map((key) => {
            return topAuthors[key];
        });

        topAuthorsArr.sort((a, b) => {
            return b.count - a.count;
        });

        return topAuthorsArr;
    };

    private toggleSubscriptionModal = () => {
        this.setState({ ...this.state, showSubscriptionManagementModal: !this.state.showSubscriptionManagementModal });
    };

    private renderSelection = (type: string, tag: TenantSettingsTag) => {
        let options = this.state.audiences.map((a) => ({ label: a.displayName, value: a.id }));

        const selectStyles = {
            multiValue: (styles) => {
                return {
                    ...styles,
                    backgroundColor: "rgba(242,249,252,0.2)",
                    border: "1px solid rgba(201,230,242)",
                    color: "rgb(3,169,244)",
                };
            },
        };

        let placeholder = "";
        let audienceValues: string[] = [];
        let isTypeOn = false;
        if (tag) {
            if (type === "mandatory") {
                isTypeOn = tag.mandatory;
                audienceValues = tag.mandatoryFor;
                if (isTypeOn) {
                    placeholder = "Everyone or select audience(s)...";
                }
            } else if (type === "default") {
                isTypeOn = tag.default;
                audienceValues = tag.defaultFor;
                if (isTypeOn) {
                    placeholder = "Everyone or select audience(s)...";
                }
            } else {
                isTypeOn = tag?.restricted;
                audienceValues = tag.restrictedTo;
                if (isTypeOn) {
                    placeholder = "Restricted subscription for....";
                }
            }
        }

        let initialValues = (audienceValues || []).map((a) => ({
            label: (this.state.audiences.find((al) => al.id === a) || { displayName: "unknown" }).displayName,
            value: a,
        }));

        if (!this.props.showAudiences) {
            placeholder = "Everyone";
        }

        return (
            <MultiSelect
                placeholder={placeholder}
                options={options}
                onChange={this.onAudiencesChange(tag, type)}
                value={initialValues}
                isDisabled={!isTypeOn}
                styles={selectStyles}
                components={!this.props.showAudiences ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}}
            />
        );
    };

    private toggleTagMandatory = (tag: TenantSettingsTag) => {
        let newMandatoryFor = tag.mandatoryFor;
        if (tag.mandatory && tag.mandatoryFor.length > 0) {
            //if mandatory was on and had mandatoryFor list, empty it before turning off
            newMandatoryFor = [];
        }
        this.props.updateTag(
            tag.id,
            tag.name,
            !tag.mandatory,
            tag.default,
            tag.disabled,
            tag.deleted,
            tag.restricted,
            tag.restrictedTo,
            newMandatoryFor,
            tag.defaultFor
        );
    };

    private toggleTagDefault = (tag: TenantSettingsTag) => {
        let newDefaultFor = tag.defaultFor;
        if (tag.default && tag.defaultFor.length > 0) {
            //if default was on and had defaultFor list, empty it before turning off
            newDefaultFor = [];
        }
        this.props.updateTag(
            tag.id,
            tag.name,
            tag.mandatory,
            !tag.default,
            tag.disabled,
            tag.deleted,
            tag.restricted,
            tag.restrictedTo,
            tag.mandatoryFor,
            newDefaultFor
        );
    };

    private toggleTagRestricted = async (tag: TenantSettingsTag) => {
        var newRestrictedTo;
        if (!tag.restricted) {
            //if restricted was off, confirm turning it on
            if (
                !(await confirm.show({
                    text: "Posts published with this topic will only be visible to users in the selected audience(s). <br/><br/>Do you want to continue?", title: "Topic restriction"
                }))
            ) {
                return; //if declines return
            }
            newRestrictedTo = tag.restrictedTo;
        }
        if (tag.restricted) {
            //if restricted was on, confirm turning it off
            if (
                !(await confirm.show({
                    text: "Posts published with this topic may become visible to anyone in your organization. <br/><br/>Do you want to continue?", title: "Topic unrestriction"
                }))
            ) {
                return; //if declines return
            }
            newRestrictedTo = [];
        }

        this.props.updateTag(
            tag.id,
            tag.name,
            tag.mandatory,
            tag.default,
            tag.disabled,
            tag.deleted,
            !tag.restricted,
            newRestrictedTo,
            tag.mandatoryFor,
            tag.defaultFor
        );
    };

    private updateTagGroups = (tag: TenantSettingsTag, audienceIds: string[], type: string) => {
        if (type === "mandatory") {
            this.props.updateTag(
                tag.id,
                tag.name,
                tag.mandatory,
                tag.default,
                tag.disabled,
                tag.deleted,
                tag.restricted,
                tag.restrictedTo,
                audienceIds,
                tag.defaultFor
            );
        } else if (type === "default") {
            this.props.updateTag(
                tag.id,
                tag.name,
                tag.mandatory,
                tag.default,
                tag.disabled,
                tag.deleted,
                tag.restricted,
                tag.restrictedTo,
                tag.mandatoryFor,
                audienceIds
            );
        } else {
            this.props.updateTag(
                tag.id,
                tag.name,
                tag.mandatory,
                tag.default,
                tag.disabled,
                tag.deleted,
                tag.restricted,
                audienceIds,
                tag.mandatoryFor,
                tag.defaultFor
            );
        }
    };

    private onAudiencesChange = (tag: TenantSettingsTag, type: string) => (newValues) => {
        this.updateTagGroups(
            tag,
            (newValues || []).map((s) => s.value),
            type
        );
    };

    private handleTagNameChange = (e: React.FormEvent<HTMLInputElement>, tag: TenantSettingsTag) => {
        let input = e.target as HTMLInputElement;
        if (tag) {
            this.props.updateTag(
                tag.id,
                input.value,
                tag.mandatory,
                tag.default,
                tag.disabled,
                tag.deleted,
                tag.restricted,
                tag.restrictedTo,
                tag.mandatoryFor,
                tag.defaultFor
            );
        }
    };

    private handleSave = () => {
        this.setState({ ...this.state, editting: false });
        this.props.saveTenantSettings();
    };

    private downloadTopicUserList = () => tagsApi.GetTopicUserCsv(this.props.match.params.tagId);
}

interface ComponentProps {
    intl: IntlShape;
}

interface ComponentState {
    currentAction: string;
    showSubscriptionManagementModal: boolean;
    audiences: Audience[];
    editting: boolean;
    isTagReadsAndPostsFetched: boolean;
    fetchedTagStats: DetailedTagStats;
    errorMessage: string;
}
interface RouteParams {
    tenant: string;
    tagId: string;
    tagGroupId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        canEditTag:
            !!state.settings.currentUser &&
            state.settings.currentUser.roles.indexOf(UserRoleStrings.OWNER) > -1 &&
            state.settings.tenantSettings.manageTopicsAudiencesInAdmin,
        tagStats: state.insights.tagStats,
        tenantSettings: state.settings.tenantSettings,
        saving: state.settings.saving,
        fetching: state.settings.fetching,
        users: state.users.userList.users,
        locale: state.localization.locale,
        userGroups: state.settings.tenantSettings.securityGroups,
        ADGroupSyncEnabled: state.settings.tenantSettings.ADGroupSyncEnabled,
        pristine: state.settings.pristine,
        errorMsg: state.settings.errorMsg,
        showAudiences: state.settings.tenantSettings.showFeatures.audiences,
    }),
    {
        fetchTagStats: actions.fetchTagStats,
        fetchTagReadsAndPostReads: actions.fetchTagReadsAndPostReads,
        fetchUsers: userActions.getUsersV1,
        updateTag: settingsActions.updateTag,
        saveTenantSettings: settingsActions.saveTenantSettings,
        resetTenantSettingsToPristine: settingsActions.resetTenantSettingsToPristine,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(injectIntl(connector(TagDetail)));
