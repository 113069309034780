import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";

import { PostFilterValues, PostListingPageId } from "../../models";

import AuthoringFilter from "modules/common/components/filters/authoringFilter";
import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CloseIcon from "@mui/icons-material/Close";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import moment from "moment";
import { TenantSettingsTagGroup } from "modules/settings";
import { NumberSelectOption, SelectOption } from "modules/common/models";
import TopicDropdown from "modules/common/components/topicDropdown";
import { availableContentStates, ContentState, contentStateLabels } from "utils/managementUtils";

const availablePostTypes: SelectOption[] = [
  { key: "standard", text: "Standard", type: "item" },
  { key: "mandatory", text: "Mandatory", type: "item" },
  { key: "compliance", text: "Compliance", type: "item" },
  { key: "public", text: "Public", type: "item" }
];

class PostFilters extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      canSearch: !this.hasNoCurrentFilters(),
      lcidToSearch: props.filters.lcidToSearch || "",
      textToSearch: props.filters.textToSearch || "",
      // "to" date needs to be stored as state as we need to add on 23 hrs 59 secs for our filter.olderThan
      olderThanDateTimeValue: "",
    };
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.filters.lcidToSearch !== prevProps.filters.lcidToSearch)
      this.setState({ lcidToSearch: this.props.filters.lcidToSearch || "" });

    if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
      this.setState({ textToSearch: this.props.filters.textToSearch || "" });
  }

  public getFilterByState = () => <FilterDropdown text="State">
    <List disablePadding>
      {availableContentStates.map((availablePostState) => (
        <ListItem key={availablePostState} dense button onClick={() => this.onChangePostStates(availablePostState)}>
          <ListItemIcon className="callout-checkbox">
            <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                size="small"
                color="primary"
                checked={!!(this.props.filters.postStates!.find((selectedPostState) => selectedPostState === availablePostState) !== undefined)}
            />
            </ListItemIcon>
          <ListItemText primary={contentStateLabels[availablePostState]} />
        </ListItem>
      ))}
    </List>
  </FilterDropdown>

  public render() {
    const { filters } = this.props;
    const tagSelected = (filters.tags?.length ?? [].length) > 0;

    return (
      <FilterContainer
        filters={
          <React.Fragment>
            <AuthoringFilter
              lcidToSearch={this.state.lcidToSearch}
              textToSearch={this.state.textToSearch}
              searchTextPlaceholder="Search post or author"
              additionalFilters={
                <React.Fragment>
                  {this.props.pageId === PostListingPageId.ALL && this.getFilterByState()}

                  <FilterDropdown text={this.props.pageId === PostListingPageId.SUBMISSIONS ? "Submitted Between" : "Published Between"}>
                  <PickerLocalization>
                    <div className="published-time">
                      <div>
                        <div>From</div>
                        <DatePicker
                          format="MMM dd yyyy"
                          value={filters.newerThan ? Date.parse(filters.newerThan) : null}
                          slotProps={{
                            textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                            openPickerIcon: { color: "primary" }
                          }}
                          maxDate={this.props.onlyOlder ? new Date() : null}
                          minDate={this.props.onlyNewer ? new Date() : null}
                          onChange={this.onChangeFromDate}
                          className="date-picker"
                        />
                      </div>
                      <div>
                        <div>To</div>
                        <DatePicker
                          format="MMM dd yyyy"
                          value={
                            this.state.olderThanDateTimeValue !== "" ? 
                              Date.parse(this.state.olderThanDateTimeValue) : null
                          }
                          slotProps={{
                            textField: { size: "small", placeholder: "", inputProps: { readOnly: true }},
                            openPickerIcon: { color: "primary" }
                          }}
                          maxDate={this.props.onlyOlder ? new Date() : null}
                          minDate={this.props.onlyNewer ? new Date() : null}
                          onChange={this.onChangeToDate}
                          className="date-picker"
                        />
                      </div>
                    </div>
                  </PickerLocalization>
                  </FilterDropdown>

                  <TopicDropdown
                    tagSelected={tagSelected}
                    availableTopics={this.props.availableTopics}
                    currentTags={filters.tags ?? []}
                    handleTagGroupClick={this.handleTagGroupClick}
                    checkGroupSelected={this.checkGroupSelected}
                    onChangeTags={this.onChangeTags}
                    onHandleAllTags={this.onHandleAllTags}
                  />

                  {
                    this.props.pageId !== PostListingPageId.SUBMISSIONS && 
                    <FilterDropdown text="Types">
                      <List disablePadding>
                        {availablePostTypes.map((availablePostType) => (
                          <ListItem key={availablePostType.key} dense button onClick={() => this.onChangePostTypes(availablePostType.key)}>
                            <ListItemIcon className="callout-checkbox">
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                size="small"
                                color="primary"
                                checked={!!filters.postTypes!.find((selectedPostType) => selectedPostType === availablePostType.key)}
                              />
                            </ListItemIcon>
                            <ListItemText primary={availablePostType.text} />
                          </ListItem>
                        ))}
                      </List>
                    </FilterDropdown>
                  }
                </React.Fragment>
              }
              onChangeTextToSearch={this.onChangeTextToSearch}
              onUpdateLcidToSearch={this.onUpdateLcidToSearch}
              onUpdateTextToSearch={this.onUpdateTextToSearch}
            />
          </React.Fragment>
        }
        filterCommands={
          <AuthoringSearch
            canSearch={this.state.canSearch}
            onApplyFilters={this.onChangeTextToSearch}
            onClearFilters={this.onClearFilters}
          />
        }
        filterSelection={this.getFilterSelection()}
      />
    );
  }

  private getFilterSelection = (): JSX.Element | undefined => {
    const { filters } = this.props;

    if (!filters.textToSearch && !filters.tags!.length && !filters.postTypes!.length && !filters.newerThan && !filters.olderThan && !filters.postStates!.length)
      return undefined;

    const availableTags: SelectOption[] = this.getAvailableTags();

    return (
      <React.Fragment>
        {!!filters.textToSearch &&
          <Chip
            key="search-text"
            label={`"${filters.textToSearch}"`}
            onDelete={this.onClearTextToSearch}
            deleteIcon={<CloseIcon />}
          />
        }
        {filters.tags!.map((selectedTag) =>
          <Chip
            key={selectedTag}
            label={availableTags.find((tag) => tag.key === selectedTag)!.text}
            onDelete={() => this.onClearTag(selectedTag)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.postTypes!.map((selectedPostType) =>
          <Chip
            key={selectedPostType}
            label={availablePostTypes.find((postType) => postType.key === selectedPostType)!.text}
            onDelete={() => this.onClearPostType(selectedPostType)}
            deleteIcon={<CloseIcon />}
          />
        )}
        {filters.postStates!.map((selectedPostState) =>
            this.props.pageId === PostListingPageId.ALL &&
                <Chip
                    key={selectedPostState}
                    label={contentStateLabels[selectedPostState]}
                    onDelete={() => this.onClearPostState(selectedPostState)}
                    deleteIcon={<CloseIcon />}
                />
        )}
        {(!!filters.newerThan || !!filters.olderThan) &&
          <Chip
            key="published-time"
            label={
                `${
                    this.props.pageId === PostListingPageId.SUBMISSIONS ? "Submitted" : "Released"
                }
                ${
                    !!filters.newerThan ?
                        ` from ${moment(filters.newerThan).format("MMM D, YYYY")}` : ""
                }
                ${
                    !!filters.olderThan ? 
                        ` up to ${moment(filters.olderThan).format("MMM D, YYYY")}` : ""
                }`
            }
            onDelete={this.onClearPublishedTime}
            deleteIcon={<CloseIcon />}
          />
        }
      </React.Fragment>
    );
  }


  private getAvailableTags = (): SelectOption[] => {
    if (!this.props.tenantSettings)
      return [];

    let availableTags: SelectOption[] = [];
    this.props.availableTopics.map((tagGroup) => {
      if (!!tagGroup.tags.length && !tagGroup.deleted && !tagGroup.disabled) {
        availableTags.push({ key: tagGroup.id, text: tagGroup.name, type: "header" });
        tagGroup.tags.map((tag) => {
          if (!tag.deleted && !tag.disabled)
            availableTags.push({ key: tag.id, text: tag.name, type: "item" });
          return tag;
        });
      }
      return tagGroup;
    });
    return availableTags;
  }

  private onChangePostStates = (currentPostState: ContentState) => {
    if (this.props.pageId === PostListingPageId.ALL) {
        const hasSelectedPostState: boolean = !!(this.props.filters.postStates!.find((selectedPostState) => selectedPostState === currentPostState) !== undefined);
        
        if (hasSelectedPostState)
            this.onChangeFilters({ postStates: this.props.filters.postStates!.filter((selectedPostState) => selectedPostState !== currentPostState) });
        else
            this.onChangeFilters({ postStates: this.props.filters.postStates!.concat([currentPostState]) });

    }
  }

  private onChangeFilters = (value: Partial<PostFilterValues>) => {
    this.props.onChangeFilters({ ...this.props.filters, lcidToSearch: this.state.lcidToSearch, textToSearch: this.state.textToSearch, ...value });
    this.setCanSearch();
  }

  private onClearFilters = () => {
    this.clearCanSearch();
    this.props.onClearFilters();
    this.setState({textToSearch: "", olderThanDateTimeValue: ""});
  }

  private checkGroupSelected = (tagGroup: TenantSettingsTagGroup) => {
    return tagGroup.tags.every(tag => {
      return this.props.filters.tags!.includes(tag.id);
    })
  }

  private handleTagGroupClick = (tagGroup: TenantSettingsTagGroup) => {
    let isCurrentlyChecked = this.checkGroupSelected(tagGroup);

    if(isCurrentlyChecked) {
      this.deselectTagGroup(tagGroup);
    }
    else {
      this.selectTagGroup(tagGroup);
    }
  }

  private selectTagGroup = (tagGroup: TenantSettingsTagGroup) => {
    let currentTags = this.props.filters.tags ?? [];

    tagGroup.tags.forEach(tag => {
      if(!currentTags.includes(tag.id)) {
        currentTags.push(tag.id);
      }
    })

    let newFilters = this.props.filters;
    newFilters.tags = currentTags;
    this.onChangeFilters(newFilters);
  }

  private deselectTagGroup = (tagGroup: TenantSettingsTagGroup) => {
    let currentTags = this.props.filters.tags ?? [];
    let tagsToRemove = tagGroup.tags.map(t => t.id);

    currentTags = currentTags.filter(currentTag => !tagsToRemove.includes(currentTag));

    let newFilters = this.props.filters;
    newFilters.tags = currentTags;
    this.onChangeFilters(newFilters);
  }

  private onHandleAllTags = (tagSelected: boolean) => {
    if(tagSelected) {
      let newFilters = this.props.filters;
      newFilters.tags = [];
      this.onChangeFilters(newFilters);
    }
    else {
      let currentTags = this.props.filters.tags ?? [];
      let tagGroups = this.props.availableTopics;

      tagGroups.forEach(tagGroup => {
        tagGroup.tags.forEach(tag => {
          if(!currentTags.includes(tag.id)) {
            currentTags.push(tag.id);
          }
        });
      });

      let newFilters = this.props.filters;
      newFilters.tags = currentTags;
      this.onChangeFilters(newFilters);
    }
  }

  private onChangeLcidToSearch = (lcidToSearch: string) => {
    this.onChangeFilters({ lcidToSearch });
  }

  private onChangePostTypes = (currentPostType: string) => {
    const hasSelectedPostType: boolean = !!this.props.filters.postTypes!.find((selectedPostType) => selectedPostType === currentPostType);
    if (hasSelectedPostType)
      this.onChangeFilters({ postTypes: this.props.filters.postTypes!.filter((selectedPostType) => selectedPostType !== currentPostType) });
    else
      this.onChangeFilters({ postTypes: this.props.filters.postTypes!.concat([currentPostType]) });
  }

  private onChangeTags = (currentTag: string) => {
    const hasSelectedTag: boolean = !!this.props.filters.tags!.find((selectedTag) => selectedTag === currentTag);
    if (hasSelectedTag)
      this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== currentTag) });
    else
      this.onChangeFilters({ tags: this.props.filters.tags!.concat([currentTag]) });
  }

  private onChangeTextToSearch = () => {
    this.onChangeFilters({ textToSearch: this.state.textToSearch });
  }

  private onClearPostType = (postType: string) => {
    this.onChangeFilters({ postTypes: this.props.filters.postTypes!.filter((selectedPostType) => selectedPostType !== postType) });
  }

  private onClearPostState = (postState: ContentState) => {
    if (this.props.pageId === PostListingPageId.ALL)
        this.onChangeFilters({ postStates: this.props.filters.postStates!.filter((selectedPostState) => selectedPostState !== postState) });
  }

  private onClearTag = (tag: string) => {
    this.onChangeFilters({ tags: this.props.filters.tags!.filter((selectedTag) => selectedTag !== tag) });
  }

  private onClearTextToSearch = () => {
    this.onChangeFilters({ textToSearch: "" });
  }

  private onUpdateLcidToSearch = (event) => {
    this.setState({ lcidToSearch: event.target.value });
    if (!!this.props.filters.textToSearch)
      this.onChangeLcidToSearch(event.target.value);
  }

  private onUpdateTextToSearch = (event) => {
    this.setState({ textToSearch: event.target.value });
    if(event.target.value && event.target.value !== "") {
      this.setCanSearch();
    }
    else if(this.hasNoCurrentFilters()){
      this.clearCanSearch();
    }
  }


  // Will set the 'newerThan' date to start at the 00:00 time of the chosen date
  private onChangeFromDate = (date) => {
    let dateToUse = new Date(date);
    let timeNow = new Date();

    if(this.props.onlyNewer) {
      if(moment(date).dayOfYear() === moment().dayOfYear()) {
        dateToUse.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds());
      }
    }

    this.onChangeFilters({ newerThan: moment(dateToUse).toISOString() });
  }

  // Will set the 'olderThan' date to start at the 23:59 time of the chosen date
  private onChangeToDate = (date) => {
    let dateToUse = new Date(date);

    // add 24 hours and subtract 1 second to force the 'To Date' to be inclusive of the chosen date
    let nextDay = dateToUse.getTime() + ((24 * 60 * 60 - 1) * 1000);
    let nextDayDateToUse = new Date(nextDay);

    let timeNow = new Date();

    if(this.props.onlyOlder) {
      if(moment(date).dayOfYear() === moment().dayOfYear()) {
        nextDayDateToUse.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds());
      }
    }

    this.onChangeFilters({ olderThan: moment(nextDayDateToUse).toISOString() });
    this.setState({ olderThanDateTimeValue: moment(dateToUse).toISOString() });
  }

  private onClearPublishedTime = () => {
    this.onChangeFilters({ newerThan: "", olderThan: "" });
    this.setState({ olderThanDateTimeValue: "" });
  }

  private clearCanSearch = () => {
    this.setState({ canSearch: false });
  }

  private setCanSearch = () => {
    this.setState({ canSearch: true });
  }

  private hasNoCurrentFilters = () => {
    let filters = this.props.filters;

    return (!filters.tags || filters.tags.length === 0)
            && filters.textToSearch === ""
            && (!filters.postTypes || filters.postTypes.length === 0)
            && !filters.newerThan && !filters.olderThan
            && filters.postStates!.length === 0
  }
}


interface ComponentProps {
  filters: Partial<PostFilterValues>;
  onChangeFilters: (filters: Partial<PostFilterValues>) => void;
  onClearFilters: () => void;
  pageId: PostListingPageId;
  availableTopics: TenantSettingsTagGroup[];
  onlyOlder?: boolean;
  onlyNewer?: boolean;
}

interface ComponentState {
  canSearch: boolean;
  lcidToSearch: string;
  textToSearch: string;
  olderThanDateTimeValue: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    lcidMappings: state.resources.lcidMappings,
    tenantSettings: state.settings.tenantSettings,
    tenant: state.tenant.id
  }),
  {
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PostFilters);
