import React from "react";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Hidden, { HiddenProps } from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PersonIcon from "@mui/icons-material/Person";
import PhotoIcon from "@mui/icons-material/Photo";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import { EventType } from "../../../models";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import RequireRole from "modules/common/components/requireRole";
import { UserRoleStrings } from "modules/authorization/models";

interface IEventCommandBar {
    eventType?: EventType;
    actions: Record<EventCommandBarAction, IEventCommandBarAction>;
};

export interface IEventCommandBarAction {
    onClick: () => void;
    disabled: boolean;
    startIcon?: JSX.Element;
}

export enum EventCommandBarAction {
    ShowBanner,
    ShowSummary,
    AddOrganizer,
    AttachFiles,
    AddQuestion,
}

interface IEventActionItem {
    key: string;
    action: IEventCommandBarAction;
    label: string;
    hiddenInBar?: HiddenProps;
}

export const EventCommandBar: React.FunctionComponent<IEventCommandBar> = ({
    eventType,
    actions
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const showMoreActionsOpen = Boolean(anchorEl);

    const actionItems: IEventActionItem[] = [
        {
            key: "banner-action",
            action: { ...actions[EventCommandBarAction.ShowBanner], startIcon: <PhotoIcon /> },
            label: "Insert Hero Banner"
        },
        {
            key: "summary-action",
            action: { ...actions[EventCommandBarAction.ShowSummary], startIcon: <PlaylistAddIcon /> },
            label: "Add Summary",
            hiddenInBar: { mdDown: true },
        },
        {
            key: "organizer-action",
            action: { ...actions[EventCommandBarAction.AddOrganizer], startIcon: <PersonIcon /> },
            label: "Custom Organizer",
            hiddenInBar: { lgDown: true },
        },
        {
            key: "files-action",
            action: { ...actions[EventCommandBarAction.AttachFiles], startIcon: <AttachFileIcon /> },
            label: "Attach Files",
            hiddenInBar: { xlDown: true },
        },
        {
            key: "question-action",
            action: { ...actions[EventCommandBarAction.AddQuestion], startIcon: <AssignmentIcon /> },
            label: "Add Question",
            hiddenInBar: { xlDown: true },
        }
    ];

    const getCommandBar = (): (JSX.Element | undefined)[] => {
        return actionItems.map(item => {
            if (eventType === "informational" && item.key === "question-action") return undefined;

            return <RequireRole key={item.key} roleName={item.key === "organizer-action" ? UserRoleStrings.AUTHOR : UserRoleStrings.CONTRIBUTOR}>
                <Hidden {...item.hiddenInBar}>
                    <Button variant="text" color="primary" {...item.action}>
                        {item.label}
                    </Button>
                </Hidden>
            </RequireRole>;
        }
        );
    };

    const onClickMoreActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event?.currentTarget);
    };

    const getMoreMenu = (): JSX.Element => {
        return <Menu
            open={showMoreActionsOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
        >
            {
                actionItems
                    .map(item => {
                        if (eventType === "informational" && item.key === "question-action") return undefined;

                        return <RequireRole key={item.key} roleName={item.key === "organizer-action" ? UserRoleStrings.AUTHOR : UserRoleStrings.CONTRIBUTOR}>
                            <MenuItem
                                key={item.key}
                                disabled={item.action.disabled}
                                onClick={() => {
                                    setAnchorEl(null);
                                    item.action.onClick();
                                }}>
                                <ListItemIcon>
                                    {item.action.startIcon}
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </MenuItem>
                        </RequireRole>;
                    })
            }
        </Menu>;
    };

    return (
        <Paper square className="tab-root">
            <div className="tab-commands">
                {getCommandBar()}
                <Hidden xlUp>
                    <IconButton onClick={onClickMoreActions} size="large">
                        <MoreHorizIcon color="primary" />
                    </IconButton>
                </Hidden>
                {getMoreMenu()}
            </div>
        </Paper>
    );
};