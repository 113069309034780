import React, { useState } from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import {actions} from '../..'

interface ComponentProps {
    title: string; 
    teamsEvent: string;
    mobileEvent: string;
    emailEvent: string;
    smsEvent: string;
    description?: string;
    id?: string;
    handleOnClick?: (any) => any;
    
}

const SettingsToggleWithBlackout: React.FC<PropsWithRedux> = ({title, teamsEvent, mobileEvent, emailEvent, smsEvent, description, toggleNotificationEvent, notificationSettings, toggleBlackoutPeriod, enableAllChannels, setSelectedChannel, eventsWithBlackout, id, handleOnClick}) => {
    
    const settings = notificationSettings.settings!;
    const anyChannelChecked = settings[smsEvent].toggle || settings[emailEvent].toggle || settings[teamsEvent].toggle || settings[mobileEvent].toggle;
    const blackoutApplied =  eventsWithBlackout.includes(smsEvent) || eventsWithBlackout.includes(emailEvent) || eventsWithBlackout.includes(teamsEvent) || eventsWithBlackout.includes(mobileEvent);
    const allChannelsChecked = settings[smsEvent].toggle && settings[emailEvent].toggle && settings[teamsEvent].toggle && settings[mobileEvent].toggle;
    const [allSelected, setSelectedChannelsChecked] = useState(allChannelsChecked);
    const type = (id ?? "");
    
    const updateCheckboxes = (event: string, platform: string, toSet: boolean) => {
        let smsStatus = platform === "sms" ? toSet : settings[smsEvent].toggle;
        let emailStatus = platform === "email" ? toSet : settings[emailEvent].toggle;
        let teamsStatus = platform === "teams" ? toSet : settings[teamsEvent].toggle;
        let mobileStatus = platform === "mobile" ? toSet : settings[mobileEvent].toggle;
    
        if(!(smsStatus || emailStatus || teamsStatus || mobileStatus))
        {
            enableAllChannels(teamsEvent, mobileEvent, emailEvent, smsEvent)
            setSelectedChannelsChecked(true)
        }
        else
        {
            setSelectedChannel(event, toSet);
        }
    }

    return (
        <div>
            <div className="clearfix">
                <div style={{float:"left", display: "inline-block", width: "1000px", fontWeight: "bold"}}>{title} 
                    <span style={{float:"right"}}>
                        <ToggleSwitch 
                            value={settings[smsEvent].toggle || settings[emailEvent].toggle || settings[teamsEvent].toggle || settings[mobileEvent].toggle}
                            onClick={() => toggleNotificationEvent(teamsEvent, mobileEvent, emailEvent, smsEvent)}        
                        />
                    </span>
                </div>
            </div>
            { anyChannelChecked &&
            <div>
                <div style={{margin: "20px 0px"}} className="description-text">{description} <span className={"pointer-text"} id={id} onClick={handleOnClick}>Open More Notification Settings</span></div>
            
                <div className="settings-checkbox-section">
                    <div className="settings-checkbox-row">
                        <input style={{cursor: "pointer"}} type="checkbox" id={"applyBlackoutPeriod"+type}
                            checked={blackoutApplied} 
                            onChange={() => toggleBlackoutPeriod(teamsEvent, mobileEvent, emailEvent, smsEvent, !blackoutApplied)}>
                        </input>
                        <label className="input-label-text" htmlFor={"applyBlackoutPeriod"+type}>Apply blackout period to notifications</label>
                    </div>
                    
                    <div className="settings-checkbox-radio">
                        <input style={{marginLeft: 20, cursor: "pointer"}}
                            type="radio" id={"enableAllChannels"+type}
                            name={title+type} 
                            checked={allSelected && allChannelsChecked}
                            onChange={() => {
                                enableAllChannels(teamsEvent, mobileEvent, emailEvent, smsEvent)
                                setSelectedChannelsChecked(true)
                                }}/>
                        <label className="input-label-text" htmlFor={"enableAllChannels"+type}>Enable all channels</label>
                    </div>

                    <div className="settings-checkbox-radio">
                        <input 
                            style={{marginLeft: 20, cursor: "pointer"}} 
                            type="radio" 
                            id={"enableSelectedChannels"+type}
                            name={title+type}
                            checked={!allSelected || !allChannelsChecked}
                            onChange={() => {setSelectedChannelsChecked(false)}}/>
                    <label className="input-label-text" htmlFor={"enableSelectedChannels"+type}>Enable notifications for selected channels:</label></div>

                    { (!allSelected || !allChannelsChecked) &&
                    <div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableTeamsChannel"+type}
                                checked={settings[teamsEvent].toggle}
                                onChange={() => updateCheckboxes(teamsEvent, "teams", !settings[teamsEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableTeamsChannel"+type}>MS Teams Chatbot</label>
                        </div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableMobileChannel"+type}
                                checked={settings[mobileEvent].toggle}
                                onChange={() => updateCheckboxes(mobileEvent, "mobile", !settings[mobileEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableMobileChannel"+type}>Mobile App Notification</label>
                        </div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableEmailChannel"+type}
                                checked={settings[emailEvent].toggle}
                                onChange={() => updateCheckboxes(emailEvent, "email", !settings[emailEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableEmailChannel"+type}>Enable Email</label>
                        </div>
                        <div className="settings-checkbox-channel">
                            <input 
                                style={{marginLeft: 40, cursor: "pointer"}} 
                                type="checkbox" id={"enableSMSChannel"+type}
                                checked={settings[smsEvent].toggle}
                                onChange={() => updateCheckboxes(smsEvent, "sms", !settings[smsEvent].toggle)}>
                            </input><label className="input-label-text" htmlFor={"enableSMSChannel"+type}>Enable SMS</label>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
        </div>
    )

}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        eventsWithBlackout: state.settings.applyBlackoutPeriodsTo,
    }),
    {
        toggleBlackoutPeriod: actions.ApplyBlackoutPeriod,
        enableAllChannels: actions.EnableAllChannels,
        setSelectedChannel: actions.EnableSelectedChannel,
        toggleNotificationEvent: actions.toggleEvent
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(SettingsToggleWithBlackout));