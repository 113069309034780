export interface IdObject<TId> {
    id: TId;
}

export interface IdNamePair<TId, TName> extends IdObject<TId> {
    name: TName;
}

export interface IEventBase<T> {
    Id: number;
    TenantId: string;
    Time: string;
    Name: string;
    Body: T;
}

export interface IUserEventBase<T> extends IEventBase<T> {
    UserId: string;
    Platform: DEVICE_PLATFORM;
}

export interface IFeatureUsageEvent extends IUserEventBase<IFeatureUsageEventBody> {
}

export interface IFeatureUsageEventBody {
    featureActionCode: string;
}

// match dbo.FeatureActions - todo get from db at somepoint
export const FEATURE_ACTION_CODES = {
    SELECT_CONTENT_ANLYSIS_TAB: "CONTENT_ANALYSIS_TAB",
    ANALYZE_AGAIN: "ANALYZE_AGAIN"
}

// match dbo.DevicePlatform - todo get from db at somepoint
export enum DEVICE_PLATFORM {
    NONE = -1,
    APPLE = 0,
    ANDROID = 1,
    WEB = 2,
    SHAREPOINT = 3,
    FLUTTER_APPLE = 4,
    FLUTTER_ANDROID = 5,
    FLUTTER_WEB = 6,
    TEAMS = 7,
    LEGACY = 8,
    SLACK = 9,
    REACT_EMBEDDED = 10
}

export interface SelectOption {
    key: string;
    text: string;
    type: "header" | "item";
};

export interface NumberSelectOption {
    key: number;
    text: string;
    type: "header" | "item";
};

export enum ContentType {
    Post,
    Event
}