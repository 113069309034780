import React from "react";

import { UserRoleStrings } from "modules/authorization/models";
import TenantLink from "modules/common/components/tenantLink";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { UserRoleWrapper } from "./wrappers/UserRoleWrapper";
import { AccordionWrapper } from "./wrappers/AccordionWrapper";
import { useMenuStyles } from "../styles/menuItemStyles";

export enum MenuItemType {
    Divider,
    Link,
    Header
}

export interface IMenuItem {
    children?: IMenuItem[];
    endIcon?: JSX.Element;
    hide?: boolean;
    icon?: JSX.Element;
    label: string;
    pushToBrowserHistory?: boolean;
    type: MenuItemType;
    url?: string;
    userRole?: UserRoleStrings;
    userRoles?: UserRoleStrings[];
    strictRole?: boolean; // for restricting users who are ONLY a certain role instead of having a role via the hierarchy 
}

export const MenuItem: React.FunctionComponent<ComponentProps> = props => {
    const { menuItem } = props;
    const classes = useMenuStyles();

    if (menuItem.hide)
        return <React.Fragment></React.Fragment>;

    return (
        <UserRoleWrapper userRole={menuItem.userRole} strictRole={menuItem.strictRole}>
            <AccordionWrapper {...props}>
                <div className={classes.menuItem}>
                    <TenantLink to={menuItem.url || ""} pushToBrowserHistory={menuItem.pushToBrowserHistory}>
                        <ListItem
                            button
                            disableRipple
                            onClick={() => {
                                props.setExpandedMenuItem("");
                            }}
                            className={`${classes.listItem} ${props.selected ? "selected" : ""}`}
                        >
                            {!props.hideIcons &&
                                <ListItemIcon title={menuItem.label} className={classes.listItemIcon}>
                                    {menuItem.icon}
                                </ListItemIcon>
                            }
                            <div className={`${classes.expandableText} ${props.expand ? "expanded" : ""}`}>
                                <ListItemText
                                    primary={menuItem.label} classes={{ primary: classes.listItemText }} />
                                {menuItem.endIcon}
                            </div>
                        </ListItem>
                    </TenantLink>
                </div>
            </AccordionWrapper>
        </UserRoleWrapper>
    );
};

interface ComponentProps {
    expand?: boolean;
    expandedMenuItem: string;
    hideIcons?: boolean;
    menuItem: IMenuItem;
    selected?: boolean;
    setExpand: (expand: boolean) => void;
    setExpandedMenuItem: (menuItem: string) => void;
}
