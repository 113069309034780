import { GlobalApplicationState } from "globalApplicationState";
import * as Actions from "./actions";
import * as EventsActions from "modules/events/actions";
import * as PostsActions from "modules/posts/actions";
import * as SettingsActions from "modules/settings/actions";
import API from "api";

import { CurrentUserSettings } from "modules/settings/models";


export const initializeUserSettings = (userSettings: CurrentUserSettings) => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.InitializeUserSettings({ userSettings }));
}

export const saveUserSettings = (userSettings: Partial<CurrentUserSettings>, shouldDispatchUpdates: boolean = true) => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  const updatedUserSettings: CurrentUserSettings = { ...getState().profile.userSettings!, ...userSettings };

  dispatch(Actions.UpdateUserSettings({ userSettings }));
  dispatch(Actions.SaveUserSettings({}));
  return dispatch(API.profile.SaveUserSettings(updatedUserSettings))
    .then((response: Response) => {
      dispatch(Actions.SaveUserSettingsComplete({ succeeded: response.status === 200 }));
      if (shouldDispatchUpdates) {
        response
            .json()
            .then((userSettings: Partial<CurrentUserSettings>) => {
                dispatch(SettingsActions.UpdateUserSettings({ userSettings }));
                dispatch(EventsActions.ClearEventFeed({}));
                dispatch(EventsActions.ClearEventFeedFilters({}));
                dispatch(PostsActions.ClearPostFeed({}));
                dispatch(PostsActions.ClearPostFeedFilters({}));
            });
      }
      return response.status === 200;
    })
    .catch(err => {
      dispatch(Actions.SaveUserSettingsComplete({ succeeded: false, err }));
      return false;
    });
}

export const sendSMSVerifyRequest = () => (dispatch, getState: () => GlobalApplicationState): Promise<boolean> => {
  dispatch(Actions.SendSMSVerify({}));
  return dispatch(API.profile.SendSMSVerify())
  .then(response => {
    dispatch(Actions.SendSMSVerifyComplete({ succeeded: response.status === 200 }))
    return response.status === 200;
  })
  .catch (err => {
    dispatch(Actions.SendSMSVerifyComplete({ succeeded: false, err }));
    return false; 
  })
}

export const clearErrorMessage = () => (dispatch, getState: () => GlobalApplicationState): void => {
  dispatch(Actions.ClearErrorMessage({}));
}