import { AxiosPromise } from 'axios';
import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';

export class DraftEventsApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public getPublishedFromDraftId = (id: string) : AxiosPromise<string>=> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/drafts/events/published', { params: { id }})
        ).catch((err) => {
            throw err;
        });
    }
}