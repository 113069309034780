import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { EventFilterValues, EventListingPage } from "../../models";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import EventList from "./eventList";

import Tabs from "pages/common/tabs";
import { SortStyle } from "utils/managementUtils";
import { tagsApi } from "api/instances";
import { TenantSettingsTagGroup } from "modules/settings";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import CreatedByFilter, { CreatedBySelectValues } from "modules/common/components/authoring/createdByFilter";
import { ContentType } from "modules/common/models";
import PublishedEventDialog from "./publishedEventDialog";
import { DraftAction } from "modules/common/components/authoring/models";
import { UserRoleStrings } from "modules/authorization/models";
import { getHighestRole } from "utils/userRoleUtils";

enum TAB_IDS {
    DRAFTS,
    SUBMISSIONS,
    SCHEDULED,
    PUBLISHED,
    ALL,
}

class EventListing extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            selectedTab: 0,
            showFilters: false,
            availableTopics: [],
            showMyEventsOnly: false
        };
    }

    public componentDidMount() {
        if (this.props.isInitialLoad || this.props.shouldFetch) {
            this.fetchLists();
            this.getTags();
        }
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
            this.fetchLists();
        }

        if (this.props.successMessage.action === DraftAction.Submit && this.state.selectedTab !== TAB_IDS.SUBMISSIONS)
            this.onSelectTab(TAB_IDS.SUBMISSIONS)
    }

    public componentWillUnmount() {
        this.props.setToDefaultState();
    }

    public render() {
        const { selectedTab } = this.state;

        return (
            <React.Fragment>
                <div className="authoring-tabs-container">
                    <Tabs
                        tabs={[
                            { label: this.getTabLabel("Drafts", this.props.drafts), dotColor: "grey" },
                            { label: this.getTabLabel(getHighestRole(this.props.user) === UserRoleStrings.CONTRIBUTOR ? "Pending Approval" : "Needs Review", this.props.submissions), dotColor: "red" },
                            { label: this.getTabLabel("Scheduled", this.props.scheduled), dotColor: "yellow" },
                            { label: this.getTabLabel("Published", this.props.published), dotColor: "green" },
                            { label: this.getTabLabel("All", this.props.allEventsList) }
                        ]}
                        selectedTab={selectedTab}
                        onSelectTab={this.onSelectTab}
                    />
                    <CreatedByFilter
                        selectedFilter={this.state.showMyEventsOnly ? CreatedBySelectValues.VIEW_MY : CreatedBySelectValues.VIEW_ALL}
                        content={ContentType.Event}
                        onSelect={this.onSelectCreatedBy}
                    />
                </div>
                <EventList show={selectedTab === TAB_IDS.ALL} page={this.props.allEventsList} fetchPage={this.fetchAllEventList} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} showMyEventsOnly={this.state.showMyEventsOnly}/>
                <EventList show={selectedTab === TAB_IDS.PUBLISHED} page={this.props.published} fetchPage={this.fetchPublished} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} showMyEventsOnly={this.state.showMyEventsOnly}/>
                <EventList show={selectedTab === TAB_IDS.SCHEDULED} page={this.props.scheduled} fetchPage={this.fetchScheduled} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} showMyEventsOnly={this.state.showMyEventsOnly}/>
                <EventList show={selectedTab === TAB_IDS.SUBMISSIONS} page={this.props.submissions} fetchPage={this.fetchSubmissions} initialSort={SortStyle.startDesc} availableTopics={this.state.availableTopics} showMyEventsOnly={this.state.showMyEventsOnly}/>
                <EventList show={selectedTab === TAB_IDS.DRAFTS} page={this.props.drafts} fetchPage={this.fetchDrafts} initialSort={SortStyle.modifiedDesc} availableTopics={this.state.availableTopics} showMyEventsOnly={this.state.showMyEventsOnly}/>
                {this.props.showPublishDialog && <PublishedEventDialog/>}
                <SuccessSnackbar successMessage={ this.props.successMessage.message || "" } clearSuccessMessage={this.props.clearSuccessMessage}/>
                <ErrorSnackbar errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                <LoadingOverlay absolute={true} show={this.props.isDeleting || this.props.isFetching || this.props.isSaving} />
            </React.Fragment>
        );
    }

    private onSelectCreatedBy = (selection: CreatedBySelectValues) => this.setState({showMyEventsOnly: selection === CreatedBySelectValues.VIEW_MY});

    private fetchLists = () => {
        this.fetchAllEventList(1, { sortType: SortStyle.startDesc });
        this.fetchDrafts(1, { sortType: SortStyle.modifiedDesc });
        this.fetchSubmissions(1, { sortType: SortStyle.startDesc });
        this.fetchPublished(1, { sortType: SortStyle.startDesc });
        this.fetchScheduled(1, { sortType: SortStyle.startDesc });
        this.props.clearShouldFetch();
    }

    private fetchDrafts = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.drafts.isFetching &&
            this.props.getDrafts(pageNumber, filters, pageAmount);
    }

    private fetchSubmissions = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.submissions.isFetching &&
            this.props.getSubmissions(pageNumber, filters, pageAmount);
    }

    private fetchPublished = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.published.isFetching &&
            this.props.getPublished(pageNumber, filters, pageAmount);
    }

    private fetchScheduled = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.scheduled.isFetching &&
            this.props.getScheduled(pageNumber, filters, pageAmount);
    }

    private fetchAllEventList = (pageNumber: number, filters: Partial<EventFilterValues>, pageAmount?: number) => {
        !this.props.allEventsList.isFetching &&
            this.props.getAllEvents(pageNumber, filters, pageAmount);
    }

    private getTabLabel = (label: string, page: EventListingPage): string => {
        if (!!page.currentPage)
            return `${label} (${page.totalEvents})`;
        return label;
    }

    private onSelectTab = (tabIndex: number) => {
        this.setState({ ...this.state, selectedTab: tabIndex });
    }

    private getTags = async () => {
        let tagsToSet: TenantSettingsTagGroup[] = [];

        try {
            tagsToSet = await tagsApi.getUserTags();
        } catch (err) {
            tagsToSet = [];
        } finally {
            this.setState({ availableTopics: tagsToSet });
        }
    }
}


interface ComponentProps {
}

interface ComponentState {
    selectedTab: number;
    showFilters: boolean;
    availableTopics: TenantSettingsTagGroup[];
    showMyEventsOnly: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        drafts: state.events.drafts,
        submissions: state.events.submissions,
        allEventsList: state.events.allEventsList,
        published: state.events.published,
        scheduled: state.events.scheduled,
        errorMessage: state.events.errorMessage,
        successMessage: state.events.successMessage,
        isDeleting: state.events.isDeleting,
        isFetching: state.events.isFetching,
        isInitialLoad: state.events.isInitialLoad,
        isSaving: state.events.isSaving,
        showPublishDialog: state.events.shouldShowDialog,
        shouldFetch: state.events.shouldFetch,
        tenantSettings: state.settings.tenantSettings,
        tenant: state.tenant.id,
        user: state.settings.currentUser
    }),
    {
        clearErrorMessage: actions.clearErrorMessage,
        clearSuccessMessage: actions.clearSuccessMessage,
        clearShouldFetch: actions.clearShouldFetch,
        getAllEvents: actions.getAllEvents,
        getDrafts: actions.getDrafts,
        getSubmissions: actions.getSubmissions,
        getPublished: actions.getPublished,
        getScheduled: actions.getScheduled,
        redirectTo: push,
        setToDefaultState: actions.setEventsFeedToDefault
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EventListing);
