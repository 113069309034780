import React from "react";

import AuthoringSearch from "modules/common/components/filters/authoringSearch";
import FilterContainer from "modules/common/components/filters/filterContainer";
import FilterDropdown from "modules/common/components/filters/filterDropdown";

import { AccountRole, AccountStatus, AccountType, UsersFilterValues } from "../models";

import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import moment from "moment";
import { TenantSettingsTagGroup } from "modules/settings";
import TopicDropdown from "modules/common/components/topicDropdown";


interface Option {
    id: string;
    text: string;
}


const AccountTypes: Option[] = [
    { id: "work", text: "Work" },
    { id: "google", text: "Google" },
    { id: "linkedIn", text: "LinkedIn" },
    { id: "facebook", text: "Facebook" },
    { id: "apple", text: "Apple" },
    { id: "microsoft", text: "Microsoft Personal" },
    { id: "sparrow", text: "Sparrow" }
];

const ProfileFields: Option[] = [
    { id: "false", text: "Standard only" },
    { id: "true", text: "Includes custom" }
];

const Roles: Option[] = [
    { id: "owner", text: "Owner" },
    { id: "author", text: "Author" },
    { id: "user", text: "User" },
    { id: "contributor", text: "Contributor" }
];

const States: Option[] = [
    { id: "enabled", text: "Activated" },
    { id: "disabled", text: "Deactivated" }
];

class UserFilters extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            canSearch: !!props.filters.accountTypes!.length || !!props.filters.roles!.length || !!props.filters.statuses!.length || !!props.filters.textToSearch || props.filters.hasCustomDetails === false || props.filters.hasCustomDetails === true,
            textToSearch: props.filters.textToSearch || ""
        };
    }

    public componentDidMount() {
        moment.locale("en");
    }

    public componentDidUpdate(prevProps: ComponentProps) {
        if (this.props.filters.textToSearch !== prevProps.filters.textToSearch)
            this.setState({ textToSearch: this.props.filters.textToSearch || "" });
    }

    public render() {
        const { filters } = this.props;
        const tagSelected = (filters.authorTopics?.length ?? [].length) > 0;

        return (
            <FilterContainer
                filters={
                    <React.Fragment>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={this.state.textToSearch}
                            placeholder="Search name or email"
                            InputProps={{
                                startAdornment: <SearchIcon className="search-icon" />
                            }}
                            onChange={this.onUpdateTextToSearch}
                            onKeyUp={this.onKeyUp}
                            className="text-to-search"
                        />
                        <div className="filter-options-group">
                            <FilterDropdown text="State">
                                <List disablePadding>
                                    {States.map((state) =>
                                        <ListItem key={state.id} dense button onClick={() => this.onChangeState(state.id as AccountStatus)}>
                                            <ListItemIcon className="callout-checkbox">
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    size="small"
                                                    color="primary"
                                                    checked={!!filters.statuses!.find((selectedState) => selectedState === state.id)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={state.text} />
                                        </ListItem>
                                    )}
                                </List>
                            </FilterDropdown>
                            <FilterDropdown text="Roles">
                                <List disablePadding>
                                    {Roles.map((role) =>
                                        <ListItem key={role.id} dense button onClick={() => this.onChangeRoles(role.id as AccountRole)}>
                                            <ListItemIcon className="callout-checkbox">
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    size="small"
                                                    color="primary"
                                                    checked={!!filters.roles!.find((selectedRole) => selectedRole === role.id)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={role.text} />
                                        </ListItem>
                                    )}
                                </List>
                            </FilterDropdown>
                            <FilterDropdown text="Account types">
                                <List disablePadding>
                                    {AccountTypes.map((accountType) =>
                                        <ListItem key={accountType.id} dense button onClick={() => this.onChangeAccountType(accountType.id as AccountType)}>
                                            <ListItemIcon className="callout-checkbox">
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    size="small"
                                                    color="primary"
                                                    checked={!!filters.accountTypes!.find((selectedAccountType) => selectedAccountType === accountType.id)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={accountType.text} />
                                        </ListItem>
                                    )}
                                </List>
                            </FilterDropdown>
                            {this.props.filters.roles?.includes("author") && this.props.filters.roles?.length === 1 &&
                                <TopicDropdown
                                    label={"Authoring Topics"}
                                    tagSelected={tagSelected}
                                    availableTopics={this.props.tagGroups}
                                    currentTags={filters.authorTopics ?? []}
                                    handleTagGroupClick={this.handleTagGroupClick}
                                    checkGroupSelected={this.checkGroupSelected}
                                    onChangeTags={this.onChangeTags}
                                    onHandleAllTags={this.onHandleAllTags}
                                />
                            }
                        </div>
                        {this.props.showProfileFields &&
                            <FilterDropdown text="Profile fields">
                                <List disablePadding>
                                    {ProfileFields.map((profileField) =>
                                        <ListItem key={profileField.id} dense button onClick={() => this.onChangeProfileField(profileField.id)}>
                                            <ListItemIcon className="callout-checkbox">
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    size="small"
                                                    color="primary"
                                                    checked={profileField.id === "true" ? !!filters.hasCustomDetails : filters.hasCustomDetails === false}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={profileField.text} />
                                        </ListItem>
                                    )}
                                </List>
                            </FilterDropdown>
                        }
                    </React.Fragment>
                }
                filterCommands={
                    <AuthoringSearch
                        canSearch={this.state.canSearch}
                        onApplyFilters={this.onChangeTextToSearch}
                        onClearFilters={this.onClearFilters}
                    />
                }
                filterSelection={this.getFilterSelection()}
            />
        );
    }


    private getFilterSelection = (): JSX.Element | undefined => {
        const { filters } = this.props;

        if (!filters.textToSearch && !filters.statuses!.length && !filters.roles!.length && !filters.accountTypes!.length && filters.hasCustomDetails !== false && filters.hasCustomDetails !== true && filters.authorTopics!.length === 0)
            return undefined;

        const availableTags: Option[] = this.getAvailableTags();

        return (
            <React.Fragment>
                {!!filters.textToSearch &&
                    <Chip
                        key="search-text"
                        label={`"${filters.textToSearch}"`}
                        onDelete={this.onClearTextToSearch}
                        deleteIcon={<CloseIcon />}
                    />
                }
                {filters.statuses!.map((selectedState) =>
                    <Chip
                        key={selectedState}
                        label={States.find((state) => state.id === selectedState)!.text}
                        onDelete={() => this.onClearState(selectedState)}
                        deleteIcon={<CloseIcon />}
                    />
                )}
                {filters.roles!.map((selectedRole) =>
                    <Chip
                        key={selectedRole}
                        label={Roles.find((role) => role.id === selectedRole)!.text}
                        onDelete={() => this.onClearRole(selectedRole)}
                        deleteIcon={<CloseIcon />}
                    />
                )}
                {filters.accountTypes!.map((selectedAccountType) => {
                    const matchedAccountType: Option | undefined = AccountTypes.find((accountType) => accountType.id === selectedAccountType);
                    if (!matchedAccountType)
                        return <React.Fragment key={selectedAccountType}></React.Fragment>;
                    return (
                        <Chip
                            key={selectedAccountType}
                            label={matchedAccountType.text}
                            onDelete={() => this.onClearAccountType(selectedAccountType)}
                            deleteIcon={<CloseIcon />}
                        />
                    );
                })}
                {filters.hasCustomDetails === false &&
                    <Chip
                        key="standard-only"
                        label="Standard only profile fields"
                        onDelete={() => this.onClearProfileField()}
                        deleteIcon={<CloseIcon />}
                    />
                }
                {filters.hasCustomDetails === true &&
                    <Chip
                        key="includes-custom"
                        label="Includes custom profile fields"
                        onDelete={() => this.onClearProfileField()}
                        deleteIcon={<CloseIcon />}
                    />
                }
                {filters.roles?.length === 1 && filters.roles.includes("author") && filters.authorTopics!.length > 0 &&
                    filters.authorTopics!.map((selectedTag) => {
                        return (
                            <Chip
                                key={selectedTag}
                                label={availableTags.find((tag) => tag.id === selectedTag)!.text}
                                onDelete={() => this.onClearTag(selectedTag)}
                                deleteIcon={<CloseIcon />}
                            />
                        )
                    })}
            </React.Fragment>
        );
    }


    private onChangeAccountType = (accountType: AccountType) => {
        const hasSelectedAccountType: boolean = !!this.props.filters.accountTypes!.find((selectedAccountType) => selectedAccountType === accountType);
        if (hasSelectedAccountType) {
            if (accountType === "work")
                this.onChangeFilters({ accountTypes: this.props.filters.accountTypes!.filter((selectedAccountType) => selectedAccountType !== "work" && selectedAccountType !== "adfs") });
            else
                this.onChangeFilters({ accountTypes: this.props.filters.accountTypes!.filter((selectedAccountType) => selectedAccountType !== accountType) });
        } else {
            if (accountType === "work")
                this.onChangeFilters({ accountTypes: this.props.filters.accountTypes!.concat(["work", "adfs"]) });
            else
                this.onChangeFilters({ accountTypes: this.props.filters.accountTypes!.concat([accountType]) });
        }
    }

    private onChangeFilters = (value: Partial<UsersFilterValues>) => {
        this.props.onChangeFilters({ ...this.props.filters, textToSearch: this.state.textToSearch, ...value });
        this.setCanSearch();
    }

    private onChangeProfileField = (hasCustom: string) => {
        const hasSelectedProfileField: boolean = this.props.filters.hasCustomDetails?.toString() === hasCustom;
        if (hasSelectedProfileField) {
            this.onChangeFilters({ hasCustomDetails: null });
        } else {
            if (hasCustom === "true")
                this.onChangeFilters({ hasCustomDetails: true });
            else
                this.onChangeFilters({ hasCustomDetails: false });
        }
    }

    private onChangeRoles = (role: AccountRole) => {
        const hasSelectedRole: boolean = !!this.props.filters.roles!.find((selectedRole) => selectedRole === role);
        if (hasSelectedRole)
            this.onChangeFilters({ roles: this.props.filters.roles!.filter((selectedRole) => selectedRole !== role) });
        else
            this.onChangeFilters({ roles: this.props.filters.roles!.concat([role]) });
    }

    private onChangeState = (state: AccountStatus) => {
        const hasSelectedState: boolean = !!this.props.filters.statuses!.find((selectedState) => selectedState === state);
        if (hasSelectedState)
            this.onChangeFilters({ statuses: this.props.filters.statuses!.filter((selectedState) => selectedState !== state) });
        else
            this.onChangeFilters({ statuses: this.props.filters.statuses!.concat([state]) });
    }

    private onChangeTextToSearch = () => {
        this.onChangeFilters({ textToSearch: this.state.textToSearch });
    }

    private onClearAccountType = (accountType: AccountType) => {
        if (accountType === "work")
            this.onChangeFilters({ accountTypes: this.props.filters.accountTypes!.filter((selectedAccountType) => selectedAccountType !== "work" && selectedAccountType !== "adfs") });
        else
            this.onChangeFilters({ accountTypes: this.props.filters.accountTypes!.filter((selectedAccountType) => selectedAccountType !== accountType) });
    }

    private onClearFilters = () => {
        this.clearCanSearch();
        this.props.onClearFilters();
        this.setState({ textToSearch: "" });
    }

    private onClearProfileField = () => {
        this.onChangeFilters({ hasCustomDetails: null });
    }

    private onClearRole = (role: AccountRole) => {
        this.onChangeFilters({ roles: this.props.filters.roles!.filter((selectedRole) => selectedRole !== role) });
    }

    private onClearState = (state: AccountStatus) => {
        this.onChangeFilters({ statuses: this.props.filters.statuses!.filter((selectedState) => selectedState !== state) });
    }

    private onClearTextToSearch = () => {
        this.onChangeFilters({ textToSearch: "" });
    }

    private onKeyUp = (key) => {
        if (key.keyCode === 13)
            this.onChangeTextToSearch();
    }

    private onUpdateTextToSearch = (event) => {
        this.setState({ textToSearch: event.target.value });
        if (event.target.value && event.target.value !== "") {
            this.setCanSearch();
        }
        else if (this.hasNoCurrentFilters()) {
            this.clearCanSearch();
        }
    }

    private clearCanSearch = () => {
        this.setState({ canSearch: false });
    }

    private setCanSearch = () => {
        this.setState({ canSearch: true });
    }

    private checkGroupSelected = (tagGroup: TenantSettingsTagGroup) => {
        return tagGroup.tags.every(tag => {
            return this.props.filters.authorTopics!.includes(tag.id);
        })
    }

    private handleTagGroupClick = (tagGroup: TenantSettingsTagGroup) => {
        let isCurrentlyChecked = this.checkGroupSelected(tagGroup);

        if (isCurrentlyChecked) {
            this.deselectTagGroup(tagGroup);
        }
        else {
            this.selectTagGroup(tagGroup);
        }
    }

    private selectTagGroup = (tagGroup: TenantSettingsTagGroup) => {
        let currentTags = this.props.filters.authorTopics ?? [];

        tagGroup.tags.forEach(tag => {
            if (!currentTags.includes(tag.id)) {
                currentTags.push(tag.id);
            }
        })

        let newFilters = this.props.filters;
        newFilters.authorTopics = currentTags;
        this.onChangeFilters(newFilters);
    }

    private deselectTagGroup = (tagGroup: TenantSettingsTagGroup) => {
        let currentTags = this.props.filters.authorTopics ?? [];
        let tagsToRemove = tagGroup.tags.map(t => t.id);

        currentTags = currentTags.filter(currentTag => !tagsToRemove.includes(currentTag));

        let newFilters = this.props.filters;
        newFilters.authorTopics = currentTags;
        this.onChangeFilters(newFilters);
    }

    private onHandleAllTags = (tagSelected: boolean) => {
        if (tagSelected) {
            let newFilters = this.props.filters;
            newFilters.authorTopics = [];
            this.onChangeFilters(newFilters);
        }
        else {
            let currentTags = this.props.filters.authorTopics ?? [];
            let tagGroups = this.props.tagGroups;

            tagGroups.forEach(tagGroup => {
                tagGroup.tags.forEach(tag => {
                    if (!currentTags.includes(tag.id)) {
                        currentTags.push(tag.id);
                    }
                });
            });

            let newFilters = this.props.filters;
            newFilters.authorTopics = currentTags;
            this.onChangeFilters(newFilters);
        }
    }

    private onChangeTags = (currentTag: string) => {
        const hasSelectedTag: boolean = !!this.props.filters.authorTopics!.find((selectedTag) => selectedTag === currentTag);
        if (hasSelectedTag)
            this.onChangeFilters({ authorTopics: this.props.filters.authorTopics!.filter((selectedTag) => selectedTag !== currentTag) });
        else
            this.onChangeFilters({ authorTopics: this.props.filters.authorTopics!.concat([currentTag]) });
    }

    private getAvailableTags = (): Option[] => {
        if (!this.props.tagGroups)
            return [];

        let availableTags: Option[] = [];
        this.props.tagGroups.map((tagGroup) => {
            if (!!tagGroup.tags.length && !tagGroup.deleted && !tagGroup.disabled) {
                availableTags.push({ id: tagGroup.id, text: tagGroup.name });
                tagGroup.tags.map((tag) => {
                    if (!tag.deleted && !tag.disabled)
                        availableTags.push({ id: tag.id, text: tag.name });
                    return tag;
                });
            }
            return tagGroup;
        });
        return availableTags;
    }

    private onClearTag = (tag: string) => {
        this.onChangeFilters({ authorTopics: this.props.filters.authorTopics!.filter((selectedTag) => selectedTag !== tag) });
    }

    private hasNoCurrentFilters = () => {
        let filters = this.props.filters;

        return (!filters.accountTypes || filters.accountTypes.length === 0)
            && (!filters.authorTopics || filters.authorTopics?.length === 0)
            && (filters.hasCustomDetails === undefined || filters.hasCustomDetails === null) //Boolean being used as 3 state variable here, ! is undesirable.
            && (!filters.loggedInAfter || filters.loggedInAfter === "")
            && (!filters.roles || filters.roles.length === 0)
            && (!filters.statuses || filters.statuses.length === 0)
    }
}


interface ComponentProps {
    filters: Partial<UsersFilterValues>;
    showProfileFields: boolean;
    tagGroups: TenantSettingsTagGroup[];
    onChangeFilters: (filters: Partial<UsersFilterValues>) => void;
    onClearFilters: () => void;
}

interface ComponentState {
    canSearch: boolean;
    textToSearch: string;
}

export default UserFilters;
