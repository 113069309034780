import { ApiRequest } from './network';
import { AccountCategory, UserFilterValues, SortField, ExternalUserFilterValues, UserCreation, UserEdit, InvitedUser, User, UsersFilterValues, UserSort, PagedInviteFilterValues, UserActivityFilter, PermissionDetails} from 'modules/users';
import { SparrowApi } from 'api/network/adapters/SparrowApi';

import Cookies, { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";

//Todo: Convert function names to camel case!

export class UsersApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public getUsers = (pageNumber: number, filters: Partial<UsersFilterValues>, sort: UserSort = {order: "increasing", category: "name"}, pageAmount?: number) => {
        
        const updatedFilters = {
            ...filters
        };
        let rowsPerPage;
        if (!pageAmount) {
            rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);
            rowsPerPage = !!rowsPerPage ? +rowsPerPage : 10;
        }
        const order = sort.order === "increasing" ? "alphabetical" : "reverseAlphabetical";
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/users?pageAmount=${pageAmount || rowsPerPage}&pageNumber=${pageNumber}&sortCategory=${sort.category}&sort=${order}`, updatedFilters)
        );
    }

    public getFilteredUsers = (currentSort: string, sortAscending: boolean, currentFilterState: UserFilterValues, continuationToken: string | null) => {
        const filter = {
            ...currentFilterState,
            continuationToken,
            currentSort,
            sortAscending
        }

        return new ApiRequest(
                    this._sparrowApi.TenantRequest()
                        .post('/{tenant}/adminapi/v1/users/filtered', filter)
        );
    }

    public GetUsersCSV = (currentFilterState: UserFilterValues, currentSort: SortField, sortAscending: boolean): ApiRequest => {
        const body = {
            ...currentFilterState,
            currentSort,
            sortAscending
        }

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/users/csv', body)
        );
    }

    public GetInvitedUsersCSV = (filter: ExternalUserFilterValues): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/invitedUsers/csv', filter)
        );
    }

    public DeleteInvitedUser = (user: User): ApiRequest => {
        var invitedUser = {
            email: user.email,
            enabled: user.enabled,
            user: user
        } as InvitedUser
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete('/{tenant}/adminapi/v1/invitedUsers', {data: invitedUser})
        );
    }

    public DeleteUser = (id: string, accountCategory: AccountCategory): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete(`/{tenant}/adminapi/v2/users/${id}?accountCategory=${accountCategory}`)
        );
    }

    public GetFilteredInvitedUsers = (filter: ExternalUserFilterValues) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .post('/{tenant}/adminapi/v1/invitedUsers/filtered', filter)
        );
    }

    public GetPagedInvitedUsers = (filter: PagedInviteFilterValues) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .post('/{tenant}/adminapi/v1/invitedUsers/paged', filter)
        );
    }

    public GetExternalUsersLobby = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/invitedUsers/lobby')
        );
    }

    public DeleteExternalUserRequest = (objectId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/invitedUsers/lobby', {objectId})
        );
    }
    public GrantExternalUserAccess = (objectId: string, issuer: string, email: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('/{tenant}/adminapi/v1/invitedUsers/grantAccess', {email, objectId, issuer})
        );
    }

    public GetUserOverviewStats = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/users/stats')
        );
    }

    public GetUserProviderBreakdown = (): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/users/providerbreakdown')
        );
    }

    public GetUserInsightStats = (userPreferenceId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/users/userProfileStats/${userPreferenceId}`)
        );
    }

    public GetSelectedTopicsForUser = (userPreferenceId: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/users/topics/${userPreferenceId}`)
        );
    }

    public CreateSparrowLocalAccount = (newUser: UserCreation): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/users/local`, newUser)
        );
    }

    public CheckIfLocalUserIdAvailable = (username: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/users/local/${username}`)
        );
    }

    public CheckIfEmailAvailable = (email: string): ApiRequest => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/users/verifyEmail?email=${email}`)
        );
    }

    public BatchCreateSparrowLocalAccounts = (csv: File) => {
        const body = new FormData();
        body.append("document", csv);
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/local/batch/`, body)
        );
    }

    public BatchInviteSparrowLocalAccounts = (csv: File) => {
        const body = new FormData();
        body.append("document", csv);
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/local/batchInvite/`, body)
        );
    }

    public DownloadSampleBatchCSV = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/local/csv')
        );
    }

    public ResetLocalUserPassword = (id: string, password: string) => {
        const headers = { "content-type": "application/json" };
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/users/${id}/resetpassword`, JSON.stringify(password), { headers })
        );
    }

    public GetBatchUploadIds = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get('/{tenant}/adminapi/v1/users/notification/batch/batchjobs')
        );
    }

    public GetBatchCSVForBatchId = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapiv1/users/notification/batch/${id}`)
        );
    }

    public SearchUsersByTerm = (term: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/users/search/${term}`)
        );
    }

    public GetUserById = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v2/users/${id}`)
        );
    }

    public GetEditableUserById = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/users/${id}`)
        );
    }

    public GetUsersById = (ids: string[]) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/users/getusers`, ids)
        );
    }

    public ResendAllInvites = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/invitedUsers/resendPendingInvite`)
        );
    }

    public UpdateUser = (id: string, user: Partial<UserEdit>) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/users/${id}`, user)
        );
    }

    public UpdateUserPermissions = (id: string, toUpdate: PermissionDetails) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/users/${id}/permissions`, toUpdate)
        );
    }

    public SendSMSVerify = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v2/users/${id}/smsVerify`)
        );
    }

    public GetIncludableAudiences = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v2/users/${id}/includableaudiences`)
        );
    }

    public DeleteAllPendingUserInvite = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete(`/{tenant}/adminapi/v1/invitedUsers/deleteAllPending`)
        );
    }
    
    public FetchEmailSuggestions = (filter: string, maxResults: number) => {
        var toSend = {
            "filter": filter,
            "maxResults": maxResults ?? 8
        };
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/invitedUsers/emailsForSuggestions`, toSend)
        );
    }

    public ResendInvitedUser = (user: InvitedUser) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v1/invitedUsers/resendInvite`, user)
        );
    }

    public GetPendingInviteCount = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/invitedUsers/pendingcount`)
        );
    }

    public DeletePendingUserInvite = (emails: String[]) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().delete(`/{tenant}/adminapi/v1/invitedUsers/deletePending`, {data: emails})
        );
    }

    public GetIntermediateResultsBulk = (csv: File) => {
        const body = new FormData();
        body.append("document", csv);
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/users/generateIntermediateBulkResults`, body)
        );
    }

    public GetIntermediateResultsCSV = (csv: File) => {
        const body = new FormData();
        body.append("document", csv);
        return new ApiRequest(
            this._sparrowApi.TenantRequest().post(`/{tenant}/adminapi/v2/users/generateIntermediateReportCSV`, body)
        );
    }
    
    public getuserActivityTypes = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/userActivity/types')
        );
    }

    public getuserActivity = (pageNumber: number, filters: UserActivityFilter) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v1/userActivity?pageAmount=10&pageNumber=${pageNumber}`, filters)
        );
    }

    public downloadUserActivity = (filters: UserActivityFilter) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v1/userActivity/download`, filters)
        );
    }

    public downloadUserData = (userIds: string[]) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/users/download`, { userids: userIds })
        );
    }

    public downloadSocialUserReport = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/userActivity/downloadInviteUserBatch/${id}`)
        );
    }
}