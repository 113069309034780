import { Action, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingsApi } from "api/instances";
import { DEFAULT_CONTENT_GUIDELINES, IContentGuidelines, IContentGuidelinesState } from "modules/settings/models";

const DEFAULT_GUIDELINE_STATE: IContentGuidelinesState = {
    guidelines: DEFAULT_CONTENT_GUIDELINES,
    isEmpty: true,
    isPristine: true,
};

interface ISetEmptyAction extends Action {
    payload: boolean;
}

interface ISetGuideline {
    guidelines: IContentGuidelines;
    isPristine: boolean;
};

interface ISetGuidelinesAction extends Action {
    payload: ISetGuideline;
}

const isGuidelineEmpty = (guideline?: IContentGuidelines): boolean => {
    const currAttachments = guideline?.attachments ?? DEFAULT_CONTENT_GUIDELINES.attachments;
    const currDescription = guideline?.description ?? DEFAULT_CONTENT_GUIDELINES.description;
    const currHeader = guideline?.header ?? DEFAULT_CONTENT_GUIDELINES.header;

    return currAttachments.length === DEFAULT_CONTENT_GUIDELINES.attachments.length
        && currDescription === DEFAULT_CONTENT_GUIDELINES.description
        && currHeader === DEFAULT_CONTENT_GUIDELINES.header;
};

export const fetchContentGuidelines = createAsyncThunk("FETCH_CONTENT_GUIDELINES", async (_, thunkApi) => {
    const res = await settingsApi.GetContentSettings();

    thunkApi.dispatch({ type: guidelinesSlice.actions.SET_EMPTY, payload: !res || isGuidelineEmpty(res) })
    return !res ? DEFAULT_CONTENT_GUIDELINES : res;
});

export const guidelinesSlice = createSlice({
    name: "contentGuidelines",
    initialState: DEFAULT_GUIDELINE_STATE,
    reducers: {
        SET_EMPTY: (state: IContentGuidelinesState, action: ISetEmptyAction) => {
            state.isEmpty = action.payload;
        },
        SET_GUIDELINES: (state: IContentGuidelinesState, action: ISetGuidelinesAction) => {
            state.guidelines = action.payload.guidelines;
            state.isPristine = action.payload.isPristine;
            state.isEmpty = isGuidelineEmpty(action.payload.guidelines)
        },
    },
    extraReducers: (builder) => builder
        .addCase(fetchContentGuidelines.fulfilled, (state, action) => {
            state.guidelines = action.payload
            state.isPristine = true;
        })
        .addCase(fetchContentGuidelines.pending, (state, action) => {
            state.guidelines = undefined;
        })
        .addCase(fetchContentGuidelines.rejected, (state) => {
            state.guidelines = DEFAULT_CONTENT_GUIDELINES;
            state.isEmpty = true;
            state.isPristine = true;
        })
});