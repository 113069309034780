import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { ConnectedProps, connect, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router";

import AuthoringAppBar from "modules/common/components/authoring/authoringAppBar";
import BasePage from "pages/common/basePage";
import { BackItem } from "pages/common/breadcrumb";
import { ContentBandsEditorActions } from "./contentBandsEditorActions";
import { useContentBands } from "modules/common/hooks/data/useContentBands";
import EditorWrapperLayout from "modules/common/components/authoring/editorLayouts/editorWrapperLayout";
import MainEditorLayout from "modules/common/components/authoring/editorLayouts/mainEditorLayout";
import { GlobalApplicationState } from "globalApplicationState";
import { setMainStyle, setShouldDisplayNav } from "modules/adminLayout/actionCreator";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import { ContentBandsSettingsPanel } from "./contentBandsSettingsPanel";
import { ContentBandsMainEditor } from "./contentBandsMainEditor";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import { DEFAULT_CONFIG, contentBandsSlice, publishContentBandConfigAsync, saveContentBandConfigAsync } from "../../reducer";
import { SortStyle } from "utils/managementUtils";
import { useAuthoringTags } from "modules/common/hooks/data/useAuthoringTags";
import { useMobileConfig } from "modules/common/hooks/data/useMobileConfig";
import { ContentBandsOneLastCheck } from "../dialogs/contentBandsOneLastCheck";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import useLanguages from "modules/common/hooks/data/useLanguages";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import { MobileWarningOverlay } from "modules/common/components/overlays/mobileWarningOverlay";
import { ContentBandContentType, ContentBandLayoutType, ReadStatus } from "../../models";
import { IColorOption } from "modules/homeScreens/models";
import { useHomeScreenConfigs } from "modules/common/hooks/data/useHomeScreenConfigs";
import { ContentBandsPreview } from "../dialogs/contentBandsPreview";

import "../../../common/components/authoring/styles/authoringLayout.sass";
import "../../styles/contentBandsEditorPage.sass";

interface IContentBandsEditorPageProps {}

export interface IContentBandSettingActionProps {
    onChangeLayout: (newValue: ContentBandLayoutType, idx: number) => void;
    onChangeContentType: (newValue: ContentBandContentType, idx: number) => void;
    onChangeDisplayFullTitles: (newValue: boolean, idx: number) => void;
    onChangeEnableSeeMoreLink: (newValue: boolean, idx: number) => void;
    onChangeContentCount: (newValue: number, idx: number) => void;
    onChangeSortBy: (newValue: SortStyle, idx: number) => void;
    onChangePinnedIds: (newValue: string[], idx: number) => void;
    onChangeLockToReadStatus: (newValue: ReadStatus, idx: number) => void;
    onChangeLockToTypes: (newValue: string[], idx: number) => void;
    onChangeLockToTopics: (newValue: string[], idx: number) => void;
    onChangeIgnoreSubscriptions: (newValue: boolean, idx: number) => void;
    onChangeAutoPlayCarousel: (newValue: boolean, idx: number) => void;
    onChangeHeaderText: (newValue: string, lcid: string, idx: number) => void;
}

const ContentBandsEditorPage: React.FunctionComponent<PropsWithRedux> = ({
    setShouldDisplayNav,
    setMainStyle,
    tenantId,
    defaultLcid,
    shouldDisplayNav,
}) => {
    const [confirmUnsavedChangesOpen, setConfirmUnsavedChangesOpen] = useState<boolean>(false);
    const [confirmDiscardChanges, setConfirmDiscardChanges] = useState<boolean>(false);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState<boolean>(() => !isSmallAndSmaller);
    const [isOneLastCheckOpen, setIsOneLastCheckOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { tags } = useAuthoringTags();
    const { isLoading: isLoadingMobileConfig, mobileConfig } = useMobileConfig();
    const { homeScreenConfigs } = useHomeScreenConfigs();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const [mobileOverlayOpen, setMobileOverlayOpen] = useState<boolean>(isSmallAndSmaller);
    const mobileWarningClosedOnce = useRef<boolean>(false);

    const includeContent = true;
    const {
        contentBands,
        publishCount,
        successMessage,
        isLoading: isContentBandsLoading,
        latestSavedContentBands,
        saveContentBands,
        fetchContentForBand,
        isValid,
        isContentBandsDirty,
        isConfigDirty,
        publishContentBands,
        config,
        pristineConfig,
        ...actions
    } = useContentBands(includeContent);

    const languageInfo = useLanguages(contentBands.length > 0 ? contentBands[0].headers : undefined);
    const { activeLcid } = languageInfo;

    const isDirty = isContentBandsDirty || isConfigDirty;

    const isLoading = isLoadingMobileConfig || isContentBandsLoading;

    const usableConfig = config || DEFAULT_CONFIG;
    const usablePristineConfig = pristineConfig || DEFAULT_CONFIG;

    useLayoutEffect(() => {
        if (shouldDisplayNav) {
            setShouldDisplayNav(false);
            setMainStyle({ backgroundColor: "#FFFFFF" });
        }
    }, [shouldDisplayNav, setMainStyle, setShouldDisplayNav]);

    // if mobile warning has been displayed and closed already, don't bother showing again
    useEffect(() => {
        if (!mobileWarningClosedOnce.current) {
            setMobileOverlayOpen(isSmallAndSmaller);
        }
    }, [isSmallAndSmaller]);

    // confirm unsaved changes
    const onBackToMobileAppSettings = () => {
        if (isDirty) setConfirmUnsavedChangesOpen(true);
        else goBackToMobileAppSettings();
    };

    // redirect back to settings page
    const goBackToMobileAppSettings = () => {
        onDiscard();

        history.push(`/${tenantId}/admin/homeScreens`);
    };

    const onPreview = () => {
        setPreviewOpen(true);
    };

    const onClickDiscard = () => {
        setConfirmDiscardChanges(true);
    };

    const onDiscard = () => {
        setConfirmDiscardChanges(false);

        if (isContentBandsDirty) {
            let { activeLcid } = languageInfo;

            // active lcid will be discarded, reset selected language to default lcid
            if (
                latestSavedContentBands.length > 0 &&
                latestSavedContentBands[0].headers &&
                !Object.keys(latestSavedContentBands[0].headers).includes(activeLcid)
            )
                languageInfo.onSelectLanguage(defaultLcid);

            dispatch({ type: contentBandsSlice.actions.SET_CONTENT_BANDS, payload: { contentBands: latestSavedContentBands } });
        }

        if (config && isConfigDirty) {
            dispatch({ type: contentBandsSlice.actions.SET_CONFIG, payload: pristineConfig });
        }
    };

    const onSave = () => {
        try {
            if (isConfigDirty && config) dispatch(saveContentBandConfigAsync(config));
            if (isContentBandsDirty) saveContentBands("Successfully saved as draft.");
        } catch (err) {
            setErrorMessage("Something went wrong. Please try again later.");
        }
    };

    // display one last check modal
    const onPublish = () => {
        setIsOneLastCheckOpen(true);
    };

    // handle confirm one last check
    const onConfirmOneLastCheck = () => {
        setIsOneLastCheckOpen(false);

        postPublish("Successfully published new content bands.");
    };

    // handle actual publish
    // publish content bands and content band config
    const postPublish = (successMessage: string) => {
        if (config) dispatch(publishContentBandConfigAsync(config));

        publishContentBands(successMessage);
        history.push(`/${tenantId}/admin/homeScreens?isPublish=true`);
    };

    const getMainEditorClassName = (): string => {
        let result: string = "";
        // on sm screens we will just overlap the two panels
        if (isSmallAndSmaller) {
            result = "main-editor-paper";
        } else {
            result = settingsDrawerOpen ? "main-editor-paper main-editor-paper-open" : "main-editor-paper main-editor-paper-closed";
        }

        return result;
    };

    const onToggleDrawer = () => {
        setSettingsDrawerOpen((prev: boolean) => !prev);
    };

    const onCloseConfirmUnsavedChanges = () => {
        setConfirmUnsavedChangesOpen(false);
    };

    const closeMobileOverlay = () => {
        mobileWarningClosedOnce.current = true;
        setMobileOverlayOpen(false);
    };

    const onSetHeaderTextColor = (newColor: IColorOption) => {
        dispatch({ type: contentBandsSlice.actions.SET_HEADER_TEXT_COLOR, payload: newColor });
    };

    const onSetGradientColor = (newColor: IColorOption) => {
        dispatch({ type: contentBandsSlice.actions.SET_GRADIENT_COLOR, payload: newColor });
    };

    return (
        <BasePage fullWidth>
            <AuthoringAppBar
                backAction={
                    isSmallAndSmaller ? ( // back button gets put in menu button on small and smaller screen
                        <></>
                    ) : (
                        <BackItem textStyle={{ fontWeight: 500 }} title="Back to Manage Home Screen" onClick={onBackToMobileAppSettings} />
                    )
                }
                actions={
                    <ContentBandsEditorActions
                        onToggleSettingsPanel={onToggleDrawer}
                        contentBands={contentBands}
                        isDirty={isDirty}
                        onSave={onSave}
                        onBack={onBackToMobileAppSettings}
                        onDiscard={onClickDiscard}
                        onPublish={onPublish}
                        disable={!isValid}
                        onPreview={onPreview}
                    />
                }
            />
            <div id="content-bands-editor-content" className="authoring-page">
                <LoadingOverlay show={isLoading} styles={{ zIndex: 1201 }} />
                <EditorWrapperLayout>
                    <MainEditorLayout
                        paperProps={{
                            className: getMainEditorClassName(),
                            elevation: 0,
                        }}
                    >
                        <ContentBandsMainEditor
                            userTagGroups={tags}
                            contentBands={contentBands}
                            fetchContentForBand={fetchContentForBand}
                            languageInfo={languageInfo}
                            {...actions}
                        />
                    </MainEditorLayout>
                </EditorWrapperLayout>
                <ContentBandsSettingsPanel
                    open={settingsDrawerOpen}
                    toggleOpen={onToggleDrawer}
                    headerTextColor={usableConfig?.contentBandsHeaderTextColor}
                    gradientColor={usableConfig.contentBandsGradientColor}
                    pristineHeaderTextColor={usablePristineConfig.contentBandsHeaderTextColor}
                    pristineGradientColor={usablePristineConfig.contentBandsGradientColor}
                    onSetHeaderTextColor={onSetHeaderTextColor}
                    onSetGradientColor={onSetGradientColor}
                />
                <ConfirmDialog
                    open={confirmUnsavedChangesOpen}
                    title="Back to Manage Home Screen"
                    confirmLabel="YES"
                    denyLabel="NO"
                    onClose={onCloseConfirmUnsavedChanges}
                    onDeny={onCloseConfirmUnsavedChanges}
                    onConfirm={goBackToMobileAppSettings}
                >
                    <div style={{ minWidth: 400 }}>
                        <div>Your changes will not be saved.</div>
                        <br />
                        <div>Do you want to continue?</div>
                    </div>
                </ConfirmDialog>
                <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")} />
                <ContentBandsOneLastCheck
                    onPreview={onPreview}
                    userTagGroups={tags}
                    activeLcid={activeLcid}
                    contentBands={contentBands}
                    open={isOneLastCheckOpen}
                    onClose={() => setIsOneLastCheckOpen(false)}
                    onPublish={onConfirmOneLastCheck}
                />
                <ConfirmDialog
                    open={confirmDiscardChanges}
                    title="Discard Changes"
                    confirmLabel="SAVE CHANGES"
                    denyLabel="DISCARD"
                    onClose={() => setConfirmDiscardChanges(false)}
                    onDeny={onDiscard}
                    onConfirm={() => {
                        setConfirmDiscardChanges(false);
                        onSave();
                    }}
                >
                    <div style={{ minWidth: 400 }}>
                        <div>You are about to leave the editor with unsaved changes.</div>
                        <br />
                        <div>Do you want to save the changes before you leave?</div>
                    </div>
                </ConfirmDialog>
                <SuccessSnackbar
                    successMessage={successMessage}
                    clearSuccessMessage={() => dispatch({ type: contentBandsSlice.actions.SET_SUCCESS_MESSAGE, payload: "" })}
                />
            </div>
            <MobileWarningOverlay
                open={mobileOverlayOpen}
                onClose={closeMobileOverlay}
                onBack={goBackToMobileAppSettings}
                onActionClick={closeMobileOverlay}
                actionMessage="CONTINUE TO THE EDITOR"
                backMessage="Back to Manage Home Screens"
                message="For the best experience, we recommend creating and editing content bands on a desktop computer or laptop."
            />
            <ContentBandsPreview
                isLoading={isLoading}
                open={previewOpen}
                onClose={() => setPreviewOpen(false)}
                mobileConfig={mobileConfig}
                homeScreenConfigs={homeScreenConfigs || []}
                activeLcid={activeLcid}
                config={usableConfig}
                contentBands={contentBands}
            />
        </BasePage>
    );
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IContentBandsEditorPageProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
        tenantTagGroups: state.settings.tenantSettings.tagGroups,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
        shouldDisplayNav: state.adminLayout.shouldDisplayNav,
    }),
    {
        setMainStyle,
        setShouldDisplayNav,
    }
);

type PropsWithRedux = ConnectedProps<typeof connector>;

export const ConnectedContentBandsEditorPage = connector(ContentBandsEditorPage);
