import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import Cookies from 'utils/cookie';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import MsalAuthModule from "authentication/msalAuthModule";
import { InteractionStatus } from "@azure/msal-browser";
import ExternalLayout from "routes/_layout/externalLayout";

const Logout: React.FunctionComponent<PropsWithRedux> = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    /**
     * React to changes in authentication and msal progress statuses
     */
    React.useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            MsalAuthModule.getInstance().logout();
        } else if (!isAuthenticated) {
            document.title = "Sparrow Connected";
            Cookies.remove("tenantId");
            Cookies.remove("multitenancy");
        }

    }, [isAuthenticated, inProgress]);

    return (
        <ExternalLayout>
            <div className="logout">
                <header className="page-header">
                    {isAuthenticated ? (
                        <FormattedMessage id="logout.signingOut" defaultMessage={"Signing you out"} tagName="h2" />
                        ):(
                        <FormattedMessage id="logout.signedOut" defaultMessage={"You are signed out"} tagName="h2" />
                        )
                    }
                </header>
                {!isAuthenticated && (
                    <div>
                        <div className="input-section">
                            <div className="btn-wrapper">
                                <Link className="continue-btn" to="/" >Sign in again</Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ExternalLayout>
    );
}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Logout);
