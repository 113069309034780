import { Action } from "redux";
import * as Actions from "./actions";

import { UsersState, UserListItem, ExternalUser, UsersTab, ValidationResponse } from "./models";

const defaultState = {
    fetching: false,
    saving: false,
    activeTab: UsersTab.Users,
    users: [],
    userList: {
        currentPage: 0,
        totalPages: 0,
        totalUsers: 0,
        users: new Array<UserListItem>()
    },
    editingUser: null,
    creatingUser: {
        firstName: "",
        lastName: "",
        userName: "",
        preferredName: "",
        initialPassword: "",
        email: null,
        audiences:[],
        employeeId: null,
        title: null,
        department: null,
        reportsTo: null,
        region: null,
        country: null,
        preferredSMSPhone: null,
        roles: [],
        canManageSubmissions: false,
        subscribedToFlaggingNotifications: false,
    },
    fetchedUser: {
        id: "",
        azureB2CObjectId: "",
        audiences: [],
        country: "",
        department: "",
        firstName: "",
        lastName: "",
        email: "",
        employeeId: "",
        providerId: "",
        providerName: "",
        firstLogin: null,
        tagIds: [],
        region: "",
        reportsTo: "",
        roles: [],
        avatarColor: "",
        enabled: false,
        legacyDisabled: false,
        title: "",
        totalComments: 0,
        totalReactions: 0,
        subscribedToFlaggingNotifications: false,
        preferredName: "",
        preferredEmail: "",
        preferredSMSConsent: null,
        preferredSMSPhone: "",
        preferredLCID: "en-us",
        canManageSubmissions: false,
    },
    invitingUser: false,
    invitedTime: new Date(),
    deletingUser: false,
    externalUserList: new Array<ExternalUser>(),
    invitingUserEmail: null,
    invitingUserValidation: null,
    invitedUsers: 0,

    processingToggleEnableEmail: null,
    processingReinviteEmail: null,
    uploadingBatchInvite: false,
    batchUploadValidation: null,

    reinvitingPendingUsers: false,
    deletingPendingUsers: false,

    legacyFixComplete: false,

    editingLocalAccountPassword: "",
} as UsersState;

const actionHandler: { [actionType: string]: (state: UsersState, action: Action) => UsersState } = {

    [Actions.UPDATE_GLOBAL_USERS]: (state: UsersState, action: Actions.UpdateGlobalUsers) => {
        return {
            ...state,
            userList: action.userList
        }
    },

    [Actions.UPDATE_GLOBAL_EXTERNAL_USERS]: (state: UsersState, action: Actions.UpdateGlobalExternalUsers) => {
        return {
            ...state,
            externalUserList: action.users
        }
    },

    [Actions.FETCH_USERS_INIT]: (state: UsersState, action: Actions.FetchUsersInit) => {
        return {
                ...state,
                fetching: true,
                saving: false
            };
    },
    [Actions.FETCH_USERS_V1_COMPLETE]: (state: UsersState, action: Actions.FetchUsersV1Complete) => {
        if (action.succeeded) {
            return {
                ...state,
                fetching: false,
                saving: false,
                users: action.users
            };
        } else {
            return state;
        }
    },
    [Actions.FETCH_USERS_COMPLETE]: (state: UsersState, action: Actions.FetchUsersComplete) => {
        if (action.succeeded) {
            return {
                ...state,
                fetching: false,
                saving: false,
                userList: action.userList
            }
        } else {
            return state;
        }
    },
    [Actions.SET_ACTIVE_USERS_TAB]: (state: UsersState, action: Actions.SetActiveUsersTab) => {
        return {
            ...state,
            activeTab: action.activeTab
        }
    },
    [Actions.SELECT_USER]: (state: UsersState, action: Actions.SelectUser) => {
        return {
            ...state,
            // editingUser: action.user ? { ...action.user } : null,
            fetchedUser: action.user,
            editingLocalAccountPassword: ""
        };
    },
    [Actions.SAVE_USER_INIT]: (state: UsersState, action: Actions.SaveUserInit) => {
        return {
            ...state,
            saving: true
        }
    },
    [Actions.SAVE_USER_COMPLETE]: (state: UsersState, action: Actions.SaveUserComplete) => {
        return {
            ...state,
            saving: false,
            editingUser: null,
            // update the user in our list with the saved version
            userList: {
                ...state.userList,
                users: state.userList.users.map(user => user.id === action.user!.id ? action.user : user)
            }
        } as UsersState;
    },
    [Actions.SET_INVITING_USER_EMAIL]: (state: UsersState, action: Actions.SetInvitingUserEmail) => {
        return {
            ...state,
            invitingUserEmail: action.email.trim(),
            invitingUserValidation: validateInvitingUserEmail(action.email.trim())
        }
    },
    [Actions.INVITE_USER_INIT]: (state: UsersState, action: Actions.InviteUserInit) => {
        return {
            ...state,
            saving: true,
            invitingUser: true
        }
    },
    [Actions.INVITE_USER_COMPLETE]: (state: UsersState, action: Actions.InviteUserComplete) => {
        if (action.succeeded) {
            return {
                ...state,
                saving: false,
                invitingUser: false,
                invitingUserEmail: null,
                invitingUserValidation: null
            }
        } else {
            return {
                ...state,
                saving: false,
                invitingUser: false,
                invitingUserValidation: action.validationResponse
            }
        }
    },
    [Actions.INVITE_BATCH_USERS_INIT]: (state: UsersState, action: Actions.InviteBatchUsersInit) => {
        return {
            ...state,
            saving: true,
            invitingUser: true
        }
    },
    [Actions.INVITE_BATCH_USERS_COMPLETE]: (state: UsersState, action: Actions.InviteBatchUsersComplete) => {
        if(action.succeeded) {
            return {
                ...state,
                invitingUser: false,
                invitedUsers: action.result ?? 0,
                invitedTime: new Date()
            }
        }
        else {
            return {
                ...state,
                invitingUser: false,
                invitedUsers: -1
            }
        }
    },
    [Actions.RESEND_INVITE_USER_INIT]: (state: UsersState, action: Actions.ResendInviteUserInit) => {
        return {
            ...state,
            saving: true,
            processingReinviteEmail: action.user.email
        }
    },
    [Actions.RESEND_INVITE_USER_COMPLETE]: (state: UsersState, action: Actions.ResendInviteUserComplete) => {
        return {
            ...state,
            saving: false,
            processingReinviteEmail: null,
            externalUserList: state.externalUserList.map(user => user.email === action.user!.email ? action.user : user)
        } as UsersState;
    },
    [Actions.SET_EXTERNAL_USER_ENABLED_INIT]: (state: UsersState, action: Actions.SetExternalUserEnabledInit) => {
        return {
            ...state,
            saving: true,
            processingToggleEnableEmail: action.email
        }
    },
    [Actions.SET_EXTERNAL_USER_ENABLED_COMPLETE]: (state: UsersState, action: Actions.SetExternalUserEnabledComplete) => {
        return {
            ...state,
            saving: false,
            externalUserList: state.externalUserList.map(user => user.email === action.user!.email ? action.user : user),
            processingToggleEnableEmail: null
        } as UsersState;
    },
    [Actions.UPLOAD_BATCH_INVITE_FILE_INIT]: (state: UsersState, action: Actions.UploadBatchInviteFileInit) => {
        return {
            ...state,
            saving: true,
            uploadingBatchInvite: true,
            batchUploadValidation: null
        }
    },
    [Actions.UPLOAD_BATCH_INVITE_FILE_COMPLETE]: (state: UsersState, action: Actions.UploadBatchInviteFileComplete) => {
        if (action.succeeded) {
            return {
                ...state,
                saving: false,
                uploadingBatchInvite: false
            };
        } else {
            return {
                ...state,
                saving: false,
                uploadingBatchInvite: false,
                batchUploadValidation: action.validationResponse
            }
        }
    },
    [Actions.CREATE_RESEND_PENDING_INVITE_INIT] : (state: UsersState, action: Actions.UploadBatchInviteFileInit) => {
        return {
            ...state,
            saving: true,
            reinvitingPendingUsers: true
        }
    },
    [Actions.CREATE_RESEND_PENDING_INVITE_COMPLETE] : (state: UsersState, action: Actions.UploadBatchInviteFileComplete) => {
        if (action.succeeded) {
            return {
                ...state,
                saving: false,
                reinvitingPendingUsers: false

            };
        } else {
            return {
                ...state,
                saving: false,
                reinvitingPendingUsers: false
            }
        }
    },
    [Actions.DELETE_PENDING_USER_LIST_INIT]: (state: UsersState, action: Actions.DeletePendingUserListInit) => {
        return {
            ...state,
            saving: true,
            deletingPendingUsers: true
        };
    },
    [Actions.DELETE_PENDING_USER_LIST_COMPLETE]: (state: UsersState, action: Actions.DeletePendingUserListComplete) => {
        const externalUserList = !action.succeeded
            ? state.externalUserList
            : state.externalUserList.filter(user => {
                return action.pendingUserEmails.indexOf(user.email) === -1
            });
        return {
            ...state,
            saving: false,
            deletingPendingUsers: false,
            externalUserList
        };
    },
    [Actions.DELETE_EXTERNAL_USER_INIT]: (state: UsersState, action: Actions.DeleteExternalUserInit) => {
        return {
            ...state,
            saving: true,
            deletingUser: true
        };
    },
    [Actions.DELETE_EXTERNAL_USER_COMPLETE]: (state: UsersState, action: Actions.DeleteExternalUserComplete) => {
        const externalUserList = !action.succeeded
            ? state.externalUserList
            : state.externalUserList.filter(user => {
                return user.email !== action.user!.email;
            });
        const userList = !action.succeeded
            ? state.userList
            : {
                ...state.userList,
                users: state.userList.users.filter(user => {
                    return user.email !== action.user!.email;
                })
            };
        return {
            ...state,
            saving: false,
            deletingUser: false,
            externalUserList,
            userList
        }
    },
    [Actions.LEGACY_FIX_INIT]: (state: UsersState, action: Actions.DeleteExternalUserComplete) => {
        return {
            ...state,
            saving: true,
            legacyFixComplete: false
        }
    },
    [Actions.LEGACY_FIX_COMPLETE]: (state: UsersState, action: Actions.DeleteExternalUserComplete) => {
        return {
            ...state,
            saving: false,
            legacyFixComplete: true
        }
    },
    /* User creation */
    [Actions.HANDLE_USER_CREATION_FIRSTNAME]: (state: UsersState, action: Actions.HandleUserCreationFirstName) => {
        let newUserState = {
            ...state.creatingUser,
            firstName: action.firstName
        }

        return {
            ...state,
            creatingUser: newUserState
        }
    },
    [Actions.HANDLE_USER_CREATION_LASTNAME]: (state: UsersState, action: Actions.HandleUserCreationLastName) => {
        let newUserState = {
            ...state.creatingUser,
            lastName: action.lastName
        }

        return {
            ...state,
            creatingUser: newUserState
        }
    },
    [Actions.HANDLE_USER_CREATION_USERID]: (state: UsersState, action: Actions.HandleUserCreationUserId) => {
        let newUserState = {
            ...state.creatingUser,
            userName: action.userId
        }

        return {
            ...state,
            creatingUser: newUserState
        }
    },
    [Actions.HANDLE_USER_CREATION_EMAIL]: (state: UsersState, action: Actions.HandleUserCreationEmail) => {
        let newUserState = {
            ...state.creatingUser,
            email: action.email
        }

        return {
            ...state,
            creatingUser: newUserState
        }
    },
    [Actions.HANDLE_USER_CREATION_PASSWORD]: (state: UsersState, action: Actions.HandleUserCreationPassword) => {
        let newUserState = {
            ...state.creatingUser,
            initialPassword: action.password
        }

        return {
            ...state,
            creatingUser: newUserState
        }
    },
    [Actions.HANDLE_USER_CREATION_EMPLOYEEID]: (state: UsersState, action: Actions.HandleUserCreationEmployeeId) => {
        let newUserState = {
            ...state.creatingUser,
            employeeId: action.employeeId
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_TITLE]: (state: UsersState, action: Actions.HandleUserCreationTitle) => {
        let newUserState = {
            ...state.creatingUser,
            title: action.title
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_DEPARTMENT]: (state: UsersState, action: Actions.HandleUserCreationDepartment) => {
        let newUserState = {
            ...state.creatingUser,
            department: action.department
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_REGION]: (state: UsersState, action: Actions.HandleUserCreationRegion) => {
        let newUserState = {
            ...state.creatingUser,
            region: action.region
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_COUNTRY]: (state: UsersState, action: Actions.HandleUserCreationCountry) => {
        let newUserState = {
            ...state.creatingUser,
            country: action.country
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_PREFERREDEMAIL]: (state: UsersState, action: Actions.HandleUserCreationPreferredEmail) => {
        let newUserState = {
            ...state.creatingUser,
            preferredEmail: action.preferredEmail
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_PREFERREDSMSPHONE]: (state: UsersState, action: Actions.HandleUserCreationPreferredSMSPhone) => {
        let newUserState = {
            ...state.creatingUser,
            preferredSMSPhone: action.preferredSMSPhone
        }

        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_AUDIENCES]: (state: UsersState, action: Actions.HandleUserCreationAudiences) => {
        let newUserState = {
            ...state.creatingUser,
            audiences: action.audiences,
        }
        return {
            ...state,
            creatingUser: newUserState
        } as UsersState
    },
    [Actions.HANDLE_USER_CREATION_ROLES]: (state: UsersState, action: Actions.ToggleUserRole) => {
        let newRoles = state.creatingUser?.roles ?? [];

        if (newRoles.includes(action.roleName)) {
            newRoles = newRoles.filter(role => role !== action.roleName)
        } else {
            newRoles.push(action.roleName)
        }
        return {
            ...state,
            creatingUser: {
                ...state.creatingUser,
                roles: newRoles
            }
        } as UsersState;
    },
    [Actions.HANDLE_USER_CREATION_SUBMISSION_MANAGER]: (state: UsersState, action: Actions.HandleUserCreationSubmissionManager) => {
        let newUserState = {
            ...state.creatingUser,
            canManageSubmissions: action.canManageSubmissions
        }

        return {
            ...state,
            creatingUser: newUserState,
        } as UsersState;
    },
    [Actions.HANDLE_USER_CREATION_SUBSCRIBED_TO_FLAGGING_NOTIFICATIONS]: (state: UsersState, action: Actions.HandleUserCreationSubscribedToFlaggingNotifications) => {
        let newUserState = {
            ...state.creatingUser,
            subscribedToFlaggingNotifications: action.subscribedToFlaggingNotifications
        }

        return {
            ...state,
            creatingUser: newUserState,
        } as UsersState;
    },

    [Actions.HANDLE_UPDATE_UPDATING_USER_ID]: (state: UsersState, action: Actions.HandleUpdateUpdatingUserId) => {
        return {
            ...state,
            fetchedUser: {
                ...state.fetchedUser,
                id: action.id
            }
        } as UsersState;
    },
    [Actions.HANDLE_EDIT_PASSWORD]: (state: UsersState, action: Actions.EditPasswordAction) => {
        return {
            ...state,
            editingLocalAccountPassword: action.password
        } as UsersState
    },
    [Actions.HANDLE_UPDATE_MOBILE_CONSENT]: (state: UsersState, action: Actions.UpdateMobileConsentAction) => {
        return {
            ...state,
            fetchedUser: {
                ...state.fetchedUser,
                preferredSMSConsent: action.consent
            }
        }
    }
}


function validateInvitingUserEmail(email: string): ValidationResponse | null {
    const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (email && !emailPattern.test(email)) {
        return {
            Message: "Invalid email",
            ModelState: {
                Email: ["Doesn't look like a valid email address."]
            }
        } as ValidationResponse;
    }
    return null;
}

export const reducer = (state: UsersState, action: Action) => {

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state || defaultState;
}
