import React from "react";
import { Button, Paper, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ContentType, SelectOption } from "modules/common/models";
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { UserRoleWrapper } from "routes/_layout/common/components/wrappers/UserRoleWrapper";
import { UserRoleStrings } from "modules/authorization/models";

interface IMyContentFilter {
    selectedFilter: CreatedBySelectValues;
    content: ContentType;
    onSelect: (selection: CreatedBySelectValues) => void;
}

export enum CreatedBySelectValues {
    VIEW_MY,
    VIEW_ALL
}

interface IContentCreatedBySelectOption extends SelectOption {
    id: CreatedBySelectValues;
}

const createdByOptions = (content: ContentType): IContentCreatedBySelectOption[] => {
    const contentType = ContentType[content].toLowerCase();

    return [
        {
            id: CreatedBySelectValues.VIEW_ALL,
            key: "allContent",
            text: `All ${contentType}s`,
            type: "item"
        },
        {
            id: CreatedBySelectValues.VIEW_MY,
            key: "myContent",
            text: `My ${contentType}s (Created by me)`,
            type: "item"
        }
    ];
};

const CreatedByFilter: React.FunctionComponent<PropsWithRedux> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const isDropDownOpen = Boolean(anchorEl);
    const contentType = ContentType[props.content].toLowerCase();

    const onPostCreatedByClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.persist();
        setAnchorEl(event.target);
    };

    const onSelect = (selection: CreatedBySelectValues) => {
        setAnchorEl(null);
        props.onSelect(selection);
    };

    const getDropDownMenu = (): JSX.Element => <Menu
        anchorEl={anchorEl}
        open={isDropDownOpen}
        onClose={() => setAnchorEl(null)}
    >
        {
            createdByOptions(props.content)
            .map((item: IContentCreatedBySelectOption) =>
                <MenuItem
                    sx={{
                        height: 48,
                        backgroundColor: item.id === props.selectedFilter ? "#f2f2ef" : "#ffffff"
                    }}
                    key={`select-${item.key}`}
                    onClick={() => onSelect(item.id)}
                >
                    {item.text}
                </MenuItem>
            )
        }
    </Menu>;

    return <UserRoleWrapper userRole={UserRoleStrings.AUTHOR}>
        <Paper
            sx={{
                borderBottomLeftRadius: 0,
                display: "flex"
            }}
        >
            <Button
                disabled={props.fetching}
                sx={{
                    color: "#5e5e5e",
                    textTransform: "none",
                    maxHeight: 48,
                    height: 48,
                    minHeight: 48
                }}
                variant="text"
                endIcon={<ArrowDropDownIcon />}
                onClick={onPostCreatedByClick}
            >
                {
                    props.selectedFilter === CreatedBySelectValues.VIEW_MY
                    ? `View my ${contentType}s`
                    : `View all ${contentType}s`
                }
            </Button>

            {getDropDownMenu()}
        </Paper>
    </UserRoleWrapper>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IMyContentFilter) => ({
        ...ownProps,
        fetching: state.posts.editor.fetching,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(CreatedByFilter);