import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import SettingsToggleWithBlackout from '../toggles/settingsToggleWithBlackout';
import SettingsToggleOnly from '../toggles/settingsToggleOnly'
import { NotificationEvents } from 'modules/messaging';
import TextBubble from 'modules/common/components/textBubble';
import { lcidMappings } from 'modules/resources/models';
import { actions } from '../..';
import SingleTextAreaInput from '../singleTextAreaInput';
import RegularNotificationPopout from '../regularNotificationPopout'
import ReminderNotificationPopout from '../reminderNotificationPopout';
import SettingsToggleOrAnd from '../toggles/settingsToggleOrAnd';
import MobileNotificationPopout from '../mobileNotificationPopout';

interface ComponentProps { }
interface ComponentState { 
    translatedContent: lcidMappings,
    eventNotificationsEditingVisible: boolean
    eventRemindersEditingVisible: boolean
    eventMentionEditingVisible: boolean
}
interface TextBubble {
    id: string;
    name: string;
}

class MessagingEventControls extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props)
        this.state = {
            translatedContent: { },
            eventNotificationsEditingVisible: false,
            eventRemindersEditingVisible: false,
            eventMentionEditingVisible: false,
        }
    }

    public render() {
        const maxTextLength = 160;

        const textSuggestions = [
            {id: "{{eventTitle}}", name: "{{eventTitle}}"},
        ] as TextBubble[];

        const eventPublishedSMSBubbles = [
            {id: "{{eventTitle}}", name: "{{eventTitle}}"},
            {id: "{{eventDescription}}", name: "{{eventDescription}}"},
            {id: "{{newLine}}", name: "{{newLine}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},
            {id: "{{tenantName}}", name: "{{tenantName}}"},
        ]

        const eventPublishedMobileBubbles = [
            {id: "{{eventTitle}}", name: "{{eventTitle}}"},
            {id: "{{eventDescription}}", name: "{{eventDescription}}"},
            {id: "{{newLine}}", name: "{{newLine}}"},
        ]

        const eventReminderSMSBubbles = [
            {id: "{{eventTitle}}", name: "{{eventTitle}}"},
            {id: "{{eventLink}}", name: "{{eventLink}}"},
            {id: "{{newLine}}", name: "{{newLine}}"},
            {id: "{{supportEmail}}", name: "{{supportEmail}}"},
            {id: "{{tenantName}}", name: "{{tenantName}}"},
        ]

        const eventMentionMobileBubbles = [
            {id: "{{eventTitle}}", name: "{{eventTitle}}"},
            {id: "{{eventAuthor}}", name: "{{eventAuthor}}"},
        ]

        return (
            <React.Fragment>
                <div className="settings-section">
                    <SettingsToggleWithBlackout 
                        title={"Enable notifications for event publishing"}
                        description={"Authors are able to manage publishing notifications for each event. To change its default settings or contents, "}
                        emailEvent={NotificationEvents.EventPublishedEmail}
                        smsEvent={NotificationEvents.EventPublishedSMS}
                        teamsEvent={NotificationEvents.EventPublishedTeams}
                        mobileEvent={NotificationEvents.EventPublishedMobile}
                        id={"eventPublished"}
                        handleOnClick={() => this.toggleWindow("eventNotificationsEditingVisible")}
                    />    
                </div>

                <div className="settings-section">
                    <SettingsToggleWithBlackout 
                        title={"Enable reminders if events are unread"}
                        description={"Authors are able to manage reminders for each event. To change its default settings or contents, "}
                        emailEvent={NotificationEvents.EventReminderEmail}
                        smsEvent={NotificationEvents.EventReminderSMS}
                        teamsEvent={NotificationEvents.EventReminderTeams}
                        mobileEvent={NotificationEvents.EventReminderMobile}
                        id={"eventReminder"}
                        handleOnClick={() => this.toggleWindow("eventRemindersEditingVisible")}
                    />    
                </div>

                <div className="settings-section">
                    <SettingsToggleOrAnd
                        title={"Notify Submission Managers when an event is submitted"}
                        description={"Managers will be notified through email or MS Teams when an event is submitted for review, approval and publishing."}
                        eventOne={NotificationEvents.EventSubmittedTeams}
                        eventTwo={NotificationEvents.EventSubmittedEmail}
                        andPrompt={"Enable MS Teams and Email notifications"}
                        eventOnePrompt={"Enable MS Teams only"}
                        eventTwoPrompt={"Enable Email only"}
                        id={"eventSubmittedNotifications"}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleOrAnd
                        title={"Notify users when mentioned in an event"}
                        description={"Send a notification to a user who has been mentioned in an event. To change its contents, "}
                        eventOne={NotificationEvents.EventMentionedTeams}
                        eventTwo={NotificationEvents.EventMentionedMobile}
                        andPrompt={"Enable Teams Chatbot and Mobile App Notifications"}
                        eventOnePrompt={"Enable Teams only"}
                        eventTwoPrompt={"Enable Mobile only"}
                        id={"mentionEventNotifications"}
                        handleOnClick={() => this.toggleWindow("eventMentionEditingVisible")}
                    />
                </div>

                <div className="settings-section">
                    <SettingsToggleOnly
                        title={"Send confirmation email to attendees"}
                        description={"Employees will receive a confirmation email for each event they respond to as Attending (Online or In-person)."}
                        emailEvent={NotificationEvents.EventRespondedEmail}
                        value={this.props.notificationSettings.settings!["eventRespondedEmailAttempt"].toggle}
                        textVisible={this.props.notificationSettings.settings!["eventRespondedEmailAttempt"].toggle}
                    />

                    { this.props.notificationSettings.settings[NotificationEvents.EventRespondedEmail].toggle &&
                    <div>
                        <div className="label-description-block">
                                <SingleTextAreaInput
                                    returnTranslatedContent={this.getTranslatedContent}
                                    maxTextLength={maxTextLength}
                                    disabled={!this.props.notificationSettings.settings[NotificationEvents.EventRespondedEmail].toggle}
                                    placeholder={this.props.emailSubjects[this.eventUppercase(NotificationEvents.EventRespondedEmail)].toString()}
                                    prompt={"Subject"}
                                    toLoad={this.props.notificationSettings.settings[NotificationEvents.EventRespondedEmail].translatedSubject}
                                    event={NotificationEvents.EventRespondedEmail}
                                    platform={'email'}
                                />                      
                        </div>
                        <div className="word-bubble-formatting">
                            <p className="suggestions-section-text">Use suggestions to personalize: </p>
                            <TextBubble list={textSuggestions} />
                        </div>
                    </div>
                    }
                </div>
            
                <div className="settings-section">
                    <SettingsToggleOnly
                        title={"Send event updated email to attendees"}
                        description={"Attendees will receive an email informing them of any updates made to events they responded to."}
                        emailEvent={NotificationEvents.EventUpdatedEmail}
                        value={this.props.notificationSettings.settings!["eventUpdatedEmailAttempt"].toggle}
                        textVisible={this.props.notificationSettings.settings!["eventUpdatedEmailAttempt"].toggle}
                    />                

                    {this.props.notificationSettings.settings[NotificationEvents.EventUpdatedEmail].toggle &&
                    <div>
                        <div className="label-description-block">
                            <SingleTextAreaInput
                                returnTranslatedContent={this.getTranslatedContent}
                                maxTextLength={maxTextLength}
                                disabled={!this.props.notificationSettings.settings[NotificationEvents.EventUpdatedEmail].toggle}
                                placeholder={this.props.emailSubjects[this.eventUppercase(NotificationEvents.EventUpdatedEmail)].toString()}
                                prompt={"Subject"}
                                toLoad={this.props.notificationSettings.settings[NotificationEvents.EventUpdatedEmail].translatedSubject}
                                event={NotificationEvents.EventUpdatedEmail}
                                platform={'email'}
                            />                       
                        </div>
                        <div className="word-bubble-formatting">
                            <p className="suggestions-section-text">Use suggestions to personalize: </p>
                            <TextBubble list={textSuggestions} />
                        </div>
                    </div> }
                </div>

                <div className="settings-section">
                    <SettingsToggleOnly
                        title={"Send cancellation email to attendees"}
                        description={"Attendees will receive a cancellation email if the event they responded to is cancelled."}
                        emailEvent={NotificationEvents.EventCancelledEmail}
                        value={this.props.notificationSettings.settings!["eventCancelledEmailAttempt"].toggle}
                        textVisible={this.props.notificationSettings.settings!["eventCancelledEmailAttempt"].toggle}
                    />        

                    {this.props.notificationSettings.settings[NotificationEvents.EventCancelledEmail].toggle &&   
                    <div>      
                        <div className="label-description-block">
                            <SingleTextAreaInput
                                returnTranslatedContent={this.getTranslatedContent}
                                maxTextLength={maxTextLength}
                                disabled={!this.props.notificationSettings.settings[NotificationEvents.EventCancelledEmail].toggle}
                                placeholder={this.props.emailSubjects[this.eventUppercase(NotificationEvents.EventCancelledEmail)].toString()}
                                prompt={"Subject"}
                                toLoad={this.props.notificationSettings.settings[NotificationEvents.EventCancelledEmail].translatedSubject}
                                event={NotificationEvents.EventCancelledEmail}
                                platform={'email'}
                            />                       
                        </div>
                        <div className="word-bubble-formatting">
                            <p className="suggestions-section-text">Use suggestions to personalize: </p>
                            <TextBubble list={textSuggestions} />
                        </div>
                    </div> }
                </div>

                {this.state.eventNotificationsEditingVisible &&
                    <RegularNotificationPopout 
                        updateToggle={() => this.toggleWindow("eventNotificationsEditingVisible")}
                        event={"eventPublished"}
                        eventPrompt={"Event Published"}
                        emailBubbles={textSuggestions}
                        smsBubbles={eventPublishedSMSBubbles}
                        mobileBubbles={eventPublishedMobileBubbles}
                        publishName={"defaultEventSettings"}
                        emailEvent={NotificationEvents.EventPublishedEmail}
                        smsEvent={NotificationEvents.EventPublishedSMS}
                        teamsEvent={NotificationEvents.EventPublishedTeams}
                        mobileEvent={NotificationEvents.EventPublishedMobile}
                    />
                }

                {this.state.eventRemindersEditingVisible &&
                    <ReminderNotificationPopout
                        updateToggle={() => this.toggleWindow("eventRemindersEditingVisible")}
                        event={"eventReminder"}
                        eventPrompt={"Event Reminder"}
                        emailBubbles={textSuggestions}
                        smsBubbles={eventReminderSMSBubbles}
                        mobileBubbles={eventPublishedSMSBubbles}
                        reminderName={"defaultEvent"}
                    />
                }

                {this.state.eventMentionEditingVisible && 
                    <MobileNotificationPopout
                        updateToggle={() => this.toggleWindow("eventMentionEditingVisible")}
                        event={"eventMentioned"}
                        eventPrompt={"Event Mentioned"}
                        bubbles={eventMentionMobileBubbles}
                    />
                }           

            </React.Fragment>
        )
    }

    private getTranslatedContent = (translatedContent: any, platform: string, event: NotificationEvents) => {
        switch(platform) {
            case 'email':
                this.props.setEmailSubject(event, translatedContent);
                break;

            default:
                console.log("unknown platform, error.")
                break;
        }
    }

    private toggleWindow = (windowName) => {
        this.setState({...this.state, [windowName]: !this.state[windowName]})
    }

    private eventUppercase = (eventToFix: string) => {
        if(eventToFix !== null && eventToFix.length > 2)
            return eventToFix[0].toUpperCase() + eventToFix.slice(1);
        else
            return "Invalid string to fix."
    }
}


const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        emailSubjects: state.resources.emailSubjectsResource,
    }),
    {
        setEmailSubject: actions.SetEmailSubject
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(MessagingEventControls));