import React from "react";
import { DraftAction, DraftActivity } from "../models";
import { Divider, TableCell, TableRow } from "@mui/material";

import moment from "moment";
interface ComponentProps {
    activity: DraftActivity;
    index: number;
}

const DraftActivityLog: React.FunctionComponent<ComponentProps> = (props) => {
    const formatDate = (date: Date): string => moment(date).format('MMM D, YYYY, h:mm A');

    const activityToString = (): string => {
        var firstLetter = props.activity.action[0].toLocaleUpperCase();
        var lowerCaseLetters = props.activity.action.slice(1, props.activity.action.length).toLocaleLowerCase();
        return `${firstLetter}${lowerCaseLetters}`;
    };

    return (
        <React.Fragment>
            {
                props.index !== 0 &&
                <TableRow>
                    <TableCell colSpan={2}>
                        <Divider></Divider>
                    </TableCell>
                </TableRow>
            }

            <TableRow>
                <TableCell className={`legend draft-history-cell`} >{`${activityToString()}ed By`}</TableCell>
                <TableCell className={`content draft-history-cell`}>{props.activity.userFullName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={`legend draft-history-cell`} padding="normal">{`${activityToString()}ed On`}</TableCell>
                <TableCell className={`content draft-history-cell`}>{formatDate(props.activity.time)}</TableCell>
            </TableRow>

            {
                props.activity.action === DraftAction.Reject &&
                <TableRow>
                    <TableCell className={`legend draft-history-cell`}>{"Comments"}</TableCell>
                    <TableCell className={`content draft-history-cell`}>{props.activity.comment ?? <i>No comment</i>}</TableCell>
                </TableRow>
            }
        </React.Fragment>
    );
};

export default DraftActivityLog;