import { NotificationRange } from "modules/documents";
import { lcidMappings } from "modules/resources";

export interface MessagingState {
    activeTab: MessagingTabs
}


export enum MessagingTabs {
    Email,
    SMS,
    Teams,
    Mobile
}

export interface Blast {
    audienceIds: string[]
    translatedBody: lcidMappings
    subject: string
}

export enum NotificationEvents {
    CommentFlaggedEmail = "commentFlaggedEmailAttempt",
    CommentFlaggedTeams = "commentFlaggedTeamsAttempt",
    ConsentInviteSms = "consentInviteSMSAttempt",
    DocLockerUpdatedMobile = "docLockerUpdatedMobileAttempt",
    EventCancelledEmail = "eventCancelledEmailAttempt",
    EventPublishedEmail = "eventPublishedEmailAttempt",
    EventPublishedMobile = "eventPublishedMobileAttempt",
    EventPublishedSMS = "eventPublishedSMSAttempt",
    EventPublishedTeams = "eventPublishedTeamsAttempt",
    EventReminderEmail = "eventReminderEmailAttempt",
    EventReminderMobile = "eventReminderMobileAttempt",
    EventReminderSMS = "eventReminderSMSAttempt",
    EventReminderTeams = "eventReminderTeamsAttempt",
    EventRespondedEmail = "eventRespondedEmailAttempt",
    EventUpdatedEmail = "eventUpdatedEmailAttempt",
    MassEmail = "massEmailAttempt",
    MassSMS = "massSMSAttempt",
    PostCommentCreatedMobile = "postCommentCreatedMobileAttempt",
    PostCommentCreatedTeams = "postCommentCreatedTeamsAttempt",
    PostPublishedEmail = "postPublishedEmailAttempt",
    PostPublishedMobile = "postPublishedMobileAttempt",
    PostPublishedSMS = "postPublishedSMSAttempt",
    PostPublishedTeams = "postPublishedTeamsAttempt",
    PostReminderEmail = "postReminderEmailAttempt",
    PostReminderMobile = "postReminderMobileAttempt",
    PostReminderSMS = "postReminderSMSAttempt",
    PostReminderTeams = "postReminderTeamsAttempt",
    UserProfileUpdatedEmail = "userProfileUpdatedEmailAttempt",
    EventMentionedTeams = "eventMentionedTeamsAttempt",
    EventMentionedMobile = "eventMentionedMobileAttempt",
    PostMentionedTeams = "postMentionedTeamsAttempt",
    PostMentionedMobile = "postMentionedMobileAttempt",
    UserMentionedTeams = "userMentionedTeamsAttempt",
    UserMentionedMobile = "userMentionedMobileAttempt",
    UserMentionedEmail = "userMentionedEmailAttempt",
    AccessGrantedEmail = "accessGrantedEmailAttempt",
    PostSubmittedTeams = "postSubmittedTeamsAttempt",
    VerifySMSPhone = "verifyPhoneSMSAttempt",
    PostSubmittedEmail = "postSubmittedEmailAttempt",
    EventSubmittedTeams = "eventSubmittedTeamsAttempt",
    EventSubmittedEmail = "eventSubmittedEmailAttempt",
}
export interface TenantNotificationSettings {
    defaultEmailBlackoutPeriod?: number,
    defaultSMSBlackoutPeriod?: number,
    defaultTeamsBlackoutPeriod?: number,
    defaultMobileBlackoutPeriod?: number,
    defaultEventReminder?: number,
    defaultEventSettings?: DefaultEventSettings,
    defaultPostReminder?: number,
    defaultPostSettings?: DefaultPostSettings,
    fromEmailAddress?: string,
    fromEmailName?: string,
    linkPreference?: string,
    settings: {
        [event in NotificationEvents]: EventAttemptModel
    }
}

export interface EventAttemptModel {
    blackoutPeriod: number,
    templaceId: string,
    toggle: boolean,
    translatedBody: any,
    translatedSubject: any,
    translatedContent: any,
}
export interface DefaultPostSettings {
    emailOnPublish?: NotificationRange,
    mobileOnPublish: NotificationRange,
    smsOnPublish: NotificationRange,
    teamsOnPublish: NotificationRange,
    reminders: Reminder[]
}
export interface DefaultEventSettings {
    emailOnPublish?: NotificationRange,
    mobileOnPublish: NotificationRange,
    smsOnPublish: NotificationRange,
    teamsOnPublish: NotificationRange,
    reminders: Reminder[]
}

export interface Reminder {
    channel: "inherit" | "email" | "sms" | "teams" | "mobile" | "none";
    delay: number;
    range: "fallback" | "subscribers" | "none";
}
