import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import "../styles/guidelineHint.sass"

interface IGuidelineHintProps {
    open: boolean;
    onClose: () => void;
    hintType: HintType;
}

export enum HintType {
    Post = "post",
    Event = "event"
}

const HINT_HEADER = "Revisit the guidelines here!";
const HINT_DESCRIPTION = "Click the book icon in the top-right corner of the editor to access your content guidelines.";
const HINT_OK = "OK, GOT IT!";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const GuidelineHint: React.FunctionComponent<IGuidelineHintProps> = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            <DialogContent className="centered-hint-content">
                <img className="gif-wrapper"
                    src={`/images/tutorials/guidelines/${props.hintType.toString()}-guidelines-hint.gif`}
                    alt="Find content guidelines on the top right edge of the screen."
                />

                <h4>{HINT_HEADER}</h4>
                <p>{HINT_DESCRIPTION}</p>
            </DialogContent>

            <DialogActions className="centered-hint-content">
                <Button variant="text" color="primary" onClick={props.onClose}>{HINT_OK}</Button>
            </DialogActions>
        </Dialog>
    );
};