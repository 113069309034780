import React from "react";
import Header from "../../../modules/common/components/header/Header";
import { GlobalApplicationState } from "../../../globalApplicationState";
import { connect, ConnectedProps } from "react-redux";

import UserPortalSplashScreen from "modules/common/components/splash-screens/userPortalSplashScreen";
import useDrawerExpanded from "modules/common/hooks/useDrawerExpanded";
import { COMMON_COOKIE_KEYS } from "utils/cookie";

import CookieBanner from "../common/components/CookieBanner";
import Menu from "./components/Menu";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsightsReactPlugin } from "appInsights";
import { useMediaQuery, useTheme } from "@mui/material";
import { withRouter, RouteComponentProps } from "react-router";


import "./index.sass";


const drawerWidth = 62;
const expandedDrawerWidth = 230;

const useStyles = makeStyles<Theme, { isSmallAndSmaller: boolean, drawerExpanded: boolean }>((theme: Theme) =>
    createStyles({
        main: {
            display: "flex",
            maxHeight: `calc(100vh - 48px)`,
            minHeight: `calc(100vh - 48px)`,
            overflow: "hidden",
            position: "relative"
        },
        drawer: ({ isSmallAndSmaller, drawerExpanded }) => ({
            flexShrink: 0,
            position: "relative",
            transition: "width linear 0.2s",
            width: (!isSmallAndSmaller || drawerExpanded)
                ? drawerWidth
                : 0,
            "&.expanded": {
                width: isSmallAndSmaller
                    ? "100%"
                    : expandedDrawerWidth,
                "& $drawerPaper": {
                    width: isSmallAndSmaller
                        ? "100%"
                        : expandedDrawerWidth,
                }
            }
        }),
        drawerPaper: ({ isSmallAndSmaller, drawerExpanded }) => ({
            backgroundColor: theme.palette.background.default,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "visible",
            position: "absolute",
            transition: "width linear 0.2s",
            width: (!isSmallAndSmaller || drawerExpanded)
                ? drawerWidth
                : 0,
            zIndex: 1
        }),
        content: {
            flexGrow: 1,
            overflow: "auto",
            padding: 12,
            position: "relative",
            zIndex: 0,
            "& .portal-page": {
                margin: -12
            }
        }
    })
);

const Layout: React.FunctionComponent<PropsWithRedux> = ({
    publicAccess,
    clientName,
    tenant,
    location,
    children
}) => {
    const { drawerExpanded, toggleDrawerExpanded } = useDrawerExpanded(COMMON_COOKIE_KEYS.USER_DRAWER_EXPANDED, true);
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const classes = useStyles({ isSmallAndSmaller, drawerExpanded });

    return (
        <div id="user-app">
            <Header
                publicAccess={publicAccess}
                onClickMenu={toggleDrawerExpanded}
                light
                displayName={clientName}
                showRole={false}
                linkToProfile
            />
            <div className={classes.main}>
                <nav className={`${classes.drawer} ${drawerExpanded ? "expanded" : ""}`}>
                    <Menu
                        expand={drawerExpanded}
                        locationPathName={location.pathname}
                        publicAccess={publicAccess}
                        setExpand={toggleDrawerExpanded}
                        tenant={tenant.id}
                        drawerPaperClassName={classes.drawerPaper}
                        hideIcons={isSmallAndSmaller && !drawerExpanded}
                    />
                </nav>
                <main className={classes.content}>
                    {children}
                </main>
            </div>
            <CookieBanner />
            <div id="pause-window" className="pause-window-execution" />
        </div>
    );
}

class UserAppLayout extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            displaySplashScreen: false
        };
    }

    public componentDidMount() {
        document.title = this.props.clientName || "Loading...";

        let favicon: any = document.querySelector("link[rel~='icon']");
        if (!!favicon)
            favicon.href = "/favicon.png";

        if (!!this.props.history.location.state && !!this.props.history.location.state.prevPath && this.props.history.location.state.prevPath.includes("/admin/"))
            this.setState({ displaySplashScreen: true });
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (!!this.props.clientName && !prevProps.clientName)
            document.title = this.props.clientName;
    }

    public render() {
        return (
            <UserPortalSplashScreen displaySplashScreen={this.state.displaySplashScreen}>
                <Layout {...this.props} />
            </UserPortalSplashScreen>
        );
    }
}

interface ComponentProps {
    publicAccess?: boolean;
}

interface ComponentState {
    displaySplashScreen: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        clientName: state.settings.clientSettings.displayName,
        tenant: state.tenant
    })
);
type PropsWithRedux = ConnectedProps<typeof connector> & RouteComponentProps<{}>;

export default withRouter(connector(withAITracking(appInsightsReactPlugin, UserAppLayout)));
