import React, { useEffect } from "react";

import { IGPTChoice, PromptTypeEnum } from "./models";
import { SmartContentSuggestion } from "./smartContentSuggestion";
import { ISmartContentListPageProps, SmartContentListPage } from "./smartContentListPage";

interface ISmartContentGeneric extends ISmartContentListPageProps {
    choices: IGPTChoice[];
    promptType: PromptTypeEnum;
    onApplyText: (newTitle: string) => void;
    onClose: () => void;
}

const SmartContentGeneric: React.FunctionComponent<ISmartContentGeneric> = ({
    choices,
    title,
    usedTinyMceDropDown,
    promptType,
    onApplyText,
    ...props
}) => {


    // before passing to propped function, scroll to bottom of list so spinner shows
    const onGenerateMoreHandler = async (promptType: PromptTypeEnum): Promise<void> => {
        const element = document.getElementById("smart-content-titles-list");
        if (element)
            element.scroll({ top: element.scrollHeight, behavior: "smooth" });

        await props.onGenerateMore(promptType);
    }

    return (
        <SmartContentListPage
            {...props}
            contentId="smart-content-titles-list"
            promptType={promptType}
            onGenerateMore={onGenerateMoreHandler}
            enableGenerateMoreListButton
            title={title}
            usedTinyMceDropDown={usedTinyMceDropDown}
        >
            {choices.map(({ index, message: { content } }: IGPTChoice) =>
                <SmartContentSuggestion

                    key={`${index}-rewrite`}
                    onConfirm={() => onApplyText(content)}
                    suggestion={{ suggestion: content, suggestionId: index.toString() }}
                    confirmLabel="Insert"
                    confirmPosition="block"
                />)}
        </SmartContentListPage>
    );
}

export { SmartContentGeneric };
