import * as React from "react";

import { AttachedFile } from "modules/gallery/models";
import DocumentGallery from "./dialogs/documentGallery";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from '@mui/icons-material/Close';
import HoverText from "modules/documents/components/action-buttons/hoverText";

import "./fileAttachments.sass"

const DEFAULT_BUTTON_LABEL = "ADD/EDIT FILES"
const DEFAULT_REMOVE_LABEL = "REMOVE ALL FILES"
const DEFAULT_NO_ATTACHMENTS_MSG = "No attachments found"

class FileAttachments extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      showDocumentGallery: false
    };
  }

  private getEmptyAttachmentText = () : JSX.Element => {
    const { fileAttachments } = this.props;
    if (!fileAttachments || fileAttachments.length === 0)
        return (
        <div className="no-attachment-message">{DEFAULT_NO_ATTACHMENTS_MSG}</div>);
    
    return <React.Fragment/>;
  }

  private _getTitle = (): string => {
    const { fileAttachments } = this.props;

    let label = "Attach files";
    if (fileAttachments && fileAttachments.length === 1)
        label = "Attached file (1)";
    else if (fileAttachments && fileAttachments.length > 1)
        label = `Attached files (${fileAttachments.length})`;

    return label;
  }

    private _getItem = (fileAttachment: AttachedFile) => {
        const { itemCloseIcon } = this.props;
        return (
            <Paper
                key={fileAttachment.ospreyId}
                elevation={1}
                className="file-attachment"
                style={{
                    height: 40,
                    border: "solid 1px rgba(0, 0, 0, 0.23)",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    margin: "5px 0 0 0",
                }}
            >
                <div>
                    {/* only show 37 characters */}
                    {fileAttachment.fileName.length > 37
                        ? <HoverText popoverProps={{ anchorOrigin: { horizontal: "left", vertical: "center"}}} underline={false} label={`${fileAttachment.fileName.substring(0, 37)}...`}>
                            <span style={{ whiteSpace: "normal", wordBreak:"break-all" }}>{fileAttachment.fileName}</span>
                            </HoverText>
                        : <span>{fileAttachment.fileName}</span>}
                </div>
                <div>
                    <IconButton style={{border: "none", boxShadow: "none", background: "none", margin: "0px"}} size="small" onClick={() => this.onRemoveFileAttachment(fileAttachment)}>
                        {itemCloseIcon || <CloseIcon style={{ color: "#b72026" }} />}
                    </IconButton>
                </div>
            </Paper>
        );
    }

  public render() {
    const {
        fileAttachments,
        footNoteStyle,
        buttonLabel,
        v2 = false,
    } = this.props;

    return (
      <Collapse className="file-attachment" in={this.props.show}>
        <Paper elevation={0} className={`section-card ${this.props.isGreyPaper ? "grey-background" : "white-background"}`}>
          <div className="optional-content-header">
            <div>{this._getTitle()}</div>
            {v2
                ? <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onShowDocumentGallery} className="add-new-item file-attachment-button">
                    {buttonLabel || DEFAULT_BUTTON_LABEL }
                </Button>
                : <>
                    {fileAttachments && fileAttachments.length > 0 &&
                        <div className="remove-optional-content">
                            <Button variant="text" color="inherit" startIcon={<BlockIcon />} onClick={this.props.onRemove}>Remove All Attachments</Button>
                        </div>}
                    </>}
          </div>
          {!!(fileAttachments || []).length && fileAttachments!.map(this._getItem)}
          {v2
            ? fileAttachments && fileAttachments.length > 1 &&
                <div className="remove-optional-content">
                    <Button variant="text" color="inherit" className="file-attachment-button" startIcon={<BlockIcon />} onClick={this.props.onRemove}>{this.props.removeLabel || DEFAULT_REMOVE_LABEL}</Button>
                </div>
            :
            <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.onShowDocumentGallery} className="add-new-item file-attachment-button">
                    {buttonLabel || DEFAULT_BUTTON_LABEL}
            </Button>}

          {this.getEmptyAttachmentText()}

          <div className="optional-content-disclaimer" style={{ ...footNoteStyle }}>
            Attaching large files can affect publishing time
          </div>
          <DocumentGallery
            show={this.state.showDocumentGallery}
            fileAttachments={fileAttachments}
            onApply={this.onApplyFileAttachmentChanges}
            onClose={this.onHideDocumentGallery}
          />
        </Paper>
      </Collapse>
    );
  }

  private onApplyFileAttachmentChanges = (fileAttachments: AttachedFile[]) => {
    this.props.onChange(fileAttachments);
  }

  private onHideDocumentGallery = () => {
    this.setState({ ...this.state, showDocumentGallery: false });
  }

  private onRemoveFileAttachment = (fileAttachment: AttachedFile) => {
    this.props.onChange(this.props.fileAttachments!.filter((file) => file.ospreyId !== fileAttachment.ospreyId));
  }

  private onShowDocumentGallery = () => {
    this.setState({ ...this.state, showDocumentGallery: true });
  }
}

interface ComponentProps {
  show: boolean;
  fileAttachments: AttachedFile[] | undefined;
  buttonLabel?: string;
  itemCloseIcon?: JSX.Element;
  removeLabel?: string;
  footNoteStyle?: React.CSSProperties;
  isGreyPaper?: boolean;
  v2?: boolean; //authoring v2 layout
  onChange: (fileAttachments: AttachedFile[]) => void;
  onRemove: () => void;
}

interface ComponentState {
  showDocumentGallery: boolean;
}

export default FileAttachments;
