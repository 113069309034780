import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { push } from "react-router-redux";
import { injectIntl, IntlShape } from "react-intl";
import moment from "moment";
import {
    useMediaQuery,
    useTheme,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import * as postActions from "modules/posts/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import * as userActions from "modules/profile/actionCreator";
import { BackItem } from "pages/common/breadcrumb";
import AuthoringAppBar from "modules/common/components/authoring/authoringAppBar";
import useIsMounted from "modules/common/hooks/useIsMounted";
import * as RemoteFocusRequests from "modules/common/remoteFocusRequest";
import Loading from "modules/common/components/loading";
import { GlobalApplicationState } from "globalApplicationState";
import BasePage from "pages/common/basePage";
import { POST_TYPES, Post, PostOverview } from "../../models";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import PostEditor from "./postEditor";
import PostContentView from "../post-view/postContentView";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import Preview, { PreviewType } from "modules/common/components/authoring/dialogs/preview";
import { Tab as ITab } from "pages/common/tabs";
import OneLastCheck from "modules/common/components/authoring/dialogs/oneLastCheck";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { draftPostsApi, loggedEventsApi, submissionsApi } from "api/instances";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import useIsFirstRender from "modules/common/hooks/useIsFirstRender";
import { useCustomTinyMceCssContent } from "modules/common/hooks/data/useCustomTinyMceCssContent";

import { UserRoleStrings } from "modules/authorization/models";
import { getHighestRole } from "utils/userRoleUtils";
import { openPostInPortal } from "utils/redirectUtils";
import { setShouldDisplayNav } from "modules/adminLayout/actionCreator";
import {
    getDefaultNotificationSetting,
    isAnyTenantPostNotificationsEnabled
} from "utils/notificationsHelper";
import { NotificationEvents } from "modules/messaging";
import { ConfirmRepublishCompliancePostDialog } from "modules/common/components/dialogs/confirmRepublishCompliancePostDialog";
import PostAuthoringActions from "./components/postAuthoringActionsAppBar";
import { getPublishBtnName } from "../../../common/components/authoring/utils";
import ContentGuidelinePreview from "modules/settings/components/content/guidelines/contentGuidelinePreview";
import { HintType } from "modules/common/components/authoring/dialogs/guidelineHint";
import RejectSubmissionDialog from "../../../common/components/authoring/dialogs/rejectSubmissionDialog";
import { BACK_POST_TEXT, CONTRIBUTOR_PUBLISH_TEXT, CONTRIBUTOR_REPUBLISH_TEXT, DFLT_PUBLISH_TEXT, DISCARD_BTN_TEXT, DRAFT_CHANGED_BTN_TEXT, SUBMISSION_PUBLISH_TEXT } from "modules/common/components/authoring/constants";

import "modules/common/components/authoring/authoring.sass";
import "modules/common/components/authoring/styles/authoringLayout.sass";
import "./postCreation.sass";
import "styles/views.sass";
import "modules/posts/components/post-view/postView.sass";
import "modules/posts/components/post-view/reactions/reactions.sass";


export const POST_SETTINGS_EDITOR_DRAWER_WIDTH = 469; // match with authoringLayout.sass $settings-drawer-width variable

export enum POST_SETTINGS_TAB_VALUES {
    POST_DETAILS,
    POST_OPTIONS,
    CONTENT_ANALYSES
}

const TABS: ITab[] = [
    {
        label: "Post details",
        value: POST_SETTINGS_TAB_VALUES.POST_DETAILS,
    },
    {
        label: "Post options",
        value: POST_SETTINGS_TAB_VALUES.POST_OPTIONS,
    },
    {
        label: "Conent analyses",
        value: POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES,
    }
];

const PostCreation: React.FunctionComponent<PropsWithRedux> = ({
    currentUser,
    notificationSettings,
    match,
    tagSettings,
    tenantSettings,
    isValid,
    post,
    tenantId,
    getTagSettings,
    setNewPost,
    fetchDraftPost,
    setShouldDisplayNav,
    redirectTo,
    setSaving,
    setChangedSinceSaved,
    save,
    updateId,
    createNew,
    ...props
}) => {
    const { userId } = currentUser;
    const { params: { draftId } } = match;
    const isDraftIdPresent = React.useMemo(() => draftId && draftId !== "new", [draftId]);

    const openedTimeStamp = useRef<string>(new Date().toISOString());
    const isMounted = useIsMounted();
    const isFirstRender = useIsFirstRender();
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const queryParams = useQueryParams();
    const history = useHistory();
    const { customCss, fetching: fetchingCustomCss } = useCustomTinyMceCssContent(tenantSettings.showFeatures.tinyMceCustomCssEnabled);
    const isContributor = getHighestRole(currentUser) === UserRoleStrings.CONTRIBUTOR;
    const isSubmission = post.isSubmission !== undefined && post.isSubmission;
    const isTenantNotifsEnabled = isAnyTenantPostNotificationsEnabled(notificationSettings);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [oneLastCheckOpen, setOneLastCheckOpen] = useState<boolean>(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState<boolean>(!isSmallAndSmaller);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewPost, setPreviewPost] = useState<PostOverview | undefined>(); // for preview page and one last check modal
    const [confirmAnalyticsDialogOpen, setConfirmAnalyticsDialogOpen] = useState<boolean>(false); // content analyses publish confirm dialog
    const [unsavedChangesWarning, setUnsavedChangesWarning] = useState<boolean>(false);
    const [confirmDiscardPost, setConfirmDiscardPost] = useState<boolean>(false);
    const [confirmRejectSubmission, setConfirmRejectSubmission] = useState<boolean>(false);
    const [selectedSettingsTab, setSelectedSettingTab] = useState<number>(TABS[0].value);
    const [dontShowConfirmDialogAgainChecked, setDontShowConfirmDialogAgainChecked] = useState<boolean>(!currentUser.showContentAnalysesDialog);
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [fullscreenEditor, setFullscreenEditor] = useState<boolean>(false);
    const [analysisIndicator, setAnalysisIndicator] = useState<boolean>(false);
    const [confirmRepublishComplianceOpen, setConfirmRepublishComplianceOpen] = useState<boolean>(false);
    const [openContentGuidelines, setOpenContentGuidelines] = useState<boolean>(false);
    const [isFirstOpenGuidelines, setIsFirstOpenGuidelines] = useState<boolean>(true);

    React.useEffect(() => {
        if (props.guidelines && !props.isGuidelineEmpty && !props.autoHideContentGuidelines && isFirstOpenGuidelines) {
            setOpenContentGuidelines(true);
            setIsFirstOpenGuidelines(false);
        }
    }, [props.guidelines, props.isGuidelineEmpty, props.autoHideContentGuidelines, isFirstOpenGuidelines]);

    const getNewPost = useCallback(() => {
        const author: string = currentUser ? (currentUser.preferredName ? `${currentUser.preferredName} ${currentUser.lastName}` : `${currentUser.firstName} ${currentUser.lastName}`) : "";
        const authorEmail: string = currentUser ? currentUser.email : "";
        const lcid: string = tenantSettings ? tenantSettings.defaultLCID : "en-us";

        const post: Partial<Post> = {
            id: "",
            attachedContent: [],
            author: author,
            authorEmail: authorEmail,
            commentingEnabled: tenantSettings.isCommentingEnabledForNewPostsByDefault,
            commentingType: "standard",
            fileAttachments: [],
            image: {
                id: "",
                url: "",
                transforms: {
                    points: [],
                    zoom: 1
                }
            },
            notifications: {
                emailOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedEmail, notificationSettings),
                mobileOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedMobile, notificationSettings),
                reminders: isTenantNotifsEnabled ?
                    notificationSettings.defaultPostSettings?.reminders || [] :
                    [],
                smsOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedSMS, notificationSettings),
                teamsOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedTeams, notificationSettings)
            },
            postType: "standard",
            reactingEnabled: tenantSettings.isReactingEnabledForNewPostsByDefault &&
                isContributor ? tenantSettings.canContributorsToggleReactions : true,
            tags: [],
            translatedContent: {
                [lcid]: {
                    body: "",
                    description: "",
                    title: ""
                }
            },
        };

        return post;
    }, [currentUser, notificationSettings, tenantSettings, isTenantNotifsEnabled]);

    const setupPreviewPost = React.useCallback(async (id?: string) => {
        const idToUse = post.id || id;
        if (!idToUse) return;

        try {
            // using api fetch instead of redux because redux clears the post from editor state
            const postOverview = await draftPostsApi.getDraftPost(idToUse);
            if (isMounted()) {
                setPreviewOpen(true);
                setPreviewPost({
                    ...postOverview,
                    author: { name: postOverview.author, email: postOverview.authorEmail, avatar: { color: "#2196f3" } },
                    imageUrl: postOverview.image.url,
                });
            }
        } catch (err) { }
    }, [post.id, isMounted]);

    const sendPostAuthoringClosedSparrow = React.useCallback(async () => {
        try {
            if (isDraftIdPresent && userId)
                await loggedEventsApi.sendPostAuthoringClosedEvent(tenantId, draftId, userId);
        } catch (err) { }
    }, [isDraftIdPresent, userId, tenantId, draftId]);

    const sendPostAuthoringClosedEventBeacon = React.useCallback(async () => {
        try {
            if (isDraftIdPresent && userId && tenantId) {
                await loggedEventsApi.sendPostAuthoringClosedEventBeacon(tenantId, draftId, userId);
            }
        } catch (err) { }
    }, [draftId, tenantId, userId, isDraftIdPresent]);

    const sendPostAuthoringOpenedEvent = React.useCallback(async (time?: string) => {
        try {
            if (isDraftIdPresent && userId)
                await loggedEventsApi.sendPostAuthoringOpenedEvent(draftId, time || openedTimeStamp.current);
        } catch (err) { }
    }, [userId, isDraftIdPresent, draftId]);

    // on page visibilitychange send post opened or post closed depending on document.visibilityState
    useEffect(() => {
        /**
         * Send post authoring depending on visibility state
         * - this is the recommended way to  send any analytics on user session end of a page
         * - more info here: https://developer.chrome.com/blog/page-lifecycle-api/
        */
        const onVisbilityChange = async () => {
            if (document.visibilityState === "hidden")
                await sendPostAuthoringClosedEventBeacon();
            else if (document.visibilityState === "visible")
                await sendPostAuthoringOpenedEvent(new Date().toISOString());
        };

        document.addEventListener("visibilitychange", onVisbilityChange);

        return () => {
            document.removeEventListener("visibilitychange", onVisbilityChange);
        };
    }, [sendPostAuthoringClosedEventBeacon, sendPostAuthoringOpenedEvent]);

    // send post authoring open event when post has id
    useEffect(() => {
        sendPostAuthoringOpenedEvent(openedTimeStamp.current);
    }, [userId, isDraftIdPresent, draftId, sendPostAuthoringOpenedEvent]);

    // hide nav
    useEffect(() => {
        setShouldDisplayNav(false);
    }, [setShouldDisplayNav]);

    // post authoring close event
    // - this will handle the navigations within our app
    useEffect(() => {
        return () => {
            sendPostAuthoringClosedSparrow();
        };
    }, [sendPostAuthoringClosedSparrow]);

    /**
     * Handle preview query param effects
     */
    useEffect(() => {
        if (queryParams.get(QUERY_PARAM_KEYS.LOAD_PREVIEW_OPEN) === "true" && match.params.draftId !== "new") {
            setupPreviewPost(match.params.draftId);

            // remove query param
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete(QUERY_PARAM_KEYS.LOAD_PREVIEW_OPEN);
            history.replace({
                search: urlParams.toString()
            });
        }
    }, [isFirstRender, queryParams, match.params.draftId, history, setupPreviewPost]);

    // effects of post changes
    useEffect(() => {
        moment.locale("en");

        const loadExistingPost = async () => {
            // redux fetch
            await fetchDraftPost(match.params.draftId);
            if (isMounted())
                setIsLoading(false);
        };

        if (!isDraftIdPresent) {
            const post = { ...getNewPost() };
            setNewPost(post);
            setIsLoading(false);
        } else if (isFirstRender) {
            loadExistingPost();
        }
    }, [getNewPost, isMounted, isFirstRender, isDraftIdPresent, setNewPost, match.params.draftId, fetchDraftPost]);

    // effects of tag settings change
    useEffect(() => {
        if (tagSettings.shouldFetch || !tagSettings.tagGroups.length)
            getTagSettings();
    }, [getTagSettings, tagSettings.shouldFetch, tagSettings.tagGroups.length]);

    const redirectToManagePosts = React.useCallback(() => {
        redirectTo(`/${match.params.tenant}/admin/posts`);
    }, [match.params.tenant, redirectTo]);

    const backToManagePosts = React.useCallback(() => {
        props.changedSinceSaved
            ? setUnsavedChangesWarning(true)
            : redirectToManagePosts();
    }, [props.changedSinceSaved, redirectToManagePosts]);

    const onSaveDraftSuccess = React.useCallback((isSubmission: boolean = false) => {
        if (isMounted()) {
            const savedItem = isSubmission ? "changes" : "as draft";
            setSuccessMessage(`Successfully saved ${savedItem}.`);
        }
    }, [isMounted]);

    /**
     * Save post
     * @param draftSaveOnly     flag whether we are only saving as a draft - controls whether we show a snackbar or not
     * @param preview           preview query param for redirect
     * @param redirecToUrl      url to redirect to after saving existing post
     * @param isSubmission      flag if we want the post to be saved as a submission or not. If false, post is saved as a draft.
     * @returns boolean on whether we redirect or not
     */
    const savePost = React.useCallback(async (
        draftSaveOnly: boolean = true,
        preview: boolean = false,
        redirectToUrl: string = "",
        isSubmission: boolean = false,
    ): Promise<boolean> => {
        let success = false;
        try {
            if (!(props.loading || props.saving || props.publishing)) {
                if (!isDraftIdPresent) {
                    const draftId = await createNew();
                    updateId(draftId);
                    success = await save(isSubmission);

                    redirectTo(`/${match.params.tenant}/admin/posts/edit/${draftId}?preview=${preview}`);

                    return true;

                } else {
                    success = await save(isSubmission);
                }
            }
        }
        finally {
            if (success && draftSaveOnly)
                onSaveDraftSuccess(isSubmission);

            if (redirectToUrl) {
                redirectTo(redirectToUrl);
                return true;
            }

            return false;
        }
    }, [match.params.tenant, props.loading, props.saving, props.publishing, isDraftIdPresent, createNew, updateId, save, redirectTo, onSaveDraftSuccess]);

    const publishPostConfirm = React.useCallback(async () => {
        if (props.changedSinceSaved)
            await savePost(false, false);

        // show confirm dialog for seeing available content analyses
        let getPreviewablePost = false;
        if (currentUser.showContentAnalysesDialog && analysisIndicator) {
            setConfirmAnalyticsDialogOpen(true);
        } else {
            getPreviewablePost = true;
            setOneLastCheckOpen(true);
        }

        if (!post.id) return undefined;

        // using api fetch instead of redux because redux clears the post from editor state
        // before then fetching which results in blank inputs for a sec
        const postOverview = getPreviewablePost ? await draftPostsApi.getDraftPost(post.id) : undefined;

        if (postOverview && isMounted())
            setPreviewPost({
                ...postOverview,
                author: { name: postOverview.author, email: postOverview.authorEmail, avatar: { color: "#2196f3" } },
                imageUrl: postOverview.image.url,
            });
    }, [isMounted, props.changedSinceSaved, currentUser.showContentAnalysesDialog, analysisIndicator, post.id, savePost]);

    const publishPost = React.useCallback(async () => {
        // show confirm dialog for re-publishing compliance posts
        if (post.postType === POST_TYPES.COMPLIANCE && post.isPreviouslyPublished) {
            setConfirmRepublishComplianceOpen(true);
        } else {
            await publishPostConfirm();
        }
    }, [post.postType, post.isPreviouslyPublished, publishPostConfirm]);

    const unsubmitPost = React.useCallback(async (postId: string, comments: string | undefined) => {
        try {
            setSaving(true);
            await submissionsApi.unsubmitSubmissionPost(postId, comments);
        }
        finally {
            setSaving(false);
            redirectToManagePosts();
            setChangedSinceSaved(false);
        }
    }, [setSaving, redirectToManagePosts, setChangedSinceSaved]);

    const publishConfirmed = async (): Promise<void> => {
        let date = post.publishTime
            ? new Date(post.publishTime)
            : new Date();

        isContributor
            ? await props.submitPost(match.params.draftId)
            : await props.publishPost(match.params.draftId, date.toISOString());
        
    };

    /**
     * Set settings drawer open to a value or toggle it by leaving parameter undefined
     * @param open
     */
    const onToggleSettingsDrawer = useCallback((open: boolean | undefined): void => {
        if (!fullscreenEditor) {
            // toggle or set as parameter "open"
            setSettingsDrawerOpen(prev => {
                let newValue = !prev;
                if (open !== undefined)
                    newValue = open;

                return newValue;
            });
        }
    }, [fullscreenEditor, setSettingsDrawerOpen]);

    const onPreview = React.useCallback(async () => {
        try {
            // save post - this will redirect if first save
            // otherwise, open previewer
            let redirecting = false;
            if (props.changedSinceSaved) redirecting = await savePost(false, true);

            // grab draft - TODO: merge our post models (PostOverview/Post etc) so we don't have to go to api here
            if (!redirecting) await setupPreviewPost();
        } catch (error) { }
    }, [props.changedSinceSaved, savePost, setupPreviewPost]);

    /**
     * Continue with publish
     * - try save dont show confirm next time
     * - grab post overview model
     * - show one last check
     */
    const onDenyAnalyticsDialog = async () => {
        setConfirmAnalyticsDialogOpen(false);

        await updateUserSettings();

        try {
            if (!post.id) return;

            // using api fetch instead of redux because redux clears the post from editor state
            let postOverview = await draftPostsApi.getDraftPost(post.id);
            if (isMounted())
                setPreviewPost({
                    ...postOverview,
                    author: { name: postOverview.author, email: postOverview.authorEmail, avatar: { color: "#2196f3" } },
                    imageUrl: postOverview.image.url,
                });
        } catch (err) { }
        finally {
            if (isMounted())
                setOneLastCheckOpen(true);
        }
    };

    /**
     * Open up content analyses tab
     */
    const onConfirmAnalyticsDialog = async () => {
        setConfirmAnalyticsDialogOpen(false);
        setSettingsDrawerOpen(true);
        setSelectedSettingTab(POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES);

        await updateUserSettings();
    };

    const onChangeSettingsTab = async (event: React.ChangeEvent, newValue: number) => {
        setSelectedSettingTab(newValue);
    };

    const updateUserSettings = async (): Promise<boolean> => {
        if (currentUser.showContentAnalysesDialog !== dontShowConfirmDialogAgainChecked) return true;

        let res = await props.saveUserSettings({ ...currentUser, showContentAnalysesDialog: !dontShowConfirmDialogAgainChecked });

        return res;
    };

    const onChangeAnalysisIndicator = (value: boolean) => setAnalysisIndicator(value);

    const getCommands = React.useMemo((): JSX.Element =>
        <PostAuthoringActions
            appBarState={{
                isSubmission,
                isContributor,
                isDraftIdPresent,
            }}
            draftState={{
                isValidDraft: isValid,
                isSettingsDrawerOpen: settingsDrawerOpen
            }}
            actions={{
                discardPost: () => setConfirmDiscardPost(true),
                previewPost: onPreview,
                backToManagePosts,
                toggleSettingsDrawer: onToggleSettingsDrawer,
                publishPost,
                savePost,
                rejectSubmission: () => setConfirmRejectSubmission(true),
                openGuidelines: () => setOpenContentGuidelines(true)
            }}
        />, [isSubmission, isContributor, isDraftIdPresent, isValid, settingsDrawerOpen, backToManagePosts, onToggleSettingsDrawer, publishPost, onPreview, savePost]);

    const getDesktopPreview = () => (
        <div className="post-preview">
            {previewPost &&
                <PostContentView
                    tinyMceCustomCssEnabled={tenantSettings.showFeatures.tinyMceCustomCssEnabled}
                    reactions={tenantSettings.reactions.filter((t) => t.enabled).map((t) => ({ ...t, order: t.sortIndex }))}
                    reactionsEnabledOnTenant={tenantSettings.reactionsEnabled}
                    commentsEnabledOnTenant={tenantSettings.commentsEnabled}
                    post={previewPost}
                    lcidMappings={props.lcidMappings}
                    defaultLcid={props.defaultLang}
                />}
        </div>
    );

    const getBackToManagePostsConfirm = (): JSX.Element => (
        <ConfirmDialog
            title={BACK_POST_TEXT}
            confirmLabel="KEEP EDITING" // this is backwards on purpose
            denyLabel="EXIT"
            open={unsavedChangesWarning}
            onClose={() => setUnsavedChangesWarning(false)}
            onConfirm={() => setUnsavedChangesWarning(false)}
            onDeny={redirectToManagePosts}
        >
            <div style={{ minWidth: 400 }}>
                <div>
                    You have unsaved changes to your post.</div>
                <br />
                <div>Do you want to continue?</div>
            </div>
        </ConfirmDialog>
    );

    const getContentAnalysesConfirm = (): JSX.Element => (
        <ConfirmDialog
            title="Boost your readership and engagement!"
            confirmLabel="VIEW ANALYSIS"
            denyLabel={`CONTINUE TO ${getPublishBtnName(isContributor, isSubmission, isDraftIdPresent).toUpperCase()}`}
            open={confirmAnalyticsDialogOpen}
            onClose={() => setConfirmAnalyticsDialogOpen(false)}
            onConfirm={onConfirmAnalyticsDialog}
            onDeny={onDenyAnalyticsDialog}
        >
            <>
                We analyzed your content and found recommendations that can help you improve its reach and engagement.
                <br />
                <br />
                Do you want to see the content analysis?
                <br />
                <br />
                <FormControlLabel
                    classes={{
                        label: "font-14"
                    }}
                    label="Don't show this again"
                    control={<Checkbox size="small" color="primary" checked={dontShowConfirmDialogAgainChecked} onChange={(_, checked) => setDontShowConfirmDialogAgainChecked(checked)} />}
                />
            </>
        </ConfirmDialog>
    );

    const getDiscardPostConfirm = (): JSX.Element => (
        <ConfirmDialog
            title="Discard Post"
            confirmLabel={DRAFT_CHANGED_BTN_TEXT}
            denyLabel={DISCARD_BTN_TEXT}
            open={confirmDiscardPost}
            onClose={() => setConfirmDiscardPost(false)}
            onConfirm={async () => {
                setConfirmDiscardPost(false);
                await savePost(false, false, `/${match.params.tenant}/admin/posts`);
            }}
            onDeny={() => {
                setConfirmDiscardPost(false);
                setChangedSinceSaved(false);
                redirectToManagePosts();
            }}
        >
            You have unsaved changes to your draft. Would you like to save these changes before exiting?
        </ConfirmDialog>
    );
    
    const getRejectSubmissionConfirm = (): JSX.Element =>
    <RejectSubmissionDialog
        unsavedChanges={props.changedSinceSaved}
        open={confirmRejectSubmission}
        setOpen={setConfirmRejectSubmission}
        saveProgress={async () => await savePost(false, false, "", true)}
        rejectSubmission={async (comments: string | undefined) => await unsubmitPost(match.params.draftId, comments)}/>

    const getConfirmRepublishCompliancePost = (): JSX.Element =>
        <ConfirmRepublishCompliancePostDialog
            open={confirmRepublishComplianceOpen}
            onClose={() => setConfirmRepublishComplianceOpen(false)}
            onDeny={() => setConfirmRepublishComplianceOpen(false)}
            onConfirm={async () => {
                setConfirmRepublishComplianceOpen(false);
                await publishPostConfirm();
            }}
        />;

    const getPreviewOpenAction = (): (() => void) | undefined => {
        return previewPost?.previouslyPublishedPostId ?
            () => openPostInPortal(tenantId, previewPost.previouslyPublishedPostId) :
            undefined;
    };

    const getPreviewPublishLabel = (): string | undefined => {
        if (!previewPost) return;

        let publishLabel: string | undefined;

        if (isContributor && isSubmission)
            publishLabel = CONTRIBUTOR_REPUBLISH_TEXT;
        else if (isContributor)
            publishLabel = CONTRIBUTOR_PUBLISH_TEXT;
        else if (!isContributor && previewPost.isSubmission)
            publishLabel = SUBMISSION_PUBLISH_TEXT;
        else if (!isContributor && !previewPost.isSubmission)
            publishLabel = DFLT_PUBLISH_TEXT;

        return publishLabel;
    };

    const contentGuidelinePreview = React.useMemo(() => {
        return <ContentGuidelinePreview
            showPreview={openContentGuidelines}
            onClose={() => setOpenContentGuidelines(false)}
            isAuthoring={true}
            hintType={HintType.Post}
        />;
    }, [openContentGuidelines]);

    return (
        <>
            {isLoading || fetchingCustomCss
                ? <Loading />
                : <BasePage fullWidth>
                    <RemoteFocusRequests.Component />
                    <AuthoringAppBar
                        backAction={
                            // back to manage posts goes into button command list (getCommands) on small screens
                            isSmallAndSmaller
                                ? <></>
                                : <BackItem textStyle={{ fontWeight: 500 }} title={BACK_POST_TEXT}
                                    onClick={backToManagePosts}
                                />
                        }
                        actions={getCommands}
                        contentGuidelinesOpen={openContentGuidelines}
                    />
                    <>
                        <div className="authoring-page" style={{ height: "100%", marginTop: 52 }}>
                            <PickerLocalization>
                                <PostEditor
                                    customCss={customCss}
                                    fullscreenEditor={fullscreenEditor}
                                    onChangeFullscreen={() => {
                                        setFullscreenEditor(prev => !prev);
                                        setSettingsDrawerOpen(false);
                                    }}
                                    selectedTab={selectedSettingsTab}
                                    onChangeTab={onChangeSettingsTab}
                                    onToggleSettingsDrawer={onToggleSettingsDrawer}
                                    settingsDrawerOpen={settingsDrawerOpen}
                                    isContributor={isContributor}
                                    analysisIndicator={analysisIndicator}
                                    onChangeAnalysisIndicator={onChangeAnalysisIndicator}
                                    isTenantNotifsEnabled={isTenantNotifsEnabled}
                                />
                            </PickerLocalization>
                            <LoadingOverlay show={props.loading || props.saving || props.publishing} />
                            <div className="clearfix"></div>
                        </div>
                    </>
                </BasePage>}
            <SuccessSnackbar
                successMessage={successMessage || ""}
                clearSuccessMessage={() => setSuccessMessage("")}
            />
            <OneLastCheck
                tags={post.tags?.map((tag) => tag.id)}
                open={oneLastCheckOpen}
                publishTime={post.publishTime}
                expiryTime={post.expiryTime}
                featuredTime={post.featuredTime}
                breakingTime={post.breakingTime}
                tenantSettings={tenantSettings}
                tenantNotificationSettings={notificationSettings}
                onClose={() => setOneLastCheckOpen(false)}
                onPublish={publishConfirmed}
                notifications={post.notifications}
                commentsEnabledOnContent={post.commentingEnabled}
                reactionsEnabledOnContent={post.reactingEnabled}
                commentType={post.commentingType}
                isPreviouslyPublished={post.isPreviouslyPublished}
                disableKeepEditing={props.publishing}
                isContributor={isContributor}
                publishBtnName={getPublishBtnName(isContributor, isSubmission, isDraftIdPresent)}
            >
                <div className="post-preview">
                    {previewPost &&
                        <PostContentView
                            tinyMceCustomCssEnabled={tenantSettings.showFeatures.tinyMceCustomCssEnabled}
                            showComments={false}
                            showReactions={false}
                            reactions={tenantSettings.reactions.filter((t) => t.enabled).map((t) => ({ ...t, order: t.sortIndex }))}
                            reactionsEnabledOnTenant={tenantSettings.reactionsEnabled}
                            commentsEnabledOnTenant={tenantSettings.commentsEnabled}
                            post={previewPost}
                            lcidMappings={props.lcidMappings}
                            defaultLcid={props.defaultLang}
                        />}
                </div>
            </OneLastCheck>
            <Preview
                open={previewOpen}
                onClose={() => setPreviewOpen(false)}
                previews={[{ type: PreviewType.Desktop, label: "DESKTOP", component: getDesktopPreview() }]}
                publishLabel={getPreviewPublishLabel()}
                publishAction={publishConfirmed}
                isLoading={props.publishing || props.submitting}
                disablePublish={!isValid}
                openAction={getPreviewOpenAction()}
                openLabel="VIEW PUBLISHED VERSION"
            />
            {getContentAnalysesConfirm()}
            {getBackToManagePostsConfirm()}
            {getDiscardPostConfirm()}
            {getConfirmRepublishCompliancePost()}
            {getRejectSubmissionConfirm()}
            {contentGuidelinePreview}
        </>
    );
};

interface RouteParams {
    tenant: string;
    draftId: string;
}

interface ComponentProps {
    intl: IntlShape;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        currentUser: state.settings.currentUser,
        notificationSettings: state.settings.notificationSettings,
        tenantSettings: state.settings.tenantSettings,
        post: state.posts.editor.post,
        saving: state.posts.editor.saving,
        loading: state.posts.editor.fetching,
        publishing: state.posts.publishing,
        submitting: state.posts.submitting,
        changedSinceSaved: state.posts.editor.changedSinceSaved,
        publishValidationErrors: Object.keys(state.posts.publishValidationErrors.errors),
        lcidMappings: state.resources.lcidMappings,
        defaultLang: state.settings.tenantSettings ? state.settings.tenantSettings.defaultLCID : "en-us",
        autoHideContentGuidelines: state.settings.currentUser.hideContentGuidelines,
        isCompliance: !!state.posts.editor.post?.postType && state.posts.editor.post?.postType.toLowerCase() === "compliance",
        isValid: state.posts.editor.isValid,
        tagSettings: state.settings.tagSettings,
        tenantId: state.tenant.id,
        guidelines: state.guidelines.guidelines,
        isGuidelineEmpty: state.guidelines.isEmpty
    }),
    {
        createNew: postActions.createNewDraftPost,
        save: postActions.saveDraft,
        redirectTo: push,
        fetchDraftPost: postActions.fetchDraftPost,
        getTagSettings: settingsActions.getTagSettings,
        publishPost: postActions.publishPost,
        submitPost: postActions.submitPost,
        publishSuccess: postActions.publishSuccess,
        setChangedSinceSaved: postActions.SetChangedSinceSaved,
        setSaving: postActions.SetSaving,
        setNewPost: postActions.setNewDraftPost,
        updateId: postActions.updateField("id"),
        updatePostContent: postActions.updateField("translatedContent"),
        saveUserSettings: userActions.saveUserSettings,
        setShouldDisplayNav
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(withRouter(connector(PostCreation)));
