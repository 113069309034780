import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import Tutorial, { Slide } from "modules/common/components/tutorial";

import Cookies, { COMMON_COOKIE_KEYS } from "utils/cookie";


const TUTORIAL_SLIDES: Slide[] = [
  {
    title: "Introducing Portal Pages",
    content: (
      <div>Your company portal now allows you to create custom pages that can display content targeted to the page purpose. For example, easily create an HR page to display HR news, policies, and events. Pages can also be secured to one or more audiences.</div>
    )
  },
  {
    title: "Portal Pages",
    content: (
      <div>
        You can manage, create, edit, or delete your portal pages in the Portal Pages section of your Admin Portal. Please note that only administrators can access this section.
        <br /><br />
        The <span className="emphasis">All</span> tab includes all your enabled, disabled, and draft pages. <span className="emphasis">Enabled</span> is a filtered view of pages that are live, while <span className="emphasis">Disabled</span> lists the inactive pages. <span className="emphasis">Drafts</span> consist of not only pages that are under construction, but also live pages that have saved but unpublished changes to them.
      </div>
    )
  },
  {
    title: "Creating Portal Pages",
    content: (
      <div>
        <span className="emphasis">Step 1.</span> When you create a new portal page, you’ll set the page title, the page URL, and optionally which audiences have access to that page.
        <br /><br />
        To create a private page, specify which audiences you want to grant access to the page. If you don’t restrict your page, the page and its contents will be accessible and visible to all users in your organization.
      </div>
    )
  },
  {
    title: "Creating Portal Pages: Page Editor",
    content: (
      <div>
        <span className="emphasis">Step 2.</span>  After you’ve set up the basic page details, it’s time to customize your page!
        <br /><br />
        The <span className="emphasis">Page Editor</span> previews your portal page and allows you with the help of Portal Page Components to customize its content. <span className="emphasis">Components</span> are widgets that you plugin to your page, they are used to personalize your banner, your page sections, and more.
      </div>
    )
  },
  {
    title: "Global Components",
    content: (
      <div>
        <span className="emphasis">Global Components</span> are reusable and uniform components that you can add to your portal pages. When a global component is edited or modified, changes are reflected in all the pages using the component.
        <br /><br />
        The <span className="emphasis">Navigation Bar</span> is a global component. This component is located at the top of your pages, and helps your employees locate and access portal pages you wish to include on the <span className="emphasis">Navigation List</span>.
        <br /><br />
        <span className="emphasis">Take note!</span> Private pages are only visible to employees that are part of their target audiences.
      </div>
    )
  }
];

const PortalPagesTutorial: React.FunctionComponent<PropsWithRedux> = props => {
  const [showTutorial] = useState(Cookies.isFirstSet(COMMON_COOKIE_KEYS.PORTAL_PAGES_VISITED));

  return (
    <Tutorial openOnLoad={showTutorial} slides={TUTORIAL_SLIDES} imageSrc="/images/tutorials/portal-pages/portal-pages" />
  );
}

interface ComponentProps {}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
  })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagesTutorial);