import React, { useRef, useState } from "react";

import Popover, { PopoverProps } from "@mui/material/Popover";

const HoverText: React.FunctionComponent<ComponentProps> = ({
    label,
    labelContainerClassName,
    labelClassName = "",
    children,
    inline = false,
    underline = true,
    popoverProps,
    paperStyle = { maxWidth: 250 },
    style,
}) => {
    let [isVisible, setIsVisible] = useState(false);
    const popoverRef = useRef(null);

    return (
        <React.Fragment>
            <div
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                ref={popoverRef}
                style={{ display: inline ? "inline" : "block", ...style }}
                className={`${labelContainerClassName || ""}`}
            >
                <span className={`${labelClassName || ""}`} style={underline ? { textDecoration: "underline dotted" } : undefined}>
                    {label}
                </span>
            </div>
            <Popover
                open={isVisible}
                anchorEl={popoverRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                slotProps={{
                    paper: {
                        onMouseEnter: () => setIsVisible(true),
                        onMouseLeave: () => setIsVisible(false),
                        style: paperStyle
                    }
                }}
                style={{ pointerEvents: "none" }}
                onClose={() => setIsVisible(false)}
                classes={{
                    paper: "help-icon-description"
                }}
                {...popoverProps}
            >
                {children}
            </Popover>
        </React.Fragment>
    );
};

interface ComponentProps {
    label: string | JSX.Element;
    labelClassName?: string;
    inline?: boolean;
    labelContainerClassName?: string;
    underline?: boolean;
    popoverProps?: Partial<PopoverProps>;
    paperStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

export default HoverText;
