import React, { useState, useEffect } from "react";

import ChipList from "modules/common/components/chipList";
import { MultiselectMui } from "modules/common/components/forms/inputs/multiselectMui";
import { SelectOption, IdNamePair } from "modules/common/models";
import { IContentBandInputWithContentType } from "./layoutInput";
import { ContentBandContentType } from "modules/contentBands/models";

export interface ITypesInputProps extends IContentBandInputWithContentType<string[]> {
    types: SelectOption[];
}

export const TypesInput: React.FunctionComponent<ITypesInputProps> = ({ idx, value, contentType, types, onChange }) => {
    // keep an internal value so we only update parent when callout closed
    const [internalValue, setInternalValue] = useState<string[]>(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const onChangeProcessor = (key: string) => {
        let newValues = [...internalValue];

        // if key is in values, then we remove, otherwise add key
        const shouldRemove = internalValue.includes(key);
        if (shouldRemove) newValues = internalValue.filter((type: string) => type !== key);
        else newValues = [...newValues, key];

        setInternalValue(newValues);
    };

    const onRemoveProcessor = (item: IdNamePair<string, string>) => {
        let newValues = internalValue.filter((key: string) => item.id !== key);

        onChange(newValues, idx);
    };

    const onClose = () => {
        onChange(internalValue, idx);
    };

    const isEvent = contentType === ContentBandContentType.Event;

    return (
        <>
            <div className="form-group">
                <label>{isEvent ? "Lock to event types" : "Lock to post types"}</label>
                <div className="input grow">
                    <MultiselectMui
                        onClose={onClose}
                        options={types}
                        buttonLabel={"ADD/EDIT TYPES"}
                        selections={internalValue}
                        onChange={onChangeProcessor}
                        contentClassName="height-auto"
                    />
                </div>
            </div>
            <div className="form-group offset">
                <div className="chip-list">
                    <ChipList
                        closable
                        showEmptyChip
                        emptyStyle={{
                            fontSize: 14,
                        }}
                        defaultLabel={isEvent ? "All event types" : "All post types"}
                        onClose={onRemoveProcessor}
                        truncateAtIndex={4}
                        items={types
                            .filter((opt: SelectOption) => internalValue.includes(opt.key))
                            .map((opt: SelectOption) => ({
                                id: opt.key,
                                name: opt.text,
                                labelStyle: {
                                    fontSize: 14,
                                },
                            }))}
                    />
                </div>
            </div>
        </>
    );
};
