import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState"
import { PropsWithChildren } from "typedActions";
import { UserRoleStrings } from "modules/authorization/models";
import { getHighestRole } from "utils/userRoleUtils";

class RequireRole extends React.Component<PropsWithRedux, {}> {
    public render() {
        const userHighestRole = getHighestRole(this.props.currentUser);
        let userHasRole = false;

        // If we have a strict role, then user's highest role must be equal to the role we are restricting to
        if (this.props.strictRole)
            userHasRole = userHighestRole === this.props.roleName;
        // Users of Sparrow have a hierarchical role structure, so an Owner has all roles
        else if (this.props.roleName === UserRoleStrings.AUTHOR)
            userHasRole = userHighestRole === UserRoleStrings.OWNER ||
                userHighestRole === UserRoleStrings.AUTHOR;
        else if (this.props.roleName === UserRoleStrings.CONTRIBUTOR)
            userHasRole = userHighestRole === UserRoleStrings.OWNER ||
                userHighestRole === UserRoleStrings.AUTHOR ||
                userHighestRole === UserRoleStrings.CONTRIBUTOR;
        else
            userHasRole = userHighestRole === this.props.roleName;

        let renderEle = this.props.unauthorizedElement ?? null;

        if (userHasRole && !this.props.children)
            renderEle = null;
        else if (userHasRole && React.isValidElement(this.props.children))
            renderEle = this.props.children;
        else if (userHasRole)
            renderEle = <span>{this.props.children}</span>

        return renderEle;
    }
}

interface ComponentProps extends PropsWithChildren {
    roleName: UserRoleStrings;
    unauthorizedElement?: React.ReactElement;
    strictRole?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps )=>({
        ...ownProps,
        currentUser: state.settings.currentUser
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(RequireRole);
