import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import { actions } from '../..';

interface ComponentProps {
    title: string; 
    emailEvent: string;
    smsEvent?: string;
    description?: string;
    value?: any;
    textVisible?: boolean;
    smaller?: boolean;
}

const SettingsToggleOnly: React.FC<PropsWithRedux> = ({title, emailEvent, smsEvent, description, value, notificationSettings, textVisible, smaller, toggleEmailChannel}) => {
    return (

        <div>
            {!smaller && 
            <div className="clearfix">
                <div style={{float:"left", display: "inline-block", width: "1000px", fontWeight: "bold"}}>{title} 
                    <span style={{float:"right"}}>
                        <ToggleSwitch 
                            value={notificationSettings.settings[emailEvent].toggle}
                            onClick={() => toggleEmailChannel(emailEvent, !notificationSettings.settings[emailEvent].toggle)}        
                        />
                    </span>
                </div>
            </div>
            }

            {smaller &&
            <div className="clearfix">
                <div style={{float:"left", display: "inline-block", width: "1000px", marginTop: "6px"}}><span style={{}}>{title}</span>
                    <span style={{float:"right"}}>
                        <ToggleSwitch 
                            value={notificationSettings.settings[emailEvent].toggle}
                            onClick={() => toggleEmailChannel(emailEvent, !notificationSettings.settings[emailEvent].toggle)}        
                        />
                    </span>
                </div>
            </div>
            }

            {(textVisible ?? true) &&
                <div style={{margin: "20px 0px"}} className="description-text">{description}</div>
            }
        </div>
    )
}



const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
    }),
    {
        toggleEmailChannel: actions.EnableSelectedChannel
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default (connector(SettingsToggleOnly));