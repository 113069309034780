import * as actions from 'network/actions';
import * as models from 'modules/posts/models';
import { PostFilterValues } from "modules/posts/models";
import cookie from '../utils/cookie'
import { SparrowApi } from './network/adapters/SparrowApi';
import { ApiRequest } from './network';
import { DraftActivity } from 'modules/common/components/authoring/models';
import { AxiosPromise } from 'axios';

export const FetchDraftPosts = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: "/{tenant}/adminapi/v1/drafts/paged",
        queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
        body: JSON.stringify(filters)
    });
}

export const FetchDraftPost = (id: string, imageScale: models.ImageScale = models.ImageScale.Mobile) => {
    return actions.NetworkRequest({
        method: 'GET',
        url: '/{tenant}/adminapi/v1/drafts',
        queryParams: { id, imageScale }
    });
}

export const FetchExpiredPosts = (pageNumber: number, filters: Partial<PostFilterValues>) => {
    //TODO: Once API is available
}

export const FetchPublishedPosts = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: "/{tenant}/adminapi/v1/posts/paged",
        queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
        body: JSON.stringify({ 
            ...filters, 
            olderThan: !filters.olderThan?
                new Date().toISOString() : filters.olderThan
        })
    });
}

export const FetchScheduledPosts = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: "/{tenant}/adminapi/v1/posts/paged",
        queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
        body: JSON.stringify({ 
            ...filters, 
            newerThan: !filters.newerThan ?
                new Date().toISOString() : filters.newerThan
        })
    });
}

export const FetchAllPost = (pageNumber: number, filters: Partial<PostFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: "/{tenant}/adminapi/v1/allposts/paged",
        queryParams: { "pageAmount": rowsPerPage, "pageNumber": pageNumber },
        body: JSON.stringify({ ...filters })
    });
}

export const CreateNewPost = () => {
    return actions.NetworkRequest({
        method: 'POST',
        url: '/{tenant}/adminapi/v1/drafts/newDraft',
        queryParams: { postType: "standard" }
    })
}

export const CreateClonedPost = (draftId: string) => {
    return actions.NetworkRequest({
        method: 'POST',
        url: '/{tenant}/adminapi/v1/drafts/clonedPost',
        queryParams: { isDraft: "true", id: draftId }
    });
}

export const DeleteDraftPost = (id: string) => {
    return actions.NetworkRequest({
        method: 'DELETE',
        url: '/{tenant}/adminapi/v1/drafts',
        queryParams: { id: id }
    })
}

export const DeleteDraftOnlyPost = (id: string) => {
    return actions.NetworkRequest({
        method: 'DELETE',
        url: '/{tenant}/adminapi/v1/drafts/delete/{id}',
        params: { id: id }
    })
}

export const FetchImageForId = (id: string) => {
    return actions.NetworkRequest({
        method: 'GET',
        url: '/{tenant}/ospreyapi/v1/images/{id}',
        params: { id: id }
    })
}

export const FetchDocumentForId = (id: string) => {
    return actions.NetworkRequest({
        method: 'GET',
        url: '/{tenant}/ospreyapi/v1/files/doc/{id}',
        params: { id: id }
    })
}

export const SaveDraftPost = (post: Partial<models.Post>, isSubmission: boolean = false) => {
    const image = !!post.image ? post.image : { id: "", transforms: "" }
    const video = !!post.video ? post.video : { id: "" }

    const body = {
        id: post.id,
        content: {
            author: post.author,
            authorEmail: post.authorEmail,
            imageId: image.id,
            imageTransforms: image.transforms,
            videoId: video.id,
            commentingEnabled: post.commentingEnabled && post.postType !== "compliance",
            commentingType: post.commentingType,
            publishTime: post.publishTime,
            expiryTime: post.expiryTime,
            breakingTime: post.breakingTime,
            featuredTime: post.featuredTime,
            notifications: post.notifications,
            tags: post.tags!.map(t => t.id),
            postType: post.postType,
            translatedContent: post.translatedContent,
            attachedContent: post.attachedContent,
            fileAttachments: post.fileAttachments,
            reactingEnabled: post.reactingEnabled && post.postType !== "compliance",
        },
        isSubmission
    };

    return actions.NetworkRequest({
        method: 'POST',
        url: '/{tenant}/adminapi/v1/drafts',
        body: body
    });
}

export const GetContentAnalysis = (id: string, postText: string, includeHighlightAnalysis: boolean = false) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/contentanalysis/${id}?platform=Web`,
        body: JSON.stringify({
            postText,
            includeHighlightAnalysis,
        })
    });
}

export const GetDraftTranslation = (id: string, content: models.TranslatablePostContent, outputLCID: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/api/v1/cognitiveservices/translatortext/translate/${id}?bodyFormat=html&outputLCID=${outputLCID}&type=post&platform=Web`,
        body: JSON.stringify({
            ...content
        })
    });
}

export const PublishPost = (id: string, publishingTime?: string) => {
    return actions.NetworkRequest({
        method: 'POST',
        url: '/{tenant}/adminapi/v1/drafts/publishing/{id}',
        params: { id: id },
        body: publishingTime ? { publishingTime: publishingTime } : {}
    });
}

export const UnpublishPost = (postId: string) => {
    return actions.NetworkRequest({
        method: 'DELETE',
        url: '/{tenant}/adminapi/v1/drafts/unpublish/{id}',
        params: { id: postId }
    });
}

export const SubmitPost = (postId: string) => {
    return actions.NetworkRequest({
        method: 'POST',
        url: '/{tenant}/adminapi/v1/drafts/submitting/{id}',
        params: {id: postId}
    });
}

export class DraftPostsApi {
    constructor(private _sparrowApi: SparrowApi) {}

    public getDraftPost = (draftId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest().get(`/{tenant}/adminapi/v1/drafts?id=${draftId}`)
        );
    }

    public getPublishedFromDraftId = (id: string) : AxiosPromise<string>=> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/drafts/published', { params: { id }})
        ).catch((err) => {
            throw err;
        });
    }

    public getDraftActivity = (id: string) : ApiRequest<DraftActivity[]>=> {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/drafts/activity', { params: { id }})
        )
    }
}
