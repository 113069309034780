import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

import { draftEventsApi } from 'api/instances';
import { ContentType } from 'modules/common/models';
import PublishedContentDialog from '../../../common/components/authoring/dialogs/publishedContentDialog';
import { useRetryableRequest } from 'modules/common/hooks/useRetryableRequest';

const MAX_RETRY_COUNT = 3;
const PublishedEventDialog: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {

    const fetchEventId = async () => 
        await draftEventsApi.getPublishedFromDraftId(props.draftId ?? "");
    
    const { response, isFailed } = useRetryableRequest<string>({
        asyncRequest: fetchEventId,
        maxRetry: MAX_RETRY_COUNT,
        requesting: !!props.draftId
    });

    return (
        <PublishedContentDialog
            content={ContentType.Event}
            expectedPublishTime={props.expectedPublishTime?.date.toString() ?? ""}
            contentId={response}
            hideFeedRedirect={isFailed}
        />
    );
};

interface ComponentProps {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        expectedPublishTime: state.events.pendingPublishing?.publishedTime,
        draftId: state.events.pendingPublishing?.draftId
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PublishedEventDialog);