import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { AttachedArticle, DateInformation, Document, DocumentsFilterValues, DocumentsListingPage, DocumentState } from "../../models";
import moment from "moment";

import { documentsApi } from "api/instances";

import AudienceChipLabels from "modules/common/components/chips/audienceChipLabels";
import CategoryChipLabels from "modules/common/components/chips/categoryTagChipLabels";
import Loading from "modules/common/components/loading";
import LoadingOverlay from "modules/common/components/loadingOverlay";

import MoreDocumentOptions from "../action-buttons/moreDocumentOptions";
import DocumentEditor from "../document-creation/documentEditor";
import DocumentFilters from "../document-filters/documentFilters";
import DocumentPreview from "../document-views/documentPreview";

import { getMimeType } from "../../utilities/getMimeType";

import TabContent from "pages/common/tabContent";
import TabContentToolbar from "pages/common/tabContentToolbar";

import confirm from "utils/notyPopups";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Paging from "modules/common/components/paging";
import { SortStyle } from "utils/managementUtils";
import Cookies from "js-cookie";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import HoverText from "../action-buttons/hoverText";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import { FileDownloader } from "utils/fileDownloader";
import { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";

interface HeaderCell {
  disablePadding: boolean;
  id: string;
  label: string | JSX.Element;
  ascValue?: SortStyle;
  descValue?: SortStyle;
  clickable?: boolean;
}


class DocumentList extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      documentFile: undefined,
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      isDownloading: false,
      isPagedLoad: false,
      previewUrl: "",
      selectedItems: [],
      showEditor: false,
      sortMethod: this.props.initialSort
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.page.id !== prevProps.page.id)
      this.setState({ selectedItems: [] });

    if (this.props.hasRefreshed && !prevProps.hasRefreshed) {
      this.setState({ filters: { ...this.getDefaultFilterValues() }, hasFiltersApplied: false });
    }
  }

  public render() {
    const { page } = this.props;

    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (page.isFetching && !this.state.isPagedLoad)
      return <Loading />;
      
    return (
      <div className="documents-list">
        {this.getToolbar()}
        <TabContent>
          {this.getFilter()}
          {this.getList()}
          <DocumentEditor onSuccess={this.onClearAllDocuments} />
          <DocumentPreview onSuccess={this.onClearAllDocuments} />
          <LoadingOverlay absolute={true} show={this.state.isDownloading} />
        </TabContent>
      </div>
    );
  }

  private formatTime = (time: DateInformation): JSX.Element => {
    if (time.date === "0001-01-01T00:00:00+00:00")
      return (
        <div>
          <span>-</span>
        </div>
      );
    
    return (
      <div>
        <span>{moment(new Date(time.date)).format("MMM D, YYYY, h:mmA")}</span>
      </div>
    );
  }

  private formatLanguage = (lcid: string): string => {
    if (!lcid)
      return "-";

    if (!this.props.lcidMappings || !this.props.lcidMappings[lcid])
      return lcid;

    return this.props.lcidMappings[lcid].language;
  }

  private formatState = (state: DocumentState): JSX.Element => {
    if (state === "disabled")
      return <span className="document-state grey">Disabled</span>;
    else if (state === "scheduled")
      return <span className="document-state yellow">Scheduled</span>;
    else
      return <span className="document-state green">Enabled</span>;
  }

  private formatViews = (totalDownloads: number, uniqueDownloads: number): JSX.Element => {
    if (!totalDownloads && !uniqueDownloads)
      return <div>-</div>;

    return (
      <div className="document-views">
        <div title="Unique views"><PersonIcon fontSize="small" />{uniqueDownloads}</div>
        <div title="Total views"><VisibilityOutlinedIcon fontSize="small" />{totalDownloads}</div>
      </div>
    );
  }


  private getDefaultFilterValues = (): Partial<DocumentsFilterValues> => {
    const filters: Partial<DocumentsFilterValues> = {
      audiences: [],
      availabilities: [],
      lcids: [],
      newerThan: "",
      olderThan: "",
      states: [],
      tags: [],
      textToSearch: "",
      sortType: SortStyle.publishDesc
    };
    return filters;
  }


  private getFilter = (): JSX.Element => {
    return (
      <DocumentFilters
        id={this.props.page.id}
        filters={this.state.filters}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
      />
    );
  }

  //Default to descending, if currently descending make it ascending.
  private onClickHeader = (descValue: SortStyle | undefined, ascValue: SortStyle | undefined) => {
    const rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);
    
    if(descValue === undefined || ascValue === undefined) {
      return;
    }

    if(this.state.sortMethod === descValue) {
      this.setState({sortMethod: ascValue, filters: {...this.state.filters, sortType: ascValue}}, () => {
        this.onChangePage(0, Number(rowsPerPage) ?? 10);
      });
    }
    else {
      this.setState({sortMethod: descValue, filters: {...this.state.filters, sortType: descValue}}, () => {
        this.onChangePage(0, Number(rowsPerPage) ?? 10);
      });
    }
  }

  private getHeader = (): HeaderCell[] => {
    const { page } = this.props;

    let header: HeaderCell[] = [
      { id: "title", disablePadding: true, label: "Document title", descValue: SortStyle.titleDesc, ascValue: SortStyle.titleAsc, clickable: true },
      { id: "options", disablePadding: true, label: "" }
    ];

    if (page.id === "all")
      header.push({ id: "state", disablePadding: false, label: "State", descValue: SortStyle.stateDesc, ascValue: SortStyle.stateAsc, clickable: true });

    if (page.id === "all" || page.id === "enabled")
      header.push({ id: "publishDate", disablePadding: false, label: "Publish date", descValue: SortStyle.publishDesc, ascValue: SortStyle.publishAsc, clickable: true });
    if (page.id === "scheduled")
      header.push({ id: "publishDate", disablePadding: false, label: "Scheduled for", descValue: SortStyle.publishDesc, ascValue: SortStyle.publishAsc, clickable: true });
    if (page.id === "disabled")
      header.push({ id: "disabledDate", disablePadding: false, label: "Disabled on", descValue: SortStyle.disableDesc, ascValue: SortStyle.disableAsc, clickable: true });

    header.push({ id: "lastModifiedBy", disablePadding: false, label: "Last modified by", descValue: SortStyle.authorDesc, ascValue: SortStyle.authorAsc, clickable: true });
    header.push({ id: "language", disablePadding: false, label: "Language", descValue: SortStyle.languageDesc, ascValue: SortStyle.languageAsc, clickable: true });
    header.push({ id: "categoryTags", disablePadding: false, label: "Category tags" });
    header.push({ id: "audiences", disablePadding: false, label: "Accessible to" });
    header.push({ id: "views", disablePadding: false, label: (
      <div className="header-view">
        <HoverText
          label="Views"
        >
          <div>These are the unique views and total views of each document.</div>
        </HoverText>
      </div>
    ), descValue: SortStyle.viewsDesc, ascValue: SortStyle.viewsAsc, clickable: true});
    
    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;
    const { selectedItems } = this.state;

    const rowCount = page.documents.length || 10;

    if (page.isFetching)
      return <Loading />;
    
    if (!page.documents.length)
      return (
        <ManagementEmptyResults searchType={"Documents"} hasFilters={this.state.hasFiltersApplied}/>
      );

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied &&
          <Typography variant="h2" className="document-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                    checked={rowCount > 0 && selectedItems.length === rowCount}
                    onChange={this.onSelectAllDocuments}
                    inputProps={{ "aria-label": "select all documents" }}
                  />
                </TableCell>
                {this.getHeader().map((headerCell) => {
                  if (headerCell.id === "options")
                    return <TableCell key={headerCell.id} padding="checkbox"></TableCell>

                  return (
                    <TableCell
                      key={headerCell.id}
                      align={headerCell.disablePadding ? "left" : "center"}
                      padding={headerCell.disablePadding ? "none" : "normal"}
                      onClick={() => this.onClickHeader(headerCell.descValue, headerCell.ascValue)}
                      style={{cursor: headerCell.clickable ? "pointer" : ""}}
                    >
                      {headerCell.label}
                      {this.state.sortMethod === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{position: "relative", top: "8px", paddingLeft: "2px"}}/>}
                      {this.state.sortMethod === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{position: "relative", top: "8px", paddingLeft: "2px"}}/>}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {page.documents
                .map((document, index) => this.getListItem(document, index))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
            currentPage={page.currentPage}
            items={page.documents}
            totalItems={page.totalDocuments}
            totalPages={page.totalPages}
            onChangePage={this.onChangePage}
            resetSelection={this.resetSelection}
        />
      </React.Fragment>
    );
  }

  private getListItem = (document: Document, index: number): JSX.Element => {
    const isDocumentSelected = this.isDocumentSelected(document);
    const labelId = `document-list-checkbox-${index}`;

    return (
      <React.Fragment key={document.id}>
        <TableRow
          hover
          onClick={() => this.onEditDocument(document)}
          role="checkbox"
          aria-checked={isDocumentSelected}
          tabIndex={-1}
          key={document.id}
          selected={isDocumentSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isDocumentSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(ev) => this.onSelectDocument(ev, document)}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">{document.title || `${document.fileName}.${document.fileType}`}</TableCell>
          <TableCell padding="checkbox">
            <MoreDocumentOptions
              document={document}
              onDownloadDocument={() => this.onDownloadDocument(document)}
              onPreviewDocument={() => this.onPreviewDocument(document)}
              onSelection={this.onClearAllDocuments}
            />
          </TableCell>
          {this.props.page.id === "all" && <TableCell align="center">{this.formatState(document.state)}</TableCell>}
          {this.props.page.id !== "disabled"
            ? <TableCell align="center">{this.formatTime(document.published)}</TableCell>
            : <TableCell align="center">{this.formatTime(document.disabled)}</TableCell>
          }
          <TableCell align="center">{document.updated.userName || "-"}</TableCell>
          <TableCell align="center">{this.formatLanguage(document.lcid)}</TableCell>
          <TableCell align="center"><CategoryChipLabels tags={document.tags} /></TableCell>
          <TableCell align="center"><AudienceChipLabels audienceIds={document.audiences.map((audience) => audience.id)} audiences={this.props.audiences} /></TableCell>
          <TableCell align="center">{this.formatViews(document.totalDownloads, document.uniqueDownloads)}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  private getToolbar = (): JSX.Element => {
    const { selectedItems } = this.state;

    if (!selectedItems.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllDocuments)}
        </div>
        <div>
          {selectedItems.length === 1 &&
            <React.Fragment>
              {this.getToolbarIcon("Download file", <SaveAltIcon />, () => this.onDownloadDocument(selectedItems[0]))}
              {this.getToolbarIcon("Edit/replace document", <EditIcon />, () => this.onEditDocument(selectedItems[0]))}
              {(selectedItems[0].fileType === "jpeg" || selectedItems[0].fileType === "jpg" || selectedItems[0].fileType === "pdf" || selectedItems[0].fileType === "png" || selectedItems[0].fileType === "txt") &&
                this.getToolbarIcon("Preview", <VisibilityIcon />, () => this.onPreviewDocument(selectedItems[0]))
              }
              {selectedItems[0].state !== "enabled" && this.getToolbarIcon("Enable", <CheckCircleOutlineIcon />, this.onEnableDocument)}
              {selectedItems[0].state === "enabled" && this.getToolbarIcon("Disable", <BlockIcon style={{ transform: "rotate(90deg)" }} />, this.onDisableDocument)}
            </React.Fragment>
          }
          {this.getToolbarIcon("Delete", <DeleteIcon />, this.onDeleteDocument)}
        </div>
      </TabContentToolbar>
    );
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }

  private isDocumentSelected = (document: Document): boolean => {
    return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === document.id) !== -1;
  }

  private onChangeFilters = (filters: Partial<DocumentsFilterValues>) => {
    const updatedFilters = { ...this.state.filters, ...filters };
    this.setState({ filters: updatedFilters, hasFiltersApplied: true, isPagedLoad: true });
    this.props.fetchPage(1, updatedFilters);
  }

  private onClearFilters = () => {
    this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
    this.props.fetchPage(1, {});
  }

  private onClearAllDocuments = () => {
    this.setState({ selectedItems: [] });
  }

  private onDeleteDocument = () => {
    Promise.all(this.state.selectedItems.map((selectedItem) => this.props.getAttachedArticles(selectedItem.id))).then(async (results) => {
      if (this.state.selectedItems.length === 1) {
        if (!!results && !!results[0] && !!results[0].totalArticles) {
          const result = results[0];
          confirm.show({
            text: (
              <div>
                <div>This document is attached to {result.totalArticles} item(s):</div>
                <ul>
                  {result.articles.map((article, index) => {
                    const text: string = `${article.title} (${article.articleType.charAt(0).toUpperCase()}${article.articleType.substr(1)})`;
                    return (
                      <li key={index}>
                        {article.articleType === "post"
                          ? <span
                              onClick={() => {
                                const location: string = window.location.href;
                                const segments: string[] = location.split(this.props.tenant);
                                window.open(`${segments[0]}${this.props.tenant}/admin/${article.articleType}s/${article.id}`);
                              }}
                              className="documents-attached-article"
                            >
                              {text}
                            </span>
                          : <span>{text}</span>
                        }
                      </li>
                    );
                  })}
                </ul>
                <div>Are you sure you want to delete this? You cannot undo this action.</div>
              </div>
            ),
            title: "Document in use",
            yesText: "Ok",
            hideNo: true
          });
        } else {
          let functionalTitle = this.state.selectedItems[0].title === "" ? `${this.state.selectedItems[0].fileName}.${this.state.selectedItems[0].fileType}` : this.state.selectedItems[0].title;
          if (await confirm.show({
            text: `You're about to permanently delete '${functionalTitle}'. You cannot undo this action. Are you sure?`,
            title: "Delete Document",
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
          })) {
            this.props.deleteDocument(this.state.selectedItems[0].id).then((succeeded) => {
              if (succeeded)
                this.onClearAllDocuments();
            });
          }
        }
      } else {
        const totalArticles = (results || []).reduce((sum, article) => sum += article.totalArticles, 0);
        if (!!results && !!totalArticles) {
          const articles: AttachedArticle[] = results.reduce((acc: AttachedArticle[], article) => acc.concat(article.articles), []);
          const affectedDocuments: number = results.filter(result => !!result.totalArticles).length;
          confirm.show({
            text: (
              <div>
                <div>{affectedDocuments} of your {this.state.selectedItems.length} selected documents are attached to other items. These document(s) are attached to the following items:</div>
                <ul>
                  {articles.filter((article, index) => articles.findIndex(searchArticle => searchArticle.id === article.id) === index).map((article, index) => {
                    const text: string = `${article.title} (${article.articleType.charAt(0).toUpperCase()}${article.articleType.substr(1)})`;
                    return (
                      <li key={index}>
                        {article.articleType === "post"
                          ? <span
                              onClick={() => {
                                const location: string = window.location.href;
                                const segments: string[] = location.split(this.props.tenant);
                                window.open(`${segments[0]}${this.props.tenant}/admin/${article.articleType}s/${article.id}`);
                              }}
                              className="documents-attached-article"
                            >
                              {text}
                            </span>
                          : <span>{text}</span>
                        }
                      </li>
                    );
                  })}
                </ul>
                <div>Are you sure you want to delete this? You cannot undo this action.</div>
              </div>
            ),
            title: "Document(s) in use",
            yesText: "Ok",
            hideNo: true
          });
        } else {
          if (await confirm.show({
            text: "You're about to permanently delete these documents. You cannot undo this action. Are you sure?",
            title: "Delete Documents",
            yesColor: "#a80000",
            yesText: "Delete",
            noText: "Cancel"
          })) {
            this.props.deleteDocuments(this.state.selectedItems.map((selectedItem) => selectedItem.id)).then((succeeded) => {
              if (succeeded)
                this.onClearAllDocuments();
            });
          }
        }
      }
    });
  }

  private onDisableDocument = () => {
    this.props.disableDocument(this.state.selectedItems[0].id).then(() => {
      this.onClearAllDocuments();
    });
  }

  private onDownloadDocument = (selectedDocument: Document) => {
    this.setState({ isDownloading: true });
    const file = {
      name: `${selectedDocument.fileName}.${selectedDocument.fileType}`
    };
    documentsApi.GetFile(selectedDocument.id)
      .then((data) => new FileDownloader(file).downloadDoc(data))
      .then(_ => this.setState({ isDownloading: false }))
      .catch(_ => this.setState({ isDownloading: false }))
  }

  private onEnableDocument = () => {
    this.props.enableDocument(this.state.selectedItems[0].id).then(() => {
      this.onClearAllDocuments();
    });
  }

  private onEditDocument = (selectedDocument: Document) => {
    this.props.showEditor(selectedDocument);
  }
  
  private onPreviewDocument = (selectedDocument: Document) => {
    this.setState({ isDownloading: true });
    documentsApi.GetFile(selectedDocument.id).then((file) => {
      var blob = new Blob([file], { type: getMimeType(selectedDocument.fileType) });
      const fileURL = URL.createObjectURL(blob);
      this.props.showPreview({ ...selectedDocument, fileUrl: fileURL });
      this.setState({ isDownloading: false });
    });
  }

  private onSelectAllDocuments = () => {
    if (this.state.selectedItems.length === this.props.page.documents.length)
      this.setState({ selectedItems: [] });
    else
      this.setState({ selectedItems: this.props.page.documents });
  }

  private onSelectDocument = (ev, document: Document) => {
    ev.stopPropagation();

    let selectedItems = this.state.selectedItems.slice();

    if (selectedItems.findIndex((selectedItem) => selectedItem.id === document.id) === -1)
      selectedItems = selectedItems.concat([document]);
    else
      selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== document.id);

    this.setState({ selectedItems });
  }

  private resetSelection = () => {
    this.setState({selectedItems: []});
  }

  private onChangePage = (page: number, rowsPerPage: number) => {
    this.props.fetchPage(page + 1, this.state.filters, rowsPerPage);
  }
}

interface ComponentProps {
  show: boolean;
  page: DocumentsListingPage;
  hasRefreshed?: boolean;
  fetchPage: (pageNumber: number, filters: Partial<DocumentsFilterValues>, rowsPerPage?: number) => void;
  initialSort: SortStyle;
}

interface ComponentState {
  documentFile: Document | undefined;
  filters: Partial<DocumentsFilterValues>;
  hasFiltersApplied: boolean;
  isDownloading: boolean;
  isPagedLoad: boolean;
  previewUrl: string;
  selectedItems: Document[];
  showEditor: boolean;
  sortMethod: SortStyle;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    audiences: state.audiences.audiences,
    lcidMappings: state.resources.lcidMappings,
    tenant: state.tenant.id
  }),
  {
    deleteDocument: actions.deleteDocument,
    deleteDocuments: actions.deleteDocuments,
    disableDocument: actions.disableDocument,
    enableDocument: actions.enableDocument,
    getAttachedArticles: actions.getAttachedArticles,
    redirectTo: push,
    showEditor: actions.showEditor,
    showPreview: actions.showPreview
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DocumentList);