import React from "react";

import { IconButton, Link, SvgIcon } from "@mui/material";
import { ITileButton, TileButtonFlexContainer } from "modules/common/buttons/tileButton";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SmartContentFooter } from "./smartContentFooter";

interface ISmartContentHomeProps {
    onClose: () => void;
    onOpenReadabilitySettings: () => void;
    buttons: ITileButton[];
}

const SmartContentHome: React.FunctionComponent<ISmartContentHomeProps> = ({
    onClose,
    onOpenReadabilitySettings,
    buttons
}) => {
    return (
        <>
            <div className="smart-content-content">
                <div className="row" style={{ textAlign: "right" }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon id="smart-content-close" />
                    </IconButton>
                </div>
                <div className="padding row" style={{ position: "relative", bottom: 15 }}>
                    <SvgIcon viewBox="0 0 50 50" style={{ fontSize: 42 }}>
                        <defs>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="21" y1="0" x2="21" y2="43" id="LinearGradient23">
                                <stop id="Stop24" stopColor="#aa214e" offset="0" />
                                <stop id="Stop25" stopColor="#1b386b" offset="1" />
                            </linearGradient>
                        </defs>
                        <g transform="matrix(1 0 0 1 -19 -31 )">
                            <path d="M 36.98863636363637 9.794444444444444  L 34.93636363636364 14.572222222222223  L 32.884090909090915 9.794444444444444  L 27.92045454545455 7.596666666666666  L 32.884090909090915 5.446666666666666  L 34.93636363636364 0.907777777777777  L 36.98863636363637 5.446666666666666  L 41.95227272727273 7.596666666666666  L 36.98863636363637 9.794444444444444  Z M 36.98863636363637 38.413333333333334  L 34.93636363636364 43  L 32.884090909090915 38.413333333333334  L 27.92045454545455 36.263333333333335  L 32.884090909090915 34.11333333333334  L 34.93636363636364 29.287777777777777  L 36.98863636363637 34.11333333333334  L 41.95227272727273 36.263333333333335  L 36.98863636363637 38.413333333333334  Z M 18.42272727272727 26.23  L 13.984090909090911 35.642222222222216  L 9.59318181818182 26.229999999999993  L 0 21.93  L 9.593181818181819 17.63  L 13.98409090909091 8.265555555555554  L 18.42272727272727 17.63  L 27.96818181818182 21.93  L 18.42272727272727 26.23  Z M 11.740909090909096 23.984444444444442  L 13.984090909090911 28.571111111111108  L 16.275000000000002 23.984444444444442  L 20.95227272727273 21.93  L 16.275000000000006 19.875555555555557  L 13.984090909090913 15.28888888888889  L 11.740909090909097 19.875555555555557  L 7.015909090909096 21.93  L 11.740909090909096 23.984444444444442  Z " fillRule="nonzero" fill="url(#LinearGradient23)" stroke="none" transform="matrix(1 0 0 1 19 31 )" />
                        </g>
                    </SvgIcon>
                </div>
                <div className="padding row">
                    <p className="title">
                        Welcome to Smart Content!
                    </p>
                    <p className="blurb">
                        Smart Content uses AI to help you create and optimize your content.
                        <br />
                        <br />

                        Generate ideas, titles, summaries, or complete articles based on topics and keywords. Sparrow’s AI companion utilizes your analytics to match tone, voice and create engaging content for your organization.
                        <br />
                        <Link target="_blank" href="https://support.sparrowconnected.com/en/smart-content-ai">
                            Learn more about Smart Content.
                        </Link>
                        <br />
                        <br />
                        Let's start with one of the tools below
                    </p>
                </div>
                <div className="padding row">
                    <TileButtonFlexContainer
                        buttons={buttons}
                    />
                </div>
            </div>
            <SmartContentFooter>
                <span>Open Readability Settings</span>
                <IconButton
                    id="smart-content-open-readability-settings"
                    onClick={onOpenReadabilitySettings}
                >
                    <OpenInNewIcon />
                </IconButton>
            </SmartContentFooter>
        </>
    );
}

export { SmartContentHome };
