import * as React from "react";

import { UserDetails, UserDetailsAudience, UserEdit } from "../../models";

import Disclaimer from "modules/common/components/disclaimer";
import InfoHover from "modules/common/components/hovers/infoHover";
import InputField from "../common/inputField";
import InputPrefixField from "../common/inputPrefixField";
import PhoneInputField from "../common/phoneInputField";
import PasswordInputField from "../common/passwordInputField";
import PronounsInputField from "../common/pronounsInputField";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from '@mui/icons-material/Close';
import EditAudiences from "../common/editAudiences";

class ProfileDetails extends React.Component<IProfileDetailsProps, IProfileDetailsState> {
    constructor(props: IProfileDetailsProps) {
        super(props);

        this.state = {
            currentTab: "standard",
            showMoreTopics: false,
            showMoreAudiences: false,
            smsPhone: props.userDetails.standardDetails.smsPhone,
            userId: props.userDetails.localId.startsWith(props.localAccountPrefix) ? props.userDetails.localId.replace(props.localAccountPrefix, "") : props.userDetails.localId,
        };
    }

    public render() {
        const { userDetails } = this.props;
        const showTopicsOption: boolean = this.checkShouldShowTopicsOption(userDetails.standardDetails.subscribedTags);
        const showAudiencesOption: boolean = this.checkShouldShowAudiencesOption(this.props.selectedAudiences);

        return (
            <React.Fragment>
                <div className="section-heading">
                    <Typography variant="h2">Profile Details</Typography>
                    {!!userDetails.customDetails.length &&
                        <ButtonGroup variant="outlined">
                            <Button variant={this.state.currentTab === "standard" ? "contained" : "outlined"} color="primary" onClick={() => this.setState({ currentTab: "standard" })}>Standard Fields</Button>
                            <Button variant={this.state.currentTab === "custom" ? "contained" : "outlined"} color="primary" onClick={() => this.setState({ currentTab: "custom" })}>Custom Fields</Button>
                        </ButtonGroup>
                    }
                </div>
                {this.state.currentTab === "standard" &&
                    <React.Fragment>
                        <section>
                            <InputField
                                label={"First Name"}
                                required={true}
                                error={this.props.errorFirstname}
                                helperText={this.props.errorFirstname ? "Enter first name" : ''}
                                value={userDetails.standardDetails.firstName}
                                disabled={userDetails.accountCategory !== "social" && userDetails.accountCategory !== "sparrow"}
                                onChange={this.onChangeFirstName}
                            />
                            <InputField
                                label="Preferred Name"
                                value={userDetails.standardDetails.preferredName}
                                disabled={true}
                            />
                            <InputField
                                label={"Last Name"}
                                required={true}
                                error={this.props.errorLastname}
                                helperText={this.props.errorLastname ? "Enter last name" : ''}
                                value={userDetails.standardDetails.lastName}
                                disabled={userDetails.accountCategory !== "social" && userDetails.accountCategory !== "sparrow"}
                                onChange={this.onChangeLastName}
                            />
                            <InputField
                                label="Email"
                                required={true}
                                value={userDetails.standardDetails.email}
                                disabled={userDetails.accountCategory !== "sparrow"}
                                onChange={this.onChangeEmail}
                            />
                            <InputField
                                label="Employee Id"
                                value={userDetails.standardDetails.employeeId}
                                onChange={this.onChangeEmployeeId}
                            />
                            <PhoneInputField
                                changedDescription="Saving a new number will ask the user to re-subscribe to SMS notifications and alerts via SMS."
                                description="If changed, user is required to opt-in the new mobile number to receive SMS notifications."
                                label="Mobile No."
                                value={userDetails.standardDetails.smsPhone}
                                country={"us"}
                                enableSearch={true}
                                autoFormat={true}
                                disabled={this.props.userDetails.isWorkAccount}
                                placeholder="Enter mobile number"
                                onChange={this.onChangePreferredSMSPhone}
                                onVerifySms={this.sendSMSVerifyMessage}
                            />
                        </section>
                        {userDetails.accountCategory === "sparrow" &&
                            <React.Fragment>
                                <Divider light />
                                <section>
                                    <form>
                                        <Disclaimer
                                            icon={<InfoOutlinedIcon />}
                                            text="The Password can only be changed for Sparrow Accounts."
                                        />
                                        {/* only show prefix for v1 sparrow accounts where we do not do auto prefixing (note below) (i.e. sign in with pre-fix + user id) */}
                                        {/* for specific tenants (Belden at time of writing) we handle the prefix behind the scenes using a custom portal url subdomain */}
                                        {userDetails.isLocalIdValidEmail || window.location.host.split(".")[0].toLowerCase() === this.props.localAccountPrefix.toLowerCase()
                                            ? <InputField
                                                required={true}
                                                disabled={true}
                                                label="User ID"
                                                refreshable
                                                value={userDetails.isLocalIdValidEmail ? userDetails.localId : this.state.userId}
                                            />
                                            : <InputPrefixField
                                                label="User ID"
                                                value={this.state.userId}
                                                prefix={this.props.localAccountPrefix}
                                                disabled={true}
                                            />}
                                        <PasswordInputField
                                            error={this.props.errorPassword}
                                            label="Password"
                                            value={this.props.password}
                                            placeholder="Change password"
                                            onChange={this.onChangePassword}
                                            description="Password must be 8-32 characters long and must contain at least one capital letter, one special character and one number."
                                        />
                                    </form>
                                </section>
                            </React.Fragment>
                        }
                        <Divider light />
                        <section>
                            <InputField
                                label="Title"
                                value={userDetails.standardDetails.title.value}
                                disabled={!userDetails.standardDetails.title.isEditable}
                                onChange={this.onChangeTitle}
                            />
                            <InputField
                                label="Reports to"
                                value={userDetails.standardDetails.reportsTo.value}
                                disabled={!userDetails.standardDetails.reportsTo.isEditable}
                                onChange={this.onChangeReportsTo}
                            />
                            <InputField
                                label="Office Location"
                                value={userDetails.standardDetails.officeLocation.value}
                                disabled={!userDetails.standardDetails.officeLocation.isEditable}
                                onChange={this.onChangeLocation}
                            />
                            <InputField
                                label="Country"
                                value={userDetails.standardDetails.country.value}
                                disabled={!userDetails.standardDetails.country.isEditable}
                                onChange={this.onChangeCountry}
                            />
                            <InputField
                                label="Region"
                                value={userDetails.standardDetails.region.value}
                                disabled={!userDetails.standardDetails.region.isEditable}
                                onChange={this.onChangeRegion}
                            />
                            <PronounsInputField
                                label="Pronouns"
                                value={userDetails.standardDetails.pronouns.value}
                                items={[
                                    { value: "she/her/hers", text: "she/her/hers" },
                                    { value: "he/him/his", text: "he/him/his" },
                                    { value: "they/them/their", text: "they/them/their" }
                                ]}
                                disabled={!userDetails.standardDetails.pronouns.isEditable}
                                onChangeValue={this.onChangePronouns}
                            />
                            <InputField
                                label="Bio"
                                value={userDetails.standardDetails.bio.value}
                                multiline
                                disabled={!userDetails.standardDetails.bio.isEditable}
                                onChange={this.onChangeBio}
                            />
                        </section>
                        {!!userDetails &&
                            <React.Fragment>
                                <Divider light />
                                <section>
                                    <div className="section-heading">
                                        <Typography variant="h2">Audiences<InfoHover>These are audiences that this user/account is included in.</InfoHover></Typography>
                                        <EditAudiences audiences={this.props.selectedAudiences} availableAudiences={this.props.includableAudiences} onChange={this.props.onChangeAudiences} />
                                    </div>
                                    <div id="subscribed-tags-bubbles">
                                        {!this.state.showMoreAudiences &&
                                            <React.Fragment>
                                                {this.props.selectedAudiences.slice(0, 9).map((audience, index) => {
                                                    return (
                                                        <Chip
                                                            className="chip-styling"
                                                            key={audience.id}
                                                            label={`${audience.title} (${audience.totalUsers})`}
                                                            onDelete={() => this.removeAudience(audience)}
                                                            deleteIcon={<CloseIcon fontSize="small"/>}
                                                        />
                                                    )
                                                })}
                                                {this.props.selectedAudiences.length === 10 &&
                                                    <Chip
                                                        className="chip-styling"
                                                        key={this.props.selectedAudiences[9].id}
                                                        label={`${this.props.selectedAudiences[9].title} (${this.props.selectedAudiences[9].totalUsers})`}
                                                        onDelete={() => this.removeAudience(this.props.selectedAudiences[9])}
                                                        deleteIcon={<CloseIcon fontSize="small"/>}
                                                    />
                                                }
                                                {this.props.selectedAudiences.length > 10 &&
                                                    <Chip
                                                        className="chip-styling"
                                                        label={`+${this.props.selectedAudiences.length - 10}`}
                                                    />
                                                }
                                            </React.Fragment>
                                        }
                                        {this.state.showMoreAudiences &&
                                            <React.Fragment>
                                                {this.props.selectedAudiences.map((audience, index) => {
                                                    return (
                                                        <Chip
                                                            className="chip-styling"
                                                            key={audience.id}
                                                            label={`${audience.title} (${audience.totalUsers})`}
                                                            onDelete={() => this.removeAudience(audience)}
                                                            deleteIcon={<CloseIcon fontSize="small"/>}
                                                        />
                                                    )
                                                })}
                                            </React.Fragment>
                                        }
                                    </div>
                                    {showAudiencesOption &&
                                        <div className="show-more-topics-option">
                                            {this.state.showMoreAudiences
                                                ? <Button variant="text" color="primary" onClick={() => this.setState({ showMoreAudiences: false })}>See less audiences</Button>
                                                : <Button variant="text" color="primary" onClick={() => this.setState({ showMoreAudiences: true })}>See all audiences</Button>
                                            }
                                        </div>
                                    }
                                </section>
                            </React.Fragment>
                        }

                        {!!userDetails.standardDetails.subscribedTags.length &&
                            <React.Fragment>
                                <Divider light />
                                <section>
                                    <div className="section-heading">
                                        <Typography variant="h2">Topic Subscriptions<InfoHover>This user is a subscriber of the following topics. *Mandatory topics the user is required to subscribe to, set through the Topic settings</InfoHover></Typography>
                                    </div>
                                    <div id="subscribed-tags-bubbles">
                                        {userDetails.standardDetails.subscribedTags.map((tagGroup) => {
                                            return (
                                                <div key={tagGroup.id} className="tag-group">
                                                    <div className="tag-group-name">{tagGroup.name}</div>
                                                    <div>
                                                        {tagGroup.tags.slice(0, 4).map((tag, index) => (
                                                            <Chip
                                                                className="chip-styling"
                                                                key={tag.id}
                                                                label={`${tag.name}${tag.isMandatory ? "*" : "" }`}
                                                            />
                                                        ))}
                                                        {tagGroup.tags.length === 5 &&
                                                            <Chip
                                                                className="chip-styling"
                                                                key={tagGroup.tags[4].id}
                                                                label={`${tagGroup.tags[4].name} ${tagGroup.tags[4].isMandatory ? "*" : ""}`}
                                                            />
                                                        }
                                                        {tagGroup.tags.length > 5 &&
                                                            <React.Fragment>
                                                                <Collapse in={this.state.showMoreTopics}>
                                                                    {tagGroup.tags.slice(4).map((tag) => (
                                                                        <Chip
                                                                            className="chip-styling"
                                                                            key={tag.id}
                                                                            label={`${tag.name}${tag.isMandatory ? "*" : ""}`}
                                                                        />
                                                                    ))}
                                                                </Collapse>
                                                                <Collapse appear={true} in={!this.state.showMoreTopics}>
                                                                    <Chip
                                                                        className="chip-styling"
                                                                        key={`${tagGroup.id}-more`}
                                                                        label={`+${tagGroup.tags.length - 4}`}
                                                                    />
                                                                </Collapse>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {showTopicsOption &&
                                        <div className="show-more-topics-option">
                                            {this.state.showMoreTopics
                                                ? <Button variant="text" color="primary" onClick={() => this.setState({ showMoreTopics: false })}>See less topics</Button>
                                                : <Button variant="text" color="primary" onClick={() => this.setState({ showMoreTopics: true })}>See all topics</Button>
                                            }
                                        </div>
                                    }
                                </section>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {this.state.currentTab === "custom" &&
                    <section>
                        <Disclaimer
                            icon={<InfoOutlinedIcon />}
                            text="Custom fields are added details synced from your human resource information system (HRIS)."
                        />
                        {userDetails.customDetails.map((customDetail) =>
                            <InputField
                                key={customDetail.key}
                                label={customDetail.title}
                                value={customDetail.value}
                                disabled={true}
                            />
                        )}
                    </section>
                }
            </React.Fragment>
        );
    }

    private checkShouldShowTopicsOption = (topics): boolean => {
        return !!topics.find((tagGroup) => tagGroup.tags.length > 5);
    }

    private checkShouldShowAudiencesOption = (audiences): boolean => {
        return audiences.length > 5;
    }

    private removeAudience = (audienceToRemove) => {
        let newAudiences = this.props.selectedAudiences.filter(s => s.id !== audienceToRemove.id);
        this.props.onChangeAudiences(newAudiences);
    }

    private onChangeBio = (event) => {
        this.props.onChangeUser({ bio: event.target.value });
    }

    private onChangeCountry = (event) => {
        this.props.onChangeUser({ country: event.target.value });
    }

    private onChangeFirstName = (event) => {
        this.props.onChangeUser({ firstName: event.target.value });
    }

    private onChangeLastName = (event) => {
        this.props.onChangeUser({ lastName: event.target.value });
    }

    private onChangePassword = (event) => {
        this.props.onChangePassword(event.target.value);
    }

    private onChangeEmployeeId = (event) => {
        this.props.onChangeUser({ employeeId: event.target.value });
    }

    private onChangePreferredSMSPhone = (event) => {
        this.props.onChangeUser({ smsPhone: event });
    }

    private onChangePronouns = (pronouns: string) => {
        this.props.onChangeUser({ pronouns });
    }

    private onChangeRegion = (event) => {
        this.props.onChangeUser({ region: event.target.value });
    }

    private onChangeReportsTo = (event) => {
        this.props.onChangeUser({ reportsTo: event.target.value });
    }

    private onChangeTitle = (event) => {
        this.props.onChangeUser({ title: event.target.value });
    }

    private onChangeEmail = (event) => {
        this.props.onChangeUser({ email: event.target.value });
    }

    private onChangeLocation = (event) => {
        this.props.onChangeUser({ officeLocation: event.target.value });
    }

    private sendSMSVerifyMessage = () => {
        this.props.onSMSVerifyMessage(this.props.userDetails.id);
    };
}


interface IProfileDetailsProps {
    localAccountPrefix: string;
    password: string;
    userDetails: UserDetails;
    onChangePassword: (password: string) => void;
    onChangeUser: (value: Partial<UserEdit>) => void;
    onChangeAudiences: (selectedAudiences: UserDetailsAudience[]) => void;
    onSMSVerifyMessage: (id: string) => void;
    errorPassword: boolean;
    includableAudiences: UserDetailsAudience[];
    selectedAudiences: UserDetailsAudience[];
    errorFirstname: boolean;
    errorLastname: boolean;
    syncLevel: string;
}

interface IProfileDetailsState {
    currentTab: "standard" | "custom";
    showMoreTopics: boolean;
    showMoreAudiences: boolean;
    smsPhone: string;
    userId: string;
}

export default ProfileDetails;
