import { Action } from "redux";
import * as Actions from "./actions";
import { DocumentGalleryPage, EventsState, EventsFeedState, EventListingPage, EventListItem, ImageGalleryPage, InfoMessage } from './models';
import { DraftAction } from "modules/common/components/authoring/models";

const defaultListingPage: EventListingPage = {
  id: "",
  currentPage: 0,
  events: [],
  totalEvents: 0,
  totalPages: 0,
  isFetching: false
};

const defaultDocumentGalleryPage: DocumentGalleryPage = {
  currentPage: 0,
  documents: [],
  totalDocuments: 0,
  totalPages: 0,
  isFetching: false
};

const defaultImageGalleryPage: ImageGalleryPage = {
  currentPage: 0,
  images: [],
  totalImages: 0,
  totalPages: 0,
  isFetching: false
};

const defaultEventsFeed: EventsFeedState = {
  eventsFeed: [],
  canLoadMore: false,
  fetching: false,
  filters: {
    eventTypes: [],
    eventStates: [],
    tags: [],
    textToSearch: ""
  },
  filtersApplied: false,
  selectedView: "upcoming",
  shouldFetch: true,
  showFilters: false
};

const defaultState: EventsState = {
  allEventsList: {...defaultListingPage, id: "all"},
  drafts: { ...defaultListingPage, id: "drafts" },
  submissions: { ...defaultListingPage, id: "submissions" },
  published: { ...defaultListingPage, id: "published" },
  scheduled: { ...defaultListingPage, id: "scheduled" },
  documentGallery: { ...defaultDocumentGalleryPage },
  imageGallery: { ...defaultImageGalleryPage },
  eventsFeed: { ...defaultEventsFeed },
  pendingDeleting: [],
  pendingPublishing: undefined,
  changedSinceSaved: false,
  errorMessage: "",
  successMessage: {
    message: "",
    action: ""
  },
  isDeleting: false,
  isFetching: false,
  isInitialLoad: true,
  isSaving: false,
  isUpdatingUrl: false,
  isUploading: false,
  isSubmitting: false,
  shouldFetch: false,
  shouldShowDialog: false,
  infoMessage: {
    icon: "",
    message: "",
    severity: "success"
  },
};

const actionHandler: { [actionType: string]: (state: EventsState, action: any) => EventsState } = {

  [Actions.CHANGE_RSVP_STATUS]: (state: EventsState, action: Actions.ChangeRSVPStatus) => {
    return { ...state, isSaving: true };
  },

  [Actions.CHANGE_RSVP_STATUS_COMPLETE]: (state: EventsState, action: Actions.ChangeRSVPStatusComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to change RSVP status",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },


  [Actions.EDIT_RESPONSE]: (state: EventsState, action: Actions.EditResponse) => {
    return { ...state, isSaving: true };
  },

  [Actions.EDIT_RESPONSE_COMPLETE]: (state: EventsState, action: Actions.EditResponseComplete) => {
    return { ...state,
      errorMessage: action.succeeded ? "" : "Unable to edit response",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },


  [Actions.GET_ATTENDANCE_LISTING_CSV]: (state: EventsState, action: Actions.GetAttendanceListingCSV) => {
    return { ...state, isFetching: true };
  },

  [Actions.GET_ATTENDANCE_LISTING_CSV_COMPLETE]: (state: EventsState, action: Actions.GetAttendanceListingCSVComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to download attendance list",
      isFetching: false
    };
  },


  [Actions.GET_DRAFT]: (state: EventsState, action: Actions.GetDraft) => {
    return { ...state, isFetching: true };
  },

  [Actions.GET_DRAFT_COMPLETE]: (state: EventsState, action: Actions.GetDraftComplete) => {
    return { ...state, isFetching: false };
  },


  [Actions.GET_DRAFTS]: (state: EventsState, action: Actions.GetDrafts) => {
    return {
      ...state,
      drafts: {
        ...state.drafts,
        isFetching: true
      }
    };
  },

  [Actions.GET_DRAFTS_COMPLETE]: (state: EventsState, action: Actions.GetDraftsComplete) => {
    return {
      ...state,
      drafts: {
        ...action,
        id: "drafts",
        isFetching: false
      },
      isInitialLoad: false
    } as EventsState;
  },

  [Actions.GET_SUBMISSIONS]: (state: EventsState, action: Actions.GetSubmissions) => {
    return {
      ...state,
      submissions: {
        ...state.submissions,
        isFetching: true
      }
    };
  },

  [Actions.GET_SUBMISSIONS_COMPLETE]: (state: EventsState, action: Actions.GetSubmissionsComplete) => {
    return {
      ...state,
      submissions: {
        ...action,
        id: "submissions",
        isFetching: false
      },
      isInitialLoad: false
    } as EventsState;
  },


  [Actions.GET_PUBLISHED]: (state: EventsState, action: Actions.GetPublished) => {
    return {
      ...state,
      published: {
        ...state.published,
        isFetching: true
      }
    };
  },

  [Actions.GET_PUBLISHED_COMPLETE]: (state: EventsState, action: Actions.GetPublishedComplete) => {
    return {
      ...state,
      published: {
        ...action,
        id: "published",
        isFetching: false
      },
      isInitialLoad: false
    } as EventsState;
  },


  [Actions.GET_SCHEDULED]: (state: EventsState, action: Actions.GetScheduled) => {
    return {
      ...state,
      scheduled: {
        ...state.scheduled,
        isFetching: true
      }
    };
  },

  [Actions.GET_SCHEDULED_COMPLETE]: (state: EventsState, action: Actions.GetScheduledComplete) => {
    return {
      ...state,
      scheduled: {
        ...action,
        id: "scheduled",
        isFetching: false
      },
      isInitialLoad: false
    } as EventsState;
  },

  [Actions.GET_ALL_EVENTS]: (state: EventsState, action: Actions.GetAllEvents) => {
    return {
      ...state,
      allEventsList: {
        ...state.allEventsList,
        isFetching: true
      }
    }
  },

  [Actions.GET_ALL_EVENTS_COMPLETE]: (state: EventsState, action: Actions.GetAllEventsComplete) => {
    return {
      ...state,
      allEventsList: {
        ...action,
        id: "all",
        isFetching: false
      },
      isInitialLoad: false
    } as EventsState;
  },


  [Actions.GET_DOCUMENT_GALLERY]: (state: EventsState, action: Actions.GetDocumentGallery) => {
    return {
      ...state,
      documentGallery: {
        ...state.documentGallery,
        isFetching: true
      }
    }
  },

  [Actions.GET_DOCUMENT_GALLERY_COMPLETE]: (state: EventsState, action: Actions.GetDocumentGalleryComplete) => {
    return {
      ...state,
      documentGallery: {
        ...action,
        isFetching: false
      }
    }
  },


  [Actions.GET_DRAFT_TRANSLATION]: (state: EventsState, action: Actions.GetDraftTranslation) => {
    return {
      ...state,
      isFetching: true
    }
  },

  [Actions.GET_DRAFT_TRANSLATION_COMPLETE]: (state: EventsState, action: Actions.GetDraftTranslationComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to load draft translation",
      isFetching: false
    }
  },


  [Actions.GET_EVENT]: (state: EventsState, action: Actions.GetEvent) => {
    return { ...state, isFetching: true };
  },

  [Actions.GET_EVENT_COMPLETE]: (state: EventsState, action: Actions.GetEventComplete) => {
    return { ...state, isFetching: false };
  },


  [Actions.GET_EVENT_FEED]: (state: EventsState, action: Actions.GetEventFeed) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        errorMessage: "",
        fetching: true,
        filtersApplied: false,
        shouldFetch: false
      }
    };
  },

  [Actions.GET_EVENT_FEED_COMPLETE]: (state: EventsState, action: Actions.GetEventFeedComplete) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        eventsFeed: state.eventsFeed.eventsFeed.concat(action.eventsFeed),
        canLoadMore: action.eventsFeed.length === 12,
        errorMessage: action.succeeded ? "" : "Unable to fetch events",
        fetching: false,
        filtersApplied: action.filtersApplied,
        shouldFetch: false
      }
    };
  },


  [Actions.GET_IMAGE]: (state: EventsState, action: Actions.GetImage) => {
    return { ...state, isFetching: true };
  },

  [Actions.GET_IMAGE_COMPLETE]: (state: EventsState, action: Actions.GetImageComplete) => {
    return { ...state, isFetching: false };
  },


  [Actions.GET_IMAGE_GALLERY]: (state: EventsState, action: Actions.GetImageGallery) => {
    return {
      ...state,
      imageGallery: {
        ...state.imageGallery,
        isFetching: true
      }
    };
  },

  [Actions.GET_IMAGE_GALLERY_COMPLETE]: (state: EventsState, action: Actions.GetImageGalleryComplete) => {
    return {
      ...state,
      imageGallery: {
        ...action,
        isFetching: false
      }
    };
  },


  [Actions.GET_VIDEO]: (state: EventsState, action: Actions.GetImage) => {
    return { ...state, isFetching: true };
  },

  [Actions.GET_VIDEOS_COMPLETE]: (state: EventsState, action: Actions.GetImageComplete) => {
    return { ...state, isFetching: false };
  },


  [Actions.CLONE_EVENT]: (state: EventsState, action: Actions.CloneEvent) => {
    return { ...state, isSaving: true };
  },

  [Actions.CLONE_EVENT_COMPLETE]: (state: EventsState, action: Actions.CloneEventComplete) => {
    return { ...state, isSaving: false, shouldFetch: action.succeeded };
  },

  [Actions.UNPUBLISH_EVENT]: (state: EventsState, action: Actions.UnpublishEvent) => {
    return { ...state, isSaving: true };
  },

  [Actions.UNPUBLISH_EVENT_COMPLETE]: (state: EventsState, action: Actions.UnpublishEventComplete) => {
    return { ...state,
        isSaving: false, 
        shouldFetch: action.succeeded,
        successMessage: {
            message: action.succeeded ? "Successfully unpublished! Your event has been moved to drafts." : "",
            action: DraftAction.Unpublish
        },};
  },

  [Actions.DELETE_EVENTS]: (state: EventsState, action: Actions.DeleteEvents) => {
    return { ...state, changedSinceSaved: false, isDeleting: true };
  },

  [Actions.DELETE_EVENTS_COMPLETE]: (state: EventsState, action: Actions.DeleteEventsComplete) => {
    if (!action.succeeded)
      return { ...state, errorMessage: "Unable to delete event(s)", isDeleting: false };

    const deletedIds: string[] = action.deletedEvents || [];

    const deletedDrafts: EventListItem[] = !!deletedIds.length
      ? state.drafts.events.filter((event) => deletedIds.includes(event.id))
      : [];

    const deletedPublished: EventListItem[] = !!deletedIds.length
      ? state.published.events.filter((event) => deletedIds.includes(event.id))
      : [];

    const deletedScheduled: EventListItem[] = !!deletedIds.length
      ? state.scheduled.events.filter((event) => deletedIds.includes(event.id))
      : [];

    return {
      ...state,
      isDeleting: false,
      pendingDeleting: deletedDrafts.concat(deletedPublished).concat(deletedScheduled),
      shouldFetch: true
    };
  },

    [Actions.SUBMIT_EVENT]: (state: EventsState, _: Actions.SubmitEvent) => {
        return { ...state,
            submitting: true,
        };
    },

    [Actions.SUBMIT_EVENT_COMPLETE]: (state: EventsState, actions: Actions.SubmitEventComplete) => {
        return { 
            ...state, 
            isSubmitting: false,
            shouldFetch: true,
            successMessage: {
                message: actions.succeeded ? "Successfully submitted! Your event is now in review." : "",
                action: DraftAction.Submit
            },
            errorMessage: actions.succeeded ? "" : (actions.err ?? "")
        };
    },

  [Actions.PUBLISH_EVENT]: (state: EventsState, action: Actions.PublishEvent) => {
    return { ...state, isSaving: true };
  },

  [Actions.PUBLISH_EVENT_COMPLETE]: (state: EventsState, action: Actions.PublishEventComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to publish event",
      isSaving: false,
      shouldFetch: action.succeeded,
      pendingPublishing: action.publishedEvents,
      shouldShowDialog: action.succeeded,
    };
  },


  [Actions.RSVP_TO_EVENT]: (state: EventsState, action: Actions.PublishEvent) => {
    return { ...state, isSaving: true };
  },

  [Actions.RSVP_TO_EVENT_COMPLETE]: (state: EventsState, action: Actions.RSVPToEventComplete) => {
    let infoMessage: InfoMessage = {
      icon: "",
      message: "",
      severity: "success"
    };

    if (action.succeeded) {
      if (action.rsvpType === "initial") {
        if (action.attendanceType === "Waitlist")
          infoMessage = {
            icon: "",
            message: "Success! You are waitlisted for this event. See My Events to review.",
            severity: "info"
          };
        else if (action.attendanceType === "NotAttending")
          infoMessage = {
            icon: "cancel",
            message: "You will not be attending this event",
            severity: "success"
          };
        else
          infoMessage = {
            icon: "",
            message: "Success! You will be attending this event. See My Events to review.",
            severity: "success"
          };
      } else if (action.rsvpType === "changeStatus") {
        infoMessage = {
          icon: "",
          message: "You've successfully modified your RSVP status.",
          severity: "success"
        };
      } else if (action.rsvpType === "editAnswers") {
        infoMessage = {
          icon: "",
          message: "You've successfully modified your answers.",
          severity: "success"
        };
      }
    }

    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to RSVP to event",
      isSaving: false,
      infoMessage: infoMessage
    };
  },


  [Actions.SAVE_EVENT]: (state: EventsState, action: Actions.SaveEvent) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.SAVE_EVENT_COMPLETE]: (state: EventsState, action: Actions.SaveEventComplete) => {
    return {
      ...state,
      changedSinceSaved: !action.succeeded,
      errorMessage: action.succeeded ? "" : "Unable to save event",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },


  [Actions.SET_EVENT_FEED_FILTERS]: (state: EventsState, action: Actions.SetEventFeedFilters) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        filters: action.filters
      }
    };
  },

  [Actions.SET_EVENT_FEED_VIEW]: (state: EventsState, action: Actions.SetEventFeedView) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        selectedView: action.selectedView
      }
    };
  },

  [Actions.SET_SHOW_EVENT_FEED_FILTERS]: (state: EventsState, action: Actions.SetShowEventFeedFilters) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        showFilters: action.show
      }
    };
  },


  [Actions.UPDATE_EVENT_FEED]: (state: EventsState, action: Actions.UpdateEventFeed) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        eventsFeed: action.eventsFeed
      }
    };
  },


  [Actions.UPDATE_URL]: (state: EventsState, action: Actions.UpdateUrl) => {
    return {
      ...state,
      isUpdatingUrl: true
    };
  },

  [Actions.UPDATE_URL_COMPLETE]: (state: EventsState, action: Actions.UpdateUrl) => {
    return {
      ...state,
      isUpdatingUrl: false
    };
  },


  [Actions.UPLOAD_IMAGE]: (state: EventsState, action: Actions.UploadImage) => {
    return {
      ...state,
      isUploading: true
    };
  },


  [Actions.UPLOAD_IMAGE_COMPLETE]: (state: EventsState, action: Actions.UploadImageComplete) => {
    return {
      ...state,
      isUploading: false
    };
  },


  [Actions.CHANGED_SINCE_SAVED]: (state: EventsState, action: Actions.ChangedSinceSaved) => {
    return { ...state, changedSinceSaved: true };
  },

  [Actions.CLEAR_CHANGED_SINCE_SAVED]: (state: EventsState, action: Actions.ChangedSinceSaved) => {
    return { ...state, changedSinceSaved: false };
  },

  [Actions.CLEAR_ERROR_MESSAGE]: (state: EventsState, action: Actions.ClearErrorMessage) => {
    return { ...state, errorMessage: "" };
  },

  [Actions.CLEAR_EVENT_FEED]: (state: EventsState, action: Actions.ClearEventFeed) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        eventsFeed: [],
        shouldFetch: true
      }
    };
  },

  [Actions.CLEAR_EVENT_FEED_FILTERS]: (state: EventsState, action: Actions.ClearEventFeedFilters) => {
    return {
      ...state,
      eventsFeed: {
        ...state.eventsFeed,
        filters: {
          eventTypes: [],
          eventStates: [],
          tags: [],
          textToSearch: ""
        },
        filtersApplied: false
      }
    }
  },

  [Actions.CLEAR_EVENT_LIST]: (state: EventsState, action: Actions.ClearEventList) => {
    return {
      ...state,
      eventList: []
    };
  },

  [Actions.CLEAR_SHOULD_FETCH]: (state: EventsState, action: Actions.ClearShouldFetch) => {
    return {
      ...state,
      shouldFetch: false
    };
  },

  [Actions.CLEAR_SHOW_PUBLISH_SUCCESS]: (state: EventsState, action: Actions.ClearShowPublishSuccess) => {
    return { ...state, shouldShowDialog: false };
  },

  [Actions.CLEAR_SUCCESS_MESSAGE]: (state: EventsState, action: Actions.ClearErrorMessage) => {
    return {
      ...state,
      successMessage: {
        message: "",
        action: ""
      },
      infoMessage: {
        icon: "",
        message: "",
        severity: "success"
      }
    };
  },

  [Actions.SET_EVENTS_FEED_TO_DEFAULT]: (state: EventsState, action: Actions.SetEventsFeedToDefault) => {
    return {
        ...defaultState
    };
  }
}

export const reducer = (state: EventsState, action: Action) => {
  const actionHandlerMethod = actionHandler[action.type];
  if (actionHandlerMethod) {
    return actionHandlerMethod(state, action);
  }
  return state || defaultState;
};
