import * as React from 'react';

import { ContentType } from 'modules/common/models';

import ConfirmDialog from 'modules/common/components/dialogs/confirmDialog';

const EDIT_PUBLISHED_WARNING = "Once approved, the content will be updated. The existing, live version will remain the same until revisions are approved.";

const EditPublishedContentDialog: React.FunctionComponent<IEditPublishedContentDialog> = (props: IEditPublishedContentDialog) => {
    return (
        <ConfirmDialog
            title={`Edit ${ContentType[props.content]}`}
            open={props.open}
            confirmLabel="EDIT"
            denyLabel="CANCEL"
            onConfirm={props.onEdit}
            onDeny={props.onClose}
            onClose={props.onClose}
        >
            {
                `Editing a published ${ContentType[props.content].toLowerCase()} and resubmitting your revisions will require re-approval.`
                + EDIT_PUBLISHED_WARNING
            }
            
            <br /><br />
            Do you want to continue?
        </ConfirmDialog>
    );
};

interface IEditPublishedContentDialog {
    content: ContentType;
    open: boolean;
    onEdit: () => void;
    onClose: () => void;
}

export default EditPublishedContentDialog;