import * as React from 'react';
import TenantLink from 'modules/common/components/tenantLink';
import { HorizontalBarChart, HBarChartProps, HBarChartDataPoint } from './charts/horizontalBarChart';
import WindowResizeListener from 'modules/common/components/window-resize-listener';
import {FormattedMessage} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import { GlobalApplicationState } from 'globalApplicationState';
import Loading from 'modules/common/components/loading';
import { PlatformAnalyticsReport, TopEvent } from '../models';
import { useEffect } from 'react';
import EmptyDataMessage from './emptyDataMessage';
import DownloadIcon from './downloadIconSvg';
import { browserHistory } from 'configureStore';
import EventPreview from 'modules/events/components/event-views/eventPreview';
import { getDraft } from 'modules/events/actionCreator';
import { ImageScale } from 'modules/posts/models';
import { Event } from 'modules/events';
import { UserRoleStrings } from 'modules/authorization/models';
import { getHighestRole } from 'utils/userRoleUtils';

interface ComponentProps {
    tenant: string
    topEvents: TopEvent[];
    loading: boolean;
    downloadExcel: () => void;
    downloadImage: () => void;
}

const TopEvents: React.FunctionComponent<PropsWithRedux> = props => {
    let chart = React.useRef<HorizontalBarChart>();
    let chartElement = React.useRef<HTMLElement>();

    const [selectedEvent, setSelectedEvent] = React.useState<Event | undefined>(undefined);

    const getEventDraft = props.getDraft;

    const getBarChartState = React.useCallback((): HBarChartProps => {

        const handleEventClick = async (e: HBarChartDataPoint) => {
            try {
                const selected = await getEventDraft(e.id, ImageScale.Modal);
                setSelectedEvent(selected);
            } catch (error) {
                setSelectedEvent(undefined);
            }
        }

        return {
            el: chartElement.current,
            locale: props.locale,
            width: "100%",
            height: 'auto',
            barHeight: 20,
            data: props.topEvents.map(s => {
                return {
                    id: s.draftId,
                    label: s.eventName,
                    value: s.viewCount,
                    onClick: handleEventClick,
                };
            }),
            labelWidth: 200
        } as HBarChartProps;
    }, [props.topEvents, props.locale, getEventDraft]);

    const onEditEvent = () => {
        if (selectedEvent) {
            browserHistory.push(`/${props.tenant}/admin/events/edit/${selectedEvent.id}`)
        }
    }
    
    const onHideEvent = () => {
        setSelectedEvent(undefined)
    }

    useEffect(() => {
        chart.current = new HorizontalBarChart(getBarChartState());

        return function cleanup() {
            if (chart && chart.current) {
                chart.current.destroy();
            }
        };
    });

    useEffect(() => {
        if (chart && chart.current) {
            chart.current.update(getBarChartState());
        }
    }, [props, getBarChartState]);

    const noDataAvailable = !props.topEvents || props.topEvents.length === 0;

    return (
        <div id={PlatformAnalyticsReport.mostViewedEvent} className="analytics-widget">
            {
                selectedEvent && 
                <EventPreview
                    show={selectedEvent !== undefined}
                    event={selectedEvent}
                    onEditDetails={onEditEvent} onClose={onHideEvent}
                    isContributor={getHighestRole(props.currentUser) === UserRoleStrings.CONTRIBUTOR}
                    inEditor={false}
                />
            }
        <div className='downloadIcon' id={`${PlatformAnalyticsReport.mostViewedEvent}-downloadIcon`}>  
            <DownloadIcon 
                downloadExcel={props.downloadExcel}
                downloadImage={props.downloadImage}
            />
        </div>
            <div className="header">
                Top 10 Events with Most Views
            </div>
            {props.loading ? 
            <div className="body" style={{position: "relative", top: "55px", height: "85%"}}>
                <Loading/>
            </div>
            : <div className="body" style={{height: noDataAvailable ? "175px" : "215px"}}>
                {
                  noDataAvailable ?
                    <EmptyDataMessage/>
                    
                : <div ref={e => chartElement.current = e!} className="bar-chart-widget"></div>
                }
                <WindowResizeListener onChange={() => chart.current?.update(getBarChartState())} />
            </div>
            }
            <footer id={`${PlatformAnalyticsReport.mostViewedEvent}-goto`} style={{position: "relative", top: "-8px"}}>
                <TenantLink to={`~/admin/events`}>
                    <FormattedMessage id="events.viewAllEvents" defaultMessage="Go to Manage Events"/>
                </TenantLink>
            </footer>
        </div>
    )
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        locale: state.localization.locale,
        currentUser: state.settings.currentUser
    }),
    {
        getDraft: getDraft,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(TopEvents)