import { TenantNotificationSettings } from "modules/messaging";
import { lcidMappings } from "modules/resources";
import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";
import { reminderData } from "./components/reminderPopout";

import {
    ClientSettings,
    ClientSettingsTagGroup,
    TenantSettings,
    ThemeSettings,
    TenantAttribute,
    TenantSettingsTag,
    TenantSettingsTagGroup,
    ReactionTab,
    CurrentUserSettings,
    ExternalPostSource,
    TranslatableLanguage,
} from "./models";

/* Fetch config */
export const FETCH_SETTINGS_CLIENT_CONFIG_INIT = "FETCH_SETTINGS_CLIENT_CONFIG_INIT";
export interface FetchClientConfigInit extends Action {}
export const CreateFetchClientConfigInitAction = ActionCreator<FetchClientConfigInit>(FETCH_SETTINGS_CLIENT_CONFIG_INIT);

export const FETCH_SETTINGS_CLIENT_CONFIG_COMPLETE = "FETCH_SETTINGS_CLIENT_CONFIG_COMPLETE";
export interface FetchClientConfigComplete extends CompleteAction {
    clientSettings: ClientSettings | null;
}
export const CreateFetchClientConfigCompleteAction = ActionCreator<FetchClientConfigComplete>(FETCH_SETTINGS_CLIENT_CONFIG_COMPLETE);

export const FETCH_SETTINGS_TENANT_CONFIG_INIT = "FETCH_SETTINGS_TENANT_CONFIG_INIT";
export interface FetchTenantConfigInit extends Action {}
export const CreateFetchTenantConfigInitAction = ActionCreator<FetchTenantConfigInit>(FETCH_SETTINGS_TENANT_CONFIG_INIT);

export const FETCH_SETTINGS_TENANT_CONFIG_COMPLETE = "FETCH_SETTINGS_TENANT_CONFIG_COMPLETE";
export interface FetchTenantConfigComplete extends CompleteAction {
    tenantSettings: TenantSettings | null;
}
export const CreateFetchTenantConfigCompleteAction = ActionCreator<FetchTenantConfigComplete>(FETCH_SETTINGS_TENANT_CONFIG_COMPLETE);

export const FETCH_TAG_SETTINGS_INIT = "FETCH_TAG_SETTINGS_INIT";
export interface FetchTagSettingsInit extends Action {}
export const FetchTagSettingsInitAction = ActionCreator<FetchTagSettingsInit>(FETCH_TAG_SETTINGS_INIT);

export const FETCH_TAG_SETTINGS_COMPLETE = "FETCH_TAG_SETTINGS_COMPLETE";
export interface FetchTagSettingsComplete extends CompleteAction {
    tagSettings: ClientSettingsTagGroup[];
}
export const FetchTagSettingsCompleteAction = ActionCreator<FetchTagSettingsComplete>(FETCH_TAG_SETTINGS_COMPLETE);

export const FETCH_TENANT_ATTRIBUTES_COMPLETE = "FETCH_TENANT_ATTRIBUTES_COMPLETE";
export interface FetchTenantAttributesComplete extends CompleteAction {
    tenantAttributes: TenantAttribute[];
}
export const FetchTenantAttributesComplete = ActionCreator<FetchTenantAttributesComplete>(FETCH_TENANT_ATTRIBUTES_COMPLETE);

/* Fatch user tag settings */

export const FETCH_USER_TAG_SETTINGS_INIT = "FETCH_TAG_SETTINGS_INIT";
export interface FetchUserTagSettingsInit extends Action {}
export const FetchUserTagSettingsInitAction = ActionCreator<FetchUserTagSettingsInit>(FETCH_USER_TAG_SETTINGS_INIT);

export const FETCH_USER_TAG_SETTINGS_COMPLETE = "FETCH_USER_TAG_SETTINGS_COMPLETE";
export interface FetchUserTagSettingsComplete extends CompleteAction {
    tagSettings: ClientSettingsTagGroup[];
}
export const FetchUserTagSettingsCompleteAction = ActionCreator<FetchUserTagSettingsComplete>(FETCH_USER_TAG_SETTINGS_COMPLETE);

export const FETCH_USER_SETTINGS_INIT = "FETCH_USER_SETTINGS_INIT";
export interface FetchUserSettingsInit extends Action {}
export const CreateFetchUserSettingsInitAction = ActionCreator<FetchUserSettingsInit>(FETCH_USER_SETTINGS_INIT);

export const FETCH_USER_SETTINGS_COMPLETE = "FETCH_USER_SETTINGS_COMPLETE";
export interface FetchUserSettingsComplete extends CompleteAction {
    user: CurrentUserSettings;
}
export const CreateFetchUserSettingsCompleteAction = ActionCreator<FetchUserSettingsComplete>(FETCH_USER_SETTINGS_COMPLETE);

export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export interface UpdateUserSettings extends Action { userSettings: Partial<CurrentUserSettings> }
export const UpdateUserSettings = ActionCreator<UpdateUserSettings>(UPDATE_USER_SETTINGS);


export const FETCH_NOTIFICATION_SETTINGS_INIT = "FETCH_NOTIFICATION_SETTINGS_INIT";
export interface FetchNotificationSettingsInit extends Action {}
export const CreateFetchNotificationSettingsInitAction = ActionCreator<FetchNotificationSettingsInit>(FETCH_NOTIFICATION_SETTINGS_INIT);

export const FETCH_NOTIFICATION_SETTINGS_COMPLETE = "FETCH_NOTIFICATION_SETTINGS_COMPLETE";
export interface FetchNotificationSettingsComplete extends CompleteAction {
    notificationSettings: TenantNotificationSettings;
}
export const CreateFetchNotificationSettingsCompleteAction = ActionCreator<FetchNotificationSettingsComplete>(FETCH_NOTIFICATION_SETTINGS_COMPLETE);

export const CHANGE_PRISTINE_STATE = "CHANGE_PRISTINE_STATE";
export interface ChangePristineState extends Action {
    newState: boolean;
}
export const CreateChangePristineStateAction = ActionCreator<ChangePristineState>(CHANGE_PRISTINE_STATE);

export const TENANT_DISPLAY_NAME_CHANGED = "TENANT_DISPLAY_NAME_CHANGED";
export interface TenantDisplayNameChanged extends Action {
    displayName: string;
}
export const CreateTenantDisplayNameChangedAction = ActionCreator<TenantDisplayNameChanged>(TENANT_DISPLAY_NAME_CHANGED);

export const SET_SHOW_INVITED_EXTERNAL_USERS_ENABLED = "SET_SHOW_INVITED_EXTERNAL_USERS_ENABLED";
export interface SetShowInvitedExternalUsersEnabled extends Action {
    enabled: boolean;
}
export const CreateSetShowInvitedExternalUsersEnabled = ActionCreator<SetShowInvitedExternalUsersEnabled>(SET_SHOW_INVITED_EXTERNAL_USERS_ENABLED);

export const SET_POST_NOTIFICATIONS_ENABLED = "SET_POST_NOTIFICATIONS_ENABLED";
export interface SetPostNotificationsEnabled extends Action {
    enabled: boolean;
}
export const CreateSetPostNotificationsEnabled = ActionCreator<SetPostNotificationsEnabled>(SET_POST_NOTIFICATIONS_ENABLED);

export const SET_SMS_NOTIFICATIONS_ENABLED = "SET_SMS_NOTIFICATIONS_ENABLED";
export interface SetSmsNotificationsEnabled extends Action {
    enabled: boolean;
}
export const CreateSetSmsNotificationsEnabled = ActionCreator<SetSmsNotificationsEnabled>(SET_SMS_NOTIFICATIONS_ENABLED);

export const SET_EMAIL_NOTIFICATIONS_ENABLED = "SET_EMAIL_NOTIFICATIONS_ENABLED";
export interface SetEmailNotificationsEnabled extends Action {
    enabled: boolean;
}
export const CreateSetEmailNotificationsEnabled = ActionCreator<SetEmailNotificationsEnabled>(SET_EMAIL_NOTIFICATIONS_ENABLED);

export const COMMENTS_ENABLED_CHANGED = "COMMENTS_ENABLED_CHANGED";
export interface CommentsEnabledChanged extends Action {
    enabled: boolean;
}
export const CreateCommentsEnabledChangedAction = ActionCreator<CommentsEnabledChanged>(COMMENTS_ENABLED_CHANGED);

export const EXTERNAL_POST_PURGE_CHANGED = "EXTERNAL_POST_PURGE_CHANGED";
export interface ExternalPostPurgeEnabledChanged extends Action {
    enabled: boolean;
}
export const CreateExternalPostPurgeChangedAction = ActionCreator<ExternalPostPurgeEnabledChanged>(EXTERNAL_POST_PURGE_CHANGED);

export const SET_MOBILE_SYNC_ENABLED = "SET_MOBILE_SYNC_ENABLED";
export interface SetMobileSyncEnabledAction extends Action {
    enabled: boolean;
}
export const CreateSetMobileSyncEnabledAction = ActionCreator<SetMobileSyncEnabledAction>(SET_MOBILE_SYNC_ENABLED);

export const SET_MOBILE_SYNC_NUMBER_SELECTED = "SET_MOBILE_SYNC_NUMBER_SELECTED";
export interface SetMobileSyncNumberSelectedAction extends Action {
    numberType: string;
}
export const SYNC_ALL_AD_USERS_ENABLED = "SYNC_ALL_AD_USERS_ENABLED";
export interface SetSyncAllAdUsersEnabledAction extends Action {
    enabled: boolean;
}
export const CreateSetSyncAllAdUsersEnabledAction = ActionCreator<SetSyncAllAdUsersEnabledAction>(SYNC_ALL_AD_USERS_ENABLED);

export const SET_SYNC_LEVEL = "SET_SYNC_LEVEL";
export interface SetSyncLevelAction extends Action {
    syncLevel: string;
}
export const CreateSetSyncLevelAction = ActionCreator<SetSyncLevelAction>(SET_SYNC_LEVEL);
export const CreateSetMobileSyncNumberSelectedAction = ActionCreator<SetMobileSyncNumberSelectedAction>(SET_MOBILE_SYNC_NUMBER_SELECTED);

export const EVERYONE_AUDIENCE_ENFORCEMENT_CHANGED = "EVERYONE_AUDIENCE_ENFORCEMENT_CHANGED";
export interface EveryoneAudienceEnforcementChanged extends Action {
    enabled: boolean;
}
export const CreateEveryoneAudienceEnforcementChangedAction = ActionCreator<EveryoneAudienceEnforcementChanged>(EVERYONE_AUDIENCE_ENFORCEMENT_CHANGED);

export const MANAGE_TOPICS_AUDIENCES_ADMIN_CHANGED = "MANAGE_TOPICS_AUDIENCES_ADMIN_CHANGED";
export interface ManageTopicsAudiencesInAdminChanged extends Action {
    enabled: boolean;
}
export const CreateManageTopicsAudiencesInAdminChangedAction = ActionCreator<ManageTopicsAudiencesInAdminChanged>(
    MANAGE_TOPICS_AUDIENCES_ADMIN_CHANGED
);

export const IS_COMMENTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT = "IS_COMMENTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT";
export interface IsCommentingEnabledForNewPostsByDefaultChanged extends Action {
    enabled: boolean;
}
export const CreateIsCommentingEnabledForNewPostsByDefaultChangedAction = ActionCreator<IsCommentingEnabledForNewPostsByDefaultChanged>(
    IS_COMMENTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT
);

export const COMMENT_MODERATION_ENABLED = "COMMENT_MODERATION_ENABLED";
export interface CommentModerationEnabled extends Action {
    enabled: boolean;
}
export const CreateCommentModerationEnabledChangedAction = ActionCreator<IsCommentingEnabledForNewPostsByDefaultChanged>(
    COMMENT_MODERATION_ENABLED
);

export const GIF_SELECTION_ENABLED = "GIF_SELECTION_ENABLED";
export interface GifSelectionEnabledForCommentsChanged extends Action {
    enabled: boolean;
}
export const CreateGifSelectionEnabledForCommentsAction = ActionCreator<GifSelectionEnabledForCommentsChanged>(
    GIF_SELECTION_ENABLED
);

export const COMMENTS_MAXLENGTH_CHANGED = "COMMENTS_MAXLENGTH_CHANGED";
export interface CommentsMaxLengthChanged extends Action {
    maxLength: number;
}
export const CreateCommentsMaxLengthChangedAction = ActionCreator<CommentsMaxLengthChanged>(COMMENTS_MAXLENGTH_CHANGED);

export const SET_EXTERNAL_AUTH_ENABLED = "SET_EXTERNAL_AUTH_ENABLED";
export interface SetExternalAuthEnabled extends Action {
    enabled: boolean;
}
export const CreateSetExternalAuthEnabledAction = ActionCreator<SetExternalAuthEnabled>(SET_EXTERNAL_AUTH_ENABLED);

export const REACTIONS_ENABLED = "REACTIONS_ENABLED";
export interface ReactionsEnabled extends Action {
    enabled: boolean;
}
export const CreateToggleReactionsAction = ActionCreator<ReactionsEnabled>(REACTIONS_ENABLED);

export const IS_REACTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT = "IS_REACTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT";
export interface IsReactingEnabledForNewPostsByDefaultChanged extends Action {
    enabled: boolean;
}
export const CreateIsReactingEnabledForNewPostsByDefaultChangedAction = ActionCreator<IsReactingEnabledForNewPostsByDefaultChanged>(
    IS_REACTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT
);

export const REACTION_ENABLED_CHANGED = "REACTION_ENABLED_CHANGED";
export interface ReactionEnabledChanged extends Action {
    id: string;
    enabled: boolean;
}
export const CreateReactionEnabledChangedAction = ActionCreator<ReactionEnabledChanged>(REACTION_ENABLED_CHANGED);

export const REACTION_NAME_CHANGED = "REACTION_NAME_CHANGED";
export interface ReactionNameChanged extends Action {
    id: string;
    name: string;
}
export const CreateReactionNameChangedAction = ActionCreator<ReactionNameChanged>(REACTION_NAME_CHANGED);

export const REACTION_REORDER = "REACTION_REORDER";
export interface ReactionReorder extends Action {
    id: string;
    up: boolean;
}
export const CreateReactionReorderAction = ActionCreator<ReactionReorder>(REACTION_REORDER);

export const SET_ACTIVE_REACTION_TAB = "SET_ACTIVE_REACTION_TAB";
export interface SetActiveReactionTab extends Action {
    activeReactionTab: ReactionTab;
}
export const CreateSetActiveReactionTabAction = ActionCreator<SetActiveReactionTab>(SET_ACTIVE_REACTION_TAB);

/* Reset config */
export const RESET_SETTINGS_COMPLETE = "RESET_SETTINGS_COMPLETE";
export interface ResetSettingsComplete extends CompleteAction {};

export const CreateResetSettingsCompleteAction = ActionCreator<ResetSettingsComplete>(RESET_SETTINGS_COMPLETE);

/* Save config */
export const SAVE_TENANT_SETTINGS_INIT = "SAVE_TENANT_SETTINGS_INIT";
export interface SaveTenantSettingsInit extends Action {};
export const CreateSaveTenantSettingsInitAction = ActionCreator<SaveTenantSettingsInit>(SAVE_TENANT_SETTINGS_INIT);

export const SAVE_SETTINGS_COMPLETE = "SAVE_SETTINGS_COMPLETE";
export interface SaveSettingsComplete extends CompleteAction {};
export const CreateSaveSettingsCompleteAction = ActionCreator<SaveSettingsComplete>(SAVE_SETTINGS_COMPLETE)

export const SAVE_TENANT_SETTINGS_COMPLETE = "SAVE_TENANT_SETTINGS_COMPLETE";
export interface SaveTenantSettingsComplete extends CompleteAction {
    tenantSettings: TenantSettings | null;
    notificationSettings: TenantNotificationSettings | null;
}
export const CreateSaveTenantSettingsCompleteAction = ActionCreator<SaveTenantSettingsComplete>(SAVE_TENANT_SETTINGS_COMPLETE);

export const GET_LANGUAGE_TRANSLATION_COMPLETE = "GET_LANGUAGE_TRANSLATION_COMPLETE";
export interface GetLanguageTranslationComplete extends CompleteAction {
    translatedContent: TranslatableLanguage | null;
}
export const CreateGetLanguageTranslationCompleteAction = ActionCreator<GetLanguageTranslationComplete>(GET_LANGUAGE_TRANSLATION_COMPLETE);

export const SET_ACTIVE_TAG_GROUP = "SET_ACTIVE_TAG_GROUP";
export interface SetActiveTagGroup extends Action {
    id: string;
}
export const CreateSetActiveTagGroupAction = ActionCreator<SetActiveTagGroup>(SET_ACTIVE_TAG_GROUP);

export const ADD_TAG_GROUP = "ADD_TAG_GROUP";
export interface AddTagGroup extends Action {
    tagGroup: TenantSettingsTagGroup;
}
export const CreateAddTagGroupAction = ActionCreator<AddTagGroup>(ADD_TAG_GROUP);

export const UPDATE_TAG_GROUP = "UPDATE_TAG_GROUP";
export interface UpdateTagGroup extends Action {
    id: string;
    name: string;
    disabled: boolean;
    deleted: boolean;
}
export const CreateUpdateTagGroupAction = ActionCreator<UpdateTagGroup>(UPDATE_TAG_GROUP);

export const ADD_TAG = "ADD_TAG";
export interface AddTag extends Action {
    tagGroupId: string;
    tag: TenantSettingsTag;
}
export const CreateAddTagAction = ActionCreator<AddTag>(ADD_TAG);

export const UPDATE_TAG = "UPDATE_TAG";
export interface UpdateTag extends Action {
    id: string;
    disabled: boolean;
    deleted: boolean;
    name: string;
    mandatory: boolean;
    defaultValue: boolean;
    restricted: boolean;
    restrictedTo: string[];
    mandatoryFor: string[];
    defaultFor: string[];
}
export const CreateUpdateTagAction = ActionCreator<UpdateTag>(UPDATE_TAG);

export const MOVE_TAG = "MOVE_TAG";
export interface MoveTag extends Action {
    tagId: string;
    tagGroupId: string;
}
export const CreateMoveTagAction = ActionCreator<MoveTag>(MOVE_TAG);

export const RESET_TENANT_SETTINGS_TO_PRISTINE = "RESET_TENANT_SETTINGS_TO_PRISTINE";
export interface ResetTenantSettingsToPristine extends Action {}
export const CreateResetTenantSettingsToPristineAction = ActionCreator<ResetTenantSettingsToPristine>(RESET_TENANT_SETTINGS_TO_PRISTINE);

export const THEME_SETTINGS_CHANGED = "THEME_SETTINGS_CHANGED";
export interface ThemeSettingsChanged extends Action {
    enabled: boolean;
    themeSettings?: ThemeSettings;
}
export const CreateThemeSettingsChangedAction = ActionCreator<ThemeSettingsChanged>(THEME_SETTINGS_CHANGED);

export const POST_BANNER_COLORS_CHANGED = "POST_BANNER_COLORS_CHANGED";
export interface PostBannerColorsChanged extends Action {
    option: number | string;
    color: string;
}
export const CreateBannerColorOptionsChangedAction = ActionCreator<PostBannerColorsChanged>(POST_BANNER_COLORS_CHANGED);

export const SPECIALTY_COLORS_CHANGED = "SPECIALTY_COLORS_CHANGED";
export interface SpecialtyColorsChanged extends Action {
    option: number | string;
    color: string;
}
export const CreateSpecialtyColorOptionsChangedAction = ActionCreator<SpecialtyColorsChanged>(SPECIALTY_COLORS_CHANGED);

export const MOBILE_COLORS_CHANGED = "MOBILE_COLORS_CHANGED";
export interface MobileColorsChanged extends Action {
    option: number | string;
    color: string;
}
export const CreateMobileColorOptionsChangedAction = ActionCreator<MobileColorsChanged>(MOBILE_COLORS_CHANGED);

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export interface SetErrorMessage extends Action {
    msg: string | null;
}
export const SetErrorMessage = ActionCreator<SetErrorMessage>(SET_ERROR_MESSAGE);

export const SET_LANGUAGE_SETTINGS = "SET_LANGUAGE_SETTINGS";
export interface SetLanguageSettings extends Action {
    chosenLanguages?: string[];
    defaultLanguage?: string;
}
export const SetLanguageSettings = ActionCreator<SetLanguageSettings>(SET_LANGUAGE_SETTINGS);

export const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE";
export interface SetActiveLanguage extends Action {
    lcid: string;
}
export const CreateSetActiveLanguageAction = ActionCreator<SetActiveLanguage>(SET_ACTIVE_LANGUAGE);

export const SET_TIME_ZONE = "SET_TIME_ZONE";
export interface SetTimeZone extends Action {
    timeZone: string;
}
export const CreateSetTimeZoneAction = ActionCreator<SetTimeZone>(SET_TIME_ZONE);

export const SET_TRANSLATED_TEXT = "SET_TRANSLATED_TEXT";
export interface SetTranslatedText extends Action {
    key: string;
    text: string;
    lcid;
}
export const SetTranslatedText = ActionCreator<SetTranslatedText>(SET_TRANSLATED_TEXT);

export const SET_SMS_BODY = "SET_SMS_BODY";
export interface SetSMSBody extends Action {
    event: string;
    content: lcidMappings;
}
export const SetSMSBody = ActionCreator<SetSMSBody>(SET_SMS_BODY);

export const SET_EMAIL_NAME = "SET_EMAIL_NAME";
export interface SetEmailName extends Action {
    name: string;
}
export const SetEmailName = ActionCreator<SetEmailName>(SET_EMAIL_NAME);

export const TOGGLE_SHARE_URL = "TOGGLE_SHARE_URL";
export interface ToggleShareUrlUrl extends Action {
    shareToggleEvent: string
    enabled: boolean;
}

export const CreateToggleShareUrlAction = ActionCreator<ToggleShareUrlUrl>(TOGGLE_SHARE_URL);

export const SET_SHARE_URL = "SET_SHARE_URL";
export interface SetShareUrl extends Action {
    shareEvent: string;
    url: string;
}

export const SetShareUrlAction = ActionCreator<SetShareUrl>(SET_SHARE_URL);

export const SET_SHARE_NAME = "SET_SHARE_NAME";
export interface SetShareName extends Action {
    shareEvent: string;
    name: string;
}
export const SetShareNameAction = ActionCreator<SetShareName>(SET_SHARE_NAME);

export const SET_EMAIL_SENDER = "SET_EMAIL_SENDER";
export interface SetEmailSender extends Action {
    name: string;
    domain: string;
}
export const SetEmailSender = ActionCreator<SetEmailSender>(SET_EMAIL_SENDER);

export const SET_EMAIL_SUBJECT = "SET_EMAIL_SUBJECT";
export interface SetEmailSubject extends Action {
    event: string;
    content: lcidMappings;
}
export const SetEmailSubject = ActionCreator<SetEmailSubject>(SET_EMAIL_SUBJECT);

export const SET_MOBILE_CONTENT = "SET_MOBILE_CONTENT";
export interface SetMobileContent extends Action {
    event: string;
    content: lcidMappings;
}
export const SetMobileContent = ActionCreator<SetMobileContent>(SET_MOBILE_CONTENT);

export const SET_FLAGGING_NOTIFICATION_WAIT = "SET_FLAGGING_NOTIFICATION_WAIT";
export interface SetFlaggingNotificationWait extends Action {
    value: number;
}
export const SetFlaggingNotificationWait = ActionCreator<SetFlaggingNotificationWait>(SET_FLAGGING_NOTIFICATION_WAIT);

export const SET_MANDATORY_EXPIRY_TIME = "SET_MANDATORY_EXPIRY_TIME";
export interface SetMandatoryExpiryTime extends Action {
    days: number;
}
export const SetMandatoryExpiryTime = ActionCreator<SetMandatoryExpiryTime>(SET_MANDATORY_EXPIRY_TIME);

export const SET_EXTERNAL_POST_PURGE_IN_DAYS = "SET_EXTERNAL_POST_PURGE_IN_DAYS"
export interface SetExternalPostPurgeInDays extends Action {
    days: number;
}
export const SetExternalPostPurgeInDays = ActionCreator<SetExternalPostPurgeInDays>(SET_EXTERNAL_POST_PURGE_IN_DAYS);

export const TOGGLE_MANDATORY_EXPIRY_TIME = "TOGGLE_MANDATORY_EXPIRY_TIME";
export interface ToggleMandatoryExpiryTime extends Action {}
export const ToggleMandatoryExpiryTime = ActionCreator<ToggleMandatoryExpiryTime>(TOGGLE_MANDATORY_EXPIRY_TIME);

export const SET_SUPPORT_EMAIL = "SET_SUPPORT_EMAIL";
export interface SetSupportEmail extends Action {
    email: string;
}
export const SetSupportEmail = ActionCreator<SetSupportEmail>(SET_SUPPORT_EMAIL);

export const SET_SURVEYS_ENABLED = "SET_SURVEYS_ENABLED";
export interface SetSurveysEnabled extends Action {
    enabled: boolean;
}
export const SetSurveysEnabled = ActionCreator<SetSurveysEnabled>(SET_SURVEYS_ENABLED);

export const SET_CHAT_ENABLED = "SET_CHAT_ENABLED";
export interface SetChatEnabled extends Action {
    enabled: boolean;
}
export const SetChatEnabled = ActionCreator<SetChatEnabled>(SET_CHAT_ENABLED);

export const SET_DOCUMENTS_ENABLED = "SET_DOCUMENTS_ENABLED";
export interface SetDocumentsEnabled extends Action {
    enabled: boolean;
}
export const SetDocumentsEnabled = ActionCreator<SetDocumentsEnabled>(SET_DOCUMENTS_ENABLED);

export const SET_MOBILE_EVENTS_ENABLED = "SET_MOBILE_EVENTS_ENABLED";
export interface SetMobileEventsEnabled extends Action {
    enabled: boolean;
}
export const SetMobileEventsEnabled = ActionCreator<SetMobileEventsEnabled>(SET_MOBILE_EVENTS_ENABLED);

export const SET_RESTRICTED_BANNER_ENABLED = "SET_RESTRICTED_BANNER_ENABLED";
export interface SetRestrictedBannerEnabled extends Action {
    enabled: boolean;
}
export const SetRestrictedBannerEnabled = ActionCreator<SetRestrictedBannerEnabled>(SET_RESTRICTED_BANNER_ENABLED);

export const SET_MOBILE_PUBLISHING_ENABLED = "SET_MOBILE_PUBLISHING_ENABLED";
export interface SetMobilePublishingEnabled extends Action {
    enabled: boolean;
}
export const SetMobilePublishingEnabled = ActionCreator<SetMobilePublishingEnabled>(SET_MOBILE_PUBLISHING_ENABLED);

export const SET_PORTAL_PAGES_ENABLED = "SET_PORTAL_PAGES_ENABLED";
export interface SetPortalPagesEnabled extends Action {
    enabled: boolean;
}
export const SetPortalPagesEnabled = ActionCreator<SetPortalPagesEnabled>(SET_PORTAL_PAGES_ENABLED);

export const SET_LOCAL_PASSWORD_RESET_ENABLED = "SET_LOCAL_PASSWORD_RESET_ENABLED";
export interface SetResetLocalUserPasswordAction extends Action {
    enabled: boolean;
}
export const CreateSetResetLocalUserPasswordAction = ActionCreator<SetResetLocalUserPasswordAction>(SET_LOCAL_PASSWORD_RESET_ENABLED);

export const REMOVE_NEW_TAG = "REMOVE_NEW_TAG";
export interface RemoveNewTag extends Action {
    groupId: string;
}
export const RemoveNewTagAction = ActionCreator<RemoveNewTag>(REMOVE_NEW_TAG);

export const ADD_EXTERNAL_POST_SOURCE_INIT = "ADD_EXTERNAL_POST_SOURCE_INIT";
export interface AddExternalPostSourceInit extends Action {}
export const AddExternalPostSourceInitAction = ActionCreator<AddExternalPostSourceInit>(ADD_EXTERNAL_POST_SOURCE_INIT);

export const ADD_EXTERNAL_POST_SOURCE_COMPLETE = "ADD_EXTERNAL_POST_SOURCE_COMPLETE";
export interface AddExternalPostSourceComplete extends Action {
    source: ExternalPostSource;
    succeeded: boolean;
}
export const AddExternalPostSourceCompleteAction = ActionCreator<AddExternalPostSourceComplete>(ADD_EXTERNAL_POST_SOURCE_COMPLETE);

export const UPDATE_EXTERNAL_POST_SOURCE_INIT = "UPDATE_EXTERNAL_POST_SOURCE_INIT";
export interface UpdateExternalPostSourceInit extends Action {}
export const UpdateExternalPostSourceInitAction = ActionCreator<UpdateExternalPostSourceInit>(UPDATE_EXTERNAL_POST_SOURCE_INIT);

export const UPDATE_EXTERNAL_POST_SOURCE_COMPLETE = "UPDATE_EXTERNAL_POST_SOURCE_COMPLETE";
export interface UpdateExternalPostSourceComplete extends Action {
    source: ExternalPostSource;
    succeeded: boolean;
}
export const UpdateExternalPostSourceCompleteAction = ActionCreator<UpdateExternalPostSourceComplete>(UPDATE_EXTERNAL_POST_SOURCE_COMPLETE);

export const DELETE_EXTERNAL_POST_SOURCE_INIT = "DELETE_EXTERNAL_POST_SOURCE_INIT";
export interface DeleteExternalPostSourceInit extends Action {}
export const DeleteExternalPostSourceInitAction = ActionCreator<DeleteExternalPostSourceInit>(DELETE_EXTERNAL_POST_SOURCE_INIT);

export const DELETE_EXTERNAL_POST_SOURCE_COMPLETE = "DELETE_EXTERNAL_POST_SOURCE_COMPLETE";
export interface DeleteExternalPostSourceComplete extends Action {
    sourceId: string;
    succeeded: boolean;
}
export const DeleteExternalPostSourceCompleteAction = ActionCreator<DeleteExternalPostSourceComplete>(DELETE_EXTERNAL_POST_SOURCE_COMPLETE);

export const SET_EXTERNAL_POST_SOURCE_ENABLED = "SET_EXTERNAL_POST_SOURCE_ENABLED";
export interface SetExternalPostSourceEnabled extends Action {
    sourceId: string;
    enabled: boolean;
}
export const SetExternalPostSourceEnabledAction = ActionCreator<SetExternalPostSourceEnabled>(SET_EXTERNAL_POST_SOURCE_ENABLED);

export const TOGGLE_NOTIFICATION_EVENT_ACTION = "TOGGLE_NOTIFICATION_EVENT_ACTION";
export interface ToggleNotificationEventAction extends Action {
    teamsEvent: string
    mobileEvent: string
    emailEvent: string
    smsEvent: string
}
export const CreateToggleNotificationEventAction = ActionCreator<ToggleNotificationEventAction>(TOGGLE_NOTIFICATION_EVENT_ACTION);

export const SET_APPLY_BLACKOUT_PERIOD = "SET_APPLY_BLACKOUT_PERIOD";
export interface ApplyBlackoutPeriodAction extends Action {
    teamsEvent: string
    mobileEvent: string
    emailEvent: string
    smsEvent: string
    checked: boolean
}
export const CreateApplyBlackoutPeriodAction = ActionCreator<ApplyBlackoutPeriodAction>(SET_APPLY_BLACKOUT_PERIOD);

export const SET_ON_PUBLISH = "SET_ON_PUBLISH";
export interface SetOnPublishAction extends Action {
    location: string
    platform: string
    value: string
}
export const CreateSetOnPublishAction = ActionCreator<SetOnPublishAction>(SET_ON_PUBLISH)

export const SET_ALL_ON_PUBLISH = "SET_ALL_ON_PUBLISH";
export interface SetAllOnPublishAction extends Action {
    location: string
    value: string
}
export const CreateSetAllOnPublishAction = ActionCreator<SetAllOnPublishAction>(SET_ALL_ON_PUBLISH)

export const SET_REMINDERS = "SET_REMINDERS";
export interface SetRemindersAction extends Action {
    reminders: reminderData[]
    location: string
}
export const CreateSetRemindersAction = ActionCreator<SetRemindersAction>(SET_REMINDERS)

export const SET_ENABLE_ALL_CHANNELS = "SET_ENABLE_ALL_CHANNELS";
export interface EnableAllChannelsAction extends Action {
    teamsEvent: string;
    mobileEvent: string;
    emailEvent: string;
    smsEvent: string;
}
export const CreateEnableAllChannelsAction = ActionCreator<EnableAllChannelsAction>(SET_ENABLE_ALL_CHANNELS);

export const SET_ENABLE_SELECTED_CHANNELS = "SET_ENABLE_SELECTED_CHANNELS";
export interface EnableSelectedChannelsAction extends Action {
    notificationEvent: string
    checked: boolean
}
export const CreateEnableSelectedChannelsAction = ActionCreator<EnableSelectedChannelsAction>(SET_ENABLE_SELECTED_CHANNELS);

export const SET_APPLY_BLACKOUT_PERIOD_FOR_SMS = "SET_APPLY_BLACKOUT_PERIOD_FOR_SMS";
export interface ApplyBlackoutPeriodForSMSAction extends Action {
    notificationEvent: string
}
export const CreateApplyBlackoutPeriodForSMSAction = ActionCreator<ApplyBlackoutPeriodForSMSAction>(SET_APPLY_BLACKOUT_PERIOD_FOR_SMS);

export const SET_ENABLE_SEND_EMAIL_NOTIFICATION = "SET_ENABLE_SEND_EMAIL_NOTIFICATION";
export interface SendEmailNotificationAction extends Action {
    checked: boolean
    notificationEvent: string
}
export const CreateSendEmailNotificationAction = ActionCreator<SendEmailNotificationAction>(SET_ENABLE_SEND_EMAIL_NOTIFICATION);

export const SET_ENABLE_SEND_SMS_NOTIFICATION = "SET_ENABLE_SEND_SMS_NOTIFICATION";
export interface SendSMSNotificationAction extends Action {
    checked: boolean
    notificationEvent: string
}
export const CreateSendSMSNotificationAction = ActionCreator<SendSMSNotificationAction>(SET_ENABLE_SEND_SMS_NOTIFICATION);

export const SET_DEFAULT_BLACKOUT_FOR_EMAIL = "SET_DEFAULT_BLACKOUT_FOR_EMAIL";
export interface SetDefaultBlackoutForEmail extends Action {
    hours: number,
    days: boolean
}
export const CreateSetDefaultBlackoutForEmailAction = ActionCreator<SetDefaultBlackoutForEmail>(SET_DEFAULT_BLACKOUT_FOR_EMAIL);

export const SET_DEFAULT_BLACKOUT_FOR_SMS = "SET_DEFAULT_BLACKOUT_FOR_SMS";
export interface SetDefaultBlackoutForSMS extends Action {
    hours: number,
    days: boolean
}
export const CreateSetDefaultBlackoutForSMSAction = ActionCreator<SetDefaultBlackoutForSMS>(SET_DEFAULT_BLACKOUT_FOR_SMS);

export const SET_DEFAULT_BLACKOUT_FOR_TEAMS = "SET_DEFAULT_BLACKOUT_FOR_TEAMS";
export interface SetDefaultBlackoutForTeams extends Action {
    hours: number,
    days: boolean
}
export const CreateSetDefaultBlackoutForTeamsAction = ActionCreator<SetDefaultBlackoutForTeams>(SET_DEFAULT_BLACKOUT_FOR_TEAMS);

export const SET_DEFAULT_BLACKOUT_FOR_MOBILE = "SET_DEFAULT_BLACKOUT_FOR_MOBILE";
export interface SetDefaultBlackoutForMobile extends Action {
    hours: number,
    days: boolean
}
export const CreateSetDefaultBlackoutForMobileAction = ActionCreator<SetDefaultBlackoutForMobile>(SET_DEFAULT_BLACKOUT_FOR_MOBILE);

export const SET_LINK_PREFERENCE = "SET_LINK_PREFERENCE";
export interface SetLinkPreference extends Action {
    linkPreference: string
}
export const CreateSetLinkPreference = ActionCreator<SetLinkPreference>(SET_LINK_PREFERENCE);

export const SET_DEFAULT_REMINDER_FOR_POSTS = "SET_DEFAULT_REMINDER_FOR_POSTS";
export interface SetDefaultReminderForPosts extends Action {
    hours: number,
    days: boolean
}
export const CreateSetDefaultReminderForPostsAction = ActionCreator<SetDefaultReminderForPosts>(SET_DEFAULT_REMINDER_FOR_POSTS);

export const SET_DEFAULT_REMINDERS_FOR_EVENTS = "SET_DEFAULT_REMINDERS_FOR_EVENTS";
export interface SetDefaultReminderForEvents extends Action {
    hours: number
    days: boolean
}
export const CreateSetDefaultReminderForEventsAction = ActionCreator<SetDefaultReminderForEvents>(SET_DEFAULT_REMINDERS_FOR_EVENTS);

export const SET_CONTRIBUTORS_CAN_TOGGLE_COMMENTS_CHANGED = "SET_CONTRIBUTORS_CAN_TOGGLE_COMMENTS_CHANGED";
export const SET_CONTRIBUTORS_CAN_TOGGLE_REACTIONS_CHANGED = "SET_CONTRIBUTORS_CAN_TOGGLE_REACTIONS_CHANGED";
export interface CanContributorsToggle extends Action {
    enabled: boolean;
}
export const CreateCanContributorsToggleCommentsAction = ActionCreator<CanContributorsToggle>(SET_CONTRIBUTORS_CAN_TOGGLE_COMMENTS_CHANGED);

export const CreateCanContributorsToggleReactionsAction = ActionCreator<CanContributorsToggle>(SET_CONTRIBUTORS_CAN_TOGGLE_REACTIONS_CHANGED);
export const SET_POST_SMART_CONTENT_ENABLED_BY_TENANT = "SET_POST_SMART_CONTENT_ENABLED_BY_TENANT";
export interface SetPostSmartContentEnabledByTenant extends Action {
    enabled: boolean;
}
export const SetPostSmartContentEnabledByTenant = ActionCreator<SetPostSmartContentEnabledByTenant>(SET_POST_SMART_CONTENT_ENABLED_BY_TENANT);
