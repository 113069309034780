import { useEffect, useState } from "react";

import { PostAverages } from "modules/posts/models";
import { postsApi } from "api/instances";
import useIsMounted from "../useIsMounted";

export type UseTenantPostAveragesReturnType =  {
    postAverages: PostAverages | undefined;
}

export const useTenantPostAverages = (): UseTenantPostAveragesReturnType => {
    const [postAverages, setPostAverages] = useState<PostAverages | undefined>();
    const isMounted = useIsMounted();

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await postsApi.GetTenantPostAverages();

                if (isMounted())
                    setPostAverages(results);
            } catch {}
        }

        fetch();
    }, [isMounted]);

    return {
        postAverages
    };
}
