import React from "react";
import { Box, Table, TableBody, TableContainer } from "@mui/material";
import { DraftActivity } from "../models";
import DraftActivityLog from "./draftActivityLog";
import "./draftActivityLog.sass";

const NO_DRAFT_ACTIVITY = "No publishing history found";

export const DraftHistory: React.FunctionComponent<IDraftHistoryProps> = ({ activities }) => {
    return (
        <React.Fragment>
            {
                !activities || activities?.length === 0
                    ? <Box padding={"20px"}>
                        <i>{NO_DRAFT_ACTIVITY}</i>
                    </Box>
                    : <TableContainer className="draft-activity-log">
                        <Table size="small" style={{ maxHeight: "400px" }}>
                            <TableBody>
                                {activities.map((activity: DraftActivity, index: number) =>
                                    <DraftActivityLog
                                        key={activity.id}
                                        activity={activity}
                                        index={index} />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </React.Fragment>
    );
};

interface IDraftHistoryProps {
    activities: DraftActivity[];
}