import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { UserRoleStrings } from "modules/authorization/models";

import { IMenuItem, MenuItem, MenuItemType } from "../../common/components/MenuItem";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BuildIcon from "@mui/icons-material/Build";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PostAddIcon from '@mui/icons-material/PostAdd';
import { MenuUtils } from "routes/_layout/common/menuUtils";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            position: "static"
        },
        poweredBy: {
            color: "#aaaaaa",
            fontSize: 10,
            marginBottom: 10,
            marginLeft: 23,
            marginTop: 10,
            opacity: 0,
            "&.expanded": {
                opacity: 1,
                transition: "opacity linear 0.1s 0.2s"
            }
        }
    })
);

const MenuItems = (props: PropsWithRedux): IMenuItem[] => {
    let items: IMenuItem[] = [];

    if (props.portalPagesEnabled && !!props.navigation.navigationItems.length) {
        items = [
            { label: "Home", icon: <HomeOutlinedIcon />, type: MenuItemType.Link, url: "~/home" }
        ];

        if (props.portalPagesEnabled && props.navigation.navigationItems.length > 1) {
            items = items.concat([
                {
                    label: "Portal Pages", icon: <MenuBookIcon />, type: MenuItemType.Link, children: props.navigation.navigationItems.slice(1).map((navigationItem) => (
                        {
                            label: navigationItem.name, type: MenuItemType.Link, url: `~/page/${navigationItem.url}`, children: navigationItem.children.map((childNavigationItem) => (
                                { label: childNavigationItem.name, type: MenuItemType.Link, url: `~/page/${childNavigationItem.url}` }
                            ))
                        }
                    ))
                }
            ]);
        }

        items = items.concat([
            { label: "", type: MenuItemType.Divider },
            { label: "My Feed", icon: <LibraryBooksIcon />, type: MenuItemType.Link, url: "~/posts" }
        ]);
    } else {
        items = [
            { label: "My Feed", icon: <LibraryBooksIcon />, type: MenuItemType.Link, url: "~/home" }
        ];
    }

    items = items.concat([
        { label: "Events", icon: <EventOutlinedIcon />, type: MenuItemType.Link, url: "~/events", hide: !props.eventsEnabled },
        { label: "Documents", icon: <FolderOutlinedIcon />, type: MenuItemType.Link, url: "~/documents", hide: !props.documentsEnabled },
        { label: "", type: MenuItemType.Divider },
        { label: "My Profile", icon: <AccountCircleOutlinedIcon />, type: MenuItemType.Link, url: "~/me" },
        { label: "", type: MenuItemType.Divider },
        { label: "Go to Admin Portal", type: MenuItemType.Link, icon: <BuildIcon />, pushToBrowserHistory: true, url: "~/admin/dashboard", userRole: UserRoleStrings.AUTHOR },
        { label: "Manage My Content", type: MenuItemType.Link, icon: <PostAddIcon />, pushToBrowserHistory: true, url: "~/admin/posts", userRole: UserRoleStrings.CONTRIBUTOR, strictRole: true },
    ]);

    return items;
}

const SignInMenuItem = (): IMenuItem => {
    return { label: "Sign in", icon: <AccountCircleOutlinedIcon />, type: MenuItemType.Link, url: "~/" };
}

const Menu: React.FunctionComponent<PropsWithRedux> = props => {
    const [expandedMenuItem, setExpandedMenuItem] = useState("");
    const classes = useStyles();

    return (
        <Drawer
            open
            variant="permanent"
            PaperProps={{
                elevation: 5
            }}
            classes={{
                paper: props.drawerPaperClassName
            }}
        >
            <List className={classes.list}>
                {!props.publicAccess
                    ? MenuItems(props).map((menuItem, index) => {
                        switch (menuItem.type) {
                            case MenuItemType.Divider:
                                return <Divider key={index} light />;
                            case MenuItemType.Link:
                                const selected = new MenuUtils(props.tenant, props.locationPathName)
                                    .isLinkItemSelected(menuItem);
                                return (
                                    <MenuItem
                                        key={index}
                                        expand={props.expand}
                                        expandedMenuItem={expandedMenuItem}
                                        menuItem={menuItem}
                                        selected={selected}
                                        setExpand={props.setExpand}
                                        setExpandedMenuItem={setExpandedMenuItem}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })
                    : <MenuItem
                        key={0}
                        expand={props.expand}
                        expandedMenuItem={expandedMenuItem}
                        menuItem={SignInMenuItem()}
                        setExpand={props.setExpand}
                        setExpandedMenuItem={setExpandedMenuItem}
                        hideIcons={true}
                    />
                }
            </List>
            <div className={`${classes.poweredBy} ${props.expand ? "expanded" : ""}`}>
                <div>Powered by Sparrow Connected</div>
                <div>{process.env.REACT_APP_ADMINPORTAL_VERSION}</div>
            </div>
        </Drawer>
    );
}

interface ComponentProps {
    expand?: boolean;
    locationPathName: string;
    publicAccess?: boolean;
    setExpand: (expand: boolean) => void;
    tenant: string;
    drawerPaperClassName: string;
    hideIcons?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        documentsEnabled: state.settings.clientSettings.documentsEnabled,
        eventsEnabled: state.settings.clientSettings.mobileEventsEnabled,
        navigation: state.portalPages.navigation,
        portalPagesEnabled: state.settings.clientSettings.portalPagesEnabled,
        currentUser: state.settings.currentUser,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Menu);
