import React, { useRef, useState } from "react";

import { Button, Checkbox, Fab, FormControlLabel } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Callout from "modules/common/components/callout";
import MagicIcon from "../common/magicIcon";

interface ISmartContentPaperFabProps {
    onClick: () => void;
    fabStyle?: React.CSSProperties;
    fabClassName?: string;
    calloutOpen?: boolean;
    onClose?: (skipCallouts: boolean) => void;
    callout: boolean; // enable/disable showing callout at all
    indicator?: boolean;
    setCalloutOpen: (value: boolean) => void;
}

const SmartContentPaperFab: React.FunctionComponent<ISmartContentPaperFabProps> = ({
    onClick,
    fabStyle,
    fabClassName,
    calloutOpen,
    onClose,
    callout = true,
    indicator = false,
    setCalloutOpen
}) => {
    const anchorEl = useRef<HTMLButtonElement | null>(null);

    const [skipCallouts, setSkipCallouts] = useState<boolean>(!callout);

    return (
        <>
            <Fab
                ref={(button: HTMLButtonElement | null) => (anchorEl.current = button)}
                id="smart-post-authoring-fab"
                className={fabClassName}
                onClick={onClick}
                style={{
                    backgroundColor: "#3b78ab",
                    ...fabStyle,
                }}
            >
                <MagicIcon fill={"#ffffff"} fontSize={"30"} />
                {indicator && (
                    <FiberManualRecordIcon style={{ fontSize: 18, position: "absolute", bottom: 43, left: 35 }} htmlColor="#b72026" />
                )}
            </Fab>
            {callout && calloutOpen && (
                <Callout
                    contentClassName="smart-content-fab-callout"
                    arrowPosition="right"
                    anchorEl={anchorEl.current}
                    open={calloutOpen}
                    setOpen={setCalloutOpen}
                    popoverProps={{
                        anchorOrigin: {
                            vertical: 700,
                            horizontal: -200,
                        },
                    }}
                >
                    <p>Writer’s block? Get some ideas for a new post using our new Smart Content AI tool!</p>
                    <FormControlLabel
                        style={{ fontSize: 14 }}
                        classes={{
                            label: "font-14",
                        }}
                        label="Skip all Smart Content help popups"
                        control={
                            <Checkbox
                                onChange={(_, checked) => setSkipCallouts(checked)}
                                checked={skipCallouts}
                                size="small"
                                color="primary"
                            />
                        }
                    />
                    <Button
                        disableRipple
                        color="primary"
                        style={{
                            textDecoration: "underline",
                            fontSize: 14,
                        }}
                        variant="text"
                        onClick={() => {
                            if (onClose) onClose(skipCallouts);
                        }}
                    >
                        Close
                    </Button>
                </Callout>
            )}
        </>
    );
}

export { SmartContentPaperFab };
