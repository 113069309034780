import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import InputField from "./common/inputField";
import InputSwitchField from "./common/inputSwitchField";
import PhoneField from "./common/phoneField";

import Typography from "@mui/material/Typography";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";


class ProfileDetails extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            showVerifySuccessMessage: null,
            showVerifyErrorMessage: null,
            showPreferredSMSPhoneMessage: false,
            errorFirstName: false,
            errorLastName: false
        };
    }

    public render() {
        const { userSettings, localAccountPrefix } = this.props;

        const subdomain = window.location.host.split(".")[0];
        const localIdToDisplay = localAccountPrefix && subdomain.toLowerCase() === localAccountPrefix.toLowerCase()
            ? userSettings.localId.replace(subdomain, "")
            : userSettings.localId;

        return (
            <React.Fragment>
                <section>
                    <div className="section-heading">
                        <Typography variant="h2">Profile Details</Typography>
                    </div>
                    <InputField
                        label="First Name"
                        required={true}
                        helperText={this.state.errorFirstName ? "Enter first name" : ''}
                        value={userSettings.firstName}
                        error={this.state.errorFirstName}
                        disabled={!userSettings.isSocialUser && !userSettings.isSparrowLocalUser}
                        onChange={this.onChangeFirstName}
                        onCheckEmptyField={this.onCheckEmptyFirstName}
                    />
                    <InputSwitchField
                        label="Preferred Name"
                        value={userSettings.preferredName}
                        placeholder="Enter preferred name"
                        onChange={this.onChangeNickname}
                        onClear={this.onClearNickname}
                    />
                    <InputField
                        label="Last Name"
                        required={true}
                        helperText={this.state.errorLastName ? "Enter last name" : ''}
                        value={userSettings.lastName}
                        error={this.state.errorLastName}
                        disabled={!userSettings.isSocialUser && !userSettings.isSparrowLocalUser}
                        onChange={this.onChangeLastName}
                        onCheckEmptyField={this.onCheckEmptyLastName}
                    />
                    <InputField
                        description="Users with Work Accounts cannot change their email address."
                        label="Email"
                        value={userSettings.email}
                        disabled={true}
                        onChange={this.onChangeEmail}
                    />
                    <PhoneField
                        description="Verify and opt-in your new mobile number to receive SMS notifications and alerts."
                        label="Mobile No."
                        country={"us"}
                        enableSearch={true}
                        autoFormat={true}
                        disabledDropDown={userSettings.isWorkAccount}
                        disabled={userSettings.isWorkAccount}
                        placeholder="Enter mobile number"
                        value={userSettings.preferredSMSPhone}
                        onVerifySms={this.sendSMSVerifyMessage}
                        onChange={this.onChangePreferredSMSPhone}
                    />
                </section>
                {userSettings.isSparrowLocalUser &&
                    <section>
                        <InputField
                            disabled={true}
                            label={"User ID"}
                            value={localIdToDisplay}
                            description="Used as the username for this account."
                        />
                    </section>
                }
                <SuccessSnackbar successMessage={this.state.showVerifySuccessMessage || ""} clearSuccessMessage={() => this.setState({ showVerifySuccessMessage: null })} />
                <ErrorSnackbar errorMessage={this.state.showVerifyErrorMessage || ""} clearErrorMessage={() => this.setState({ showVerifyErrorMessage: null })} />
            </React.Fragment>
        );
    }

    private onChangeEmail = (event) => {
        this.props.saveUserSettings({ email: event.target.value });
    }

    private onChangeFirstName = (event) => {
        if(event.target.value !== "") {
            this.props.saveUserSettings({ firstName: event.target.value });
        } else {
            this.setState({errorFirstName: true})
        }
    }

    private onCheckEmptyFirstName = (event) => {
        if(!event.target.value) {
            this.setState({errorFirstName: true})
        } else {
            this.setState({errorFirstName: false})
        }
    }

    private onChangeLastName = (event) => {
        if(event.target.value) {
            this.props.saveUserSettings({ lastName: event.target.value });
        } else {
            this.setState({errorLastName: true})
        }
    }

    private onCheckEmptyLastName = (event) => {
        if(!event.target.value) {
            this.setState({errorLastName: true})
        } else {
            this.setState({errorLastName: false})
        }
    }

    private onChangeNickname = (event) => {
        this.props.saveUserSettings({ preferredName: event.target.value });
    }

    private onChangePreferredSMSPhone = (event) => {
        this.props.saveUserSettings({ preferredSMSPhone: event.target.value, preferredSMSConsent: null });
    }

    private onClearNickname = () => {
        this.props.saveUserSettings({ preferredName: "" });
    }
    private sendSMSVerifyMessage = () => {
        this.props.sendSMSVerify()
        .then((response) => {
            if(response === true) {
                this.setState({ showVerifySuccessMessage: "An SMS verification and opt-in invite was sent to your number." })
            } else {
                this.setState({ showVerifySuccessMessage: "Something went wrong. Please try again." })
            }
        })
    }
}


interface ComponentProps {
}

interface ComponentState {
    showVerifySuccessMessage: string | null;
    showVerifyErrorMessage: string | null;
    showPreferredSMSPhoneMessage: boolean;
    errorFirstName: boolean;
    errorLastName: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        userSettings: state.profile.userSettings!,
        syncLevel: state.settings.tenantSettings.syncConfig.syncLevel,
        localAccountPrefix: state.settings.tenantSettings.localAccountPrefix
    }),
    {
        saveUserSettings: actions.saveUserSettings,
        sendSMSVerify: actions.sendSMSVerifyRequest
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(ProfileDetails);
