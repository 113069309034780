import React from "react";
import { Button, Divider, Typography } from "@mui/material";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Bodyv2 from "modules/common/components/authoring/bodyv2";
import FileAttachments from "modules/common/components/authoring/fileAttachments";
import FilledInput from "modules/common/components/forms/inputs/filledInput";

import { DEFAULT_CONTENT_GUIDELINES, IContentGuidelines } from "modules/settings";
import { AttachedFile } from "modules/gallery";

import ContentGuidelinePreview from "./contentGuidelinePreview";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import "../../../../common/components/authoring/authoring.sass";
import "./contentGuidelines.sass";

interface IContentGuidelinesProps {
    isGuidelineEmpty: boolean;
    onChange: (newGuidelines: Partial<IContentGuidelines>) => void;
}

const TAB_HEADER = "Content Guidelines";
const DISCLAIMER_TEXT = "Provide brand or writing guidelines for employees when creating new content.";
const MAX_HEADER_LENGTH = 100;

const ContentGuidelines: React.FunctionComponent<IContentGuidelinesProps> = (props) => {
    const [showPreview, setShowPreview] = React.useState<boolean>(false);
    const { guidelines } = useSelector((state: GlobalApplicationState) => state.guidelines);

    const onChangeBody = (description: string) => props.onChange({ ...guidelines, description });

    const onChangeHeader = (event: React.FocusEvent<HTMLInputElement>) => {
        let newHeader = event.target.value as string;

        const originalHeader = guidelines?.header ?? DEFAULT_CONTENT_GUIDELINES.header;
        if (originalHeader !== newHeader) props.onChange({ ...guidelines, header: newHeader });
    };

    const onChangeAttachments = (attachments: AttachedFile[]) => props.onChange({ ...guidelines, attachments });

    return (
        <div className="content-guidelines content-settings-background">
            <div style={{ maxWidth: 787 }} className="authoring-page">
                <div className="content-guideline-settings-title">
                    <Typography variant="h2" alignContent={"center"}> {TAB_HEADER} </Typography>

                    <Button
                        className="content-guideline-button"
                        variant="text"
                        disabled={props.isGuidelineEmpty}
                        onClick={() => setShowPreview(true)}
                        startIcon={<VisibilityOutlinedIcon />}>
                        Preview
                    </Button>
                </div>
                
                <Typography className="content-guideline-subtext">{DISCLAIMER_TEXT}</Typography>

                <label>Header</label>
                <FilledInput
                    refreshable
                    label={""}
                    value={guidelines?.header || DEFAULT_CONTENT_GUIDELINES.header}
                    characterCount
                    maxCount={MAX_HEADER_LENGTH}
                    inputProps={{
                        style: { fontSize: "18px" },
                        placeholder: "Enter a header",
                        onBlur: onChangeHeader
                    }}
                />

                <Bodyv2
                    label="Description"
                    value={guidelines?.description || DEFAULT_CONTENT_GUIDELINES.description}
                    onChange={onChangeBody}
                />

                <Divider className="authoring-divider" />

                <FileAttachments
                    show
                    v2
                    fileAttachments={guidelines?.attachments || DEFAULT_CONTENT_GUIDELINES.attachments}
                    onChange={onChangeAttachments}
                    onRemove={() => onChangeAttachments([])}
                    footNoteStyle={{ visibility: "hidden" }}
                />
                {
                    guidelines &&
                    <ContentGuidelinePreview
                        showPreview={showPreview}
                        onClose={() => setShowPreview(false)}
                        isAuthoring={false}
                    />
                }
            </div>
        </div>
    );
};

export default ContentGuidelines;
