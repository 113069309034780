import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import HoverText from "modules/documents/components/action-buttons/hoverText";
import { PromptTypeEnum } from "./models";

interface ISmartContentSuggestedHeaderProps {
    title: string;
    fetching?: boolean;
    promptType?: PromptTypeEnum;
    usedTinymceDropDown?: boolean;
    onTrash?: () => void; // funciton/flag to enable clear action
    onGenerateMore?: (promptType: PromptTypeEnum) => Promise<void>; 
    onClose: () => void;
    onBack: () => void;
}

const SmartContentSuggestedHeader: React.FunctionComponent<ISmartContentSuggestedHeaderProps> = ({
    title,
    fetching,
    promptType,
    usedTinymceDropDown,
    onTrash,
    onGenerateMore,
    onClose,
    onBack,
}) => {
    return (
        <div
            style={{
                height: 65,
                minHeight: 65,
                maxHeight: 65,
                borderBottom: "solid 1px #dde1e5",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            {usedTinymceDropDown ? (
                <div style={{ paddingLeft: "2%" }}>
                    <span style={{ fontWeight: 500, color: "#333333" }}>{title}</span>
                </div>
            ) : (
                <div>
                    <HoverText
                        inline
                        label={
                            <IconButton id="smart-content-back-to-home" onClick={onBack} style={{ marginRight: 10 }}>
                                <ArrowBackIcon />
                            </IconButton>
                        }
                    >
                        Back to Smart Content menu
                    </HoverText>
                    <span style={{ fontWeight: 500, color: "#333333" }}>{title}</span>
                </div>
            )}
            <div>
                {!usedTinymceDropDown && (
                    <>
                        {onTrash && (
                            <HoverText
                                inline
                                label={
                                    <IconButton
                                        classes={{ disabled: "disabled" }}
                                        id="smart-content-trash-suggestions"
                                        disabled={fetching}
                                        onClick={onTrash}
                                    >
                                        <DeleteIcon htmlColor={fetching ? "#aaaaaa" : "#3b78ab"} />
                                    </IconButton>
                                }
                            >
                                Clear all suggestions
                            </HoverText>
                        )}
                        {onGenerateMore && promptType && (
                            <HoverText
                                inline
                                label={
                                    <IconButton
                                        id="smart-content-generate-more-icon"
                                        disabled={fetching}
                                        onClick={async () => await onGenerateMore(promptType)}
                                    >
                                        <AutorenewIcon htmlColor={fetching ? "#aaaaaa" : "#3b78ab"} />
                                    </IconButton>
                                }
                            >
                                Generate more
                            </HoverText>
                        )}
                    </>
                )}
                <IconButton id="smart-content-close-icon" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
    );
};

export { SmartContentSuggestedHeader };
