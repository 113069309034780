import React from "react";

import { Button } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { SmartContentSuggestedHeader } from "./smartContentSuggestedHeader";
import Disclaimer from "modules/common/components/disclaimer";
import Loading from "modules/common/components/loading";
import { IBaseSmartContentPageProps, PromptTypeEnum } from "./models";
import { POST_SMART_CONTENT_PAGES } from "modules/posts/components/post-creation/smartContent/postSmartContentPanel";
import { POST_SMART_CONTENT_TILE_BUTTON_IDX } from "modules/posts/components/post-creation/smartContent/postSmartContentHome";
import { SmartContentFooter } from "./smartContentFooter";

export interface ISmartContentListPageProps extends IBaseSmartContentPageProps {
    id?: string;
    contentId?: string;
    title: string;
    usedTinyMceDropDown?: boolean;
    fetching?: boolean;
    fetchType?: "full" | "more"; // controls where the spinner goes, more = at the end of list, full = list disappears
    disclaimerText?: string;
    enableGenerateMoreListButton?: boolean; // flag whether to add generate more at bottom of list
    contentStyle?: React.CSSProperties;
    promptType: PromptTypeEnum;
    onChangePage: (newPage: POST_SMART_CONTENT_PAGES, concatenate?: boolean, button?: POST_SMART_CONTENT_TILE_BUTTON_IDX) => void;
    onGenerateMore: (promptType: PromptTypeEnum) => Promise<void>;
    onTrash: () => void;
}

const SmartContentListPage: React.FunctionComponent<ISmartContentListPageProps> = ({
    id,
    contentId,
    fetching,
    title,
    children,
    enableGenerateMoreListButton = false,
    usedTinyMceDropDown,
    contentStyle,
    disclaimerText,
    promptType,
    fetchType = "more",
    onChangePage,
    onGenerateMore,
    onTrash,
    ...restProps
}) => {
    return (
        <>
            <div id={id} style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}>
                <SmartContentSuggestedHeader
                    {...restProps}
                    promptType={promptType}
                    title={title}
                    usedTinymceDropDown={usedTinyMceDropDown}
                    onGenerateMore={onGenerateMore}
                    fetching={fetching}
                    onTrash={onTrash}
                />
                <div id={contentId} className="smart-content-content">
                    <>
                        <div className="padding-horizontal" style={{ ...contentStyle }}>
                            {disclaimerText && <Disclaimer
                                containerStyle={{ marginBottom: 22, marginTop: 12 }}
                                icon={<InfoOutlinedIcon htmlColor="#e6911a" />}
                                text={disclaimerText}
                            />}
                            {fetching && fetchType === "full"
                                ? <Loading />
                                : <>{children}</>}
                            {enableGenerateMoreListButton &&
                                <div style={{ display: "flex", justifyContent: "center", marginBottom: 15, height: 70 }}>
                                    {fetching
                                        ? <>{fetchType === "more" && <Loading />}</>
                                        :
                                        <Button
                                            style={{ height: 45 }}
                                            color="primary"
                                            variant="text"
                                            id="smart-content-list-generate-more"
                                            onClick={async () => await onGenerateMore(promptType)}
                                        >
                                            GENERATE MORE
                                        </Button>
                                    }
                                </div>}
                        </div>
                    </>
                </div>
            </div>
            {!usedTinyMceDropDown &&
            <SmartContentFooter>
                <Button
                    id="smart-content-list-edit-keywords"
                    disabled={fetching}
                    type="link"
                    color="primary"
                    variant="text"
                    onClick={() => onChangePage(POST_SMART_CONTENT_PAGES.KEYWORDS)}
                    style={{
                        fontWeight: "normal",
                        textTransform: "none",
                        textDecoration: "underline"
                    }}
                    href=""
                >
                    Edit keywords
                </Button>
            </SmartContentFooter>
            }
        </>
    );
}

export { SmartContentListPage };
