import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link, LinkProps } from "react-router-dom";
import { GlobalApplicationState } from "globalApplicationState"

class TenantLink extends React.Component<PropsWithRedux, {}> {

    public shouldComponentUpdate(nextProps: PropsWithRedux) {
        return (nextProps.to !== this.props.to || nextProps.tenantId !== this.props.tenantId || nextProps.children !== this.props.children)
    }

    public render() {
        return (
            <Link to={{ pathname: this.addTenantToLink(this.props.to.toString()), state: this.props.pushToBrowserHistory && { prevPath: window.location.pathname } }}
                className={this.props.className}
                onClick={this.onClick}
                target={this.props.inNewTab ? "_blank" : undefined}
                rel={this.props.inNewTab ? "noopener noreferrer" : undefined}>
                {this.props.children}
            </Link>
        );
    }

    private addTenantToLink(to: string): string {
        if (!!this.props.tenantId)
            return to.replace("~/", "/" + this.props.tenantId + "/");
        else
            return to.replace("~/", "/");
    }

    private onClick = () => {
        this.props.onClick ? this.props.onClick() : (() => {})();
    }
}

interface ComponentProps {
    pushToBrowserHistory?: boolean;
    to: string;
    onClick?: () => any;
    inNewTab?: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: LinkProps & ComponentProps)=>({
        ...ownProps,
        tenantId: state.tenant.id
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(TenantLink);
