import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { GlobalApplicationState } from 'globalApplicationState';

import { draftPostsApi } from 'api/instances';
import { ContentType } from 'modules/common/models';
import { useRetryableRequest } from 'modules/common/hooks/useRetryableRequest';
import PublishedContentDialog from '../../../../common/components/authoring/dialogs/publishedContentDialog';

const MAX_RETRY_COUNT = 3;
const PublishedPostDialog: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {
    const fetchPostId = async () =>
        await draftPostsApi.getPublishedFromDraftId(props.draftId ?? "");

    const { response, isFailed } = useRetryableRequest<string>({
        asyncRequest: fetchPostId,
        maxRetry: MAX_RETRY_COUNT,
        requesting: !!props.draftId
    });

    return (
        <PublishedContentDialog
            content={ContentType.Post}
            expectedPublishTime={props.expectedPublishTime}
            containsVideoContent={props.publishedPostContainsVideoContent}
            contentId={response}
            hideFeedRedirect={isFailed}
        />
    );
};

interface ComponentProps {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        expectedPublishTime: state.posts.publishConfirmation.expectedPublishTime,
        publishedPostContainsVideoContent: state.posts.publishConfirmation.containsVideoContent,
        draftId: state.posts.publishConfirmation.publishedDraftId,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PublishedPostDialog);