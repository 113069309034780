import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Disclaimer from "modules/common/components/disclaimer";
import { ReadabilitySettings } from "./readabilitySettings";
import { IReadabilitySettings, IReadabilitySettingsOptionModel } from "../../../models";
import { useTenantPostAverages } from "modules/common/hooks/data/useTenantPostAverageScores";

interface IReadabilitySettingsFormProp {
    readabilityOptions?: IReadabilitySettingsOptionModel;
    readabilitySettings?: IReadabilitySettings;
    defaultReadabilitySettings?: IReadabilitySettings;
    onChangeReadabilitySettings: (newSettings: IReadabilitySettings) => void;
}

const ReadabilitySettingsForm: React.FunctionComponent<IReadabilitySettingsFormProp> = ({
    readabilityOptions,
    readabilitySettings,
    defaultReadabilitySettings,
    onChangeReadabilitySettings
}) => {
    const { postAverages }  = useTenantPostAverages();

    const getPostAveragesProps = (): { internalAverages: IReadabilitySettings, enableInternalAverages: boolean} | undefined => {
        if (!postAverages) return undefined;

        return {
            enableInternalAverages: true,
            internalAverages: {
                id: "",
                tone: postAverages.averageToneLabel,
                sentiment: postAverages.averageSentimentLabel,
                personalism: postAverages.averagePersonalismLabel,
                readability: postAverages.averageReadabilityLabel,
                gender: "",
                length: ""
            }
        };
    }

    return (
        <div className="content-settings-background">
            <div style={{ maxWidth: 787 }}>
                <p style={{ fontSize: 20, fontWeight: 500 }}>Default Readability Settings</p>
                <Disclaimer
                    containerStyle={{ marginBottom: 25, marginTop: 25 }}
                    icon={<InfoOutlinedIcon htmlColor="#e6911a" />}
                    text={<div>Set the default readability settings used in Smart Content when your employees create new content. Internal average scores listed below are taken from your organization’s most engaging posts.</div>}
                />
                {readabilityOptions && readabilitySettings &&
                    <ReadabilitySettings
                        defaults={defaultReadabilitySettings}
                        options={readabilityOptions}
                        selections={readabilitySettings}
                        onChange={onChangeReadabilitySettings}
                        {...getPostAveragesProps()}
                    />}
            </div>
        </div>
    );
}

export { ReadabilitySettingsForm };
