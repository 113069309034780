import React from "react";

import { IGPTChoice, PromptTypeEnum } from "modules/smartContent/models";
import { ISmartContentListPageProps, SmartContentListPage } from "modules/smartContent/smartContentListPage";
import { SmartContentSuggestion } from "modules/smartContent/smartContentSuggestion";

interface IPostSmartContentIdeasProps extends ISmartContentListPageProps {
    ideas: IGPTChoice[];
    onApplyIdea: (newIdea: IGPTChoice) => void;
}

const PostSmartContentIdeas: React.FunctionComponent<IPostSmartContentIdeasProps> = ({
    ideas,
    onApplyIdea,
    ...props
}) => {
    return (
        <SmartContentListPage
            {...props}
            promptType={PromptTypeEnum.Idea}
            title={"Suggested Ideas"}
            enableGenerateMoreListButton
            disclaimerText="Edit the keywords and enter more specific phrases or instructions to get results closer to your ideas."
        >
            {ideas.map((choice: IGPTChoice) =>
                <SmartContentSuggestion
                    key={`${choice.index}-${PromptTypeEnum.Idea}`}
                    onConfirm={() => onApplyIdea(choice)}
                    suggestion={{ suggestionId: choice.index.toString(), suggestion: choice.message.content }}
                    confirmLabel="GENERATE POST"
                    confirmPosition="block"
                />)}
        </SmartContentListPage>
    );
}

export { PostSmartContentIdeas };
