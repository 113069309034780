import React, { useState } from "react";
import Cookies, { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import numeral from "numeral";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import TablePagination from "@mui/material/TablePagination";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
/*
    Display component designed to showcase a lack of results.
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paging: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      position: "sticky",
      backgroundColor: "#ffffff",
      bottom: 0,
      padding: "5px 20px 0 20px"
    },
    paginationActions: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    caption: {
      fontWeight: 500
    },
    callout: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
      right: 24,
      borderRadius: 5,
      boxSizing: "border-box",
      padding: "5px 0"
    },
    calloutArrow: {
      position: "relative",
      height: 15,
      left: "calc(50% - 20px)",
      width: 30,
      overflow: "hidden",
      "&::after": {
        content: `""`,
        position: "absolute",
        width: 15,
        height: 15,
        backgroundColor: "#ffffff",
        transform: "rotate(45deg)",
        bottom: 8,
        right: 8,
        boxShadow: "0 0 4px #aaaaaa"
      }
    }
  })
);


const getRowsPerPage = (): number => {
  const rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);
  if (!!rowsPerPage) return +rowsPerPage;
  return 10;
}

const Paging: React.FunctionComponent = () => {
  const [rowsPerPage] = useState(getRowsPerPage());
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  
  return (
    <div className={classes.paging}>
      <Button
        variant="text"
        color="primary"
        endIcon={<ExpandMoreIcon />}
        onClick={(ev: any) => setAnchorEl(ev.currentTarget)}
        disabled
      >
        Show {rowsPerPage} rows
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible"
          }
        }}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <div className={classes.callout}>
        </div>
        <div className={classes.calloutArrow}></div>
      </Popover>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        backIconButtonProps={{ color: "primary" }}
        nextIconButtonProps={{ color: "primary" }}
        count={0}
        labelDisplayedRows={({ from, to, count }) => `${numeral(from).format("0,0")} - ${numeral(to).format("0,0")} of ${numeral(count).format("0,0")}`}
        rowsPerPage={rowsPerPage}
        page={0}
        onPageChange={() => {}} //Never should be able to click this.
        classes={{
          selectLabel: classes.caption,
          displayedRows: classes.caption
        }}
      />
    </div>
  );
};

export default Paging;