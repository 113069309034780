import React from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import RestoreIcon from '@mui/icons-material/Restore';

import HoverText from "modules/documents/components/action-buttons/hoverText";
import { DraftActivity } from "../models";
import { draftPostsApi as authoringApi } from "api/instances";
import { DraftHistory } from "./draftHistory";

const DRAFT_HISTORY_TOOLTIP = "Publishing and submission log";

const DraftHistoryButton: React.FunctionComponent<IDraftHistoryButtonProps> = (
    props
) => {
    const [activities, setActivities] = React.useState<DraftActivity[] | undefined>(undefined);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [initialOpen, setInitialOpen] = React.useState<boolean>(false);

    const iconButtonRef = React.createRef<HTMLButtonElement>();
    const open = Boolean(anchorEl);

    const {
        inToolbar = false,
        otherPopoutsClosed,
    } = props;

    const handleOpen = () => {
        if (iconButtonRef.current) setAnchorEl(iconButtonRef.current);
    };

    const handleClose = () => setAnchorEl(null);

    React.useEffect(() => {
        const fetchActivities = async () => {
            const draftId = getDraftId();
            const act = await authoringApi.getDraftActivity(draftId);
            setActivities(act);
        };

        if (activities === undefined) fetchActivities();
    }, [activities]);

    React.useEffect(() => {
        if ((activities?.length ?? 0) > 0 && iconButtonRef.current !== null && !initialOpen && otherPopoutsClosed) {
            setAnchorEl(iconButtonRef.current);
            setInitialOpen(true);
        }
    }, [initialOpen, activities, iconButtonRef, otherPopoutsClosed]);

    const getDraftId = () => {
        const url = window.location.href;
        return url.substring(url.lastIndexOf("/") + 1);
    };

    return <React.Fragment>
        <IconButton
            aria-label={DRAFT_HISTORY_TOOLTIP}
            onClick={handleOpen}
            ref={iconButtonRef}
            style={inToolbar ? {} : { height: "fit-content", padding: "unset", marginLeft: "15px" }}
        >
            <HoverText label={<RestoreIcon />} paperStyle={{ padding: "unset" }}>
                {<DraftHistory activities={activities ?? []} />}
            </HoverText>
        </IconButton>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <DraftHistory activities={activities ?? []} />
        </Popover>
    </React.Fragment>;
};

interface IDraftHistoryButtonProps {
    otherPopoutsClosed: boolean;
    inToolbar?: boolean;
}

export default DraftHistoryButton;