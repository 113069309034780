
import { UserRoleStrings } from "modules/authorization/models";
import { CurrentUserSettings } from "modules/settings";

export const getHighestRole = (currentUser: CurrentUserSettings): UserRoleStrings => {
    if (currentUser.roles.includes(UserRoleStrings.OWNER)) return UserRoleStrings.OWNER;
    else if (currentUser.roles.includes(UserRoleStrings.AUTHOR)) return UserRoleStrings.AUTHOR;
    else if (currentUser.roles.includes(UserRoleStrings.CONTRIBUTOR)) return UserRoleStrings.CONTRIBUTOR;
    else return UserRoleStrings.INTERNAL;
}
