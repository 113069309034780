export interface ISuggestion {
    suggestionId: string;
    suggestion: string;
}

export interface IBaseSmartContentPageProps {
    onClose: () => void;
    onBack: () => void;
}

export interface IGPTMessage {
    content: string;
}

export interface IGPTChoice {
    message: IGPTMessage;
    index: number;
    finish_reason: string;
    logprogs?: number;
}

export interface IGPTUsage {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
}

export enum PromptTypeEnum {
    Title = "title",
    Body = "body",
    Idea = "idea",
    Summarize = "summarize",
    Rewrite = "rewrite",
    Shorten = "shorten",
    Expand = "expand",
    Friendly = "friendly",
    Professional = "professional",
    Heartfelt = "heartfelt",
    Educational = "educational",
    Witty = "witty",
    GenderNeutral = "genderneutral",
}

export interface IGenerateContentRequest {
    sessionId?: string;
    contentType: "post" | "event";
    promptType: PromptTypeEnum;
    numberOfContentToGenerate?: number;
    readability?: string;
    tone?: string;
    personalism?: string;
    sentiment?: string;
    length?: string;
    text?: string;
}

export interface IGenerateContentResponse {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: IGPTChoice[];
    usage: IGPTUsage;
    error?: string;
    responseStatusCode: number;
    isSuccessStatusCode: boolean;
    responseReasonPhrase: string;
    sessionId: string;
}
