import React from "react";
import { Link } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import RequireRole from "../../requireRole";
import { UserRoleStrings } from "modules/authorization/models";
import Disclaimer from "../../disclaimer";
import { ContentType } from "modules/common/models";

interface ISubmissionDisclaimer {
    show: boolean;
    contentType: ContentType
}

export const SubmissionDisclaimer: React.FunctionComponent<ISubmissionDisclaimer> = (props) => {

    return (
        <RequireRole roleName={UserRoleStrings.CONTRIBUTOR} strictRole>
            {
                props.show &&
                <Disclaimer
                    containerStyle={{ marginTop: "20px", marginBottom: "20px" }}
                    icon={<InfoOutlinedIcon />}
                    text={
                        <span>
                            A submission manager or administrator will review, approve and publish this {ContentType[props.contentType].toLowerCase()} for you.&nbsp;
                            <Link
                                href="https://support.sparrowconnected.com/en/understanding-roles-and-permissions"
                                underline="always"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Learn more
                            </Link>.
                        </span>
                    }
                    backgroundColor="#ffe9af"
                />
            }
        </RequireRole>
    );
};